.move-1{
	-webkit-animation: move1 5s infinite;
	animation: move1 5s infinite; 
}
.move-2{
	-webkit-animation: move2 5s infinite;
	animation: move2 5s infinite;
}
.move-3{
	-webkit-animation: move3 5s infinite;
	animation: move3 5s infinite;
}
@-webkit-keyframes move1 {
  0% {
	-webkit-transform: rotate(-1deg) translate(-2px, -2px);
	transform: rotate(-1deg) translate(-2px, -2px); 
	}
  50% {
	-webkit-transform: rotate(1deg) translate(2px, 2px);
	transform: rotate(1deg) translate(2px, 2px); 
	}
  100% {
	-webkit-transform: rotate(-1deg) translate(-2px, -2px);
	transform: rotate(-1deg) translate(-2px, -2px); 
		} 
}
@keyframes move1 {
  0% {
	-webkit-transform: rotate(-1deg) translate(-2px, -2px);
	transform: rotate(-1deg) translate(-2px, -2px);
	}
  50% {
	-webkit-transform: rotate(1deg) translate(2px, 2px);
	transform: rotate(1deg) translate(2px, 2px); }
  100% {
	-webkit-transform: rotate(-1deg) translate(-2px, -2px);
	transform: rotate(-1deg) translate(-2px, -2px); } 
}

@-webkit-keyframes move2 {
  0% {
	-webkit-transform: rotate(1deg) translate(2px, 2px);
	transform: rotate(1deg) translate(2px, 2px); 
	}
  50% {
	-webkit-transform: rotate(-1deg) translate(-2px, -2px);
	transform: rotate(-1deg) translate(-2px, -2px); 
	}
  100% {
	-webkit-transform: rotate(1deg) translate(2px, 2px);
	transform: rotate(1deg) translate(2px, 2px); 
		} 
}
@-webkit-keyframes move3 {
	0% {
		transform: translate(0, 0);
	}
	20% {
		transform: translate(5px, 0);
	}
	40% {
		transform: translate(5px, 5px);
	}
	65% {
		transform: translate(0, 5px);
	}
	65% {
		transform: translate(5px, 0);
	}
	100% {
		transform: translate(0, 0);
	} 
}