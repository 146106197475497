[dir='rtl']{
	
	// Blog Details
	.comments-area{
		.comment-list>.comment .comment-body{
			padding: 0 110px 0 0;
			
			.comment-author{
				.avatar {
					left: auto;
					right: 0;
				}
			}
			.reply .comment-reply-link {
				right: auto;
				left: 0;
				i {
					margin-right: 0;
					font-size: 14px;
					margin-left: 8px;
				}
			}
			@include respond('phone-land'){
				padding: 0 75px 0 0;				
			}
		}
		.comment-list>.comment>.children{
			padding-left: 0;
			padding-right: 50px;
		}
	}

	.dz-share-post ul li {
		margin-right: 0;
		margin-left: 5px;
	}
	.post-tags strong {
		margin-right: 0;
		margin-left: 10px;
	}
	.dz-page-text ul>li:before, 
	.dz-page-text ol>li:before, 
	.dz-card.blog-single .dz-post-text ul>li:before, 
	.dz-card.blog-single .dz-post-text ol>li:before {
		left: auto;
		right: -0.938rem;
	}
	.dz-page-text>ol, 
	.dz-page-text>ul, 
	.dz-card.blog-single 
	.dz-post-text>ol, 
	.dz-card.blog-single .dz-post-text>ul, 
	.dz-card.blog-single .dz-post-text.text>ol, 
	.dz-card.blog-single .dz-post-text.text>ul {
		padding-left: 0;
		padding-right: 1.25rem;
	}
	.wp-block-quote.is-large cite:before, 
	.wp-block-quote.is-style-large cite:before, 
	blockquote.wp-block-quote cite:before, 
	blockquote.wp-block-pullquote cite:before, 
	blockquote.wp-block-pullquote.alignright cite:before, 
	blockquote.wp-block-pullquote.alignleft cite:before, 
	blockquote cite:before {
		margin-left: 0.625rem;
		margin-right: 0;
	}
	
	// BLOCKQUOTE
	.wp-block-quote.is-large, 
	.wp-block-quote.is-style-large,
	blockquote.wp-block-quote,
	blockquote.wp-block-pullquote,
	blockquote.wp-block-pullquote.alignright, 
	blockquote.wp-block-pullquote.alignleft,
	blockquote {
		padding: 1.25rem 3rem 1.25rem 2rem;
		@include respond('tab-port'){
			padding: 1.25rem 1.25rem 0.938rem 1.25rem;
		}
		cite{
			&:before{
				margin-right: 0;
				margin-left: 0.625rem;
			}
		}
		&:after{
			left: auto;
			right: 0;
		}
		&:before {
			left: auto;
			right: 20px;
		}
		&.wp-block-pullquote.alignleft{
			margin: 0rem 0rem 1.563rem 1.563rem;
		}
		&.wp-block-pullquote.alignright{
			margin: 0rem 1.563rem 1.563rem 0;
		}
		&.style-1{
			&:before {
				right: auto;
				left: 70px;
			}
		}
		&.style-2{
			padding: 10px 25px 10px 0;
		}
		&.style-3{
			&:before{
				left:auto;
				right:0;
			}
		}
		&.style-4{
			&:before {
				left: auto;
				right: 30%;
			}
		}
	}

	.widget_tag_cloud .tagcloud a {
		margin: 0 0 15px 15px;
		@include respond('phone'){
			margin: 0 0 10px 10px;
		}
	}
	.dz-page-text>.wp-block-gallery, 
	.dz-card.blog-single .dz-post-text>.wp-block-gallery, 
	.dz-card.blog-single .dz-post-text.text>.wp-block-gallery{
		padding: 0;
	}
	.search-bx {
		button {
			border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
		}
	}
	.search-bx .form-control, 
	.search-bx .wp-block-categories-dropdown select, 
	.wp-block-categories-dropdown .search-bx select, 
	.search-bx .wp-block-archives-dropdown select, 
	.wp-block-archives-dropdown .search-bx select, 
	.search-bx .comment-respond .comment-form p.comment-form-author input, 
	.comment-respond .comment-form p.comment-form-author .search-bx input, 
	.search-bx .comment-respond .comment-form p.comment-form-email input, 
	.comment-respond .comment-form p.comment-form-email .search-bx input, 
	.search-bx .comment-respond .comment-form p.comment-form-url input, 
	.comment-respond .comment-form p.comment-form-url .search-bx input, 
	.search-bx .comment-respond .comment-form p textarea, 
	.comment-respond .comment-form p .search-bx textarea {
		border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0!important;
	}
    
    // Blog Card
	.dz-meta{
		ul{
			li{
				margin-left:20px;
				margin-right:0;
				&:last-child{
					margin-left:0;
				}
				i{
					margin-left: 2px;
					margin-right: 0;
				}
			}	
		}	
	}
	.post-video .post-video-icon {
		padding-left: 0;
		padding-right: 5px;
		
		&:before,
		&:after{
			left: auto;
			right: 0;
		}
	}
    
    //Blog Post
    //blog post:-1
    
    .dz-card{
        &.style-1{
            .dz-media + .dz-info .dz-meta{
                left: auto;
                right: 30px;
                @include respond ('phone'){
                    left: auto;				
                    right: 15px;				
                }
            }
            .dz-meta{	
                ul li {
                    margin-right: 0;
                    margin-left: 10px;
                    @include respond ('phone'){
                        margin-right:0;					
                        margin-left: 5px;					
                    }
                }
            }
        }
    }
    
    //blog post:-2
    
    .dz-card{
        &.style-2{
            .dz-meta{
                ul li {
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
            &.blog-half{
                .dz-info{
                    padding: 0 0 0 70px;
                }
                .dz-media + .dz-info{
                    padding: 0 70px 0 0;
                }
                @include respond ('tab-land'){
                    .dz-info{
                        padding: 0 0 0 30px;
                    }
                    .dz-media + .dz-info{
                        padding: 0 30px 0 0;
                    }
                }
                @include respond ('tab-port'){
                    .dz-info{
                        padding: 0 0 0 30px;
                    }
                }
            }
        }
    }
    
    //blog post:-3
    
    .dz-card{
        &.style-3{
            .dz-meta{
                left: auto;
                right: 10px;
                ul li{
                    margin-right: 0;
                    margin-left: 10px;
                    i {
                        margin-right: 0;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    
    //Blog-deafult:-
    
    .dz-meta{
        ul{
            li{
                margin-right:0;
                margin-left:20px;
                &:last-child{
                    margin-left:0;
                }
                i{
                    margin-right: 0;
                    margin-left: 2px;
                }
            }	
        }	
    }
    .post-video .post-video-icon {
        padding-left: 0;
        padding-right: 5px;
        &:before{
            left: auto;
            right: 0;
        }
        &:after{
            left: auto;
            right: 0;
        }
    }
    
    
    //blog detail
    .author-box .author-profile-info .author-profile-content{
        padding-left: 0;    
        padding-right: 25px;    
    }
    
    //blog single
    .blog-single {       
        .dz-social-icon{
            .title{
                margin-right:0;
                margin-left: 18px;
            }
        }
    }
    .comment-respond .comment-form p{
        width: 100%;
    }
    
    
    /////////////////////////////
    .post-link-in {
        left: auto;
        right: 0;
    }
    
    .side-bar {
        padding-left: 0;
        padding-right: 20px;
        @include respond('tab-port'){
             padding-left: 0;
             padding-right: 0;
        }
        &.left{
            padding-left: 20px;
            padding-right: 0;
            @include respond('tab-port'){
                 padding-right: 0;
                 padding-left: 0;
            }
        }
    }
    
    // > ALIGN RIGHT
    .alignright {
        float: left;
        &img,
        &.wp-caption,
        &.is-resized{
            float: left;
        }
    }
    // > ALIGN LEFT
    .alignleft{
        float: right;
        margin: 0.3125rem 0 1.563rem 1.563rem;
        &img,
        &.wp-caption,
        &.is-resized{
            margin: 0.3125rem 0 1.563rem 1.563rem;
            float: right;
        }
    }
    
    .wp-caption {
        p.wp-caption-text {
            text-align: right;
        }
    }
    
    // BLOCKQUOTE 
    .wp-block-quote.is-large, 
    .wp-block-quote.is-style-large,
    blockquote.wp-block-quote,
    blockquote.wp-block-pullquote,
    blockquote.wp-block-pullquote.alignright, 
    blockquote.wp-block-pullquote.alignleft,
    blockquote {
        padding: 1.25rem 3rem 1.25rem 2rem;
        cite{
            &:before{
                margin-right: 0;
                margin-left: 0.625rem;
            }
        }
        &:after{
            left: auto;
            right: 0;
        }
        
        &:before {
            left: auto;
            right: 20px;
        }
        &.wp-block-pullquote.alignleft{
            margin: 0rem 0 1.563rem 1.563rem;
        }
        &.wp-block-pullquote.alignright{
            margin: 0rem 1.563rem 1.563rem 0;
        }
        
        &.style-1{
            &:before{
                left: auto;
                right: 20px;
            }
        }
        
    }
    
    .dz-page-text > ol,
    .dz-page-text > ul,
    .dz-card.blog-single .dz-post-text > ol,
    .dz-card.blog-single .dz-post-text > ul,
    .dz-card.blog-single .dz-post-text.text > ol,
    .dz-card.blog-single .dz-post-text.text > ul {
        padding-left: 0;
        padding-right: 1.25rem;
        ol,
        ul{
            padding-left:0;
            padding-right:1.563rem;
        }
    }
    .wp-block-gallery .blocks-gallery-image, 
    .wp-block-gallery .blocks-gallery-item{
        margin: 0 0 0.5rem 0.5rem;
    }
    .wp-block-gallery.columns-4 .blocks-gallery-image, 
    .wp-block-gallery.columns-4 .blocks-gallery-item,
    .wp-block-gallery.columns-3 .blocks-gallery-image, 
    .wp-block-gallery.columns-3 .blocks-gallery-item{
        margin-right: 0;
        margin-left: 0.5rem;
    }
    .alignfull,
    .alignfullwide{
        margin-left: 0;
        margin-right: calc(50% - 50vw - (var(--content-share-ml,0px)/2));
    }
    .wp-block-quote.has-text-align-right{
        border-left:0;
    }
    .dz-page-text ul > li:before,
    .dz-page-text ol > li:before,
    .dz-card.blog-single .dz-post-text ul > li:before,
    .dz-card.blog-single .dz-post-text ol > li:before{
        left: auto;
        right: -0.938rem;
    }

    .wp-block-gallery .blocks-gallery-image,
    .wp-block-gallery .blocks-gallery-item {
        margin: 0 0 1rem 1rem;
    }
    
    .wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
    .wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
        margin-left: 0
    }

    .wp-block-gallery.columns-1 .blocks-gallery-image,
    .wp-block-gallery.columns-1 .blocks-gallery-item {
        margin-left: 0;
    }
    @media (min-width:37.5rem) {
        .wp-block-gallery.columns-3 .blocks-gallery-image,
        .wp-block-gallery.columns-3 .blocks-gallery-item {
            margin-right: 0;
            margin-left: 1rem;
            width: calc(33.33333% - 0.666rem)
        }
        .wp-block-gallery.columns-4 .blocks-gallery-image,
        .wp-block-gallery.columns-4 .blocks-gallery-item {
            margin-right: 0;
            margin-left: 1rem;
        }
        .wp-block-gallery.columns-5 .blocks-gallery-image,
        .wp-block-gallery.columns-5 .blocks-gallery-item {
            margin-right: 0;
            margin-left: 1rem;
        }
        .wp-block-gallery.columns-6 .blocks-gallery-image,
        .wp-block-gallery.columns-6 .blocks-gallery-item {
            margin-right: 0;
            margin-left: 1rem;
        }
        .wp-block-gallery.columns-7 .blocks-gallery-image,
        .wp-block-gallery.columns-7 .blocks-gallery-item {
            margin-right: 0;
            margin-left: 1rem;
        }
        .wp-block-gallery.columns-8 .blocks-gallery-image,
        .wp-block-gallery.columns-8 .blocks-gallery-item {
            margin-right: 0;
            margin-left: 1rem;
        }
        .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
        .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
        .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
        .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
        .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
        .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
        .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
        .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
        .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
        .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
        .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
        .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
        .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
        .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
        .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
        .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
            margin-left: 0
        }
    }
    
    .wp-block-gallery .blocks-gallery-image:last-child,
    .wp-block-gallery .blocks-gallery-item:last-child {
        margin-left: 0
    }
    
    
    .blog-overlap {
        border-radius: 0.25rem 0 0rem 0.25rem;
    }
    
    // Comments Avatar
    .wp-block-latest-comments {
        .wp-block-latest-comments__comment {
            .wp-block-latest-comments__comment-excerpt {
                margin-left: 0;
                margin-right: 4.375rem;
            }
        }
        img {
            + {
                article {
                    .wp-block-latest-comments__comment {
                        .wp-block-latest-comments__comment-meta {
                            margin-left: 0;
                            margin-right: 4.375rem;
                        }
                    }
                }
            }
        }
    }
    
    
    // POST FOOTER
    .post-footer {
        .dz-meta {
            .tag-list {
                text-align: right;	
            }
            ul li {
                margin-right: 0;
                margin-left: 0.3125rem;
            }    
        }
    }
    
    // comments-area
    .comments-area{
        .comment-list{
            &>.comment{
                .comment-body{
                    padding: 0 115px 25px 0;                    
                    @include respond('phone-land'){
                        padding: 0 75px 25px 0;
                    }
                    @include respond('phone'){
                        padding: 0 75px 20px 0;
                    }
                    .comment-author{
                        .avatar{
                            left: auto;
                            right: 0;
                        }
                    }
                    .reply{
                        .comment-reply-link{
                            right: auto;        
                            left: 0;        	
                            i{
                                margin-right: 0;
                                margin-left: 8px;
                            }
                        }
                    }
                }
                &>.children{
                    padding-left: 0;
                    padding-right: 50px;
                }
            }
        }
    }
    .comment-respond{
        small{
            margin-left: 0;
            margin-right: 7px;
        }
    }
    .dz-share-post{
        @include respond ('phone'){
            .post-tags{
                a{
                    margin-right:0;
                    margin-left:5px;
                }
            }
        }
    }


    .post-swiper{
        .prev-post-swiper-btn{
            left:auto;
            right:10px;
        }
        .next-post-swiper-btn{
            right:auto;
            left:10px;
        }
    }

    // Author Box
    .author-box{
        .author-profile-info{
            .author-profile-content{
                padding-left:0;
                padding-right:25px;
                @include respond('phone'){
                    padding-left:0;
                    padding-right:0;
                }
                ul{
                    float: right;
                    
                    @include respond('phone'){
                        margin-left: 0;
                        float: none;
                    }
                    li{
                        margin-right:0;
                        margin-left:10px;
                        float: right;
                        @include respond('phone'){
                            margin-right:5px;
                            margin-left:5px;
                        }
                    }
                }
            }
        }
    }
	
	@include respond('phone'){
		.blog-single.dz-card .post-tags {
			margin-left: 0;
			margin-right: 0;
		}
	}

}