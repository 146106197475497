@charset "UTF-8";
/*
    Name: Indiro 
    Author: DexignZone
    Portfolio : https://themeforest.net/user/dexignzone/portfolio
   
    Table of Content: 

    1. Abstract
    2. Bootstrap CSS
    3. Base
    4. Layout
    5. Component
    7. Pages
    
*/
@import "https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700;900&display=swap";
@import "https://cdn.rawgit.com/alpertuna/react-metismenu/master/dist/react-metismenu-standart.min.css";
@import url("./../vendor/animate/animate.min.css");
@import url("../icons/line-awesome/css/line-awesome.min.css");
@import url("../icons/font-awesome/css/all.min.css");
@import url("../icons/flaticon/flaticon.css");
@import url("../icons/themify/themify-icons.css");
@import url("../icons/iconfont/material-icons.css");
:root {
  --gradient: linear-gradient( to right, rgb(255,64,62) 0%, rgb(255,129,27) 100%);
  --gradient-sec: linear-gradient( to right, rgb(57,26,166) 0%, rgb(6,163,245) 100%);
  --primary: #ff5317;
  --secondary: #000;
  --primary-hover: #e33b00;
  --primary-dark: #7d2000;
  --rgba-primary-1: rgba(255, 83, 23, 0.1);
  --rgba-primary-2: rgba(255, 83, 23, 0.2);
  --rgba-primary-3: rgba(255, 83, 23, 0.3);
  --rgba-primary-4: rgba(255, 83, 23, 0.4);
  --rgba-primary-5: rgba(255, 83, 23, 0.5);
  --rgba-primary-6: rgba(255, 83, 23, 0.6);
  --rgba-primary-7: rgba(255, 83, 23, 0.7);
  --rgba-primary-8: rgba(255, 83, 23, 0.8);
  --rgba-primary-9: rgba(255, 83, 23, 0.9);
  --font-family-base: Roboto, sans-serif;
  --border-radius-base: 0;
  --font-family-title: Oswald, sans-serif;
  --title: #212529; }

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #ff5317;
  --bs-secondary: #192436;
  --bs-success: #10ca93;
  --bs-info: #00afef;
  --bs-warning: #ff9f00;
  --bs-danger: #ff5269;
  --bs-light: #faf8f2;
  --bs-dark: #181c32;
  --bs-primary-rgb: 255, 83, 23;
  --bs-secondary-rgb: 25, 36, 54;
  --bs-success-rgb: 16, 202, 147;
  --bs-info-rgb: 0, 175, 239;
  --bs-warning-rgb: 255, 159, 0;
  --bs-danger-rgb: 255, 82, 105;
  --bs-light-rgb: 250, 248, 242;
  --bs-dark-rgb: 24, 28, 50;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 85, 85, 85;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Roboto, sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6;
  --bs-body-color: #555;
  --bs-body-bg: #ffffff; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, .comment-reply-title,
.wp-block-search .wp-block-search__label, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #212529; }

h1, .h1 {
  font-size: calc(1.3875rem + 1.65vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.625rem; } }

h2, .h2 {
  font-size: calc(1.34375rem + 1.125vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2.1875rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4, .comment-reply-title,
.wp-block-search .wp-block-search__label {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4, .comment-reply-title,
    .wp-block-search .wp-block-search__label {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #ff5317;
  text-decoration: underline; }
  a:hover {
    color: #cc4212; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.75rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #AEAED5;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1199px; } }

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #555;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #555;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #555;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #555;
  vertical-align: top;
  border-color: #E1E1F0; }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:first-child) {
    border-top: 2px solid currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #ffddd1;
  --bs-table-striped-bg: #f2d2c7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6c7bc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecccc1;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6c7bc; }

.table-secondary {
  --bs-table-bg: #d1d3d7;
  --bs-table-striped-bg: #c7c8cc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcbec2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1c3c7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcbec2; }

.table-success {
  --bs-table-bg: #cff4e9;
  --bs-table-striped-bg: #c5e8dd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badcd2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2d8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badcd2; }

.table-info {
  --bs-table-bg: #cceffc;
  --bs-table-striped-bg: #c2e3ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d7e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bddde9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8d7e3; }

.table-warning {
  --bs-table-bg: #ffeccc;
  --bs-table-striped-bg: #f2e0c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d4b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdabd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d4b8; }

.table-danger {
  --bs-table-bg: #ffdce1;
  --bs-table-striped-bg: #f2d1d6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6c6cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecccd0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6c6cb; }

.table-light {
  --bs-table-bg: #faf8f2;
  --bs-table-striped-bg: #eeece6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1dfda;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7e5e0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e1dfda; }

.table-dark {
  --bs-table-bg: #181c32;
  --bs-table-striped-bg: #24273c;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #2f3347;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #292d41;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #2f3347; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #AEAED5; }

.form-control, .wp-block-categories-dropdown select,
.wp-block-archives-dropdown select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #555;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .wp-block-categories-dropdown select,
    .wp-block-archives-dropdown select {
      transition: none; } }
  .form-control[type="file"], .wp-block-categories-dropdown select[type="file"],
  .wp-block-archives-dropdown select[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]), .wp-block-categories-dropdown select[type="file"]:not(:disabled):not([readonly]),
    .wp-block-archives-dropdown select[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus, .wp-block-categories-dropdown select:focus,
  .wp-block-archives-dropdown select:focus {
    color: #555;
    background-color: #ffffff;
    border-color: #ffa98b;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(255, 83, 23, 0.25); }
  .form-control::-webkit-date-and-time-value, .wp-block-categories-dropdown select::-webkit-date-and-time-value,
  .wp-block-archives-dropdown select::-webkit-date-and-time-value {
    height: 1.6em; }
  .form-control::placeholder, .wp-block-categories-dropdown select::placeholder,
  .wp-block-archives-dropdown select::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .wp-block-categories-dropdown select:disabled,
  .wp-block-archives-dropdown select:disabled, .form-control[readonly], .wp-block-categories-dropdown select[readonly],
  .wp-block-archives-dropdown select[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button, .wp-block-categories-dropdown select::file-selector-button,
  .wp-block-archives-dropdown select::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #555;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button, .wp-block-categories-dropdown select::file-selector-button,
      .wp-block-archives-dropdown select::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button, .wp-block-categories-dropdown select:hover:not(:disabled):not([readonly])::file-selector-button,
  .wp-block-archives-dropdown select:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .form-control::-webkit-file-upload-button, .wp-block-categories-dropdown select::-webkit-file-upload-button,
  .wp-block-archives-dropdown select::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #555;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button, .wp-block-categories-dropdown select::-webkit-file-upload-button,
      .wp-block-archives-dropdown select::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button, .wp-block-categories-dropdown select:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
  .wp-block-archives-dropdown select:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: #555;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.6em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.6em;
    border-radius: 0.25rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.6em;
    border-radius: 0.25rem; }

.form-select, select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #555;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select, select {
      transition: none; } }
  .form-select:focus, select:focus {
    border-color: #ffa98b;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(255, 83, 23, 0.25); }
  .form-select[multiple], select[multiple], .form-select[size]:not([size="1"]), select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled, select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring, select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #555; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 1rem; }

.form-check {
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.3em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #ffa98b;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(255, 83, 23, 0.25); }
  .form-check-input:checked {
    background-color: #ff5317;
    border-color: #ff5317; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #ff5317;
    border-color: #ff5317;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffa98b'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check[disabled] + .wp-block-button__link, .btn-check:disabled + .btn, .btn-check:disabled + .wp-block-button__link {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(255, 83, 23, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(255, 83, 23, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #ff5317;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #ffcbb9; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #ff5317;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #ffcbb9; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control, .wp-block-categories-dropdown .form-floating > select,
  .wp-block-archives-dropdown .form-floating > select,
  .form-floating > .form-select,
  .form-floating > select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control, .wp-block-categories-dropdown .form-floating > select,
  .wp-block-archives-dropdown .form-floating > select {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder, .wp-block-categories-dropdown .form-floating > select::placeholder,
    .wp-block-archives-dropdown .form-floating > select::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .wp-block-categories-dropdown .form-floating > select:focus,
    .wp-block-archives-dropdown .form-floating > select:focus, .form-floating > .form-control:not(:placeholder-shown), .wp-block-categories-dropdown .form-floating > select:not(:placeholder-shown),
    .wp-block-archives-dropdown .form-floating > select:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill, .wp-block-categories-dropdown .form-floating > select:-webkit-autofill,
    .wp-block-archives-dropdown .form-floating > select:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  
  .form-floating > .form-select,
  .form-floating > select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label, .wp-block-categories-dropdown .form-floating > select:focus ~ label, .wp-block-archives-dropdown .form-floating > select:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .wp-block-categories-dropdown .form-floating > select:not(:placeholder-shown) ~ label,
  .wp-block-archives-dropdown .form-floating > select:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label,
  .form-floating > select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label, .wp-block-categories-dropdown .form-floating > select:-webkit-autofill ~ label, .wp-block-archives-dropdown .form-floating > select:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .wp-block-categories-dropdown .input-group > select,
  .wp-block-archives-dropdown .input-group > select,
  .input-group > .form-select,
  .input-group > select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus, .wp-block-categories-dropdown .input-group > select:focus,
  .wp-block-archives-dropdown .input-group > select:focus,
  .input-group > .form-select:focus,
  .input-group > select:focus {
    z-index: 3; }
  .input-group .btn, .input-group .wp-block-button__link {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus, .input-group .wp-block-button__link:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #555;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-lg > .form-control, .wp-block-categories-dropdown .input-group-lg > select,
.wp-block-archives-dropdown .input-group-lg > select,
.input-group-lg > .form-select,
.input-group-lg > select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.input-group-lg > .wp-block-button__link {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem; }

.input-group-sm > .form-control, .wp-block-categories-dropdown .input-group-sm > select,
.wp-block-archives-dropdown .input-group-sm > select,
.input-group-sm > .form-select,
.input-group-sm > select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.input-group-sm > .wp-block-button__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem; }


.input-group-lg > .form-select,
.input-group-lg > select,
.input-group-sm > .form-select,
.input-group-sm > select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #10ca93; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(16, 202, 147, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .wp-block-categories-dropdown select:valid, .wp-block-categories-dropdown .was-validated select:valid,
.was-validated .wp-block-archives-dropdown select:valid, .wp-block-archives-dropdown .was-validated select:valid, .form-control.is-valid, .wp-block-categories-dropdown select.is-valid,
.wp-block-archives-dropdown select.is-valid {
  border-color: #10ca93;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310ca93' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .wp-block-categories-dropdown select:valid:focus, .wp-block-categories-dropdown .was-validated select:valid:focus,
  .was-validated .wp-block-archives-dropdown select:valid:focus, .wp-block-archives-dropdown .was-validated select:valid:focus, .form-control.is-valid:focus, .wp-block-categories-dropdown select.is-valid:focus,
  .wp-block-archives-dropdown select.is-valid:focus {
    border-color: #10ca93;
    box-shadow: 0 0 0 0rem rgba(16, 202, 147, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem); }

.was-validated .form-select:valid, .was-validated select:valid, .form-select.is-valid, select.is-valid {
  border-color: #10ca93; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .was-validated select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310ca93' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .form-select:valid:focus, .was-validated select:valid:focus, .form-select.is-valid:focus, select.is-valid:focus {
    border-color: #10ca93;
    box-shadow: 0 0 0 0rem rgba(16, 202, 147, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #10ca93; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #10ca93; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0rem rgba(16, 202, 147, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #10ca93; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .was-validated .input-group .wp-block-categories-dropdown select:valid, .wp-block-categories-dropdown .was-validated .input-group select:valid,
.was-validated .input-group .wp-block-archives-dropdown select:valid, .wp-block-archives-dropdown .was-validated .input-group select:valid, .input-group .form-control.is-valid, .input-group .wp-block-categories-dropdown select.is-valid, .wp-block-categories-dropdown .input-group select.is-valid,
.input-group .wp-block-archives-dropdown select.is-valid, .wp-block-archives-dropdown .input-group select.is-valid, .was-validated .input-group .form-select:valid, .was-validated .input-group select:valid,
.input-group .form-select.is-valid,
.input-group select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .was-validated .input-group .wp-block-categories-dropdown select:valid:focus, .wp-block-categories-dropdown .was-validated .input-group select:valid:focus,
  .was-validated .input-group .wp-block-archives-dropdown select:valid:focus, .wp-block-archives-dropdown .was-validated .input-group select:valid:focus, .input-group .form-control.is-valid:focus, .input-group .wp-block-categories-dropdown select.is-valid:focus, .wp-block-categories-dropdown .input-group select.is-valid:focus,
  .input-group .wp-block-archives-dropdown select.is-valid:focus, .wp-block-archives-dropdown .input-group select.is-valid:focus, .was-validated .input-group .form-select:valid:focus, .was-validated .input-group select:valid:focus,
  .input-group .form-select.is-valid:focus,
  .input-group select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ff5269; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(255, 82, 105, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .wp-block-categories-dropdown select:invalid, .wp-block-categories-dropdown .was-validated select:invalid,
.was-validated .wp-block-archives-dropdown select:invalid, .wp-block-archives-dropdown .was-validated select:invalid, .form-control.is-invalid, .wp-block-categories-dropdown select.is-invalid,
.wp-block-archives-dropdown select.is-invalid {
  border-color: #ff5269;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5269'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5269' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .wp-block-categories-dropdown select:invalid:focus, .wp-block-categories-dropdown .was-validated select:invalid:focus,
  .was-validated .wp-block-archives-dropdown select:invalid:focus, .wp-block-archives-dropdown .was-validated select:invalid:focus, .form-control.is-invalid:focus, .wp-block-categories-dropdown select.is-invalid:focus,
  .wp-block-archives-dropdown select.is-invalid:focus {
    border-color: #ff5269;
    box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem); }

.was-validated .form-select:invalid, .was-validated select:invalid, .form-select.is-invalid, select.is-invalid {
  border-color: #ff5269; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .was-validated select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5269'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5269' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .was-validated select:invalid:focus, .form-select.is-invalid:focus, select.is-invalid:focus {
    border-color: #ff5269;
    box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ff5269; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #ff5269; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #ff5269; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .was-validated .input-group .wp-block-categories-dropdown select:invalid, .wp-block-categories-dropdown .was-validated .input-group select:invalid,
.was-validated .input-group .wp-block-archives-dropdown select:invalid, .wp-block-archives-dropdown .was-validated .input-group select:invalid, .input-group .form-control.is-invalid, .input-group .wp-block-categories-dropdown select.is-invalid, .wp-block-categories-dropdown .input-group select.is-invalid,
.input-group .wp-block-archives-dropdown select.is-invalid, .wp-block-archives-dropdown .input-group select.is-invalid, .was-validated .input-group .form-select:invalid, .was-validated .input-group select:invalid,
.input-group .form-select.is-invalid,
.input-group select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .was-validated .input-group .wp-block-categories-dropdown select:invalid:focus, .wp-block-categories-dropdown .was-validated .input-group select:invalid:focus,
  .was-validated .input-group .wp-block-archives-dropdown select:invalid:focus, .wp-block-archives-dropdown .was-validated .input-group select:invalid:focus, .input-group .form-control.is-invalid:focus, .input-group .wp-block-categories-dropdown select.is-invalid:focus, .wp-block-categories-dropdown .input-group select.is-invalid:focus,
  .input-group .wp-block-archives-dropdown select.is-invalid:focus, .wp-block-archives-dropdown .input-group select.is-invalid:focus, .was-validated .input-group .form-select:invalid:focus, .was-validated .input-group select:invalid:focus,
  .input-group .form-select.is-invalid:focus,
  .input-group select.is-invalid:focus {
    z-index: 3; }

.btn, .wp-block-button__link {
  display: inline-block;
  font-weight: 400;
  line-height: 1.6;
  color: #555;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .wp-block-button__link {
      transition: none; } }
  .btn:hover, .wp-block-button__link:hover {
    color: #555; }
  .btn-check:focus + .btn, .btn-check:focus + .wp-block-button__link, .btn:focus, .wp-block-button__link:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(255, 83, 23, 0.25); }
  .btn:disabled, .wp-block-button__link:disabled, .btn.disabled, .disabled.wp-block-button__link,
  fieldset:disabled .btn,
  fieldset:disabled .wp-block-button__link {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary, .wp-block-button__link {
  color: #000;
  background-color: #ff5317;
  border-color: #ff5317; }
  .btn-primary:hover, .wp-block-button__link:hover {
    color: #000;
    background-color: #ff6d3a;
    border-color: #ff642e; }
  .btn-check:focus + .btn-primary, .btn-check:focus + .wp-block-button__link, .btn-primary:focus, .wp-block-button__link:focus {
    color: #000;
    background-color: #ff6d3a;
    border-color: #ff642e;
    box-shadow: 0 0 0 0rem rgba(217, 71, 20, 0.5); }
  .btn-check:checked + .btn-primary, .btn-check:checked + .wp-block-button__link,
  .btn-check:active + .btn-primary,
  .btn-check:active + .wp-block-button__link, .btn-primary:active, .wp-block-button__link:active, .btn-primary.active, .active.wp-block-button__link,
  .show > .btn-primary.dropdown-toggle,
  .show > .dropdown-toggle.wp-block-button__link {
    color: #000;
    background-color: #ff7545;
    border-color: #ff642e; }
    .btn-check:checked + .btn-primary:focus, .btn-check:checked + .wp-block-button__link:focus,
    .btn-check:active + .btn-primary:focus,
    .btn-check:active + .wp-block-button__link:focus, .btn-primary:active:focus, .wp-block-button__link:active:focus, .btn-primary.active:focus, .active.wp-block-button__link:focus,
    .show > .btn-primary.dropdown-toggle:focus,
    .show > .dropdown-toggle.wp-block-button__link:focus {
      box-shadow: 0 0 0 0rem rgba(217, 71, 20, 0.5); }
  .btn-primary:disabled, .wp-block-button__link:disabled, .btn-primary.disabled, .disabled.wp-block-button__link {
    color: #000;
    background-color: #ff5317;
    border-color: #ff5317; }

.btn-secondary {
  color: #fff;
  background-color: #192436;
  border-color: #192436; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #151f2e;
    border-color: #141d2b; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #151f2e;
    border-color: #141d2b;
    box-shadow: 0 0 0 0rem rgba(60, 69, 84, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #141d2b;
    border-color: #131b29; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(60, 69, 84, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #192436;
    border-color: #192436; }

.btn-success {
  color: #000;
  background-color: #10ca93;
  border-color: #10ca93; }
  .btn-success:hover {
    color: #000;
    background-color: #34d2a3;
    border-color: #28cf9e; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #000;
    background-color: #34d2a3;
    border-color: #28cf9e;
    box-shadow: 0 0 0 0rem rgba(14, 172, 125, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #000;
    background-color: #40d5a9;
    border-color: #28cf9e; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(14, 172, 125, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #000;
    background-color: #10ca93;
    border-color: #10ca93; }

.btn-info {
  color: #000;
  background-color: #00afef;
  border-color: #00afef; }
  .btn-info:hover {
    color: #000;
    background-color: #26bbf1;
    border-color: #1ab7f1; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000;
    background-color: #26bbf1;
    border-color: #1ab7f1;
    box-shadow: 0 0 0 0rem rgba(0, 149, 203, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #33bff2;
    border-color: #1ab7f1; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(0, 149, 203, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #00afef;
    border-color: #00afef; }

.btn-warning {
  color: #000;
  background-color: #ff9f00;
  border-color: #ff9f00; }
  .btn-warning:hover {
    color: #000;
    background-color: #ffad26;
    border-color: #ffa91a; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #ffad26;
    border-color: #ffa91a;
    box-shadow: 0 0 0 0rem rgba(217, 135, 0, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffb233;
    border-color: #ffa91a; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(217, 135, 0, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #ff9f00;
    border-color: #ff9f00; }

.btn-danger {
  color: #000;
  background-color: #ff5269;
  border-color: #ff5269; }
  .btn-danger:hover {
    color: #000;
    background-color: #ff6c80;
    border-color: #ff6378; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #000;
    background-color: #ff6c80;
    border-color: #ff6378;
    box-shadow: 0 0 0 0rem rgba(217, 70, 89, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #000;
    background-color: #ff7587;
    border-color: #ff6378; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(217, 70, 89, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #000;
    background-color: #ff5269;
    border-color: #ff5269; }

.btn-light {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2; }
  .btn-light:hover {
    color: #000;
    background-color: #fbf9f4;
    border-color: #fbf9f3; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: #fbf9f4;
    border-color: #fbf9f3;
    box-shadow: 0 0 0 0rem rgba(213, 211, 206, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #fbf9f5;
    border-color: #fbf9f3; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(213, 211, 206, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #faf8f2;
    border-color: #faf8f2; }

.btn-dark {
  color: #fff;
  background-color: #181c32;
  border-color: #181c32; }
  .btn-dark:hover {
    color: #fff;
    background-color: #14182b;
    border-color: #131628; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #14182b;
    border-color: #131628;
    box-shadow: 0 0 0 0rem rgba(59, 62, 81, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #131628;
    border-color: #121526; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(59, 62, 81, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #181c32;
    border-color: #181c32; }

.btn-outline-primary, .is-style-outline .wp-block-button__link {
  color: #ff5317;
  border-color: #ff5317; }
  .btn-outline-primary:hover, .is-style-outline .wp-block-button__link:hover {
    color: #000;
    background-color: #ff5317;
    border-color: #ff5317; }
  .btn-check:focus + .btn-outline-primary, .is-style-outline .btn-check:focus + .wp-block-button__link, .btn-outline-primary:focus, .is-style-outline .wp-block-button__link:focus {
    box-shadow: 0 0 0 0rem rgba(255, 83, 23, 0.5); }
  .btn-check:checked + .btn-outline-primary, .is-style-outline .btn-check:checked + .wp-block-button__link,
  .btn-check:active + .btn-outline-primary,
  .is-style-outline .btn-check:active + .wp-block-button__link, .btn-outline-primary:active, .is-style-outline .wp-block-button__link:active, .btn-outline-primary.active, .is-style-outline .active.wp-block-button__link, .btn-outline-primary.dropdown-toggle.show, .is-style-outline .dropdown-toggle.show.wp-block-button__link {
    color: #000;
    background-color: #ff5317;
    border-color: #ff5317; }
    .btn-check:checked + .btn-outline-primary:focus, .is-style-outline .btn-check:checked + .wp-block-button__link:focus,
    .btn-check:active + .btn-outline-primary:focus,
    .is-style-outline .btn-check:active + .wp-block-button__link:focus, .btn-outline-primary:active:focus, .is-style-outline .wp-block-button__link:active:focus, .btn-outline-primary.active:focus, .is-style-outline .active.wp-block-button__link:focus, .btn-outline-primary.dropdown-toggle.show:focus, .is-style-outline .dropdown-toggle.show.wp-block-button__link:focus {
      box-shadow: 0 0 0 0rem rgba(255, 83, 23, 0.5); }
  .btn-outline-primary:disabled, .is-style-outline .wp-block-button__link:disabled, .btn-outline-primary.disabled, .is-style-outline .disabled.wp-block-button__link {
    color: #ff5317;
    background-color: transparent; }

.btn-outline-secondary {
  color: #192436;
  border-color: #192436; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #192436;
    border-color: #192436; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0rem rgba(25, 36, 54, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #192436;
    border-color: #192436; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(25, 36, 54, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #192436;
    background-color: transparent; }

.btn-outline-success {
  color: #10ca93;
  border-color: #10ca93; }
  .btn-outline-success:hover {
    color: #000;
    background-color: #10ca93;
    border-color: #10ca93; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0rem rgba(16, 202, 147, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #000;
    background-color: #10ca93;
    border-color: #10ca93; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(16, 202, 147, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #10ca93;
    background-color: transparent; }

.btn-outline-info {
  color: #00afef;
  border-color: #00afef; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #00afef;
    border-color: #00afef; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0rem rgba(0, 175, 239, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #00afef;
    border-color: #00afef; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(0, 175, 239, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #00afef;
    background-color: transparent; }

.btn-outline-warning {
  color: #ff9f00;
  border-color: #ff9f00; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #ff9f00;
    border-color: #ff9f00; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0rem rgba(255, 159, 0, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #ff9f00;
    border-color: #ff9f00; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(255, 159, 0, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ff9f00;
    background-color: transparent; }

.btn-outline-danger {
  color: #ff5269;
  border-color: #ff5269; }
  .btn-outline-danger:hover {
    color: #000;
    background-color: #ff5269;
    border-color: #ff5269; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #000;
    background-color: #ff5269;
    border-color: #ff5269; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #ff5269;
    background-color: transparent; }

.btn-outline-light {
  color: #faf8f2;
  border-color: #faf8f2; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #faf8f2;
    border-color: #faf8f2; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #faf8f2;
    border-color: #faf8f2; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #faf8f2;
    background-color: transparent; }

.btn-outline-dark {
  color: #181c32;
  border-color: #181c32; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #181c32;
    border-color: #181c32; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0rem rgba(24, 28, 50, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #181c32;
    border-color: #181c32; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(24, 28, 50, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #181c32;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #ff5317;
  text-decoration: underline; }
  .btn-link:hover {
    color: #cc4212; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .wp-block-button__link {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem; }

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .wp-block-button__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #555;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #ff5317; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      background-color: #ff5317; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .btn-group > .wp-block-button__link,
  .btn-group-vertical > .btn,
  .btn-group-vertical > .wp-block-button__link {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:checked + .wp-block-button__link,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn-check:focus + .wp-block-button__link,
  .btn-group > .btn:hover,
  .btn-group > .wp-block-button__link:hover,
  .btn-group > .btn:focus,
  .btn-group > .wp-block-button__link:focus,
  .btn-group > .btn:active,
  .btn-group > .wp-block-button__link:active,
  .btn-group > .btn.active,
  .btn-group > .active.wp-block-button__link,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:checked + .wp-block-button__link,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn-check:focus + .wp-block-button__link,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .wp-block-button__link:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .wp-block-button__link:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .wp-block-button__link:active,
  .btn-group-vertical > .btn.active,
  .btn-group-vertical > .active.wp-block-button__link {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .btn-group > .wp-block-button__link:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .wp-block-button__link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3), .btn-group > .wp-block-button__link:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > :not(.btn-check) + .wp-block-button__link,
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .wp-block-button__link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .wp-block-button__link + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .wp-block-button__link + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  
  .btn-group-vertical > .btn,
  .btn-group-vertical > .wp-block-button__link,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .wp-block-button__link:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .btn-group:not(:last-child) > .wp-block-button__link {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .wp-block-button__link ~ .btn, .btn-group-vertical > .btn ~ .wp-block-button__link, .btn-group-vertical > .wp-block-button__link ~ .wp-block-button__link,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .wp-block-button__link {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #ff5317;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #cc4212; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff5317; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #555;
  text-align: left;
  background-color: #ffffff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: #e64b15;
    background-color: #ffeee8;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e64b15'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23555'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #ffa98b;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(255, 83, 23, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #ff5317;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #cc4212;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #cc4212;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(255, 83, 23, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff5317;
  border-color: #ff5317; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge, .wp-block-button__link .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  color: #99320e;
  background-color: #ffddd1;
  border-color: #ffcbb9; }
  .alert-primary .alert-link {
    color: #7a280b; }

.alert-secondary {
  color: #0f1620;
  background-color: #d1d3d7;
  border-color: #babdc3; }
  .alert-secondary .alert-link {
    color: #0c121a; }

.alert-success {
  color: #0a7958;
  background-color: #cff4e9;
  border-color: #b7efdf; }
  .alert-success .alert-link {
    color: #086146; }

.alert-info {
  color: #00698f;
  background-color: #cceffc;
  border-color: #b3e7fa; }
  .alert-info .alert-link {
    color: #005472; }

.alert-warning {
  color: #995f00;
  background-color: #ffeccc;
  border-color: #ffe2b3; }
  .alert-warning .alert-link {
    color: #7a4c00; }

.alert-danger {
  color: #99313f;
  background-color: #ffdce1;
  border-color: #ffcbd2; }
  .alert-danger .alert-link {
    color: #7a2732; }

.alert-light {
  color: #646361;
  background-color: #fefefc;
  border-color: #fefdfb; }
  .alert-light .alert-link {
    color: #504f4e; }

.alert-dark {
  color: #0e111e;
  background-color: #d1d2d6;
  border-color: #babbc2; }
  .alert-dark .alert-link {
    color: #0b0e18; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ff5317;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #555;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #ff5317;
    border-color: #ff5317; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #99320e;
  background-color: #ffddd1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #99320e;
    background-color: #e6c7bc; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #99320e;
    border-color: #99320e; }

.list-group-item-secondary {
  color: #0f1620;
  background-color: #d1d3d7; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #0f1620;
    background-color: #bcbec2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #0f1620;
    border-color: #0f1620; }

.list-group-item-success {
  color: #0a7958;
  background-color: #cff4e9; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0a7958;
    background-color: #badcd2; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0a7958;
    border-color: #0a7958; }

.list-group-item-info {
  color: #00698f;
  background-color: #cceffc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #00698f;
    background-color: #b8d7e3; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #00698f;
    border-color: #00698f; }

.list-group-item-warning {
  color: #995f00;
  background-color: #ffeccc; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #995f00;
    background-color: #e6d4b8; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #995f00;
    border-color: #995f00; }

.list-group-item-danger {
  color: #99313f;
  background-color: #ffdce1; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #99313f;
    background-color: #e6c6cb; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #99313f;
    border-color: #99313f; }

.list-group-item-light {
  color: #646361;
  background-color: #fefefc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #646361;
    background-color: #e5e5e3; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #646361;
    border-color: #646361; }

.list-group-item-dark {
  color: #0e111e;
  background-color: #d1d2d6; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0e111e;
    background-color: #bcbdc1; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0e111e;
    border-color: #0e111e; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(255, 83, 23, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 15px; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #E1E1F0;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px); }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.6; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #E1E1F0;
  border-bottom-right-radius: calc(1rem - 1px);
  border-bottom-left-radius: calc(1rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff; }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff; }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0; }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #212529;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #555; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.6; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5; }
  .placeholder.btn::before, .placeholder.wp-block-button__link::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #ff5317; }
  .link-primary:hover, .link-primary:focus {
    color: #ff7545; }

.link-secondary {
  color: #192436; }
  .link-secondary:hover, .link-secondary:focus {
    color: #141d2b; }

.link-success {
  color: #10ca93; }
  .link-success:hover, .link-success:focus {
    color: #40d5a9; }

.link-info {
  color: #00afef; }
  .link-info:hover, .link-info:focus {
    color: #33bff2; }

.link-warning {
  color: #ff9f00; }
  .link-warning:hover, .link-warning:focus {
    color: #ffb233; }

.link-danger {
  color: #ff5269; }
  .link-danger:hover, .link-danger:focus {
    color: #ff7587; }

.link-light {
  color: #faf8f2; }
  .link-light:hover, .link-light:focus {
    color: #fbf9f5; }

.link-dark {
  color: #181c32; }
  .link-dark:hover, .link-dark:focus {
    color: #131628; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #E1E1F0 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #E1E1F0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #E1E1F0 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #E1E1F0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #E1E1F0 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #ff5317 !important; }

.border-secondary {
  border-color: #192436 !important; }

.border-success {
  border-color: #10ca93 !important; }

.border-info {
  border-color: #00afef !important; }

.border-warning {
  border-color: #ff9f00 !important; }

.border-danger {
  border-color: #ff5269 !important; }

.border-light {
  border-color: #faf8f2 !important; }

.border-dark {
  border-color: #181c32 !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.3875rem + 1.65vw) !important; }

.fs-2 {
  font-size: calc(1.34375rem + 1.125vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.6 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #AEAED5 !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.75rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 1rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.625rem !important; }
  .fs-2 {
    font-size: 2.1875rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

* {
  outline: none;
  padding: 0; }
  *::after {
    margin: 0;
    padding: 0; }
  *::before {
    margin: 0;
    padding: 0; }

body {
  line-height: 1.6;
  color: #555;
  font-size: 16px;
  font-family: var(--font-family-base); }
  body.fixed {
    overflow: hidden;
    position: fixed; }

h1 a, .h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
.comment-reply-title a,
.wp-block-search .wp-block-search__label a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: var(--title); }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, .comment-reply-title,
.wp-block-search .wp-block-search__label, h5, .h5, h6, .h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: var(--font-family-title);
  font-weight: 700;
  color: var(--title); }

h1, .h1,
.h1 {
  line-height: 1.3; }
  @media only screen and (max-width: 767px) {
    h1, .h1,
    .h1 {
      font-size: 2rem; } }

h2, .h2,
.h2 {
  line-height: 1.3; }
  @media only screen and (max-width: 767px) {
    h2, .h2,
    .h2 {
      font-size: 1.75rem; } }

h3, .h3,
.h3 {
  line-height: 1.4; }
  @media only screen and (max-width: 991px) {
    h3, .h3,
    .h3 {
      font-size: 1.5rem; } }

h4, .h4, .comment-reply-title,
.wp-block-search .wp-block-search__label,
.h4 {
  line-height: 1.4; }
  @media only screen and (max-width: 767px) {
    h4, .h4, .comment-reply-title,
    .wp-block-search .wp-block-search__label,
    .h4 {
      font-size: 1.25rem; } }

h5, .h5,
.h5 {
  line-height: 1.5; }
  @media only screen and (max-width: 767px) {
    h5, .h5,
    .h5 {
      font-size: 1.125rem; } }

h6, .h6,
.h6 {
  line-height: 1.5; }

ul {
  padding: 0;
  margin: 0; }

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle; }

li {
  list-style: none; }

a {
  text-decoration: none;
  outline: none;
  color: var(--primary);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  a:hover, a:focus, a.active {
    text-decoration: none;
    outline: none;
    color: var(--primary-hover); }

.text-primary {
  color: var(--primary) !important; }

.text-secondary {
  color: var(--secondary) !important; }

.bg-primary {
  background-color: var(--primary) !important; }

b,
strong,
.strong {
  font-weight: 500; }

.h-0 {
  height: 0; }

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 60px; }
  #main-wrapper.show {
    opacity: 1; }

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px; }


.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 30px;
  padding-right: 30px; }
  @media only screen and (max-width: 1199px) {
    
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      padding-left: 20px;
      padding-right: 20px; } }
  @media only screen and (max-width: 575px) {
    
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      padding-left: 15px;
      padding-right: 15px; } }

.bg-gray {
  background-color: #f0f6ff; }

.text-black {
  color: #071c35; }

::selection {
  color: #fff;
  background: var(--primary); }

.row.sp60,
.sp60 {
  margin-left: -30px;
  margin-right: -30px; }
  .row.sp60 [class*="col-"],
  .sp60 [class*="col-"] {
    padding-left: 30px;
    padding-right: 30px; }

.row.sp40,
.sp40 {
  margin-left: -20px;
  margin-right: -20px; }
  .row.sp40 [class*="col-"],
  .sp40 [class*="col-"] {
    padding-left: 20px;
    padding-right: 20px; }

.row.sp20,
.sp20 {
  margin-left: -10px;
  margin-right: -10px; }
  .row.sp20 [class*="col-"],
  .sp20 [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px; }

.row.sp16,
.sp16 {
  margin-left: -8px;
  margin-right: -8px; }
  .row.sp16 [class*="col-"],
  .sp16 [class*="col-"] {
    padding-left: 8px;
    padding-right: 8px; }

.row.sp10,
.sp10 {
  margin-left: -5px;
  margin-right: -5px; }
  .row.sp10 [class*="col-"],
  .sp10 [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px; }

.row.sp4,
.sp4 {
  margin-left: -2px;
  margin-right: -2px; }
  .row.sp4 [class*="col-"],
  .sp4 [class*="col-"] {
    padding-left: 2px;
    padding-right: 2px; }

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0; }
  .row.spno [class*="col-"],
  .spno [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }

.modal-backdrop {
  z-index: 99999; }

.modal {
  z-index: 100000; }

/* Flaticon */
[class*="flaticon-"] {
  font-family: "Flaticon"; }

@media only screen and (max-width: 991px) {
  .dz-order-1 {
    order: 1;
    margin-top: 20px; } }

.gradient {
  background-image: var(--gradient); }

.gradient-sec {
  background-image: var(--gradient-sec); }

#pointer-dot {
  left: -4px;
  top: -4px;
  width: 12px;
  height: 12px;
  position: fixed;
  border-radius: 10px;
  z-index: 999998;
  pointer-events: none;
  transition: border-color 0.5s;
  background: #333;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2); }

#pointer-ring {
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  display: block;
  border: 1px solid var(--primary) !important;
  position: fixed;
  border-radius: 100px;
  z-index: 999999;
  pointer-events: none;
  transition: width 0.3s, height 0.3s; }

#pointer-ring.active {
  width: 50px;
  height: 50px;
  opacity: 0.5; }

#pointer-ring.active + #pointer-dot {
  opacity: 0; }

.content-inner {
  padding-top: 120px;
  padding-bottom: 90px; }
  @media only screen and (max-width: 991px) {
    .content-inner {
      padding-top: 50px;
      padding-bottom: 20px; } }

.content-inner-1 {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media only screen and (max-width: 1200px) {
    .content-inner-1 {
      padding-top: 70px;
      padding-bottom: 70px; } }
  @media only screen and (max-width: 991px) {
    .content-inner-1 {
      padding-top: 50px;
      padding-bottom: 50px; } }

.content-inner-2 {
  padding-top: 120px;
  padding-bottom: 0; }
  @media only screen and (max-width: 1200px) {
    .content-inner-2 {
      padding-top: 90px; } }
  @media only screen and (max-width: 991px) {
    .content-inner-2 {
      padding-top: 50px; } }

.content-inner-3 {
  padding-top: 60px;
  padding-bottom: 30px; }
  @media only screen and (max-width: 991px) {
    .content-inner-3 {
      padding-top: 50px;
      padding-bottom: 20px; } }

.content-inner-4 {
  padding-top: 150px;
  padding-bottom: 150px; }
  @media only screen and (max-width: 1200px) {
    .content-inner-4 {
      padding-top: 120px;
      padding-bottom: 120px; } }
  @media only screen and (max-width: 991px) {
    .content-inner-4 {
      padding-top: 50px;
      padding-bottom: 50px; } }

.content-inner-5 {
  padding-top: 60px;
  padding-bottom: 60px; }
  @media only screen and (max-width: 991px) {
    .content-inner-5 {
      padding-top: 40px;
      padding-bottom: 40px; } }

.content-inner-6 {
  padding-top: 70px;
  padding-bottom: 150px; }
  @media only screen and (max-width: 991px) {
    .content-inner-6 {
      padding-top: 50px;
      padding-bottom: 50px; } }

.content-inner-7 {
  padding-top: 120px;
  padding-bottom: 180px; }
  @media only screen and (max-width: 1200px) {
    .content-inner-7 {
      padding-top: 90px;
      padding-bottom: 150px; } }
  @media only screen and (max-width: 991px) {
    .content-inner-7 {
      padding-top: 50px;
      padding-bottom: 50px; } }

.mfp-bg {
  z-index: 99999; }

.mfp-wrap {
  z-index: 100000; }

.rounded {
  border-radius: var(--border-radius-base) !important; }

#loading-area {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999999999;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px;
  overflow: hidden; }
  #loading-area.loading-page-1 {
    display: flex;
    align-items: center;
    justify-content: center; }
    #loading-area.loading-page-1 .bar-load {
      width: 100%; }
    #loading-area.loading-page-1:before, #loading-area.loading-page-1:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 50%;
      left: 0;
      top: 0;
      background: #192436;
      transition: all 1.5s;
      transform-origin: left top;
      z-index: -1; }
    #loading-area.loading-page-1:before {
      top: auto;
      bottom: 0; }
    #loading-area.loading-page-1.active:before, #loading-area.loading-page-1.active:after {
      height: 0px; }
    #loading-area.loading-page-1 .line {
      width: 100%;
      height: 3px;
      position: relative;
      background-color: rgba(255, 255, 255, 0.05);
      overflow: hidden; }
      #loading-area.loading-page-1 .line:after {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100px;
        content: "";
        background-color: var(--primary);
        animation-name: center-animate;
        animation-duration: 3s;
        animation-fill-mode: forwards; }
  #loading-area.loading-page-2 {
    background-color: #071c35; }
    #loading-area.loading-page-2 .loading-inner {
      font-family: var(--font-family-title);
      position: absolute;
      top: 50%;
      left: 50%;
      width: 150px;
      height: 150px;
      border: 3px solid #ffffff;
      transform: translate(-50%, -50%);
      background: transparent;
      border-radius: 50%;
      text-align: center;
      font-size: 20px;
      line-height: 150px;
      color: var(--primary);
      text-transform: uppercase;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }
      #loading-area.loading-page-2 .loading-inner:before {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        border: 3px solid transparent;
        height: 100%;
        width: 100%;
        border-top: 3px solid var(--primary);
        border-radius: 50%;
        border-right: 3px solid var(--primary);
        animation: dzLoad1 2s linear infinite; }
      #loading-area.loading-page-2 .loading-inner .loading {
        display: block;
        position: absolute;
        left: 50%;
        width: 50%;
        top: calc(50% - 2px);
        height: 4px;
        transform-origin: left;
        background: transparent;
        animation: dzLoad2 2s linear infinite; }
        #loading-area.loading-page-2 .loading-inner .loading:before {
          content: "";
          position: absolute;
          background: var(--primary);
          width: 16px;
          box-shadow: 0 0 20px var(--primary);
          height: 16px;
          border-radius: 50%;
          top: -6px;
          right: -8px; }
    #loading-area.loading-page-2 .load-text .text-load {
      font-weight: 900;
      display: inline-block;
      color: rgba(255, 255, 255, 0.1);
      position: relative;
      font-size: 70px;
      line-height: 70px;
      font-size: 24px;
      text-align: center;
      user-select: none;
      text-transform: uppercase;
      font-family: var(--font-family-title);
      letter-spacing: -1px; }
      #loading-area.loading-page-2 .load-text .text-load:before {
        animation: letters-loading 4s infinite;
        color: #fff;
        content: attr(data-text);
        left: 0;
        opacity: 0;
        top: 0;
        line-height: 70px;
        position: absolute;
        text-transform: uppercase; }
      #loading-area.loading-page-2 .load-text .text-load:nth-child(2):before {
        animation-delay: 0.2s; }
      #loading-area.loading-page-2 .load-text .text-load:nth-child(3):before {
        animation-delay: 0.4s; }
      #loading-area.loading-page-2 .load-text .text-load:nth-child(4):before {
        animation-delay: 0.6s; }
      #loading-area.loading-page-2 .load-text .text-load:nth-child(5):before {
        animation-delay: 0.8s; }
      #loading-area.loading-page-2 .load-text .text-load:nth-child(6):before {
        animation-delay: 1s; }
      #loading-area.loading-page-2 .load-text .text-load:nth-child(7):before {
        animation-delay: 1.2s; }
      #loading-area.loading-page-2 .load-text .text-load:nth-child(8):before {
        animation-delay: 1.4s; }
  #loading-area.loading-page-3 {
    background-color: #071c35; }
    #loading-area.loading-page-3 .loading-inner {
      width: 160px;
      height: 160px;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      #loading-area.loading-page-3 .loading-inner .loader {
        background-color: rgba(0, 0, 0, 0.04);
        border: 4px solid rgba(255, 255, 255, 0.1);
        border-left: 4px solid var(--primary);
        border-top: 4px solid var(--primary);
        border-radius: 50%;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        animation: dzLoad3 3s linear infinite; }
        #loading-area.loading-page-3 .loading-inner .loader .circle {
          transform-origin: left;
          position: absolute;
          top: calc(50% - 1px);
          left: 50%;
          width: 50%;
          height: 2px; }
          #loading-area.loading-page-3 .loading-inner .loader .circle:before {
            content: '';
            background: #fff;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: -57px;
            right: 14px;
            background: var(--primary);
            box-shadow: 0 0 20px var(--primary), 0 0 40px var(--primary), 0 0 60px var(--primary), 0 0 80px var(--primary), 0 0 100px var(--primary), 0 0 0 5px rgba(255, 255, 0, 0.1); }
        #loading-area.loading-page-3 .loading-inner .loader:nth-of-type(2) {
          background-color: rgba(0, 0, 0, 0.025);
          border: 4px solid rgba(255, 255, 255, 0.1);
          border-right: 4px solid #5aaf1e;
          border-bottom: 4px solid #5aaf1e;
          top: 30px;
          left: 30px;
          right: 30px;
          bottom: 30px;
          animation: dzLoad3 3s reverse linear infinite; }
          #loading-area.loading-page-3 .loading-inner .loader:nth-of-type(2) .circle {
            transform: rotate(-45deg); }
            #loading-area.loading-page-3 .loading-inner .loader:nth-of-type(2) .circle:before {
              top: -4px;
              right: -6px;
              background: #5aaf1e;
              box-shadow: 0 0 20px #5aaf1e, 0 0 40px #5aaf1e, 0 0 60px #5aaf1e, 0 0 80px #5aaf1e, 0 0 100px #5aaf1e, 0 0 0 5px rgba(3, 169, 244, 0.1); }
  #loading-area.loading-page-4 {
    background-color: #fff; }
    #loading-area.loading-page-4 .loading-inner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
      #loading-area.loading-page-4 .loading-inner .wrapper {
        margin: auto;
        position: relative;
        height: 100px;
        width: 100px;
        overflow: hidden; }
        #loading-area.loading-page-4 .loading-inner .wrapper svg,
        #loading-area.loading-page-4 .loading-inner .wrapper img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%; }
        #loading-area.loading-page-4 .loading-inner .wrapper .img1 {
          -webkit-animation: rotate360 5s linear infinite;
          animation: rotate360 5s linear infinite; }
      #loading-area.loading-page-4 .loading-inner .load-text .text-load {
        font-weight: 900;
        letter-spacing: 15px;
        display: inline-block;
        color: rgba(0, 0, 0, 0.15);
        position: relative;
        font-size: 70px;
        line-height: 70px;
        font-size: 60px;
        letter-spacing: 1px;
        text-align: center;
        user-select: none;
        text-transform: uppercase;
        font-family: var(--font-family-title); }
        #loading-area.loading-page-4 .loading-inner .load-text .text-load:before {
          animation: letters-loading 4s infinite;
          color: var(--title);
          content: attr(data-text);
          left: 0;
          opacity: 0;
          top: 0;
          line-height: 70px;
          position: absolute;
          text-transform: uppercase; }
        #loading-area.loading-page-4 .loading-inner .load-text .text-load:nth-child(2):before {
          animation-delay: 0.2s; }
        #loading-area.loading-page-4 .loading-inner .load-text .text-load:nth-child(3):before {
          animation-delay: 0.4s; }
        #loading-area.loading-page-4 .loading-inner .load-text .text-load:nth-child(4):before {
          animation-delay: 0.6s; }
        #loading-area.loading-page-4 .loading-inner .load-text .text-load:nth-child(5):before {
          animation-delay: 0.8s; }
        #loading-area.loading-page-4 .loading-inner .load-text .text-load:nth-child(6):before {
          animation-delay: 1s; }
        #loading-area.loading-page-4 .loading-inner .load-text .text-load:nth-child(7):before {
          animation-delay: 1.2s; }
        #loading-area.loading-page-4 .loading-inner .load-text .text-load:nth-child(8):before {
          animation-delay: 1.4s; }
      @media only screen and (max-width: 575px) {
        #loading-area.loading-page-4 .loading-inner .load-text .text-load {
          font-size: 30px; }
        #loading-area.loading-page-4 .loading-inner .wrapper {
          height: 80px;
          width: 80px; } }
  #loading-area.loading-page-5 {
    background-color: #fff; }
    #loading-area.loading-page-5 .loading-inner {
      text-align: center;
      position: absolute;
      line-height: 50px;
      text-transform: uppercase;
      width: 250px;
      height: 50px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: var(--font-family-title);
      color: var(--title);
      letter-spacing: 5px;
      font-weight: 900; }
      #loading-area.loading-page-5 .loading-inner::before, #loading-area.loading-page-5 .loading-inner::after {
        content: "";
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;
        animation: dzLoad4 .7s infinite alternate ease-in-out;
        background-color: var(--primary); }
      #loading-area.loading-page-5 .loading-inner::before {
        top: 0; }
      #loading-area.loading-page-5 .loading-inner::after {
        bottom: 0; }

@keyframes dzLoad4 {
  0% {
    left: 0;
    height: 30px;
    width: 15px; }
  50% {
    height: 8px;
    width: 40px; }
  100% {
    left: 235px;
    height: 30px;
    width: 15px; } }

@keyframes center-animate {
  from {
    width: 0; }
  to {
    width: 100%; } }

@keyframes dzLoad1 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes dzLoad2 {
  0% {
    transform: rotate(45deg); }
  100% {
    transform: rotate(405deg); } }

@keyframes dzLoad3 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes rotation {
  100% {
    transform: rotate(360deg); } }

@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg); }
  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg); } }

@keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.font-10 {
  font-size: 10px; }

.font-12 {
  font-size: 12px; }

.font-13 {
  font-size: 13px; }

.font-14 {
  font-size: 14px; }

.font-16 {
  font-size: 16px; }

.font-18 {
  font-size: 18px; }

.font-20 {
  font-size: 20px; }

.font-22 {
  font-size: 22px; }

.font-24 {
  font-size: 24px; }

.font-26 {
  font-size: 26px; }

.font-30 {
  font-size: 30px; }

.font-35 {
  font-size: 35px; }

.font-40 {
  font-size: 40px; }

.font-45 {
  font-size: 45px; }

.font-50 {
  font-size: 50px; }

.font-55 {
  font-size: 55px; }

.font-60 {
  font-size: 60px; }

.font-70 {
  font-size: 70px; }

.font-75 {
  font-size: 75px; }

.font-80 {
  font-size: 80px; }

.font-90 {
  font-size: 90px; }

.font-100 {
  font-size: 100px; }

.font-weight-300 {
  font-weight: 300; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-700 {
  font-weight: 700; }

.font-weight-800 {
  font-weight: 800; }

.font-weight-900 {
  font-weight: 900; }

.p-a0 {
  padding: 0; }

.p-a5 {
  padding: 5px; }

.p-a10 {
  padding: 10px; }

.p-a15 {
  padding: 15px; }

.p-a20 {
  padding: 20px; }

.p-a25 {
  padding: 25px; }

.p-a30 {
  padding: 30px; }

.p-a40 {
  padding: 40px; }

.p-a50 {
  padding: 50px; }

.p-a60 {
  padding: 60px; }

.p-a70 {
  padding: 70px; }

.p-a80 {
  padding: 80px; }

.p-a90 {
  padding: 90px; }

.p-a100 {
  padding: 100px; }

.p-t0 {
  padding-top: 0; }

.p-t5 {
  padding-top: 5px; }

.p-t10 {
  padding-top: 10px; }

.p-t15 {
  padding-top: 15px; }

.p-t20 {
  padding-top: 20px; }

.p-t30 {
  padding-top: 30px; }

.p-t40 {
  padding-top: 40px; }

.p-t50 {
  padding-top: 50px; }

.p-t60 {
  padding-top: 60px; }

.p-t70 {
  padding-top: 70px; }

.p-t80 {
  padding-top: 80px; }

.p-t90 {
  padding-top: 90px; }

.p-t100 {
  padding-top: 100px; }

.p-b0 {
  padding-bottom: 0; }

.p-b5 {
  padding-bottom: 5px; }

.p-b10 {
  padding-bottom: 10px; }

.p-b15 {
  padding-bottom: 15px; }

.p-b20 {
  padding-bottom: 20px; }

.p-b30 {
  padding-bottom: 30px; }

.p-b40 {
  padding-bottom: 40px; }

.p-b50 {
  padding-bottom: 50px; }

.p-b60 {
  padding-bottom: 60px; }

.p-b70 {
  padding-bottom: 70px; }

.p-b80 {
  padding-bottom: 80px; }

.p-b90 {
  padding-bottom: 90px; }

.p-b100 {
  padding-bottom: 100px; }

.p-l0 {
  padding-left: 0; }

.p-l5 {
  padding-left: 5px; }

.p-l10 {
  padding-left: 10px; }

.p-l15 {
  padding-left: 15px; }

.p-l20 {
  padding-left: 20px; }

.p-l30 {
  padding-left: 30px; }

.p-l40 {
  padding-left: 40px; }

.p-l50 {
  padding-left: 50px; }

.p-l60 {
  padding-left: 60px; }

.p-l70 {
  padding-left: 70px; }

.p-l80 {
  padding-left: 80px; }

.p-l90 {
  padding-left: 90px; }

.p-l100 {
  padding-left: 100px; }

.p-r0 {
  padding-right: 0; }

.p-r5 {
  padding-right: 5px; }

.p-r10 {
  padding-right: 10px; }

.p-r15 {
  padding-right: 15px; }

.p-r20 {
  padding-right: 20px; }

.p-r30 {
  padding-right: 30px; }

.p-r40 {
  padding-right: 40px; }

.p-r50 {
  padding-right: 50px; }

.p-r60 {
  padding-right: 60px; }

.p-r70 {
  padding-right: 70px; }

.p-r80 {
  padding-right: 80px; }

.p-r90 {
  padding-right: 90px; }

.p-r100 {
  padding-right: 100px; }

.p-lr0 {
  padding-left: 0;
  padding-right: 0; }

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px; }

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px; }

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px; }

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px; }

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px; }

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px; }

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px; }

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px; }

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px; }

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px; }

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px; }

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px; }

.p-tb0 {
  padding-bottom: 0;
  padding-top: 0; }

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px; }

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px; }

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px; }

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px; }

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px; }

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px; }

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px; }

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px; }

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px; }

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px; }

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px; }

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px; }

.m-auto {
  margin: auto; }

.m-a0 {
  margin: 0; }

.m-a5 {
  margin: 5px; }

.m-a10 {
  margin: 10px; }

.m-a15 {
  margin: 15px; }

.m-a20 {
  margin: 20px; }

.m-a30 {
  margin: 30px; }

.m-a40 {
  margin: 40px; }

.m-a50 {
  margin: 50px; }

.m-a60 {
  margin: 60px; }

.m-a70 {
  margin: 70px; }

.m-a80 {
  margin: 80px; }

.m-a90 {
  margin: 90px; }

.m-a100 {
  margin: 100px; }

.m-t0 {
  margin-top: 0; }

.m-t5 {
  margin-top: 5px; }

.m-t10 {
  margin-top: 10px; }

.m-t15 {
  margin-top: 15px; }

.m-t20 {
  margin-top: 20px; }

.m-t30 {
  margin-top: 30px; }

.m-t40 {
  margin-top: 40px; }

.m-t50 {
  margin-top: 50px; }

.m-t60 {
  margin-top: 60px; }

.m-t70 {
  margin-top: 70px; }

.m-t80 {
  margin-top: 80px; }

.m-t90 {
  margin-top: 90px; }

.m-t100 {
  margin-top: 100px; }

.m-b0 {
  margin-bottom: 0; }

.m-b5 {
  margin-bottom: 5px; }

.m-b10 {
  margin-bottom: 10px; }

.m-b15 {
  margin-bottom: 15px; }

.m-b20 {
  margin-bottom: 20px; }

.m-b30 {
  margin-bottom: 30px; }

.m-b40 {
  margin-bottom: 40px; }

.m-b50 {
  margin-bottom: 50px; }

.m-b60 {
  margin-bottom: 60px; }

.m-b70 {
  margin-bottom: 70px; }

.m-b80 {
  margin-bottom: 80px; }

.m-b90 {
  margin-bottom: 90px; }

.m-b100 {
  margin-bottom: 100px; }

.m-l0 {
  margin-left: 0; }

.m-l5 {
  margin-left: 5px; }

.m-l10 {
  margin-left: 10px; }

.m-l15 {
  margin-left: 15px; }

.m-l20 {
  margin-left: 20px; }

.m-l30 {
  margin-left: 30px; }

.m-l40 {
  margin-left: 40px; }

.m-l50 {
  margin-left: 50px; }

.m-l60 {
  margin-left: 60px; }

.m-l70 {
  margin-left: 70px; }

.m-l80 {
  margin-left: 80px; }

.m-l90 {
  margin-left: 90px; }

.m-l100 {
  margin-left: 100px; }

.m-r0 {
  margin-right: 0; }

.m-r5 {
  margin-right: 5px; }

.m-r10 {
  margin-right: 10px; }

.m-r15 {
  margin-right: 15px; }

.m-r20 {
  margin-right: 20px; }

.m-r30 {
  margin-right: 30px; }

.m-r40 {
  margin-right: 40px; }

.m-r50 {
  margin-right: 50px; }

.m-r60 {
  margin-right: 60px; }

.m-r70 {
  margin-right: 70px; }

.m-r80 {
  margin-right: 80px; }

.m-r90 {
  margin-right: 90px; }

.m-r100 {
  margin-right: 100px; }

.m-lr0 {
  margin-left: 0;
  margin-right: 0; }

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px; }

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px; }

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px; }

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px; }

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px; }

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px; }

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px; }

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px; }

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px; }

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px; }

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px; }

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px; }

.m-tb0 {
  margin-bottom: 0;
  margin-top: 0; }

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px; }

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px; }

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px; }

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px; }

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px; }

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px; }

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px; }

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px; }

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px; }

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px; }

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px; }

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px; }

@media only screen and (max-width: 1200px) {
  .m-lg-t0 {
    margin-top: 0; }
  .m-lg-t5 {
    margin-top: 5px; }
  .m-lg-t10 {
    margin-top: 10px; }
  .m-lg-t15 {
    margin-top: 15px; }
  .m-lg-t20 {
    margin-top: 20px; }
  .m-lg-t30 {
    margin-top: 30px; }
  .m-lg-t40 {
    margin-top: 40px; }
  .m-lg-t50 {
    margin-top: 50px; }
  .m-lg-t60 {
    margin-top: 60px; }
  .m-lg-t70 {
    margin-top: 70px; }
  .m-lg-t80 {
    margin-top: 80px; }
  .m-lg-t90 {
    margin-top: 90px; }
  .m-lg-t100 {
    margin-top: 100px; }
  .m-lg-b0 {
    margin-bottom: 0; }
  .m-lg-b5 {
    margin-bottom: 5px; }
  .m-lg-b10 {
    margin-bottom: 10px; }
  .m-lg-b15 {
    margin-bottom: 15px; }
  .m-lg-b20 {
    margin-bottom: 20px; }
  .m-lg-b30 {
    margin-bottom: 30px; }
  .m-lg-b40 {
    margin-bottom: 40px; }
  .m-lg-b50 {
    margin-bottom: 50px; }
  .m-lg-b60 {
    margin-bottom: 60px; }
  .m-lg-b70 {
    margin-bottom: 70px; }
  .m-lg-b80 {
    margin-bottom: 80px; }
  .m-lg-b90 {
    margin-bottom: 90px; }
  .m-lg-b100 {
    margin-bottom: 100px; }
  .m-lg-l20 {
    margin-left: 20px; }
  .m-lg-r20 {
    margin-right: 20px; } }

@media only screen and (max-width: 991px) {
  .m-md-t0 {
    margin-top: 0; }
  .m-md-t5 {
    margin-top: 5px; }
  .m-md-t10 {
    margin-top: 10px; }
  .m-md-t15 {
    margin-top: 15px; }
  .m-md-t20 {
    margin-top: 20px; }
  .m-md-t30 {
    margin-top: 30px; }
  .m-md-t40 {
    margin-top: 40px; }
  .m-md-t50 {
    margin-top: 50px; }
  .m-md-t60 {
    margin-top: 60px; }
  .m-md-t70 {
    margin-top: 70px; }
  .m-md-t80 {
    margin-top: 80px; }
  .m-md-t90 {
    margin-top: 90px; }
  .m-md-t100 {
    margin-top: 100px; }
  .m-md-b0 {
    margin-bottom: 0; }
  .m-md-b5 {
    margin-bottom: 5px; }
  .m-md-b10 {
    margin-bottom: 10px; }
  .m-md-b15 {
    margin-bottom: 15px; }
  .m-md-b20 {
    margin-bottom: 20px; }
  .m-md-b30 {
    margin-bottom: 30px; }
  .m-md-b40 {
    margin-bottom: 40px; }
  .m-md-b50 {
    margin-bottom: 50px; }
  .m-md-b60 {
    margin-bottom: 60px; }
  .m-md-b70 {
    margin-bottom: 70px; }
  .m-md-b80 {
    margin-bottom: 80px; }
  .m-md-b90 {
    margin-bottom: 90px; }
  .m-md-b100 {
    margin-bottom: 100px; }
  .m-md-r0 {
    margin-right: 0; }
  .m-md-l0 {
    margin-left: 0; }
  .p-md-r0 {
    padding-right: 0; } }

@media only screen and (max-width: 767px) {
  .m-sm-t0 {
    margin-top: 0; }
  .m-sm-t5 {
    margin-top: 5px; }
  .m-sm-t10 {
    margin-top: 10px; }
  .m-sm-t15 {
    margin-top: 15px; }
  .m-sm-t20 {
    margin-top: 20px; }
  .m-sm-t30 {
    margin-top: 30px; }
  .m-sm-t40 {
    margin-top: 40px; }
  .m-sm-t50 {
    margin-top: 50px; }
  .m-sm-t60 {
    margin-top: 60px; }
  .m-sm-t70 {
    margin-top: 70px; }
  .m-sm-t80 {
    margin-top: 80px; }
  .m-sm-t90 {
    margin-top: 90px; }
  .m-sm-t100 {
    margin-top: 100px; }
  .m-sm-b0 {
    margin-bottom: 0; }
  .m-sm-b5 {
    margin-bottom: 5px; }
  .m-sm-b10 {
    margin-bottom: 10px; }
  .m-sm-b15 {
    margin-bottom: 15px; }
  .m-sm-b20 {
    margin-bottom: 20px; }
  .m-sm-b30 {
    margin-bottom: 30px; }
  .m-sm-b40 {
    margin-bottom: 40px; }
  .m-sm-b50 {
    margin-bottom: 50px; }
  .m-sm-b60 {
    margin-bottom: 60px; }
  .m-sm-b70 {
    margin-bottom: 70px; }
  .m-sm-b80 {
    margin-bottom: 80px; }
  .m-sm-b90 {
    margin-bottom: 90px; }
  .m-sm-b100 {
    margin-bottom: 100px; } }

@media only screen and (max-width: 576px) {
  .m-xs-t0 {
    margin-top: 0; }
  .m-xs-t5 {
    margin-top: 5px; }
  .m-xs-t10 {
    margin-top: 10px; }
  .m-xs-t15 {
    margin-top: 15px; }
  .m-xs-t20 {
    margin-top: 20px; }
  .m-xs-t30 {
    margin-top: 30px; }
  .m-xs-t40 {
    margin-top: 40px; }
  .m-xs-t50 {
    margin-top: 50px; }
  .m-xs-t60 {
    margin-top: 60px; }
  .m-xs-t70 {
    margin-top: 70px; }
  .m-xs-t80 {
    margin-top: 80px; }
  .m-xs-t90 {
    margin-top: 90px; }
  .m-xs-t100 {
    margin-top: 100px; }
  .m-xs-b0 {
    margin-bottom: 0; }
  .m-xs-b5 {
    margin-bottom: 5px; }
  .m-xs-b10 {
    margin-bottom: 10px; }
  .m-xs-b15 {
    margin-bottom: 15px; }
  .m-xs-b20 {
    margin-bottom: 20px; }
  .m-xs-b30 {
    margin-bottom: 30px; }
  .m-xs-b40 {
    margin-bottom: 40px; }
  .m-xs-b50 {
    margin-bottom: 50px; }
  .m-xs-b60 {
    margin-bottom: 60px; }
  .m-xs-b70 {
    margin-bottom: 70px; }
  .m-xs-b80 {
    margin-bottom: 80px; }
  .m-xs-b90 {
    margin-bottom: 90px; }
  .m-xs-b100 {
    margin-bottom: 100px; }
  .p-sm-r0 {
    padding-right: 0; } }

.max-w50 {
  max-width: 50px; }

.max-w60 {
  max-width: 60px; }

.max-w80 {
  max-width: 80px; }

.max-w100 {
  max-width: 100px; }

.max-w200 {
  max-width: 200px; }

.max-w300 {
  max-width: 300px; }

.max-w400 {
  max-width: 400px; }

.max-w500 {
  max-width: 500px; }

.max-w600 {
  max-width: 600px; }

.max-w700 {
  max-width: 700px; }

.max-w800 {
  max-width: 800px; }

.max-w900 {
  max-width: 900px; }

.max-w1000 {
  max-width: 1000px; }

.bg-img-fix {
  background-attachment: fixed;
  background-size: cover; }

.full-img {
  width: 100%; }

.sticky-top {
  top: 120px; }

.page-title {
  padding: 65px 0px 45px;
  position: relative;
  background: #faf8f2;
  background-size: cover;
  background-position: center; }
  .page-title p {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #7272A8;
    text-transform: capitalize; }
    @media only screen and (max-width: 575px) {
      .page-title p {
        font-size: 16px; } }
  .page-title h3, .page-title .h3 {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 600;
    text-transform: capitalize; }
    @media only screen and (max-width: 575px) {
      .page-title h3, .page-title .h3 {
        font-size: 28px; } }

.img-cover {
  height: 100%;
  width: 100%;
  object-fit: cover; }

section {
  position: relative;
  z-index: 1; }

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0; }
  .row.spno [class*="col"],
  .row.spno [class*="col"],
  .spno [class*="col"],
  .spno [class*="col"] {
    padding-left: 0;
    padding-right: 0; }

.row.sp4,
.sp4 {
  margin-left: -4px;
  margin-right: -4px; }
  .row.sp4 [class*="col"],
  .row.sp4 [class*="col"],
  .sp4 [class*="col"],
  .sp4 [class*="col"] {
    padding-left: 4px;
    padding-right: 4px; }

.row.sp15,
.sp15 {
  margin-left: -7px;
  margin-right: -7px; }
  .row.sp15 [class*="col"],
  .row.sp15 [class*="col"],
  .sp15 [class*="col"],
  .sp15 [class*="col"] {
    padding-left: 7px;
    padding-right: 7px; }

.row.sp10,
.sp10 {
  margin-left: -10px;
  margin-right: -10px; }
  .row.sp10 [class*="col"],
  .row.sp10 [class*="col"],
  .sp10 [class*="col"],
  .sp10 [class*="col"] {
    padding-left: 10px;
    padding-right: 10px; }

.rounded-xl {
  border-radius: 50% !important; }

.rounded-lg {
  border-radius: 20px !important; }

.rounded-md {
  border-radius: 10px !important; }

.rounded-sm {
  border-radius: 5px !important; }

.text-maroon {
  color: #9e0168; }

.text-orange {
  color: #ff8853; }

.text-yellow {
  color: #ffa808; }

.text-skyblue {
  color: #029e76; }

.text-red {
  color: #ff586e; }

.text-green {
  color: #029e76; }

.text-blue {
  color: #5543d1; }

.twitter {
  background-color: #1da1f2; }

.facebook {
  background-color: #3b5998; }

.pinterest {
  background-color: #bd081c; }

.youtube {
  background-color: #FF0000; }

.bg-maroon {
  background-color: #9e0168; }

.bg-orange {
  background-color: #ff8853; }

.bg-yellow {
  background-color: #ffa808; }

.bg-skyblue {
  background-color: #00aeff; }

.bg-red {
  background-color: #ff586e; }

.bg-green {
  background-color: #029e76; }

.bg-blue {
  background-color: #5543d1; }

.scale8 {
  transform: scale(1.85);
  -moz-transform: scale(1.85);
  -webkit-transform: scale(1.85);
  -ms-transform: scale(1.85);
  -o-transform: scale(1.85); }

.scale7 {
  transform: scale(1.7);
  -moz-transform: scale(1.7);
  -webkit-transform: scale(1.7);
  -ms-transform: scale(1.7);
  -o-transform: scale(1.7); }

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5); }

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3); }

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2); }

.scale08 {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8); }

.scale05 {
  transform: scale(0.5);
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5); }

.tl {
  transform-origin: left;
  -moz-transform-origin: left;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  -o-transform-origin: left; }

.shadow-none {
  box-shadow: unset; }

.object-cover {
  object-fit: cover; }

.overlay-gadient-white:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  width: 100%;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.31) 0%, rgba(255, 255, 255, 0.34) 1%, white 25%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.31) 0%, rgba(255, 255, 255, 0.34) 1%, white 25%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.31) 0%, rgba(255, 255, 255, 0.34) 1%, white 25%); }

.overlay-gadient-sec:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  width: 100%;
  background: -moz-linear-gradient(left, rgba(25, 36, 54, 0.31) 0%, rgba(25, 36, 54, 0.34) 1%, #192436 25%);
  background: -webkit-linear-gradient(left, rgba(25, 36, 54, 0.31) 0%, rgba(25, 36, 54, 0.34) 1%, #192436 25%);
  background: linear-gradient(to right, rgba(25, 36, 54, 0.31) 0%, rgba(25, 36, 54, 0.34) 1%, #192436 25%); }

.border-bottom-10 {
  border-bottom: 10px solid; }

.border-top-10 {
  border-top: 10px solid; }

.border-top-5 {
  border-top: 5px solid; }

.height-lg {
  height: 605px; }

.height-md {
  height: 472px; }

.height-sm {
  height: 288px; }

/* Letter Spacing */
.letter-1 {
  letter-spacing: 1px; }

.letter-2 {
  letter-spacing: 2px; }

.letter-s3 {
  letter-spacing: 3px; }

.letter-s4 {
  letter-spacing: 4px; }

.letter-s5 {
  letter-spacing: 5px; }

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

.card {
  border: 0px solid #E1E1F0;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  background: #fff; }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E1E1F0;
    background: #fff;
    padding: 20px 30px; }
  .card-title {
    font-size: 24px;
    margin-bottom: 0px; }
  .card .card-body {
    padding: 20px 30px;
    background: #fff;
    border-radius: 15px; }

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #555;
  margin-bottom: 10px;
  background: transparent;
  border-color: #E1E1F0; }

.form-control, .wp-block-categories-dropdown select,
.wp-block-archives-dropdown select {
  height: 60px;
  border: 1px solid #cccccc;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #555;
  transition: all 0.3s ease-in-out;
  background: #fff;
  border-radius: 0 !important; }
  .form-control .select, .wp-block-categories-dropdown select .select, .wp-block-archives-dropdown select .select {
    padding: 12px 0; }
  .form-control span, .wp-block-categories-dropdown select span, .wp-block-archives-dropdown select span {
    margin-top: 0; }
  .form-control::-webkit-input-placeholder, .wp-block-categories-dropdown select::-webkit-input-placeholder,
  .wp-block-archives-dropdown select::-webkit-input-placeholder {
    color: #555; }
  .form-control:-ms-input-placeholder, .wp-block-categories-dropdown select:-ms-input-placeholder,
  .wp-block-archives-dropdown select:-ms-input-placeholder {
    color: #555; }
  .form-control::placeholder, .wp-block-categories-dropdown select::placeholder,
  .wp-block-archives-dropdown select::placeholder {
    color: #555; }
  .form-control:focus, .wp-block-categories-dropdown select:focus,
  .wp-block-archives-dropdown select:focus, .form-control:active, .wp-block-categories-dropdown select:active,
  .wp-block-archives-dropdown select:active, .form-control.active, .wp-block-categories-dropdown select.active,
  .wp-block-archives-dropdown select.active {
    border-color: var(--primary); }

textarea.form-control {
  min-height: unset;
  height: auto; }

textarea {
  height: 120px;
  resize: none; }

input:-internal-autofill-selected {
  background: white !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important; }

.input-group-text {
  padding: 5px 15px;
  background: #ffffff;
  margin-bottom: 0px !important;
  color: #555;
  border-color: transparent; }

.toggle {
  cursor: pointer;
  display: block; }

.toggle-switch {
  display: inline-block;
  background: #f7f7f7;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s; }
  .toggle-switch:before, .toggle-switch:after {
    content: ""; }
  .toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 1px;
    left: 3px;
    transition: left 0.25s; }
  .toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%); }
  .toggle-checkbox:checked + .toggle-switch {
    background: var(--primary); }
    .toggle-checkbox:checked + .toggle-switch:before {
      left: 26px; }

.toggle-checkbox {
  position: absolute;
  visibility: hidden; }

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400; }

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #E1E1F0;
  border-radius: 5px;
  color: #AEAED5; }
  .file-upload-wrapper:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    padding: 4px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: calc(1.5em + 1rem + 2px);
    line-height: 30px;
    border-radius: 5px 10px 10px 5px;
    font-weight: 400;
    overflow: hidden; }
  .file-upload-wrapper:before {
    content: 'Upload';
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: calc(1.5em + 1rem + 2px);
    background: var(--primary);
    color: #fff;
    font-weight: 400;
    z-index: 25;
    font-size: 14px;
    line-height: 40px;
    padding: 0 15px;
    text-transform: capitalize;
    pointer-events: none;
    border-radius: 0 5px 5px 0; }
  .file-upload-wrapper:hover:before {
    background: #e33b00; }
  .file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: calc(1.5em + 1rem + 2px);
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%; }

#ui-datepicker-div {
  display: none;
  background: #fafafa;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.input-group-prepend {
  position: absolute;
  left: 10px;
  top: 30px;
  z-index: 99;
  transform: translateY(-50%);
  background-color: transparent;
  border-right: 1px solid #9192a4;
  margin-right: 10px; }
  .input-group-prepend .input-group-text {
    padding: 0px;
    font-size: 1.2rem;
    width: 40px;
    height: 20px;
    justify-content: center;
    color: var(--primary); }

.input-group-prepend + .form-control, .wp-block-categories-dropdown .input-group-prepend + select,
.wp-block-archives-dropdown .input-group-prepend + select,
.input-group-prepend + .form-control + .bootstrap-select,
.wp-block-categories-dropdown .input-group-prepend + select + .bootstrap-select,
.wp-block-archives-dropdown .input-group-prepend + select + .bootstrap-select,
.input-group-prepend + .form-control + .custom-file-label,
.wp-block-categories-dropdown .input-group-prepend + select + .custom-file-label,
.wp-block-archives-dropdown .input-group-prepend + select + .custom-file-label {
  padding: 10px 20px 10px 65px; }

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #555; }

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0; }
  .ui-datepicker-calendar tbody td a {
    display: block;
    border-radius: 0.25rem;
    line-height: 2rem;
    transition: 0.3s all;
    color: #555;
    font-size: 0.875rem;
    text-decoration: none; }
    .ui-datepicker-calendar tbody td a:hover {
      background-color: var(--primary);
      color: white; }
    .ui-datepicker-calendar tbody td a.ui-state-active {
      background-color: var(--primary);
      color: white; }

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all; }
  .ui-datepicker-header a.ui-corner-all:hover {
    background-color: #ffc4b0;
    color: #fff; }

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg); }

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%; }

.ui-datepicker-header a > span {
  display: none; }

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem; }

.ui-datepicker-week-col {
  color: #555;
  font-weight: 400;
  font-size: 0.75rem; }

label.error {
  color: #ff5269;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400; }

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important; }
  .nice-select ul {
    width: 100% !important; }

.nice-select .list {
  background: #181c32; }

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #181c32;
  color: var(--primary); }

.form-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn), select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%; }

.form-select, select {
  border: 1px solid transparent; }
  .form-select .dropdown-toggle, select .dropdown-toggle {
    border: 0 !important;
    padding: 0;
    font-weight: normal;
    text-transform: unset;
    outline: none; }
    .form-select .dropdown-toggle:focus, select .dropdown-toggle:focus {
      outline: 0 !important;
      outline-offset: 0; }
    .form-select .dropdown-toggle:after, select .dropdown-toggle:after {
      content: none; }
  .form-select.show, select.show {
    box-shadow: none !important;
    outline: 0px !important; }
    .form-select.show .dropdown-toggle, select.show .dropdown-toggle {
      box-shadow: none !important;
      outline: 0px !important; }
    .form-select.show.btn-group .dropdown-menu.inner, select.show.btn-group .dropdown-menu.inner {
      display: block; }
  .form-select .dropdown-menu, select .dropdown-menu {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-top: -1px;
    padding: 10px 0;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    font-size: 14px; }
    .form-select .dropdown-menu ul li, select .dropdown-menu ul li {
      padding: 0; }
      .form-select .dropdown-menu ul li a, select .dropdown-menu ul li a {
        display: block;
        padding: 8px 20px;
        clear: both;
        font-weight: 400;
        color: #333;
        white-space: nowrap; }
        .form-select .dropdown-menu ul li a img, select .dropdown-menu ul li a img {
          border-radius: 100%;
          -webkit-border-radius: 100%;
          margin-right: 5px; }
        .form-select .dropdown-menu ul li a:hover, select .dropdown-menu ul li a:hover {
          background-color: #f2f2f4; }
      .form-select .dropdown-menu ul li.selected a, select .dropdown-menu ul li.selected a {
        background-color: var(--primary);
        color: #fff; }
  .form-select:focus, select:focus, .form-select:active, select:active, .form-select.active, select.active {
    border-color: var(--primary); }

.form-select.btn-group, select.btn-group {
  padding: 10px 20px;
  border-width: 1px; }
  .form-select.btn-group.show, select.btn-group.show {
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1) !important;
    border-color: var(--primary); }
  .form-select.btn-group .form-control .dropdown-toggle.btn-default, select.btn-group .form-control .dropdown-toggle.btn-default, .form-select.btn-group .wp-block-categories-dropdown select .dropdown-toggle.btn-default, .wp-block-categories-dropdown .form-select.btn-group select .dropdown-toggle.btn-default, select.btn-group .wp-block-categories-dropdown select .dropdown-toggle.btn-default, .wp-block-categories-dropdown select.btn-group select .dropdown-toggle.btn-default, .form-select.btn-group .wp-block-archives-dropdown select .dropdown-toggle.btn-default, .wp-block-archives-dropdown .form-select.btn-group select .dropdown-toggle.btn-default, select.btn-group .wp-block-archives-dropdown select .dropdown-toggle.btn-default, .wp-block-archives-dropdown select.btn-group select .dropdown-toggle.btn-default {
    padding: 0; }
  .form-select.btn-group .dropdown-toggle .caret, select.btn-group .dropdown-toggle .caret {
    font-size: 10px;
    top: 50%;
    right: 5px;
    transform: translateY(-50%); }
    .form-select.btn-group .dropdown-toggle .caret:before, select.btn-group .dropdown-toggle .caret:before {
      content: "\f078"; }

.custom-file-label {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  height: 45px;
  border-radius: 30px !important;
  padding: 10px 20px;
  font-size: 15px;
  border-color: transparent; }
  .custom-file-label:after {
    content: none !important; }

.dz-form.radius-no .form-control, .dz-form.radius-no .wp-block-categories-dropdown select, .wp-block-categories-dropdown .dz-form.radius-no select,
.dz-form.radius-no .wp-block-archives-dropdown select, .wp-block-archives-dropdown .dz-form.radius-no select {
  border-radius: 0; }

.dz-form.style-1 textarea {
  height: 185px;
  padding: 15px ​20px; }

.input-group {
  margin-bottom: 20px; }

button:hover,
button:active,
button:focus {
  outline: 0; }

.btn, .wp-block-button__link {
  padding: 15px 30px;
  display: inline-flex;
  font-size: 15px;
  font-weight: 500;
  border-radius: 0;
  position: relative;
  line-height: 1.5;
  align-items: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  font-family: var(--font-family-base);
  justify-content: center; }
  .btn.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .wp-block-button__link, .btn-lg.wp-block-button__link {
    padding: 24px 40px;
    font-size: 16px; }
  .btn.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .wp-block-button__link, .btn-sm.wp-block-button__link {
    font-size: 15px;
    padding: 13px 24px; }
  .btn.btn-xs, .btn-xs.wp-block-button__link {
    font-size: 14px;
    padding: 11px 25px; }
  @media only screen and (max-width: 1200px) {
    .btn, .wp-block-button__link {
      padding: 14px 25px; } }
  .btn.btn-primary, .wp-block-button__link {
    color: #fff; }
    .btn.btn-primary:hover, .wp-block-button__link:hover {
      color: #fff; }
  .btn i, .wp-block-button__link i {
    font-size: 18px;
    line-height: 1; }
  .btn:not(.btn-icon):hover [class*="right"], .wp-block-button__link:not(.btn-icon):hover [class*="right"] {
    -webkit-animation: upToRight 0.5s forwards;
    -moz-animation: upToRight 0.5s forwards;
    animation: upToRight 0.5s forwards; }

.shadow-primary {
  box-shadow: 0px 12px 20px 0px var(--rgba-primary-3); }

.btn-info {
  color: #fff; }
  .btn-info:hover {
    color: #fff; }

.btn-success {
  color: #fff; }
  .btn-success:hover {
    color: #fff; }

.btn-danger {
  color: #fff; }
  .btn-danger:hover {
    color: #fff; }

.btn-warning {
  color: #fff; }
  .btn-warning:hover {
    color: #fff; }

.btn-white {
  background-color: #fff;
  color: var(--primary); }
  .btn-white:hover {
    color: var(--primary); }

.btn-primary, .wp-block-button__link {
  border-color: var(--primary);
  background-color: var(--primary); }
  .btn-primary.disabled, .disabled.wp-block-button__link, .btn-primary:disabled, .wp-block-button__link:disabled, .btn-primary:not(:disabled):not(.disabled).active, .wp-block-button__link:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .wp-block-button__link:not(:disabled):not(.disabled):active, .btn-primary:active, .wp-block-button__link:active, .btn-primary:focus, .wp-block-button__link:focus, .btn-primary:hover, .wp-block-button__link:hover {
    border-color: var(--primary-hover);
    background-color: var(--primary-hover); }

.btn-link {
  color: var(--primary);
  text-decoration: none; }
  .btn-link:hover {
    color: var(--primary-hover); }

.btn-outline-primary, .is-style-outline .wp-block-button__link {
  color: var(--primary);
  border-color: var(--primary); }
  .btn-outline-primary.disabled, .is-style-outline .disabled.wp-block-button__link, .btn-outline-primary:disabled, .is-style-outline .wp-block-button__link:disabled, .btn-outline-primary:not(:disabled):not(.disabled).active, .is-style-outline .wp-block-button__link:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .is-style-outline .wp-block-button__link:not(:disabled):not(.disabled):active, .btn-outline-primary:active, .is-style-outline .wp-block-button__link:active, .btn-outline-primary:focus, .is-style-outline .wp-block-button__link:focus, .btn-outline-primary:hover, .is-style-outline .wp-block-button__link:hover {
    border-color: var(--primary-hover);
    background-color: var(--primary-hover);
    color: #fff; }

.btn-link-lg {
  font-weight: 700;
  font-size: 18px; }
  .btn-link-lg:hover {
    text-decoration: unset; }

.btn-light {
  background-color: #fff; }
  .btn-light:hover {
    background-color: var(--primary);
    color: #fff !important;
    border-color: transparent; }
  .btn-light.text-primary:hover {
    color: #fff !important; }

.wp-block-button {
  margin-bottom: 0.3125rem; }

.is-style-squared .wp-block-button__link {
  border-radius: 0; }

.wp-block-button__link {
  border-radius: 60px; }
  .wp-block-button__link:after {
    content: none; }

.btn.btn-icon, .btn-icon.wp-block-button__link {
  padding: 14px 65px 14px 25px; }
  .btn.btn-icon i, .btn-icon.wp-block-button__link i {
    height: 36px;
    width: 36px;
    background-color: #fff;
    color: #000;
    line-height: 36px;
    font-size: 14px;
    display: block;
    position: absolute;
    transform: translateY(-50%);
    right: 8px;
    top: 50%;
    font-weight: 700;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
    .btn.btn-icon i:before, .btn-icon.wp-block-button__link i:before {
      position: absolute;
      line-height: 1; }
  .btn.btn-icon.btn-sm, .btn-group-sm > .btn.btn-icon, .btn-group-sm > .btn-icon.wp-block-button__link, .btn-icon.btn-sm.wp-block-button__link {
    padding: 12px 60px 12px 25px;
    font-size: 14px; }
    .btn.btn-icon.btn-sm i, .btn-group-sm > .btn.btn-icon i, .btn-group-sm > .btn-icon.wp-block-button__link i, .btn-icon.btn-sm.wp-block-button__link i {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 12px; }
  .btn.btn-icon.btn-lg, .btn-group-lg > .btn.btn-icon, .btn-group-lg > .btn-icon.wp-block-button__link, .btn-icon.btn-lg.wp-block-button__link {
    padding: 20px 85px 20px 40px; }
    .btn.btn-icon.btn-lg i, .btn-group-lg > .btn.btn-icon i, .btn-group-lg > .btn-icon.wp-block-button__link i, .btn-icon.btn-lg.wp-block-button__link i {
      width: 45px;
      height: 45px;
      line-height: 45px; }
  .btn.btn-icon.btn-light, .btn-icon.btn-light.wp-block-button__link {
    color: #ff5317; }
    .btn.btn-icon.btn-light i, .btn-icon.btn-light.wp-block-button__link i {
      background-color: #ff5317;
      color: #fff; }
  .btn.btn-icon:hover i:before, .btn-icon.wp-block-button__link:hover i:before {
    -webkit-animation: upToRight 0.5s forwards;
    -moz-animation: upToRight 0.5s forwards;
    animation: upToRight 0.5s forwards; }

.btn.btn-border, .btn-border.wp-block-button__link {
  padding: 14px 39px 20px 35px;
  margin: 6px 0 0 6px; }
  .btn.btn-border.btn-border-white:after, .btn-border.btn-border-white.wp-block-button__link:after {
    border: 2px solid #fff; }
  .btn.btn-border.white-border:after, .btn-border.white-border.wp-block-button__link:after {
    border-color: #fff; }
  .btn.btn-border:after, .btn-border.wp-block-button__link:after {
    content: "";
    height: 100%;
    width: 100%;
    border: 2px solid #000;
    position: absolute;
    left: -6px;
    top: -6px;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
  .btn.btn-border:hover, .btn-border.wp-block-button__link:hover {
    margin: 0 6px 6px 0;
    padding: 20px 35px 14px 39px; }
    .btn.btn-border:hover:after, .btn-border.wp-block-button__link:hover:after {
      left: 6px;
      top: 6px; }

@-webkit-keyframes upToRight {
  49% {
    -webkit-transform: translateX(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%); }
  51% {
    opacity: 1; } }

@-moz-keyframes upToRight {
  49% {
    -moz-transform: translateX(100%); }
  50% {
    opacity: 0;
    -moz-transform: translateX(-100%); }
  51% {
    opacity: 1; } }

@keyframes upToRight {
  49% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }
  51% {
    opacity: 1; } }

.table-responsive-sm {
  min-width: 48rem; }

.table {
  margin-bottom: 0px;
  font-weight: 400; }
  .table tr td,
  .table tr th {
    padding: 15px; }

.table-bordered > :not(caption) > * {
  border-width: 1px 1px; }

.table tr th {
  color: #212529;
  font-weight: 500; }

.table-striped thead tr th {
  padding-bottom: 15px !important; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff; }

.table-striped tfoot {
  border-top: 2px solid #E1E1F0; }
  .table-striped tfoot tr th {
    padding-top: 10px; }

.table > :not(:first-child) {
  border-top-color: #e3e3e3; }

.table.table-dark > :not(:first-child) {
  border-top-color: #2f3556; }

.table.table-dark tr th,
.table.bg-dark tr th {
  color: #fff; }

.table.table > :not(:first-child) {
  border-top: none; }

.table.table-1 thead {
  background: var(--primary); }
  .table.table-1 thead tr th {
    color: #fff; }

.table.table-2 thead tr th {
  border: 1px solid var(--primary); }

.dz-separator {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 8px;
  position: relative; }
  .dz-separator.m-b0 {
    margin-bottom: 0; }
  .dz-separator.style-liner {
    width: 20px; }
  .dz-separator.style-icon {
    width: 30px;
    height: auto;
    text-align: center;
    font-size: 20px; }
  .dz-separator.style-skew {
    width: 15px;
    height: 10px;
    margin-left: 1px;
    margin-right: 1px;
    -moz-transform: skewX(-10deg);
    -webkit-transform: skewX(-10deg);
    -o-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    transform: skewX(-10deg); }
    .dz-separator.style-skew[class*="style-"]:after, .dz-separator.style-skew[class*="style-"]:before {
      width: 80px;
      height: 4px;
      left: 20px;
      -moz-transform: translateY(-50%) skewX(-10deg);
      -webkit-transform: translateY(-50%) skewX(-10deg);
      -o-transform: translateY(-50%) skewX(-10deg);
      -ms-transform: translateY(-50%) skewX(-10deg);
      transform: translateY(-50%) skewX(-10deg); }
    .dz-separator.style-skew[class*="style-"]:before {
      right: 20px;
      left: auto; }
  .dz-separator.bnr-title {
    height: 1px;
    width: 155px;
    opacity: 0.5; }
    .dz-separator.bnr-title:before, .dz-separator.bnr-title:after {
      position: absolute;
      content: "";
      background-color: inherit; }
    .dz-separator.bnr-title:before {
      height: inherit;
      right: -80px;
      width: 25px; }
    .dz-separator.bnr-title:after {
      height: inherit;
      right: -90px;
      top: 0;
      width: 6px; }
    .dz-separator.bnr-title i {
      background-color: inherit;
      display: block;
      height: inherit;
      position: absolute;
      right: -50px;
      width: 45px; }

.dz-separator-outer {
  overflow: hidden; }

.dz-separator.style-1 {
  height: 2px;
  width: 80px;
  display: block;
  margin-top: 10px; }

.dz-separator.style-2 {
  height: 10px;
  display: block;
  width: 82px;
  position: relative;
  margin: 0; }
  .dz-separator.style-2:before, .dz-separator.style-2:after {
    content: "";
    height: 3px;
    width: 89px;
    position: absolute;
    background: var(--primary);
    left: 0; }
  .dz-separator.style-2:after {
    width: 80%;
    bottom: 0; }
  .dz-separator.style-2:before {
    width: 100%;
    top: 0; }

.site-filters {
  margin-bottom: 30px; }
  .site-filters ul {
    margin: 0;
    list-style: none; }
  .site-filters li {
    display: inline-block;
    padding: 0;
    margin-bottom: 3px; }
    .site-filters li.btn, .site-filters li.wp-block-button__link {
      box-shadow: none;
      -webkit-box-shadow: none;
      outline: none !important; }
    .site-filters li input {
      display: none; }
    .site-filters li a {
      margin: 0 5px 5px 0;
      display: block; }
    .site-filters li.active [class*="btn"] {
      color: #fff;
      background-color: #EFBB20; }
  .site-filters.center {
    text-align: center; }
    .site-filters.center ul {
      display: inline-block;
      margin: auto; }
    .site-filters.center [class*="btn"] {
      display: inline-block;
      margin: 0 5px 10px;
      text-transform: uppercase; }
      @media only screen and (max-width: 575px) {
        .site-filters.center [class*="btn"] {
          margin: 0 1px 5px;
          padding: 5px 10px;
          font-size: 12px; } }
  .site-filters.style-1 {
    margin-bottom: 45px; }
    .site-filters.style-1 .filters {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
      .site-filters.style-1 .filters li {
        text-transform: capitalize !important;
        margin: 0 15px -1px;
        border: 0;
        border-radius: 0; }
        .site-filters.style-1 .filters li:after {
          content: none; }
        .site-filters.style-1 .filters li a {
          color: #777777;
          font-size: 18px;
          margin: 0;
          border-bottom: 3px solid;
          border-color: transparent;
          padding: 0 2px 12px;
          font-weight: 500;
          text-transform: capitalize; }
          .site-filters.style-1 .filters li a.active {
            color: var(--primary);
            border-color: var(--primary); }
        .site-filters.style-1 .filters li.active a {
          color: var(--primary);
          border-color: var(--primary); }
        .site-filters.style-1 .filters li:first-child {
          margin-left: 0; }
        .site-filters.style-1 .filters li:last-child {
          margin-right: 0; }
        @media only screen and (max-width: 991px) {
          .site-filters.style-1 .filters li {
            margin: 0 5px -1px; }
            .site-filters.style-1 .filters li a {
              font-size: 15px; } }
        @media only screen and (max-width: 575px) {
          .site-filters.style-1 .filters li {
            margin: 0 0px -6px; }
            .site-filters.style-1 .filters li a {
              font-size: 14px; } }
  .site-filters.style-2 {
    margin-bottom: 45px; }
    .site-filters.style-2 .filters {
      margin: 0 -5px; }
      .site-filters.style-2 .filters li {
        text-transform: capitalize !important;
        margin: 0 5px;
        border: 0;
        border-radius: 0; }
        .site-filters.style-2 .filters li:after {
          content: none; }
        .site-filters.style-2 .filters li a {
          color: #777777;
          font-size: 16px;
          margin: 0;
          border: 2px solid rgba(0, 0, 0, 0.1);
          padding: 8px 24px;
          font-weight: 500;
          text-transform: capitalize; }
        .site-filters.style-2 .filters li.active a, .site-filters.style-2 .filters li:hover a {
          color: #fff;
          background-color: var(--primary); }
        .site-filters.style-2 .filters li:first-child {
          margin-left: 0; }
        .site-filters.style-2 .filters li:last-child {
          margin-right: 0; }
        @media only screen and (max-width: 991px) {
          .site-filters.style-2 .filters li {
            margin: 0 5px -1px; }
            .site-filters.style-2 .filters li a {
              font-size: 15px; } }
        @media only screen and (max-width: 575px) {
          .site-filters.style-2 .filters li {
            margin: 0 0px -6px; }
            .site-filters.style-2 .filters li a {
              font-size: 14px; } }
  .site-filters.style-3 {
    margin-bottom: 45px; }
    .site-filters.style-3 .filters {
      margin: 0 -5px; }
      .site-filters.style-3 .filters li {
        text-transform: capitalize !important;
        margin: 0 10px;
        border: 0;
        border-radius: 0; }
        .site-filters.style-3 .filters li:after {
          content: none; }
        .site-filters.style-3 .filters li a {
          color: #777777;
          font-size: 18px;
          margin: 0;
          padding: 0;
          font-weight: 500;
          text-transform: capitalize; }
          .site-filters.style-3 .filters li a:before {
            content: "";
            height: 3px;
            background-color: var(--primary);
            width: 0;
            -webkit-transition: all 0.2s;
            -ms-transition: all 0.2s;
            transition: all 0.2s;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.5;
            transform-origin: left;
            -moz-transform-origin: left;
            -ms-transform-origin: left;
            -webkit-transform-origin: left;
            -o-transform-origin: left; }
        .site-filters.style-3 .filters li.active a, .site-filters.style-3 .filters li:hover a {
          color: var(--primary); }
          .site-filters.style-3 .filters li.active a:before, .site-filters.style-3 .filters li:hover a:before {
            left: 0;
            right: auto;
            width: 100%;
            transform-origin: right;
            -moz-transform-origin: right;
            -ms-transform-origin: right;
            -webkit-transform-origin: right;
            -o-transform-origin: right; }
        .site-filters.style-3 .filters li:first-child {
          margin-left: 0; }
        .site-filters.style-3 .filters li:last-child {
          margin-right: 0; }
        @media only screen and (max-width: 991px) {
          .site-filters.style-3 .filters li {
            margin: 0 5px -1px; }
            .site-filters.style-3 .filters li a {
              font-size: 15px; } }
        @media only screen and (max-width: 575px) {
          .site-filters.style-3 .filters li {
            margin: 0 0px -6px; }
            .site-filters.style-3 .filters li a {
              font-size: 14px; } }

.dz-img-overlay1,
.dz-img-overlay2,
.dz-img-overlay3,
.dz-img-overlay4,
.dz-img-overlay5,
.dz-img-overlay6,
.dz-img-overlay7,
.dz-img-overlay8,
.dz-img-overlay9,
.dz-img-overlay11,
.dz-img-overlay12 {
  position: relative; }
  .dz-img-overlay1:before, .dz-img-overlay1:after,
  .dz-img-overlay2:before,
  .dz-img-overlay2:after,
  .dz-img-overlay3:before,
  .dz-img-overlay3:after,
  .dz-img-overlay4:before,
  .dz-img-overlay4:after,
  .dz-img-overlay5:before,
  .dz-img-overlay5:after,
  .dz-img-overlay6:before,
  .dz-img-overlay6:after,
  .dz-img-overlay7:before,
  .dz-img-overlay7:after,
  .dz-img-overlay8:before,
  .dz-img-overlay8:after,
  .dz-img-overlay9:before,
  .dz-img-overlay9:after,
  .dz-img-overlay11:before,
  .dz-img-overlay11:after,
  .dz-img-overlay12:before,
  .dz-img-overlay12:after {
    content: "";
    background: #282d32;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0.4;
    z-index: 1;
    -webkit-transition: all 0.8s linear;
    -moz-transition: all 0.8s linear;
    -ms-transition: all 0.8s linear;
    -o-transition: all 0.8s linear;
    transition: all 0.8s linear; }
  .dz-img-overlay1.overlay-primary,
  .dz-img-overlay2.overlay-primary,
  .dz-img-overlay3.overlay-primary,
  .dz-img-overlay4.overlay-primary,
  .dz-img-overlay5.overlay-primary,
  .dz-img-overlay6.overlay-primary,
  .dz-img-overlay7.overlay-primary,
  .dz-img-overlay8.overlay-primary,
  .dz-img-overlay9.overlay-primary,
  .dz-img-overlay11.overlay-primary,
  .dz-img-overlay12.overlay-primary {
    background: var(--primary); }

.dz-box:hover .dz-img-overlay1:before {
  opacity: 0.7; }

.dz-box:hover .dz-img-overlay2:before {
  opacity: 0.9; }

.dz-box:hover .dz-img-overlay3:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.dz-box:hover .dz-img-overlay4:before {
  width: 100%;
  left: 0; }

.dz-box:hover .dz-img-overlay5:before {
  height: 100%;
  top: 0; }

.dz-box:hover .dz-img-overlay6:before {
  height: 100%; }

.dz-box:hover .dz-img-overlay7:before,
.dz-box:hover .dz-img-overlay7:after {
  width: 50%; }

.dz-box:hover .dz-img-overlay8:before,
.dz-box:hover .dz-img-overlay8:after {
  width: 100%;
  opacity: 0.3; }

.dz-box:hover .dz-img-overlay9:before,
.dz-box:hover .dz-img-overlay9:after {
  width: 100%;
  height: 100%;
  opacity: 0.3; }

.dz-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0; }

.dz-img-overlay1:hover:before {
  opacity: 0.5; }

.dz-img-overlay2:before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%); }
  .dz-img-overlay2:before:hover:before {
    opacity: 0.9; }

.dz-img-overlay3:before {
  left: 50%;
  top: 50%; }

.dz-img-overlay3:hover:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.dz-img-overlay4:before {
  left: 50%;
  top: 0;
  height: 100%; }

.dz-img-overlay4:hover:before {
  width: 100%;
  left: 0; }

.dz-img-overlay5:before {
  left: 0;
  top: 50%;
  width: 100%; }

.dz-img-overlay5:hover:before {
  height: 100%;
  top: 0; }

.dz-img-overlay6:before {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%; }

.dz-img-overlay6:hover:before {
  height: 100%; }

.dz-img-overlay7:before, .dz-img-overlay7:after {
  height: 100%; }

.dz-img-overlay7:after {
  left: auto;
  right: 0; }

.dz-img-overlay7:hover:before, .dz-img-overlay7:hover:after {
  width: 50%; }

.dz-img-overlay8:before, .dz-img-overlay8:after {
  height: 100%; }

.dz-img-overlay8:after {
  left: auto;
  right: 0; }

.dz-img-overlay8:hover:before, .dz-img-overlay8:hover:after {
  width: 100%;
  opacity: 0.3; }

.dz-img-overlay9:before:before, .dz-img-overlay9:before:after,
.dz-img-overlay9:after:before,
.dz-img-overlay9:after:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0; }

.dz-img-overlay9:before:hover:before, .dz-img-overlay9:before:hover:after,
.dz-img-overlay9:after:hover:before,
.dz-img-overlay9:after:hover:after {
  width: 100%;
  height: 100%;
  opacity: 0.3; }

/*  */
.overlay-black-light,
.overlay-black-middle,
.overlay-black-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-gradient-dark,
.overlay-white-light,
.overlay-white-middle,
.overlay-white-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-primary-dark,
.overlay-primary {
  position: relative; }
  .overlay-black-light:before,
  .overlay-black-middle:before,
  .overlay-black-dark:before,
  .overlay-gradient-light:before,
  .overlay-gradient-middle:before,
  .overlay-gradient-dark:before,
  .overlay-white-light:before,
  .overlay-white-middle:before,
  .overlay-white-dark:before,
  .overlay-primary-light:before,
  .overlay-primary-middle:before,
  .overlay-primary-dark:before,
  .overlay-primary:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .overlay-black-light .container,
  .overlay-black-light .container-fluid,
  .overlay-black-light .container-sm,
  .overlay-black-light .container-md,
  .overlay-black-light .container-lg,
  .overlay-black-light .container-xl,
  .overlay-black-middle .container,
  .overlay-black-middle .container-fluid,
  .overlay-black-middle .container-sm,
  .overlay-black-middle .container-md,
  .overlay-black-middle .container-lg,
  .overlay-black-middle .container-xl,
  .overlay-black-dark .container,
  .overlay-black-dark .container-fluid,
  .overlay-black-dark .container-sm,
  .overlay-black-dark .container-md,
  .overlay-black-dark .container-lg,
  .overlay-black-dark .container-xl,
  .overlay-gradient-light .container,
  .overlay-gradient-light .container-fluid,
  .overlay-gradient-light .container-sm,
  .overlay-gradient-light .container-md,
  .overlay-gradient-light .container-lg,
  .overlay-gradient-light .container-xl,
  .overlay-gradient-middle .container,
  .overlay-gradient-middle .container-fluid,
  .overlay-gradient-middle .container-sm,
  .overlay-gradient-middle .container-md,
  .overlay-gradient-middle .container-lg,
  .overlay-gradient-middle .container-xl,
  .overlay-gradient-dark .container,
  .overlay-gradient-dark .container-fluid,
  .overlay-gradient-dark .container-sm,
  .overlay-gradient-dark .container-md,
  .overlay-gradient-dark .container-lg,
  .overlay-gradient-dark .container-xl,
  .overlay-white-light .container,
  .overlay-white-light .container-fluid,
  .overlay-white-light .container-sm,
  .overlay-white-light .container-md,
  .overlay-white-light .container-lg,
  .overlay-white-light .container-xl,
  .overlay-white-middle .container,
  .overlay-white-middle .container-fluid,
  .overlay-white-middle .container-sm,
  .overlay-white-middle .container-md,
  .overlay-white-middle .container-lg,
  .overlay-white-middle .container-xl,
  .overlay-white-dark .container,
  .overlay-white-dark .container-fluid,
  .overlay-white-dark .container-sm,
  .overlay-white-dark .container-md,
  .overlay-white-dark .container-lg,
  .overlay-white-dark .container-xl,
  .overlay-primary-light .container,
  .overlay-primary-light .container-fluid,
  .overlay-primary-light .container-sm,
  .overlay-primary-light .container-md,
  .overlay-primary-light .container-lg,
  .overlay-primary-light .container-xl,
  .overlay-primary-middle .container,
  .overlay-primary-middle .container-fluid,
  .overlay-primary-middle .container-sm,
  .overlay-primary-middle .container-md,
  .overlay-primary-middle .container-lg,
  .overlay-primary-middle .container-xl,
  .overlay-primary-dark .container,
  .overlay-primary-dark .container-fluid,
  .overlay-primary-dark .container-sm,
  .overlay-primary-dark .container-md,
  .overlay-primary-dark .container-lg,
  .overlay-primary-dark .container-xl,
  .overlay-primary .container,
  .overlay-primary .container-fluid,
  .overlay-primary .container-sm,
  .overlay-primary .container-md,
  .overlay-primary .container-lg,
  .overlay-primary .container-xl {
    position: relative;
    z-index: 1; }

.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before {
  background: #000; }

.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); }

.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before {
  background: #FFF; }

.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  background: var(--primary); }

.overlay-primary-light:before,
.overlay-white-light:before,
.overlay-gradient-light:before,
.overlay-black-light:before {
  opacity: 0.3; }

.overlay-primary-middle:before,
.overlay-white-middle:before,
.overlay-gradient-middle:before,
.overlay-black-middle:before {
  opacity: 0.7; }

.overlay-primary-dark:before,
.overlay-white-dark:before,
.overlay-gradient-dark:before,
.overlay-black-dark:before {
  opacity: 0.9; }

.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  .overlay-bx .align-b {
    height: auto;
    left: 0;
    list-style: outside none none;
    margin: 0;
    position: absolute;
    top: auto;
    bottom: 0;
    width: 100%;
    transform: translate(0);
    -o-transform: translate(0);
    -moz-transform: translate(0);
    -webkit-transform: translate(0); }
  .overlay-bx .align-m {
    height: auto;
    left: 50%;
    list-style: outside none none;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 10px; }

.overlay-icon {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px; }
  .overlay-icon a {
    display: inline-block;
    padding: 0;
    margin: 0 2px; }
    .overlay-icon a i {
      background-color: #FFF; }

.overlay-bx:hover a > i,
.dz-media:hover .overlay-bx a > i,
.dz-box-bx:hover .overlay-bx a > i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }

.overlay-bx:hover,
.dz-media:hover .overlay-bx,
.dz-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible; }

.gallery-box-1 .overlay-bx .overlay-icon span i,
.gallery-box-1 .overlay-bx .overlay-icon a i {
  background-color: #000;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer; }

.gallery-box-1 .overlay-bx .icon-bx-xs {
  width: 60px;
  height: 60px;
  line-height: 60px; }

.img-overlay-gradients {
  position: relative; }
  .img-overlay-gradients:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%; }
  .img-overlay-gradients.gradients-secondary:before {
    background: -moz-linear-gradient(bottom, transparent 0%, #192436 100%);
    background: -webkit-linear-gradient(bottom, transparent 0%, #192436 100%);
    background: linear-gradient(to bottom, transparent 0%, #192436 100%); }
  .img-overlay-gradients.gradients-primary:before {
    background: -moz-linear-gradient(bottom, transparent 0%, var(--primary) 100%);
    background: -webkit-linear-gradient(bottom, transparent 0%, var(--primary) 100%);
    background: linear-gradient(to bottom, transparent 0%, var(--primary) 100%); }

.overlay-shine .dz-media {
  position: relative;
  overflow: hidden; }
  .overlay-shine .dz-media:before {
    position: absolute;
    top: 0;
    left: -85%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg); }

.overlay-shine:hover .dz-media:before {
  -webkit-animation: shine .75s;
  animation: shine .75s; }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

/* info box show */
.dz-info-has {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 30px 15px 40px;
  opacity: 0;
  margin-bottom: -100px;
  z-index: 2; }
  .dz-info-has.p-a15 {
    padding: 15px; }
  .dz-info-has.p-a20 {
    padding: 20px; }
  .dz-info-has h1, .dz-info-has .h1,
  .dz-info-has h2,
  .dz-info-has .h2,
  .dz-info-has h3,
  .dz-info-has .h3,
  .dz-info-has h4,
  .dz-info-has .h4,
  .dz-info-has .comment-reply-title,
  .dz-info-has .wp-block-search .wp-block-search__label,
  .wp-block-search .dz-info-has .wp-block-search__label,
  .dz-info-has h5,
  .dz-info-has .h5,
  .dz-info-has a,
  .dz-info-has p,
  .dz-info-has h6,
  .dz-info-has .h6 {
    color: #fff; }
  .dz-info-has .dz-info-has-text {
    margin-bottom: 20px;
    line-height: 24px; }
  .dz-info-has.bg-white h1, .dz-info-has.bg-white .h1,
  .dz-info-has.bg-white h2,
  .dz-info-has.bg-white .h2,
  .dz-info-has.bg-white h3,
  .dz-info-has.bg-white .h3,
  .dz-info-has.bg-white h4,
  .dz-info-has.bg-white .h4,
  .dz-info-has.bg-white .comment-reply-title,
  .dz-info-has.bg-white .wp-block-search .wp-block-search__label,
  .wp-block-search .dz-info-has.bg-white .wp-block-search__label,
  .dz-info-has.bg-white h5,
  .dz-info-has.bg-white .h5,
  .dz-info-has.bg-white h6,
  .dz-info-has.bg-white .h6 {
    color: #3d474a; }
    .dz-info-has.bg-white h1 a, .dz-info-has.bg-white .h1 a,
    .dz-info-has.bg-white h2 a,
    .dz-info-has.bg-white .h2 a,
    .dz-info-has.bg-white h3 a,
    .dz-info-has.bg-white .h3 a,
    .dz-info-has.bg-white h4 a,
    .dz-info-has.bg-white .h4 a,
    .dz-info-has.bg-white .comment-reply-title a,
    .dz-info-has.bg-white .wp-block-search .wp-block-search__label a,
    .wp-block-search .dz-info-has.bg-white .wp-block-search__label a,
    .dz-info-has.bg-white h5 a,
    .dz-info-has.bg-white .h5 a,
    .dz-info-has.bg-white h6 a,
    .dz-info-has.bg-white .h6 a {
      color: #3d474a; }
  .dz-info-has.bg-white p {
    color: #3d474a; }

.dz-info-has.bg-primary {
  background-color: rgba(27, 188, 232, 0.9); }

.dz-info-has.bg-green {
  background-color: rgba(106, 179, 62, 0.9); }

.dz-info-has.bg-black {
  background-color: rgba(0, 0, 0, 0.6); }

.dz-info-has.bg-white {
  background-color: rgba(255, 255, 255, 0.8) !important; }

.dz-media {
  overflow: hidden;
  position: relative; }
  .dz-media:hover .dz-info-has {
    opacity: 1;
    width: 100%;
    margin-bottom: 0; }

.dz-box:hover .dz-info-has {
  opacity: 1;
  width: 100%;
  margin-bottom: 0; }

.overlay-bx.no-hover,
.dz-info-has.no-hover {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
  visibility: visible; }

[class*="slider-btn-"] .swiper-button .button-prev,
[class*="slider-btn-"] .swiper-button .button-next {
  cursor: pointer; }
  [class*="slider-btn-"] .swiper-button .button-prev:after,
  [class*="slider-btn-"] .swiper-button .button-next:after {
    content: none; }

.slider-btn-1 .swiper-pagination {
  position: unset;
  font-size: 20px;
  font-weight: 500; }
  .slider-btn-1 .swiper-pagination.swiper-pagination-fraction {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: -18px; }
    .slider-btn-1 .swiper-pagination.swiper-pagination-fraction .swiper-pagination-current {
      color: var(--primary); }

.slider-btn-1 .swiper-button {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 180px;
  margin: 30px auto 0;
  position: relative; }
  .slider-btn-1 .swiper-button .button-prev,
  .slider-btn-1 .swiper-button .button-next {
    position: unset;
    display: block;
    text-align: center;
    font-size: 25px;
    line-height: 1;
    color: #000;
    margin: 0 40px;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
  @media only screen and (max-width: 575px) {
    .slider-btn-1 .swiper-button {
      margin-top: 20px; } }

.pricingtable-features {
  margin: 0;
  padding: 0;
  list-style: none; }
  .pricingtable-features li {
    padding: 12px; }
    .pricingtable-features li i {
      margin: 0 3px; }

.no-col-gap .pricingtable-wrapper {
  margin-left: -1px; }

.table-option {
  border: 2px solid #adadad;
  border-radius: 6px;
  padding: 50px 0px 30px 0; }
  .table-option .pricingtable-footer {
    border: 0;
    background-color: transparent; }
  .table-option .pricingtable-features {
    border: 0;
    background-color: transparent; }
    .table-option .pricingtable-features li {
      border: 0;
      background-color: transparent;
      color: #919191;
      font-size: 18px; }
  .table-option .pricingtable-title {
    border: 0;
    background-color: transparent;
    padding: 0; }
    .table-option .pricingtable-title * {
      color: #000; }
    .table-option .pricingtable-title h2, .table-option .pricingtable-title .h2 {
      font-size: 28px;
      line-height: 36px; }
  .table-option .pricingtable-price {
    padding: 0;
    border: 0;
    background-color: transparent; }
  .table-option .pricingtable-bx {
    color: #000;
    font-size: 63px;
    font-weight: 800; }
    .table-option .pricingtable-bx strong, .table-option .pricingtable-bx sup {
      font-size: 30px;
      font-weight: 700; }
  .table-option:hover, .table-option.active {
    background-color: #eeeeee;
    border: 2px solid #eeeeee; }
  .table-option.dark {
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
    .table-option.dark:hover, .table-option.dark.active {
      background-color: #404040;
      border: 2px solid #404040;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
      transition: all 0.5s; }
      .table-option.dark:hover .pricingtable-features li,
      .table-option.dark:hover .pricingtable-title h2,
      .table-option.dark:hover .pricingtable-title .h2,
      .table-option.dark:hover .pricingtable-price .pricingtable-bx, .table-option.dark.active .pricingtable-features li,
      .table-option.dark.active .pricingtable-title h2,
      .table-option.dark.active .pricingtable-title .h2,
      .table-option.dark.active .pricingtable-price .pricingtable-bx {
        color: #fff;
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s; }
      .table-option.dark:hover .pricingtable-footer .btn.black, .table-option.dark:hover .pricingtable-footer .black.wp-block-button__link, .table-option.dark.active .pricingtable-footer .btn.black, .table-option.dark.active .pricingtable-footer .black.wp-block-button__link {
        background-color: #fff;
        border-color: rgba(0, 0, 0, 0);
        color: #000; }

.pricingtable-toggle {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 0;
  margin-bottom: 10px; }
  .pricingtable-toggle span {
    font-size: 18px;
    margin-top: 5px;
    font-weight: 500; }
  .pricingtable-toggle .custom-control-label {
    margin: 0 35px 0 50px; }

.pricingtable-wrapper {
  box-shadow: 1px 0 50px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-base) !important;
  overflow: hidden;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  background-color: #fff; }
  .pricingtable-wrapper.style-1 {
    padding: 35px;
    position: relative;
    z-index: 1;
    height: 100%; }
    .pricingtable-wrapper.style-1 .pricingtable-inner {
      display: flex;
      flex-direction: column;
      height: 100%; }
    .pricingtable-wrapper.style-1 .pricingtable-head {
      padding-bottom: 25px; }
      .pricingtable-wrapper.style-1 .pricingtable-head .pricingtable-title {
        margin-bottom: 0;
        font-size: 35px; }
        @media only screen and (max-width: 1199px) {
          .pricingtable-wrapper.style-1 .pricingtable-head .pricingtable-title {
            font-size: 24px; } }
      .pricingtable-wrapper.style-1 .pricingtable-head .sub-title {
        color: var(--title);
        font-size: 14px;
        font-family: var(--font-family-title); }
    .pricingtable-wrapper.style-1 .pricingtable-media {
      margin: -35px -35px 40px; }
      .pricingtable-wrapper.style-1 .pricingtable-media img {
        width: 100%; }
    .pricingtable-wrapper.style-1 .pricingtable-footer {
      margin-top: auto; }
    .pricingtable-wrapper.style-1 .pricingtable-price {
      margin-bottom: 30px; }
      .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx {
        display: flex;
        font-size: 78px;
        line-height: 78px; }
        @media only screen and (max-width: 1199px) {
          .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx {
            font-size: 50px;
            line-height: 1;
            font-size: 40px;
            margin: 7px 5px 0;
            line-height: 40px; } }
  @media only screen and (max-width: 1199px) and (max-width: 1199px) {
    .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx {
      font-size: 28px;
      line-height: 1;
      margin: 5px 2px 0; } }
        .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx .ext {
          margin-left: 12px; }
      .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-type {
        display: block;
        font-weight: 300;
        font-size: 24px;
        line-height: 24px;
        font-family: var(--font-family-title);
        margin-top: 10px; }
        @media only screen and (max-width: 1199px) {
          .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-type {
            font-size: 16px;
            line-height: 1;
            margin-top: 2px; } }
    .pricingtable-wrapper.style-1 .pricingtable-features {
      border-top: 1px solid #E1E1F0;
      padding-top: 20px;
      padding-bottom: 25px;
      text-align: left; }
      .pricingtable-wrapper.style-1 .pricingtable-features li {
        font-size: 20px;
        padding: 5px 0 5px 35px;
        position: relative;
        font-family: var(--font-family-title);
        color: #666e82;
        cursor: pointer; }
        @media only screen and (max-width: 1199px) {
          .pricingtable-wrapper.style-1 .pricingtable-features li {
            font-size: 16px;
            padding: 5px 0 5px 25px; } }
        .pricingtable-wrapper.style-1 .pricingtable-features li:after {
          content: "\f00c";
          font-family: 'Font Awesome 5 Free';
          font-size: 16px;
          font-weight: 600;
          display: block;
          position: absolute;
          left: 0;
          color: var(--title);
          border-radius: 35px;
          text-align: center;
          top: 10px;
          -webkit-transition: all 0.8s;
          -ms-transition: all 0.8s;
          transition: all 0.8s; }
          @media only screen and (max-width: 1199px) {
            .pricingtable-wrapper.style-1 .pricingtable-features li:after {
              font-size: 14px;
              top: 7px; } }
        .pricingtable-wrapper.style-1 .pricingtable-features li:hover:after {
          transform: scale(1.25);
          color: var(--primary); }

.dz-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
  vertical-align: middle; }
  .dz-img-effect img {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
  .dz-img-effect.opacity img:hover {
    opacity: 0.8; }
  .dz-img-effect.zoom-slow img {
    transition: all 10s;
    -moz-transition: all 10s;
    -webkit-transition: all 10s;
    -o-transition: all 10s; }
  .dz-img-effect.zoom-slow:hover img {
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2); }
  .dz-img-effect.zoom:hover img {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5); }
  .dz-img-effect.shrink:hover img {
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8); }
  .dz-img-effect.side-pan:hover img {
    margin-left: -11%;
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2); }
  .dz-img-effect.vertical-pan:hover img {
    margin-top: -10%;
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2); }
  .dz-img-effect.fade-in {
    background: #000; }
    .dz-img-effect.fade-in img {
      opacity: 0.65; }
    .dz-img-effect.fade-in:hover img {
      opacity: 1; }
  .dz-img-effect.fade-out {
    background: #000; }
    .dz-img-effect.fade-out:hover img {
      opacity: 0.7; }
  .dz-img-effect.rotate:hover img {
    -moz-transform: scale(1.5) rotate(-20deg);
    -webkit-transform: scale(1.5) rotate(-20deg);
    -o-transform: scale(1.5) rotate(-20deg);
    -ms-transform: scale(1.5) rotate(-20deg);
    transform: scale(1.5) rotate(-20deg); }
  .dz-img-effect.image-sepia img {
    -webkit-filter: sepia(100%);
    filter: sepia(100%); }
  .dz-img-effect.blurr img {
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
  .dz-img-effect.blurr:hover img {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px); }
  .dz-img-effect.blurr-invert img {
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    filter: blur(3px);
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px); }
  .dz-img-effect.blurr-invert:hover img {
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px); }
  .dz-img-effect.off-color img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -o-filter: grayscale(0);
    -ms-filter: grayscale(0); }
  .dz-img-effect.off-color:hover img {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
    -ms-filter: grayscale(1); }
  .dz-img-effect.on-color img {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
    -ms-filter: grayscale(1); }
  .dz-img-effect.on-color:hover img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -o-filter: grayscale(0);
    -ms-filter: grayscale(0); }

.dz-box:hover .dz-img-effect.rotate img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg); }

.accordion.dz-accordion1 .accordion-item {
  overflow: hidden;
  background: #fff;
  margin-bottom: 20px;
  border: 0; }

.accordion.dz-accordion1 .accordion-body {
  padding: 25px 30px;
  border-radius: 0px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-width: 0 2px 2px; }

.accordion.dz-accordion1 .accordion-collapse {
  border: 0; }

.accordion.dz-accordion1 .accordion-header .accordion-button {
  font-size: 18px;
  border: 2px solid;
  border-color: var(--primary);
  border-radius: 0;
  padding: 20px 65px 20px 35px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  color: #fff;
  background-color: var(--primary);
  font-weight: bold; }
  .accordion.dz-accordion1 .accordion-header .accordion-button:after {
    content: none; }
  .accordion.dz-accordion1 .accordion-header .accordion-button .toggle-close {
    font-family: themify;
    display: block;
    position: absolute;
    right: 25px;
    top: 21px;
    text-align: center;
    font-size: 18px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    z-index: 1;
    overflow: hidden;
    height: 20px;
    width: 20px;
    transform: rotate(90deg); }
    .accordion.dz-accordion1 .accordion-header .accordion-button .toggle-close:before {
      content: "\f054";
      color: var(--title);
      font-size: 16px;
      font-family: "Font Awesome 5 Free";
      z-index: 99;
      opacity: 1;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      display: block;
      color: #fff;
      font-weight: bold; }
  .accordion.dz-accordion1 .accordion-header .accordion-button.collapsed {
    background: #fff;
    color: inherit;
    border-color: rgba(0, 0, 0, 0.2); }
    .accordion.dz-accordion1 .accordion-header .accordion-button.collapsed:after {
      opacity: 0; }
    .accordion.dz-accordion1 .accordion-header .accordion-button.collapsed .toggle-close {
      transform: rotate(0); }
      .accordion.dz-accordion1 .accordion-header .accordion-button.collapsed .toggle-close:before {
        opacity: 1;
        color: var(--title);
        transform: rotate(0); }

@media only screen and (max-width: 575px) {
  .accordion.dz-accordion1 .accordion-header .accordion-button {
    padding: 15px 65px 15px 15px;
    font-size: 16px; } }

.accordion.dz-accordion2 .accordion-item {
  box-shadow: 1px 0px 60px 0 rgba(0, 0, 0, 0.08);
  border-radius: var(--border-radius-base);
  overflow: hidden;
  background: #fff;
  margin-bottom: 20px; }

.accordion.dz-accordion2 .accordion-body {
  padding: 25px 30px;
  border-radius: 0; }

.accordion.dz-accordion2 .accordion-collapse {
  border: 0; }

.accordion.dz-accordion2 .accordion-header .accordion-button {
  font-size: 18px;
  border: 0;
  border-radius: 4px 4px 0 0;
  padding: 22px 65px 22px 35px;
  transition: all 0.5s;
  color: #fff;
  box-shadow: 5px 0 15px var(--rgba-primary-4);
  background-color: var(--primary);
  font-weight: bold; }
  .accordion.dz-accordion2 .accordion-header .accordion-button:after {
    content: none; }
  .accordion.dz-accordion2 .accordion-header .accordion-button .toggle-close {
    background-color: #fff;
    font-family: themify;
    height: 30px;
    width: 30px;
    display: block;
    position: absolute;
    right: 25px;
    top: 22px;
    border-radius: 30px;
    line-height: 25px;
    text-align: center;
    font-size: 16px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    z-index: 1;
    overflow: hidden;
    transform: rotate(90deg); }
    .accordion.dz-accordion2 .accordion-header .accordion-button .toggle-close:after {
      content: "";
      position: absolute;
      z-index: -1;
      background-color: var(--primary);
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0; }
    .accordion.dz-accordion2 .accordion-header .accordion-button .toggle-close:before {
      content: "\f054";
      color: var(--primary);
      font-size: 14px;
      font-family: "Font Awesome 5 Free";
      z-index: 99;
      opacity: 1;
      transform: rotate(-90deg);
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      line-height: 28px;
      display: block;
      left: 48%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      font-weight: bold; }
    @media only screen and (max-width: 575px) {
      .accordion.dz-accordion2 .accordion-header .accordion-button .toggle-close {
        right: 20px; } }
  .accordion.dz-accordion2 .accordion-header .accordion-button.collapsed {
    background: #fff;
    color: inherit; }
    .accordion.dz-accordion2 .accordion-header .accordion-button.collapsed:after {
      opacity: 0; }
    .accordion.dz-accordion2 .accordion-header .accordion-button.collapsed .toggle-close {
      transform: rotate(0); }
      .accordion.dz-accordion2 .accordion-header .accordion-button.collapsed .toggle-close:after {
        opacity: 1; }
      .accordion.dz-accordion2 .accordion-header .accordion-button.collapsed .toggle-close:before {
        opacity: 1;
        color: #fff; }

@media only screen and (max-width: 575px) {
  .accordion.dz-accordion2 .accordion-header .accordion-button {
    padding: 15px 65px 15px 15px;
    font-size: 16px; } }

.pagination li {
  margin: 0 8px; }
  .pagination li .page-numbers,
  .pagination li .page-link {
    height: 60px;
    min-width: 60px;
    line-height: 60px;
    border-radius: 60px;
    color: #777777;
    text-align: center;
    border: 0;
    padding: 0;
    transition: all 0.5s;
    font-size: 18px;
    padding: 0 10px;
    display: block;
    background-color: #ebebeb; }
    .pagination li .page-numbers.current, .pagination li .page-numbers.active, .pagination li .page-numbers:hover,
    .pagination li .page-link.current,
    .pagination li .page-link.active,
    .pagination li .page-link:hover {
      color: #fff;
      background-color: var(--primary);
      border-color: var(--primary);
      box-shadow: 0px 5px 12px var(--rgba-primary-4); }
  @media only screen and (max-width: 575px) {
    .pagination li {
      margin: 0 5px; }
      .pagination li .page-numbers,
      .pagination li .page-link {
        height: 40px;
        min-width: 40px;
        line-height: 40px;
        border-radius: 40px; } }
  .pagination li .prev,
  .pagination li .next {
    background-color: transparent;
    border-color: transparent;
    font-size: 18px; }
    .pagination li .prev:hover,
    .pagination li .next:hover {
      background-color: transparent;
      box-shadow: none;
      color: var(--primary); }

.pagination.text-center {
  justify-content: center; }

.pagination.rounded-0 .page-item .page-link {
  border-radius: 0; }

.pagination.pagination-lg li .page-numbers,
.pagination.pagination-lg li .page-link {
  height: 80px;
  min-width: 80px;
  line-height: 80px;
  border-radius: 80px; }
  @media only screen and (max-width: 575px) {
    .pagination.pagination-lg li .page-numbers,
    .pagination.pagination-lg li .page-link {
      height: 50px;
      min-width: 50px;
      line-height: 50px;
      border-radius: 50px; } }

.pagination.pagination-sm li .page-numbers,
.pagination.pagination-sm li .page-link {
  height: 40px;
  min-width: 40px;
  line-height: 40px;
  border-radius: 40px; }

.dz-content-bx {
  position: relative;
  overflow: hidden; }
  .dz-content-bx .content-media img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .dz-content-bx .dz-content-inner {
    position: relative; }
  .dz-content-bx.style-1 .dz-content-inner .content-media {
    width: 50vw;
    height: calc(100% + 50px);
    float: right;
    margin-top: -50px;
    z-index: 1;
    padding-bottom: 50px;
    background: #fff; }
  .dz-content-bx.style-1 .dz-content-inner .inner-content {
    padding: 50px 0px 50px 50px; }
  @media only screen and (max-width: 991px) {
    .dz-content-bx.style-1 .dz-content-inner .content-media {
      width: 100%;
      padding-bottom: 0; }
    .dz-content-bx.style-1 .dz-content-inner .inner-content {
      padding: 60px 15px 10px; } }
  @media only screen and (max-width: 767px) {
    .dz-content-bx.style-1 .dz-content-inner .inner-content {
      padding: 50px 15px 0; } }
  .dz-content-bx.style-2 .content-media {
    width: 50vw;
    margin: -120px 0 0 0;
    float: right;
    height: 100%; }
    .dz-content-bx.style-2 .content-media.right {
      float: left; }
    .dz-content-bx.style-2 .content-media img {
      height: 100%;
      object-fit: cover; }
    @media only screen and (max-width: 991px) {
      .dz-content-bx.style-2 .content-media {
        margin: 0;
        padding-top: 40px;
        float: none;
        width: 100%; } }
  .dz-content-bx.style-2 .dz-content-inner {
    margin-top: 80px; }
    @media only screen and (max-width: 991px) {
      .dz-content-bx.style-2 .dz-content-inner {
        margin-top: 20px; } }
  .dz-content-bx.style-3 .dz-content-inner {
    padding-right: 80px; }
  .dz-content-bx.style-3 .counter-info {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 80px 80px 50px 80px; }
    .dz-content-bx.style-3 .counter-info .counter {
      margin: 0 10px 0 0;
      writing-mode: tb-rl;
      border-right: 2px solid #a3cc02;
      font-size: 50px;
      line-height: 40px;
      padding-right: 10px; }
    .dz-content-bx.style-3 .counter-info .counter-bx {
      display: flex;
      align-items: center; }
  .dz-content-bx.style-3 .video-btn {
    margin-top: 120px; }
  .dz-content-bx.style-4 {
    position: relative; }
    .dz-content-bx.style-4 .content-media {
      margin: 100px auto;
      max-width: 1000px; }
      .dz-content-bx.style-4 .content-media img {
        box-shadow: 40px -40px 0 0 var(--primary);
        margin: 40px 40px 0 0; }
      .dz-content-bx.style-4 .content-media:after {
        content: attr(data-name);
        font-size: 100px;
        opacity: 0.6;
        position: absolute;
        color: transparent;
        top: 50px;
        z-index: 1;
        right: 10px;
        line-height: 80px;
        -webkit-text-stroke: 1px #fff;
        text-transform: uppercase;
        font-weight: 700;
        width: 100%;
        text-align: right;
        letter-spacing: 10px; }
    .dz-content-bx.style-4:after {
      content: "";
      left: 0;
      bottom: 0;
      height: 300px;
      background-color: #fff;
      width: 100%;
      position: absolute;
      z-index: -1; }

.list-circle li,
.list-angle-double li,
.list-square li,
.list-square-check li,
.list-check-circle li,
.list-check li,
.list-plus-circle li,
.list-arrow-left-circle li,
.list-arrow-right-circle li {
  position: relative;
  padding: 5px 5px 5px 30px;
  font-family: var(--font-family-title);
  font-size: 18px;
  font-weight: 500;
  color: var(--title); }
  @media only screen and (max-width: 1199px) {
    .list-circle li,
    .list-angle-double li,
    .list-square li,
    .list-square-check li,
    .list-check-circle li,
    .list-check li,
    .list-plus-circle li,
    .list-arrow-left-circle li,
    .list-arrow-right-circle li {
      font-size: 16px;
      font-weight: 400; } }
  @media only screen and (max-width: 991px) {
    .list-circle li,
    .list-angle-double li,
    .list-square li,
    .list-square-check li,
    .list-check-circle li,
    .list-check li,
    .list-plus-circle li,
    .list-arrow-left-circle li,
    .list-arrow-right-circle li {
      font-size: 18px;
      font-weight: 500; } }
  .list-circle li:before,
  .list-angle-double li:before,
  .list-square li:before,
  .list-square-check li:before,
  .list-check-circle li:before,
  .list-check li:before,
  .list-plus-circle li:before,
  .list-arrow-left-circle li:before,
  .list-arrow-right-circle li:before {
    position: absolute;
    left: 0;
    top: 8px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }

.list-circle.primary li:before,
.list-angle-double.primary li:before,
.list-square.primary li:before,
.list-square-check.primary li:before,
.list-check-circle.primary li:before,
.list-check.primary li:before,
.list-plus-circle.primary li:before,
.list-arrow-left-circle.primary li:before,
.list-arrow-right-circle.primary li:before {
  color: var(--primary); }

.list-circle.white li:before,
.list-angle-double.white li:before,
.list-square.white li:before,
.list-square-check.white li:before,
.list-check-circle.white li:before,
.list-check.white li:before,
.list-plus-circle.white li:before,
.list-arrow-left-circle.white li:before,
.list-arrow-right-circle.white li:before {
  color: #fff; }

.list-circle.gray li:before,
.list-angle-double.gray li:before,
.list-square.gray li:before,
.list-square-check.gray li:before,
.list-check-circle.gray li:before,
.list-check.gray li:before,
.list-plus-circle.gray li:before,
.list-arrow-left-circle.gray li:before,
.list-arrow-right-circle.gray li:before {
  color: #cbcbcb; }

ul.list-circle li {
  padding: 5px 5px 5px 22px; }
  ul.list-circle li:before {
    content: "\f111";
    font-size: 6px;
    top: 15px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  ul.list-circle li:before {
    color: var(--primary);
    box-shadow: 0 0 20px var(--rgba-primary-5); }

ul.list-angle-double li:before {
  content: "\f101";
  font-size: 18px; }

ul.list-square li:before {
  content: "\f45c";
  top: 50%;
  transform: translateY(-50%); }

ul.list-check li:before {
  content: "\f00c";
  font-size: 16px; }

ul.list-check-circle li:before {
  content: "\f058";
  font-size: 18px;
  font-weight: 500;
  top: 5px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s; }

ul.list-check-circle li:hover:before {
  transform: scale(1.25); }

ul.list-plus-circle li:before {
  content: "\f055";
  font-size: 18px;
  top: 5px; }

ul.list-arrow-left-circle li:before, ul.list-arrow-right-circle li:before {
  font-size: 10px;
  top: 10px;
  height: 20px;
  width: 20px;
  background-color: #888888;
  border-radius: 50px;
  text-align: center;
  line-height: 21px; }

ul.list-arrow-right-circle li:before {
  content: "\f054";
  padding-left: 1px; }

ul.list-arrow-right-circle li:hover:before {
  background: var(--primary); }

ul.list-arrow-left-circle li:before {
  content: "\f053";
  padding-right: 1px; }

ul.grid-2 {
  display: flex;
  flex-wrap: wrap; }
  ul.grid-2 li {
    width: 50%; }
  @media only screen and (max-width: 575px) {
    ul.grid-2 li {
      width: 100%; } }

.list-square-check li {
  padding: 10px 15px 10px 60px;
  min-height: 45px;
  font-weight: 600;
  color: var(--title);
  margin-bottom: 15px; }
  .list-square-check li:before {
    content: "\f00c";
    top: 50%;
    height: 45px;
    width: 45px;
    line-height: 45px;
    background-color: var(--rgba-primary-1);
    color: #000;
    text-align: center;
    padding: 0 0 0 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    transform: translateY(-50%); }
  .list-square-check li:hover:before {
    background-color: var(--primary);
    color: #fff; }

.list-number-circle li {
  padding: 0px 15px 0px 70px;
  position: relative;
  margin-bottom: 30px; }
  .list-number-circle li:after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50px;
    background: var(--primary);
    color: #fff;
    font-family: var(--font-family-title);
    font-size: 24px;
    font-weight: 600;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .list-number-circle li:hover:after {
    transform: rotate(-360deg); }
  .list-number-circle li p {
    margin-bottom: 0; }

.list-square-box li {
  padding: 0px 0 0px 25px;
  position: relative;
  margin-bottom: 30px; }
  .list-square-box li:last-child {
    margin-bottom: 0; }
  .list-square-box li:after {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    height: 12px;
    width: 12px;
    background: var(--primary); }
  .list-square-box li p {
    margin-bottom: 0;
    font-size: 17px; }
    @media only screen and (max-width: 1199px) {
      .list-square-box li p {
        font-size: 16px; } }

.list-check-box li {
  padding: 20px 10px 20px 70px;
  position: relative;
  margin-bottom: 10px;
  color: #fff;
  background: var(--secondary);
  font-size: 24px;
  font-family: var(--font-family-title);
  border-radius: var(--border-radius-base);
  text-transform: uppercase;
  margin-right: 40px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  font-weight: 500; }
  .list-check-box li:after {
    content: "\f058";
    font-size: 25px;
    font-weight: 500;
    position: absolute;
    left: 30px;
    color: #fff;
    font-family: "Font Awesome 5 Free";
    top: 50%;
    transform: translateY(-50%); }
  .list-check-box li:hover {
    background: var(--primary);
    margin-right: 0; }
  @media only screen and (max-width: 1200px) {
    .list-check-box li {
      font-size: 20px; } }
  @media only screen and (max-width: 575px) {
    .list-check-box li {
      font-size: 18px;
      padding: 20px 10px 20px 55px; }
      .list-check-box li:after {
        left: 20px; } }

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6); }

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent; }

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9); }

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff; }

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: var(--primary); }

.lightimg {
  cursor: pointer; }

.SRLThumb {
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-right: 5px; }
  .SRLThumb.SRLThumbnailSelected {
    border-color: var(--primary); }

.progress-bx {
  overflow: hidden; }
  .progress-bx .progress-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px; }
    .progress-bx .progress-info .title {
      margin-bottom: 0; }
    .progress-bx .progress-info .progress-value {
      margin-bottom: 0; }
  .progress-bx .progress {
    overflow: unset; }
    .progress-bx .progress .progress-bar {
      position: relative;
      overflow: unset; }
  .progress-bx.style-1 .progress {
    background-color: #efefef;
    height: 20px;
    border-radius: 0; }
    .progress-bx.style-1 .progress .progress-bar {
      border-radius: var(--primary);
      background: var(--primary);
      animation-duration: 5s;
      animation-name: myanimation;
      transition: all 5s ease 0s; }
  .progress-bx.style-1 .title,
  .progress-bx.style-1 .progress-value {
    font-size: 20px;
    font-family: var(--font-family-title); }

@keyframes myanimation {
  0% {
    width: 0; } }

svg.radial-progress {
  height: auto;
  overflow: visible;
  max-width: 250px;
  transform: rotate(-90deg); }
  svg.radial-progress .percentage {
    font-size: 16px;
    fill: var(--primary);
    font-weight: 500;
    font-family: var(--font-family-title); }
  svg.radial-progress circle {
    fill: rgba(0, 0, 0, 0);
    stroke: #fff;
    stroke-dashoffset: 219.91148575129;
    /* Circumference */
    stroke-width: 3px; }
    svg.radial-progress circle.incomplete {
      opacity: 0; }
    svg.radial-progress circle.complete {
      stroke-dasharray: 219.91148575129;
      /* Circumference */ }
  svg.radial-progress text {
    fill: #000;
    text-anchor: middle; }
  svg.radial-progress .basic-circle {
    stroke: #f2f2f2 !important; }
  svg.radial-progress:nth-of-type(6n+1) circle {
    stroke: var(--primary); }

.radial-progress {
  height: auto;
  overflow: visible;
  position: relative; }
  .radial-progress .percentage {
    font-size: 40px;
    color: var(--primary);
    font-weight: 700;
    font-family: var(--font-family-title);
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .radial-progress circle {
    fill: rgba(0, 0, 0, 0);
    stroke: #fff;
    stroke-dashoffset: 219.91148575129;
    /* Circumference */
    stroke-width: 3px; }
    .radial-progress circle.incomplete {
      opacity: 0; }
    .radial-progress circle.complete {
      stroke-dasharray: 219.91148575129;
      /* Circumference */ }
  .radial-progress text {
    fill: #000;
    text-anchor: middle; }
  .radial-progress .basic-circle {
    stroke: #f2f2f2 !important; }
  .radial-progress:nth-of-type(6n+1) circle {
    stroke: var(--primary); }

.scroltop {
  box-shadow: -4px 4px 24px -10px var(--primary) !important;
  background: var(--primary);
  border-color: var(--primary);
  border-style: solid;
  border-width: 0;
  bottom: 15px;
  color: #fff;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  margin: 0;
  position: fixed;
  right: 15px;
  text-align: center;
  border-radius: var(--border-radius);
  width: 50px;
  z-index: 999;
  box-shadow: -4px 4px 24px -10px;
  transition: all .8s;
  -moz-transition: all .8s;
  -webkit-transition: all .8s;
  -ms-transition: all .8s;
  -o-transition: all .8s; }
  .scroltop svg {
    display: none; }
  @media only screen and (max-width: 575px) {
    .scroltop {
      height: 40px;
      width: 40px;
      line-height: 40px; } }

.video-bx {
  position: relative; }
  .video-bx.style-1 {
    min-width: 170px;
    width: 170px;
    height: 170px;
    border-radius: var(--border-radius-base);
    overflow: hidden; }
    .video-bx.style-1 img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .video-bx.style-1 .video-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center; }
      .video-bx.style-1 .video-btn .popup-youtube {
        display: inline-block;
        height: 60px;
        width: 60px;
        min-width: 60px;
        line-height: 60px;
        font-size: 20px;
        border-radius: 60px;
        background-color: #fff;
        color: var(--primary); }
        .video-bx.style-1 .video-btn .popup-youtube:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          border: 1px solid #fff;
          -webkit-border-radius: 50%;
          -khtml-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 100%;
          animation: animationSignal1;
          animation-iteration-count: infinite;
          animation-duration: 3s;
          -webkit-animation: animationSignal1;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-duration: 3s;
          z-index: -1;
          transform: scale(1);
          -moz-transform: scale(1);
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
          -moz-transition: all 0.5;
          -o-transition: all 0.5;
          -webkit-transition: all 0.5;
          -ms-transition: all 0.5;
          transition: all 0.5; }
        .video-bx.style-1 .video-btn .popup-youtube:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          border: 1px solid #fff;
          -webkit-border-radius: 100%;
          -khtml-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
          border-radius: 100%;
          animation: animationSignal2;
          animation-iteration-count: infinite;
          animation-duration: 3s;
          -webkit-animation: animationSignal2;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-duration: 3s;
          z-index: -1;
          transform: scale(1);
          -moz-transform: scale(1);
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
          -moz-transition: all 0.5;
          -o-transition: all 0.5;
          -webkit-transition: all 0.5;
          -ms-transition: all 0.5;
          transition: all 0.5; }
    @media only screen and (max-width: 575px) {
      .video-bx.style-1 {
        height: 120px;
        width: 120px;
        min-width: 120px; }
        .video-bx.style-1 .video-btn .popup-youtube {
          height: 40px;
          width: 40px;
          min-width: 40px;
          line-height: 40px;
          font-size: 16px; } }
  .video-bx.style-2 {
    height: 500px; }
    .video-bx.style-2.large {
      height: 615px;
      border-radius: var(--border-radius-base);
      overflow: hidden; }
      .video-bx.style-2.large img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .video-bx.style-2 .video-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center; }
      .video-bx.style-2 .video-btn.sm .popup-youtube {
        height: 72px;
        width: 72px;
        min-width: 72px;
        line-height: 72px;
        font-size: 22px; }
      .video-bx.style-2 .video-btn .popup-youtube {
        display: inline-block;
        height: 100px;
        width: 100px;
        min-width: 100px;
        line-height: 100px;
        font-size: 30px;
        border-radius: 100px;
        background-color: var(--primary);
        color: #fff; }
        .video-bx.style-2 .video-btn .popup-youtube:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          border: 1px solid var(--primary);
          -webkit-border-radius: 50%;
          -khtml-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 100%;
          animation: animationSignal1;
          animation-iteration-count: infinite;
          animation-duration: 3s;
          -webkit-animation: animationSignal1;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-duration: 3s;
          z-index: -1;
          transform: scale(1);
          -moz-transform: scale(1);
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
          -moz-transition: all 0.5;
          -o-transition: all 0.5;
          -webkit-transition: all 0.5;
          -ms-transition: all 0.5;
          transition: all 0.5; }
        .video-bx.style-2 .video-btn .popup-youtube:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          border: 1px solid var(--primary);
          -webkit-border-radius: 100%;
          -khtml-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
          border-radius: 100%;
          animation: animationSignal2;
          animation-iteration-count: infinite;
          animation-duration: 3s;
          -webkit-animation: animationSignal2;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-duration: 3s;
          z-index: -1;
          transform: scale(1);
          -moz-transform: scale(1);
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
          -moz-transition: all 0.5;
          -o-transition: all 0.5;
          -webkit-transition: all 0.5;
          -ms-transition: all 0.5;
          transition: all 0.5; }
    @media only screen and (max-width: 991px) {
      .video-bx.style-2 {
        height: 330px; }
        .video-bx.style-2.large {
          height: 380px; } }
    @media only screen and (max-width: 575px) {
      .video-bx.style-2 {
        height: 200px; }
        .video-bx.style-2.large {
          height: 250px; }
        .video-bx.style-2 .video-btn .popup-youtube,
        .video-bx.style-2 .video-btn.sm .popup-youtube {
          height: 60px;
          width: 60px;
          min-width: 60px;
          line-height: 60px;
          font-size: 20px; } }
  .video-bx.style-3 {
    display: inline-block; }
    .video-bx.style-3 .video-btn .popup-youtube {
      display: inline-block;
      height: 60px;
      width: 60px;
      line-height: 52px;
      text-align: center;
      font-size: 18px;
      border-radius: 60px;
      min-width: 60px;
      border: 4px solid #ececec;
      color: var(--secondary); }
      .video-bx.style-3 .video-btn .popup-youtube:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        border: 1px solid var(--secondary);
        -webkit-border-radius: 50%;
        -khtml-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 100%;
        animation: animationSignal1;
        animation-iteration-count: infinite;
        animation-duration: 3s;
        -webkit-animation: animationSignal1;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-duration: 3s;
        z-index: -1;
        transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        -moz-transition: all 0.5;
        -o-transition: all 0.5;
        -webkit-transition: all 0.5;
        -ms-transition: all 0.5;
        transition: all 0.5; }
      .video-bx.style-3 .video-btn .popup-youtube:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        border: 1px solid var(--secondary);
        -webkit-border-radius: 100%;
        -khtml-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
        border-radius: 100%;
        animation: animationSignal2;
        animation-iteration-count: infinite;
        animation-duration: 3s;
        -webkit-animation: animationSignal2;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-duration: 3s;
        z-index: -1;
        transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        -moz-transition: all 0.5;
        -o-transition: all 0.5;
        -webkit-transition: all 0.5;
        -ms-transition: all 0.5;
        transition: all 0.5; }

@keyframes animationSignal1 {
  /*Video Popup*/
  0% {
    opacity: 0.8;
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9); }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0; } }

@-webkit-keyframes animationSignal1 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9); }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0; } }

@keyframes animationSignal2 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9); }
  100% {
    transform: scale(1.9);
    -moz-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    -o-transform: scale(1.9);
    opacity: 0; } }

@-webkit-keyframes animationSignal2 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9); }
  100% {
    transform: scale(1.9);
    -moz-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    -o-transform: scale(1.9);
    opacity: 0; } }

.video-wrapper {
  display: flex;
  align-items: center; }

.swiper-button {
  font-size: 0; }

.btn-next,
.btn-prev {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  width: 75px;
  height: 75px;
  color: #fff;
  background: var(--primary);
  font-size: 15px;
  letter-spacing: 2px;
  z-index: 1;
  text-align: center;
  line-height: 75px;
  margin: 0 5px;
  display: inline-block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  @media only screen and (max-width: 1400px) {
    .btn-next,
    .btn-prev {
      width: 45px;
      height: 45px;
      line-height: 45px; } }
  .btn-next:hover,
  .btn-prev:hover {
    background: var(--secondary);
    color: #fff;
    box-shadow: 0px 12px 20px 0px var(--rgba-primary-3); }
  .btn-next:after,
  .btn-prev:after {
    content: none; }
  .btn-next i,
  .btn-prev i {
    font-size: 32px;
    line-height: inherit; }
    @media only screen and (max-width: 1400px) {
      .btn-next i,
      .btn-prev i {
        font-size: 24px; } }

.btn-bottom-left {
  position: relative; }
  .btn-bottom-left .swiper-button {
    position: absolute;
    bottom: 25px;
    left: 20px; }

.btn-center-lr {
  position: relative; }
  .btn-center-lr .btn-next,
  .btn-center-lr .btn-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .btn-center-lr .btn-next {
    right: -110px; }
  .btn-center-lr .btn-prev {
    left: -110px; }
  @media only screen and (max-width: 1400px) {
    .btn-center-lr .btn-next {
      right: 0px; }
    .btn-center-lr .btn-prev {
      left: 0px; } }

/* Widget */
.widget {
  margin-bottom: 50px; }
  @media only screen and (max-width: 767px) {
    .widget {
      margin-bottom: 30px; } }

.widget-title {
  margin-bottom: 30px;
  padding-bottom: 10px; }
  @media only screen and (max-width: 1199px) {
    .widget-title {
      margin-bottom: 20px; } }
  .widget-title .title {
    position: relative;
    margin-bottom: 5px; }
    @media only screen and (max-width: 767px) {
      .widget-title .title {
        font-size: 24px; } }

.search-bx form {
  margin-bottom: 10px; }

.search-bx .form-control, .search-bx .wp-block-categories-dropdown select, .wp-block-categories-dropdown .search-bx select,
.search-bx .wp-block-archives-dropdown select, .wp-block-archives-dropdown .search-bx select {
  border-radius: var(--border-radius-base) !important;
  background-color: #fff;
  box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.2);
  border: 0;
  z-index: 0 !important;
  padding: 6px 62px 6px 30px; }

.search-bx .input-group-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center; }

.search-bx button {
  height: 60px;
  width: 60px;
  font-size: 24px;
  padding: 0;
  justify-content: center;
  border-radius: var(--border-radius-base); }

.download-file {
  background-image: var(--gradient);
  color: #fff;
  padding: 25px 25px 20px 20px;
  border-radius: 4px; }
  .download-file .title {
    color: #fff;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 600; }
  .download-file ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .download-file ul li a {
      background-color: #fff;
      color: #000;
      display: block;
      margin-bottom: 10px;
      border-radius: 4px;
      width: 100%;
      padding: 15px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      align-items: center;
      display: flex;
      justify-content: space-between; }
      .download-file ul li a i {
        width: 18px;
        height: 18px;
        position: relative; }
        .download-file ul li a i:after, .download-file ul li a i:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          left: 0;
          top: 0; }
        .download-file ul li a i:before {
          -webkit-animation: download1 2s ease infinite;
          animation: download1 2s ease infinite; }
      .download-file ul li a .text {
        display: inline;
        position: relative; }
      .download-file ul li a:hover .text {
        color: var(--primary); }

@-webkit-keyframes download1 {
  0%, 10%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); } }

@keyframes download1 {
  0%, 10%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); } }

.widget_contact {
  position: relative;
  padding: 50px 30px;
  background-size: cover;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden; }
  .widget_contact:after {
    background: var(--rgba-primary-9);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.85;
    z-index: -1;
    background-size: 200%; }
  .widget_contact img {
    margin-bottom: 20px; }
  .widget_contact h4, .widget_contact .h4, .widget_contact .comment-reply-title,
  .widget_contact .wp-block-search .wp-block-search__label, .wp-block-search .widget_contact .wp-block-search__label {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 30px; }
  .widget_contact .phone-number {
    position: relative;
    font-size: 24px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 11px; }
    .widget_contact .phone-number a {
      color: #fff; }
  .widget_contact .email {
    position: relative;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 30px; }
    .widget_contact .email a {
      color: #fff; }

.service-carouse .item {
  overflow: hidden;
  border-radius: 6px; }

.list-2 {
  display: table; }
  .list-2 li {
    width: 50% !important;
    float: left; }
    @media only screen and (max-width: 575px) {
      .list-2 li {
        width: 100% !important; } }

/* widget listing*/
.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }
  .wp-block-latest-posts li,
  .wp-block-categories-list li,
  .wp-block-archives-list li,
  .widget_categories ul li,
  .widget_archive ul li,
  .widget_meta ul li,
  .widget_pages ul li,
  .widget_recent_comments ul li,
  .widget_nav_menu ul li,
  .widget_recent_entries ul li,
  .widget_services ul li {
    padding-bottom: 1.125rem;
    margin-bottom: 0.8125rem;
    position: relative;
    padding: 0.5rem 0rem 0.5rem 0.9375rem;
    margin-bottom: 0;
    line-height: 1.25rem; }
    .wp-block-latest-posts li a,
    .wp-block-categories-list li a,
    .wp-block-archives-list li a,
    .widget_categories ul li a,
    .widget_archive ul li a,
    .widget_meta ul li a,
    .widget_pages ul li a,
    .widget_recent_comments ul li a,
    .widget_nav_menu ul li a,
    .widget_recent_entries ul li a,
    .widget_services ul li a {
      color: inherit;
      text-transform: capitalize;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s; }
      .wp-block-latest-posts li a:before,
      .wp-block-categories-list li a:before,
      .wp-block-archives-list li a:before,
      .widget_categories ul li a:before,
      .widget_archive ul li a:before,
      .widget_meta ul li a:before,
      .widget_pages ul li a:before,
      .widget_recent_comments ul li a:before,
      .widget_nav_menu ul li a:before,
      .widget_recent_entries ul li a:before,
      .widget_services ul li a:before {
        content: "\f0da";
        font-family: "Font Awesome 5 Free";
        background: transparent;
        width: auto;
        height: auto;
        position: absolute;
        left: 0;
        top: 7px;
        font-size: 1rem;
        font-weight: 700;
        color: var(--primary); }
      .wp-block-latest-posts li a:hover,
      .wp-block-categories-list li a:hover,
      .wp-block-archives-list li a:hover,
      .widget_categories ul li a:hover,
      .widget_archive ul li a:hover,
      .widget_meta ul li a:hover,
      .widget_pages ul li a:hover,
      .widget_recent_comments ul li a:hover,
      .widget_nav_menu ul li a:hover,
      .widget_recent_entries ul li a:hover,
      .widget_services ul li a:hover {
        color: var(--primary); }
    .wp-block-latest-posts li li,
    .wp-block-categories-list li li,
    .wp-block-archives-list li li,
    .widget_categories ul li li,
    .widget_archive ul li li,
    .widget_meta ul li li,
    .widget_pages ul li li,
    .widget_recent_comments ul li li,
    .widget_nav_menu ul li li,
    .widget_recent_entries ul li li,
    .widget_services ul li li {
      border-bottom: none;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      line-height: 1.375rem; }
      .wp-block-latest-posts li li:last-child,
      .wp-block-categories-list li li:last-child,
      .wp-block-archives-list li li:last-child,
      .widget_categories ul li li:last-child,
      .widget_archive ul li li:last-child,
      .widget_meta ul li li:last-child,
      .widget_pages ul li li:last-child,
      .widget_recent_comments ul li li:last-child,
      .widget_nav_menu ul li li:last-child,
      .widget_recent_entries ul li li:last-child,
      .widget_services ul li li:last-child {
        border-bottom: 0;
        padding-bottom: 0; }
      .wp-block-latest-posts li li:before,
      .wp-block-categories-list li li:before,
      .wp-block-archives-list li li:before,
      .widget_categories ul li li:before,
      .widget_archive ul li li:before,
      .widget_meta ul li li:before,
      .widget_pages ul li li:before,
      .widget_recent_comments ul li li:before,
      .widget_nav_menu ul li li:before,
      .widget_recent_entries ul li li:before,
      .widget_services ul li li:before {
        top: 0.5rem;
        left: 0; }
  .wp-block-latest-posts ul,
  .wp-block-categories-list ul,
  .wp-block-archives-list ul,
  .widget_categories ul ul,
  .widget_archive ul ul,
  .widget_meta ul ul,
  .widget_pages ul ul,
  .widget_recent_comments ul ul,
  .widget_nav_menu ul ul,
  .widget_recent_entries ul ul,
  .widget_services ul ul {
    padding-left: 1.125rem;
    margin-top: 0.5rem;
    margin-left: -0.9375rem; }

/*widget categories*/
.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul {
  margin-bottom: -0.625rem; }

.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
  text-align: right;
  display: table;
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 0.9375rem !important;
  line-height: 1.3 !important; }

.wp-block-categories-list li:before,
.wp-block-archives-list li:before,
.wp-block-latest-posts li:before {
  content: none !important; }

.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
  float: left;
  text-transform: capitalize;
  text-align: left; }

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list {
  padding: 0 !important; }

.widget_recent_comments ul {
  margin-top: -0.625rem; }
  .widget_recent_comments ul li {
    padding-left: 1.875rem;
    line-height: 1.625rem; }
    .widget_recent_comments ul li a:hover {
      color: inherit; }
    .widget_recent_comments ul li a:before {
      content: none; }
    .widget_recent_comments ul li:before {
      content: "\f27a";
      font-family: "Font Awesome 5 Free";
      background: transparent;
      width: auto;
      height: auto;
      position: absolute;
      left: 0;
      top: 0.625rem;
      font-size: 1.125rem;
      font-weight: 500; }

.widget_meta ul li a abbr[title] {
  color: #333;
  border-bottom: none; }

.widget_calendar th,
.widget_calendar td {
  text-align: center;
  border: 0.0625rem solid var(--rgba-primary-1);
  padding: 0.4em; }

.widget_calendar th,
.widget_calendar tfoot td {
  border-color: rgba(0, 0, 0, 0.05); }
  .widget_calendar th:last-child,
  .widget_calendar tfoot td:last-child {
    border: 0; }

.widget_calendar tr {
  border-bottom: 0.0625rem solid var(--rgba-primary-1); }
  .widget_calendar tr:nth-child(2n+2) {
    background-color: var(--rgba-primary-05); }

.widget_calendar table {
  border-collapse: collapse;
  margin: 0;
  width: 100%; }

.widget_calendar .calendar_wrap th,
.widget_calendar tfoot td a {
  color: #fff;
  background: var(--primary); }

.widget_calendar .calendar_wrap td a {
  font-weight: 600;
  text-transform: uppercase;
  color: inherit; }

.widget_calendar caption {
  text-align: center;
  font-weight: 600;
  caption-side: top;
  background: var(--primary);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9375rem; }

.wp-block-calendar table th {
  font-weight: 400;
  background: var(--primary);
  color: #fff; }

footer .widget_calendar th,
footer .widget_calendar td {
  border-color: rgba(255, 255, 255, 0.1); }

footer .widget_calendar tr {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1); }
  footer .widget_calendar tr:nth-child(2n+2) {
    background-color: rgba(255, 255, 255, 0.05); }

.wp-calendar-nav {
  background: var(--primary);
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center; }
  .wp-calendar-nav span {
    padding: 0.3125rem 1.25rem;
    width: 44%;
    border-right: 0.0625rem solid rgba(255, 255, 255, 0.2); }
    .wp-calendar-nav span a {
      color: #fff; }

.widget_archive select {
  width: 100%;
  padding: 0.3125rem;
  border: 0.0625rem solid #CCC; }

.list-2-column ul {
  display: table; }
  .list-2-column ul li {
    width: 50%;
    float: left; }

.widget_categories select,
.widget_archive select {
  width: 100%;
  border: 0.0625rem solid #CCC; }

.widget_text select {
  width: 100%;
  border: 0.0625rem solid #CCC; }
  .widget_text select option {
    width: 100%; }

.widget_text p {
  font-size: 0.9375rem;
  line-height: 1.75rem; }

/*widget rss  */
.rsswidget img {
  display: inherit; }

.widget-title .rsswidget {
  display: inline-block; }

ul a.rsswidget,
ul cite {
  font-weight: 600;
  color: var(--title); }

ul a.rsswidget {
  font-size: 1.125rem;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  ul a.rsswidget:hover {
    color: var(--primary); }

.rssSummary {
  margin: 1.25rem 0;
  line-height: 1.7;
  font-size: 15px; }

.rss-date {
  display: block;
  margin-top: 0.3125rem;
  font-weight: 400; }

.widget_rss ul li {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.1);
  padding-top: 0; }
  .widget_rss ul li:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0; }

.widget_gallery ul {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.3rem;
  margin-right: -0.3rem; }

.widget_gallery.gallery-grid-2 li {
  width: 50%; }

.widget_gallery.gallery-grid-4 li {
  width: 25%; }

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  padding: 5px; }
  .widget_gallery li img {
    display: inline-block;
    width: 100%;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .widget_gallery li .lightimg {
    display: block;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .widget_gallery li:nth-child(3n-3) {
    margin-right: 0; }
  .widget_gallery li:hover .lightimg {
    background: var(--primary); }
  .widget_gallery li:hover img {
    opacity: 0.5; }
  .widget_gallery li a {
    display: inline-block; }

.widget_tag_cloud .tagcloud {
  clear: both;
  display: table; }
  .widget_tag_cloud .tagcloud a {
    position: relative;
    border: 2px solid;
    padding: 10px 20px;
    display: inline-block;
    margin: 0 10px 10px 0;
    font-size: 14px;
    color: inherit;
    line-height: 1.4;
    border-color: rgba(0, 0, 0, 0.1); }
    .widget_tag_cloud .tagcloud a:hover {
      background-color: var(--primary-hover);
      border-color: var(--primary-hover);
      color: #fff; }

.recent-posts-entry .widget-post-bx .widget-post {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  margin-bottom: 20px;
  transition: all 0.5s; }
  .recent-posts-entry .widget-post-bx .widget-post .dz-media {
    width: 92px;
    min-width: 92px; }
  .recent-posts-entry .widget-post-bx .widget-post .dz-info {
    padding-left: 14px; }
  .recent-posts-entry .widget-post-bx .widget-post .dz-meta {
    margin-bottom: 10px; }
    .recent-posts-entry .widget-post-bx .widget-post .dz-meta ul {
      line-height: 1; }
  .recent-posts-entry .widget-post-bx .widget-post .title {
    margin-bottom: 0;
    display: inline-block;
    font-weight: 500; }
  .recent-posts-entry .widget-post-bx .widget-post:last-child {
    margin-bottom: 0; }

.service_menu_nav {
  background: #fff;
  padding: 30px 30px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px; }
  @media only screen and (max-width: 1199px) {
    .service_menu_nav {
      padding: 15px; } }
  .service_menu_nav ul li {
    padding: 0; }
    .service_menu_nav ul li a {
      background-color: #fff;
      display: block;
      border-radius: 6px;
      padding: 15px 25px;
      margin-bottom: 10px;
      color: #000;
      font-weight: 600;
      border: 1px solid rgba(0, 0, 0, 0.1);
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      background-size: 200%;
      position: relative;
      z-index: 1;
      overflow: hidden; }
      @media only screen and (max-width: 1199px) {
        .service_menu_nav ul li a {
          padding: 15px; } }
      .service_menu_nav ul li a:before {
        content: "\f30b";
        font-weight: 900;
        font-family: 'Line Awesome Free';
        position: absolute;
        right: 50px;
        left: auto;
        top: 50%;
        font-size: 30px;
        opacity: 0;
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s;
        transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%); }
        @media only screen and (max-width: 1199px) {
          .service_menu_nav ul li a:before {
            font-size: 22px; } }
      .service_menu_nav ul li a:after {
        background: var(--primary);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s;
        content: "";
        background-size: 200%;
        opacity: 0;
        z-index: -1; }
    .service_menu_nav ul li:hover a, .service_menu_nav ul li.current-menu-item a, .service_menu_nav ul li.active a {
      padding-left: 20px;
      border-color: transparent;
      transform: translateX(0);
      -moz-transform: translateX(0);
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      color: #fff; }
      .service_menu_nav ul li:hover a:before, .service_menu_nav ul li.current-menu-item a:before, .service_menu_nav ul li.active a:before {
        opacity: 1;
        right: 10px;
        color: #fff; }
        @media only screen and (max-width: 1199px) {
          .service_menu_nav ul li:hover a:before, .service_menu_nav ul li.current-menu-item a:before, .service_menu_nav ul li.active a:before {
            font-size: 22px;
            right: 5px; } }
      .service_menu_nav ul li:hover a:after, .service_menu_nav ul li.current-menu-item a:after, .service_menu_nav ul li.active a:after {
        opacity: 1; }

.wp-block-categories-dropdown,
.wp-block-archives-dropdown {
  margin-bottom: 20px; }
  .wp-block-categories-dropdown select:after,
  .wp-block-archives-dropdown select:after {
    content: "\f078";
    font-weight: 900;
    font-family: 'Line Awesome Free';
    position: absolute;
    right: 50px;
    left: auto;
    top: 50%; }

.post-tags strong {
  font-weight: 700;
  color: #212529;
  margin-right: 10px; }

.post-tags a {
  margin-right: 10px;
  display: inline-block; }

.widget_profile .dz-media {
  width: 100%;
  min-width: 100%; }
  .widget_profile .dz-media img {
    border-radius: var(--border-radius-base); }

.widget_profile p {
  font-size: 16px; }

.widget-newslatter .form-control, .widget-newslatter .wp-block-categories-dropdown select, .wp-block-categories-dropdown .widget-newslatter select,
.widget-newslatter .wp-block-archives-dropdown select, .wp-block-archives-dropdown .widget-newslatter select {
  background-color: #f3f4f6;
  border-radius: var(--border-radius);
  border: 0; }

.widget-newslatter p {
  font-size: 16px; }

.widget-newslatter button {
  width: 60px;
  height: 60px;
  padding: 0;
  justify-content: center; }

.widget-newslatter .input-group {
  margin-bottom: 0; }

.shop-widget,
.widget.style-1 {
  box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.1);
  padding: 35px;
  border-bottom: 4px solid;
  border-color: var(--primary); }
  .shop-widget .search-bx,
  .widget.style-1 .search-bx {
    margin-bottom: 0; }
    .shop-widget .search-bx .form-control, .shop-widget .search-bx .wp-block-categories-dropdown select, .wp-block-categories-dropdown .shop-widget .search-bx select,
    .shop-widget .search-bx .wp-block-archives-dropdown select, .wp-block-archives-dropdown .shop-widget .search-bx select,
    .widget.style-1 .search-bx .form-control,
    .widget.style-1 .search-bx .wp-block-categories-dropdown select,
    .wp-block-categories-dropdown .widget.style-1 .search-bx select,
    .widget.style-1 .search-bx .wp-block-archives-dropdown select,
    .wp-block-archives-dropdown .widget.style-1 .search-bx select {
      background-color: #f3f4f6;
      box-shadow: unset; }
    .shop-widget .search-bx .input-group,
    .widget.style-1 .search-bx .input-group {
      margin-bottom: 0; }
  @media only screen and (max-width: 1199px) {
    .shop-widget,
    .widget.style-1 {
      padding: 20px; } }

.side-bar h1, .side-bar .h1 {
  font-size: 2rem; }

.side-bar h2, .side-bar .h2 {
  font-size: 1.625rem; }

.side-bar h3, .side-bar .h3 {
  font-size: 1.5rem; }

.side-bar h4, .side-bar .h4, .side-bar .comment-reply-title,
.side-bar .wp-block-search .wp-block-search__label, .wp-block-search .side-bar .wp-block-search__label {
  font-size: 1.25rem; }

.side-bar h5, .side-bar .h5 {
  font-size: 1rem; }

.sidebar-widget .wp-block-woocommerce-attribute-filter > h1, .sidebar-widget .wp-block-woocommerce-attribute-filter > .h1,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h4,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h4,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .comment-reply-title,
.sidebar-widget .wp-block-search .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.wp-block-search .sidebar-widget .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h6,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h6,
.sidebar-widget .wp-block-group__inner-container > h1,
.sidebar-widget .wp-block-group__inner-container > .h1,
.sidebar-widget .wp-block-group__inner-container > h2,
.sidebar-widget .wp-block-group__inner-container > .h2,
.sidebar-widget .wp-block-group__inner-container > h3,
.sidebar-widget .wp-block-group__inner-container > .h3,
.sidebar-widget .wp-block-group__inner-container > h4,
.sidebar-widget .wp-block-group__inner-container > .h4,
.sidebar-widget .wp-block-group__inner-container > .comment-reply-title,
.sidebar-widget .wp-block-search .wp-block-group__inner-container > .wp-block-search__label,
.wp-block-search .sidebar-widget .wp-block-group__inner-container > .wp-block-search__label,
.sidebar-widget .wp-block-group__inner-container > h5,
.sidebar-widget .wp-block-group__inner-container > .h5,
.sidebar-widget .wp-block-group__inner-container > h6,
.sidebar-widget .wp-block-group__inner-container > .h6,
.shop-widget .wp-block-woocommerce-attribute-filter > h1,
.shop-widget .wp-block-woocommerce-attribute-filter > .h1,
.shop-widget .wp-block-woocommerce-attribute-filter > h2,
.shop-widget .wp-block-woocommerce-attribute-filter > .h2,
.shop-widget .wp-block-woocommerce-attribute-filter > h3,
.shop-widget .wp-block-woocommerce-attribute-filter > .h3,
.shop-widget .wp-block-woocommerce-attribute-filter > h4,
.shop-widget .wp-block-woocommerce-attribute-filter > .h4,
.shop-widget .wp-block-woocommerce-attribute-filter > .comment-reply-title,
.shop-widget .wp-block-search .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.wp-block-search .shop-widget .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.shop-widget .wp-block-woocommerce-attribute-filter > h5,
.shop-widget .wp-block-woocommerce-attribute-filter > .h5,
.shop-widget .wp-block-woocommerce-attribute-filter > h6,
.shop-widget .wp-block-woocommerce-attribute-filter > .h6,
.shop-widget .wp-block-group__inner-container > h1,
.shop-widget .wp-block-group__inner-container > .h1,
.shop-widget .wp-block-group__inner-container > h2,
.shop-widget .wp-block-group__inner-container > .h2,
.shop-widget .wp-block-group__inner-container > h3,
.shop-widget .wp-block-group__inner-container > .h3,
.shop-widget .wp-block-group__inner-container > h4,
.shop-widget .wp-block-group__inner-container > .h4,
.shop-widget .wp-block-group__inner-container > .comment-reply-title,
.shop-widget .wp-block-search .wp-block-group__inner-container > .wp-block-search__label,
.wp-block-search .shop-widget .wp-block-group__inner-container > .wp-block-search__label,
.shop-widget .wp-block-group__inner-container > h5,
.shop-widget .wp-block-group__inner-container > .h5,
.shop-widget .wp-block-group__inner-container > h6,
.shop-widget .wp-block-group__inner-container > .h6 {
  position: relative;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 30px; }
  @media only screen and (max-width: 1199px) {
    .sidebar-widget .wp-block-woocommerce-attribute-filter > h1, .sidebar-widget .wp-block-woocommerce-attribute-filter > .h1,
    .sidebar-widget .wp-block-woocommerce-attribute-filter > h2,
    .sidebar-widget .wp-block-woocommerce-attribute-filter > .h2,
    .sidebar-widget .wp-block-woocommerce-attribute-filter > h3,
    .sidebar-widget .wp-block-woocommerce-attribute-filter > .h3,
    .sidebar-widget .wp-block-woocommerce-attribute-filter > h4,
    .sidebar-widget .wp-block-woocommerce-attribute-filter > .h4,
    .sidebar-widget .wp-block-woocommerce-attribute-filter > .comment-reply-title,
    .sidebar-widget .wp-block-search .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
    .wp-block-search .sidebar-widget .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
    .sidebar-widget .wp-block-woocommerce-attribute-filter > h5,
    .sidebar-widget .wp-block-woocommerce-attribute-filter > .h5,
    .sidebar-widget .wp-block-woocommerce-attribute-filter > h6,
    .sidebar-widget .wp-block-woocommerce-attribute-filter > .h6,
    .sidebar-widget .wp-block-group__inner-container > h1,
    .sidebar-widget .wp-block-group__inner-container > .h1,
    .sidebar-widget .wp-block-group__inner-container > h2,
    .sidebar-widget .wp-block-group__inner-container > .h2,
    .sidebar-widget .wp-block-group__inner-container > h3,
    .sidebar-widget .wp-block-group__inner-container > .h3,
    .sidebar-widget .wp-block-group__inner-container > h4,
    .sidebar-widget .wp-block-group__inner-container > .h4,
    .sidebar-widget .wp-block-group__inner-container > .comment-reply-title,
    .sidebar-widget .wp-block-search .wp-block-group__inner-container > .wp-block-search__label,
    .wp-block-search .sidebar-widget .wp-block-group__inner-container > .wp-block-search__label,
    .sidebar-widget .wp-block-group__inner-container > h5,
    .sidebar-widget .wp-block-group__inner-container > .h5,
    .sidebar-widget .wp-block-group__inner-container > h6,
    .sidebar-widget .wp-block-group__inner-container > .h6,
    .shop-widget .wp-block-woocommerce-attribute-filter > h1,
    .shop-widget .wp-block-woocommerce-attribute-filter > .h1,
    .shop-widget .wp-block-woocommerce-attribute-filter > h2,
    .shop-widget .wp-block-woocommerce-attribute-filter > .h2,
    .shop-widget .wp-block-woocommerce-attribute-filter > h3,
    .shop-widget .wp-block-woocommerce-attribute-filter > .h3,
    .shop-widget .wp-block-woocommerce-attribute-filter > h4,
    .shop-widget .wp-block-woocommerce-attribute-filter > .h4,
    .shop-widget .wp-block-woocommerce-attribute-filter > .comment-reply-title,
    .shop-widget .wp-block-search .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
    .wp-block-search .shop-widget .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
    .shop-widget .wp-block-woocommerce-attribute-filter > h5,
    .shop-widget .wp-block-woocommerce-attribute-filter > .h5,
    .shop-widget .wp-block-woocommerce-attribute-filter > h6,
    .shop-widget .wp-block-woocommerce-attribute-filter > .h6,
    .shop-widget .wp-block-group__inner-container > h1,
    .shop-widget .wp-block-group__inner-container > .h1,
    .shop-widget .wp-block-group__inner-container > h2,
    .shop-widget .wp-block-group__inner-container > .h2,
    .shop-widget .wp-block-group__inner-container > h3,
    .shop-widget .wp-block-group__inner-container > .h3,
    .shop-widget .wp-block-group__inner-container > h4,
    .shop-widget .wp-block-group__inner-container > .h4,
    .shop-widget .wp-block-group__inner-container > .comment-reply-title,
    .shop-widget .wp-block-search .wp-block-group__inner-container > .wp-block-search__label,
    .wp-block-search .shop-widget .wp-block-group__inner-container > .wp-block-search__label,
    .shop-widget .wp-block-group__inner-container > h5,
    .shop-widget .wp-block-group__inner-container > .h5,
    .shop-widget .wp-block-group__inner-container > h6,
    .shop-widget .wp-block-group__inner-container > .h6 {
      margin-bottom: 20px; } }

.comment-reply-title,
.wp-block-search .wp-block-search__label {
  color: var(--title);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 30px; }

/* Widget End */
.inquiry-modal .modal-dialog {
  max-width: 700px;
  display: flex;
  min-height: auto;
  justify-content: stretch;
  align-items: stretch; }

.inquiry-modal .form-control, .inquiry-modal .wp-block-categories-dropdown select, .wp-block-categories-dropdown .inquiry-modal select,
.inquiry-modal .wp-block-archives-dropdown select, .wp-block-archives-dropdown .inquiry-modal select {
  font-size: 15px;
  color: #000; }

.inquiry-modal .inquiry-adv {
  flex: 0 0 50%;
  max-width: 50%; }
  @media only screen and (max-width: 767px) {
    .inquiry-modal .inquiry-adv {
      display: none; } }
  .inquiry-modal .inquiry-adv img {
    height: 100%;
    object-fit: cover;
    width: 100%; }

.inquiry-modal .modal-content {
  border: 0;
  border-radius: 0;
  background: #fff;
  padding: 30px;
  flex: 0 0 50%;
  max-width: 50%; }
  @media only screen and (max-width: 767px) {
    .inquiry-modal .modal-content {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 20px; } }
  .inquiry-modal .modal-content .modal-header {
    border: 0;
    padding: 0;
    display: block;
    text-align: center; }
    .inquiry-modal .modal-content .modal-header .modal-title {
      font-size: 24px;
      margin-bottom: 15px;
      line-height: 1.3;
      font-family: var(--font-family-title);
      text-align: center;
      width: 100%; }
    .inquiry-modal .modal-content .modal-header i {
      color: var(--primary);
      font-size: 54px;
      line-height: 1;
      display: inline-block; }
  .inquiry-modal .modal-content .modal-body {
    padding: 0; }

.inquiry-modal .btn-close {
  margin: 0;
  position: absolute;
  right: -40px;
  top: -40px;
  color: #fff;
  font-weight: 100;
  text-shadow: none;
  opacity: 1;
  font-size: 40px;
  padding: 0;
  height: 40px;
  line-height: 40px;
  width: 40px;
  border: 0;
  background: transparent; }
  @media only screen and (max-width: 767px) {
    .inquiry-modal .btn-close {
      right: 0; } }

.modal-content {
  border: 0;
  border-radius: var(--border-radius-base); }

.dz-divider {
  height: 1px;
  position: relative;
  margin: 30px 0; }
  .dz-divider.divider-2px {
    height: 2px; }
  .dz-divider.divider-3px {
    height: 3px; }
  .dz-divider.divider-4px {
    height: 4px; }
  .dz-divider i:not(.fa-square) {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%) rotate(45deg); }
  .dz-divider i {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: block;
    font-size: 14px;
    border-radius: 4px;
    top: 50%;
    transform: translateY(-50%); }
    .dz-divider i:before {
      display: inline-block;
      transform: rotate(-45deg); }
  .dz-divider.icon-left {
    margin-left: 40px; }
    .dz-divider.icon-left i {
      left: -40px; }
  .dz-divider.icon-right {
    margin-right: 40px; }
    .dz-divider.icon-right i {
      right: -40px; }
  .dz-divider.icon-center i {
    left: 50%;
    margin-left: -5px; }

.bg-gray-dark {
  background-color: #d3d3d3; }

.popover {
  border-radius: 4px; }
  .popover .popover-body {
    padding: 12px 25px;
    font-size: 16px; }

.nav-tabs .nav-link {
  font-family: var(--font-family-title); }

.nav-tabs.style-1 {
  border: 1px solid #dee2e6;
  border-radius: var(--border-radius-base);
  overflow: hidden;
  display: flex;
  flex-wrap: unset; }
  .nav-tabs.style-1 .nav-item {
    border-right: 1px solid #dee2e6;
    width: 100%; }
    .nav-tabs.style-1 .nav-item:last-child {
      border-right: 0; }
  .nav-tabs.style-1 .nav-link {
    border-radius: 0;
    border: 0;
    color: #2b2a2a;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 15px 15px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .nav-tabs.style-1 .nav-link.active {
      background: var(--primary);
      color: #fff; }

.nav-tabs.style-2 {
  overflow: hidden;
  border: 0; }
  .nav-tabs.style-2 .nav-link {
    border-radius: 0;
    border: 0;
    color: #2b2a2a;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: var(--border-radius-base);
    padding: 15px 30px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .nav-tabs.style-2 .nav-link.active {
      background: var(--primary);
      color: #fff; }

.nav-tabs.style-3 {
  overflow: hidden;
  border: 1px solid #e9e9e9;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 8px; }
  .nav-tabs.style-3 .nav-link {
    border-radius: 0;
    border: 0;
    color: #2b2a2a;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: var(--border-radius-base);
    padding: 15px 30px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .nav-tabs.style-3 .nav-link.active {
      background: var(--primary);
      color: #fff; }

.dz-social-icon ul {
  display: flex; }
  .dz-social-icon ul li {
    display: inline-block;
    padding: 0 5px; }
    .dz-social-icon ul li a {
      display: block;
      width: 45px;
      height: 45px;
      background-color: var(--primary);
      color: #fff;
      text-align: center;
      line-height: 45px; }

[class*="play-btn"] {
  position: relative;
  display: block; }

.play-btn1 {
  height: 95px;
  width: 95px;
  line-height: 95px;
  text-align: center;
  border: 4px solid;
  border-color: inherit;
  font-size: 30px;
  box-sizing: content-box;
  border-radius: 50%; }
  @media only screen and (max-width: 575px) {
    .play-btn1 {
      height: 60px;
      width: 60px;
      line-height: 60px;
      font-size: 20px; } }
  .play-btn1:after {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: -1;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: animationSignal;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    -webkit-animation: animationSignal;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .play-btn1:hover {
    color: var(--primary); }

.play-btn2 {
  height: 100px;
  width: 100px;
  background-color: inherit;
  text-align: center;
  line-height: 100px;
  font-size: 28px;
  color: #fff;
  background-color: var(--primary);
  border-radius: 50%; }
  @media only screen and (max-width: 575px) {
    .play-btn2 {
      height: 60px;
      width: 60px;
      line-height: 60px;
      font-size: 20px; } }
  .play-btn2:after {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: -1;
    background-color: var(--primary);
    border-radius: 50%;
    animation: animationSignal1;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    -webkit-animation: animationSignal1;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .play-btn2:hover {
    background-color: #fff; }
  .play-btn2:focus {
    color: #fff;
    background: var(--primary); }

.play-btn3 {
  width: 115px;
  height: 115px;
  background-color: var(--primary);
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-family: var(--font-family-title);
  line-height: 115px;
  font-size: 18px;
  font-weight: 500; }
  .play-btn3:after {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: -1;
    border: 2px solid var(--primary);
    border-radius: inherit;
    animation: animationSignal1;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    -webkit-animation: animationSignal1;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .play-btn3:hover {
    color: #fff; }
  .play-btn3:focus {
    color: #fff; }

.play-btn4 {
  width: 92px;
  height: 92px;
  line-height: 92px;
  background-color: #fff;
  color: var(--primary);
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  border: 3px solid;
  border-color: var(--primary); }
  .play-btn4:hover {
    color: #fff;
    background-color: var(--primary);
    border-color: #fff; }

.play-btn5 {
  height: 100px;
  width: 100px;
  line-height: 100px;
  font-size: 28px;
  background-color: inherit;
  text-align: center;
  color: var(--primary);
  background-color: #fff;
  border-radius: 50%; }
  .play-btn5:after {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: -1;
    border-radius: 50%;
    background-color: #fff;
    animation: animationSignal1;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    -webkit-animation: animationSignal1;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  @media only screen and (max-width: 575px) {
    .play-btn5 {
      height: 60px;
      width: 60px;
      line-height: 60px;
      font-size: 20px; } }
  .play-btn5:hover {
    background-color: #fff; }

.play-btn6 {
  height: 70px;
  width: 70px;
  background-color: inherit;
  text-align: center;
  line-height: 70px;
  font-size: 20px;
  color: #fff;
  background-color: var(--primary);
  border-radius: 50%; }
  .play-btn6:after {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: -1;
    border-radius: 50%;
    background-color: #fff;
    animation: animationSignal1;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    -webkit-animation: animationSignal1;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .play-btn6:hover {
    background-color: #fff; }

@keyframes animationSignal {
  0% {
    opacity: 0.8;
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    -webkit-transform: scale(1.2); }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0; } }

@-webkit-keyframes animationSignal {
  0% {
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    -webkit-transform: scale(1.2); }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0; } }

.counter-style-1 .counter-num {
  display: flex;
  align-items: center; }
  .counter-style-1 .counter-num .counter {
    font-size: 60px;
    line-height: 60px; }
  .counter-style-1 .counter-num small, .counter-style-1 .counter-num .small {
    font-size: 50px;
    font-weight: 700;
    margin-left: 5px;
    line-height: 50px; }

.counter-style-1 .counter-text {
  margin-bottom: 0; }

.counter-style-2 .counter-num {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px; }
  .counter-style-2 .counter-num .counter {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 0; }
  .counter-style-2 .counter-num small, .counter-style-2 .counter-num .small {
    font-size: 50px;
    font-weight: 900;
    margin-left: 5px;
    line-height: 40px; }

.counter-style-2 .counter-text {
  margin-bottom: 0;
  font-family: var(--font-family-base);
  font-size: 18px;
  font-weight: 500; }

.counter-style-3 {
  display: flex;
  align-items: center; }
  .counter-style-3 .icon-bx {
    display: block;
    width: 80px;
    background-color: #000;
    height: 80px;
    min-width: 80px;
    text-align: center;
    line-height: 80px;
    outline: 2px solid #fff;
    outline-offset: 10px;
    margin: 12px 32px 12px 12px; }
  .counter-style-3 .counter-num {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px; }
    .counter-style-3 .counter-num .counter {
      font-size: 45px;
      line-height: 45px;
      margin-bottom: 0; }
    .counter-style-3 .counter-num small, .counter-style-3 .counter-num .small {
      font-size: 36px;
      font-weight: 900;
      margin-left: 5px;
      line-height: 36px; }
  .counter-style-3 .counter-text {
    margin-bottom: 0;
    font-family: var(--font-family-base);
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase; }

.blockquote.style-1 {
  box-shadow: unset;
  padding: 60px 5px 5px 30px; }
  .blockquote.style-1 p {
    color: #999999;
    font-weight: 400; }
    @media only screen and (max-width: 575px) {
      .blockquote.style-1 p {
        font-size: 18px; } }
  .blockquote.style-1:before {
    left: 30px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    opacity: 1;
    content: "\f10d";
    font-family: 'Font Awesome 5 Free';
    transform: rotate(0deg);
    font-size: 40px;
    background-image: none; }
  .blockquote.style-1:after {
    width: 2px; }
  .blockquote.style-1 cite {
    color: #999999;
    font-size: 16px;
    font-weight: 400;
    margin-left: 6px; }
    .blockquote.style-1 cite:before {
      content: "/";
      background: unset;
      height: auto;
      margin-right: -5px;
      margin-left: 5px; }
  .blockquote.style-1 .quote-info {
    display: flex;
    align-items: center; }

.main-slider1,
.main-slider2,
.main-slider3 {
  position: relative;
  overflow: hidden;
  height: 100%; }
  .main-slider1 .swiper-button,
  .main-slider2 .swiper-button,
  .main-slider3 .swiper-button {
    position: absolute;
    display: flex;
    align-items: center; }
    .main-slider1 .swiper-button .swiper-button-prev,
    .main-slider1 .swiper-button .swiper-button-next,
    .main-slider2 .swiper-button .swiper-button-prev,
    .main-slider2 .swiper-button .swiper-button-next,
    .main-slider3 .swiper-button .swiper-button-prev,
    .main-slider3 .swiper-button .swiper-button-next {
      position: unset;
      opacity: 1;
      -webkit-transition: all 0.2s;
      -ms-transition: all 0.2s;
      transition: all 0.2s; }
      .main-slider1 .swiper-button .swiper-button-prev:after,
      .main-slider1 .swiper-button .swiper-button-next:after,
      .main-slider2 .swiper-button .swiper-button-prev:after,
      .main-slider2 .swiper-button .swiper-button-next:after,
      .main-slider3 .swiper-button .swiper-button-prev:after,
      .main-slider3 .swiper-button .swiper-button-next:after {
        content: none; }

.main-slider1 {
  padding: 140px 140px 0 0; }
  @media only screen and (max-width: 1400px) {
    .main-slider1 {
      padding: 100px 100px 0px 0px; } }
  @media only screen and (max-width: 575px) {
    .main-slider1 {
      padding: 80px 0px 0px 0px; } }
  .main-slider1 .banner-inner {
    z-index: 1;
    position: relative;
    overflow: hidden;
    border-right: 4px solid var(--primary); }
    .main-slider1 .banner-inner .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 600px;
      height: calc(100vh - 140px);
      display: flex;
      background-size: cover; }
      .main-slider1 .banner-inner .swiper-slide:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background: #000;
        opacity: 0.3;
        height: 100%; }
      @media only screen and (max-width: 1400px) {
        .main-slider1 .banner-inner .swiper-slide {
          min-height: 600px; } }
      @media only screen and (max-width: 991px) {
        .main-slider1 .banner-inner .swiper-slide {
          height: calc(100vh - 120px); } }
    .main-slider1 .banner-inner .banner-content {
      position: absolute;
      left: calc((100% - 970px) / 2); }
      @media only screen and (max-width: 1200px) {
        .main-slider1 .banner-inner .banner-content {
          left: calc((100% - 805px) / 2); } }
      @media only screen and (max-width: 991px) {
        .main-slider1 .banner-inner .banner-content {
          left: calc((100% - 590px) / 2); } }
      @media only screen and (max-width: 767px) {
        .main-slider1 .banner-inner .banner-content {
          left: 0;
          padding: 0 30px; } }
      @media only screen and (max-width: 575px) {
        .main-slider1 .banner-inner .banner-content {
          padding: 0 15px; } }
    .main-slider1 .banner-inner .title {
      font-size: 85px;
      color: #fff;
      line-height: 85px;
      margin-bottom: 20px;
      font-weight: 600; }
      @media only screen and (max-width: 1400px) {
        .main-slider1 .banner-inner .title {
          font-size: 62px;
          line-height: 70px; } }
      @media only screen and (max-width: 991px) {
        .main-slider1 .banner-inner .title {
          font-size: 55px;
          line-height: 58px; } }
      @media only screen and (max-width: 575px) {
        .main-slider1 .banner-inner .title {
          font-size: 40px;
          line-height: 45px; } }
    .main-slider1 .banner-inner p {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 45px; }
      @media only screen and (max-width: 1400px) {
        .main-slider1 .banner-inner p {
          font-size: 16px; } }
      @media only screen and (max-width: 575px) {
        .main-slider1 .banner-inner p {
          margin-bottom: 30px; } }
  .main-slider1 .image-slider__pagination {
    position: absolute;
    top: 50%;
    right: -80px;
    z-index: 1;
    transform: translateY(-50%) rotate(90deg);
    color: #fff;
    font-family: var(--font-family-title);
    font-size: 22px;
    display: flex;
    align-items: center; }
    .main-slider1 .image-slider__pagination .image-slider__current,
    .main-slider1 .image-slider__pagination .image-slider__total {
      transform: rotate(-90deg); }
    @media only screen and (max-width: 1400px) {
      .main-slider1 .image-slider__pagination {
        right: -100px; } }
    @media only screen and (max-width: 575px) {
      .main-slider1 .image-slider__pagination {
        display: none; } }
  .main-slider1 .swiper-pagination {
    height: 3px;
    width: 200px;
    position: relative;
    background-color: #444444;
    margin: 0 30px; }
    .main-slider1 .swiper-pagination .swiper-pagination-progressbar-fill {
      background-color: #b5b4b4;
      height: 100%;
      width: 100%;
      top: 0;
      position: absolute;
      transform-origin: top left; }
  .main-slider1 .swiper-button {
    right: 0;
    bottom: 0; }
    .main-slider1 .swiper-button .swiper-button-prev,
    .main-slider1 .swiper-button .swiper-button-next {
      height: 105px;
      width: 105px;
      background-color: var(--primary);
      line-height: 105px;
      font-size: 30px;
      color: #fff;
      text-align: center;
      cursor: pointer; }
      @media only screen and (max-width: 1400px) {
        .main-slider1 .swiper-button .swiper-button-prev,
        .main-slider1 .swiper-button .swiper-button-next {
          height: 70px;
          width: 70px;
          line-height: 70px; } }
      .main-slider1 .swiper-button .swiper-button-prev:hover,
      .main-slider1 .swiper-button .swiper-button-next:hover {
        background-color: var(--secondary); }
    .main-slider1 .swiper-button .swiper-button-next {
      border-left: 1px solid rgba(0, 0, 0, 0.2); }
  .main-slider1 .slider-thumbs-wraper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100vh - 105px);
    min-height: 785px;
    z-index: 0; }
    @media only screen and (max-width: 1400px) {
      .main-slider1 .slider-thumbs-wraper {
        height: calc(100vh - 70px); } }
    .main-slider1 .slider-thumbs-wraper .swiper-slide {
      width: 100%;
      height: 100%; }
      .main-slider1 .slider-thumbs-wraper .swiper-slide .slider-thumbs {
        height: 100%; }
        .main-slider1 .slider-thumbs-wraper .swiper-slide .slider-thumbs img {
          width: 100%;
          height: 100%;
          object-fit: cover; }

.main-slider2 {
  height: 100%; }
  .main-slider2 .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    min-height: 780px; }
    .main-slider2 .swiper-slide:after {
      content: "";
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      position: absolute;
      background: -moz-linear-gradient(left, rgba(25, 25, 25, 0.9) 40%, rgba(125, 185, 232, 0) 100%);
      background: -webkit-linear-gradient(left, rgba(25, 25, 25, 0.9) 40%, rgba(125, 185, 232, 0) 100%);
      background: linear-gradient(to right, rgba(25, 25, 25, 0.9) 40%, rgba(125, 185, 232, 0) 100%); }
    @media only screen and (max-width: 575px) {
      .main-slider2 .swiper-slide {
        min-height: 620px; } }
  .main-slider2 .banner-inner {
    z-index: 1;
    position: relative; }
    .main-slider2 .banner-inner .banner-content {
      z-index: 1;
      position: relative; }
      .main-slider2 .banner-inner .banner-content .title {
        font-size: 100px;
        line-height: 100px;
        color: #fff;
        margin-bottom: 25px; }
        @media only screen and (max-width: 1400px) {
          .main-slider2 .banner-inner .banner-content .title {
            font-size: 65px;
            line-height: 70px; } }
        @media only screen and (max-width: 767px) {
          .main-slider2 .banner-inner .banner-content .title {
            font-size: 50px;
            line-height: 50px; } }
        @media only screen and (max-width: 575px) {
          .main-slider2 .banner-inner .banner-content .title {
            font-size: 38px;
            line-height: 38px; } }
      .main-slider2 .banner-inner .banner-content p {
        color: #fff;
        font-size: 18px;
        margin-bottom: 50px;
        font-weight: 500; }
        @media only screen and (max-width: 767px) {
          .main-slider2 .banner-inner .banner-content p {
            margin-bottom: 30px; } }
        @media only screen and (max-width: 575px) {
          .main-slider2 .banner-inner .banner-content p {
            font-size: 16px;
            padding-right: 0px; } }
  .main-slider2 .swiper-button {
    left: 0;
    bottom: 0;
    width: 100%;
    justify-content: space-between; }
    .main-slider2 .swiper-button .swiper-button-prev,
    .main-slider2 .swiper-button .swiper-button-next {
      height: 85px;
      width: 85px;
      background-color: rgba(255, 255, 255, 0.7);
      line-height: 105px;
      font-size: 36px;
      color: var(--primary);
      text-align: center; }
      .main-slider2 .swiper-button .swiper-button-prev:hover,
      .main-slider2 .swiper-button .swiper-button-next:hover {
        background-color: #fff; }
      @media only screen and (max-width: 991px) {
        .main-slider2 .swiper-button .swiper-button-prev,
        .main-slider2 .swiper-button .swiper-button-next {
          height: 60px;
          width: 60px;
          line-height: 60px;
          font-size: 30px; } }

.main-slider3 {
  height: 100%;
  padding: 0 80px 80px 0;
  background-color: var(--primary); }
  @media only screen and (max-width: 991px) {
    .main-slider3 {
      padding: 0; } }
  .main-slider3:after {
    content: "";
    height: 100%;
    width: calc(calc(100% - 1200px) / 2);
    background-color: #fff;
    position: absolute;
    left: 0;
    bottom: 0; }
  .main-slider3 .banner-inner .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 148px);
    min-height: 800px;
    background-position: center left; }
    .main-slider3 .banner-inner .swiper-slide:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      background: #000;
      opacity: 0.7;
      height: 100%; }
    .main-slider3 .banner-inner .swiper-slide:after {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("../images/main-slider/pic1.png"); }
    @media only screen and (max-width: 1400px) {
      .main-slider3 .banner-inner .swiper-slide {
        min-height: 680px; } }
    @media only screen and (max-width: 767px) {
      .main-slider3 .banner-inner .swiper-slide {
        min-height: auto;
        height: auto;
        padding-bottom: 180px;
        padding-top: 30px; } }
  .main-slider3 .slider-thumbs-wraper {
    width: 800px;
    position: absolute;
    right: 100px;
    bottom: 130px; }
    .main-slider3 .slider-thumbs-wraper .swiper-slide {
      opacity: 0.5; }
      .main-slider3 .slider-thumbs-wraper .swiper-slide.swiper-slide-active {
        opacity: 1; }
    @media only screen and (max-width: 991px) {
      .main-slider3 .slider-thumbs-wraper {
        width: 400px;
        right: 20px;
        bottom: 40px; } }
    @media only screen and (max-width: 767px) {
      .main-slider3 .slider-thumbs-wraper {
        bottom: 50px; } }
    @media only screen and (max-width: 575px) {
      .main-slider3 .slider-thumbs-wraper {
        bottom: 50px;
        width: calc(100% - 30px);
        right: auto;
        left: 15px; } }
  .main-slider3 .slider-thumbs {
    display: flex;
    align-items: center;
    cursor: pointer; }
    .main-slider3 .slider-thumbs .dz-media {
      min-width: 120px;
      height: 90px;
      overflow: hidden; }
      .main-slider3 .slider-thumbs .dz-media img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      @media only screen and (max-width: 575px) {
        .main-slider3 .slider-thumbs .dz-media {
          min-width: 90px; } }
    .main-slider3 .slider-thumbs .dz-info {
      padding-left: 20px; }
      .main-slider3 .slider-thumbs .dz-info .title {
        color: #fff; }
      .main-slider3 .slider-thumbs .dz-info p {
        color: #fff;
        margin-bottom: 0; }
        @media only screen and (max-width: 575px) {
          .main-slider3 .slider-thumbs .dz-info p {
            font-size: 16px; } }
  .main-slider3 .banner-inner {
    z-index: 1;
    position: relative; }
    .main-slider3 .banner-inner .banner-content {
      z-index: 1;
      position: relative;
      padding-left: 55px; }
      .main-slider3 .banner-inner .banner-content .title {
        font-size: 80px;
        line-height: 90px;
        color: #fff;
        margin-bottom: 25px; }
        @media only screen and (max-width: 1400px) {
          .main-slider3 .banner-inner .banner-content .title {
            font-size: 62px;
            line-height: 72px; } }
        @media only screen and (max-width: 767px) {
          .main-slider3 .banner-inner .banner-content .title {
            font-size: 45px;
            line-height: 55px; } }
        @media only screen and (max-width: 575px) {
          .main-slider3 .banner-inner .banner-content .title {
            font-size: 38px;
            line-height: 45px; } }
      .main-slider3 .banner-inner .banner-content p {
        color: #fff;
        font-size: 18px;
        margin-bottom: 50px;
        padding-right: 150px; }
        @media only screen and (max-width: 767px) {
          .main-slider3 .banner-inner .banner-content p {
            padding-right: 0px;
            margin-bottom: 30px; } }
      @media only screen and (max-width: 575px) {
        .main-slider3 .banner-inner .banner-content {
          padding-left: 15px;
          margin-bottom: 30px; }
          .main-slider3 .banner-inner .banner-content p {
            font-size: 16px; } }
  .main-slider3 .swiper-button {
    left: 0;
    bottom: 0;
    width: 100%;
    justify-content: space-between; }
    .main-slider3 .swiper-button .swiper-button-prev,
    .main-slider3 .swiper-button .swiper-button-next {
      height: 85px;
      width: 85px;
      background-color: rgba(255, 255, 255, 0.7);
      line-height: 105px;
      font-size: 36px;
      color: var(--primary); }
      .main-slider3 .swiper-button .swiper-button-prev:hover,
      .main-slider3 .swiper-button .swiper-button-next:hover {
        background-color: #fff; }

.banner-one {
  height: calc(100vh - 100px);
  overflow: hidden;
  min-height: 700px;
  position: relative;
  z-index: 1;
  background-size: cover; }
  @media only screen and (max-width: 575px) {
    .banner-one {
      height: auto;
      min-height: auto; } }
  .banner-one .banner-inner {
    width: 50%;
    margin-left: auto;
    display: flex;
    height: 100%;
    align-items: flex-end; }
    @media only screen and (max-width: 767px) {
      .banner-one .banner-inner {
        width: 60%; } }
    @media only screen and (max-width: 575px) {
      .banner-one .banner-inner {
        width: 100%; } }
  .banner-one .video-bx {
    display: flex;
    align-items: center; }
    .banner-one .video-bx .video-text {
      font-size: 20px;
      text-transform: uppercase;
      color: #fff;
      font-family: var(--font-family-title);
      margin-left: 30px;
      line-height: 1.2;
      font-weight: 500; }
      @media only screen and (max-width: 575px) {
        .banner-one .video-bx .video-text {
          margin-left: 10px; } }
  .banner-one .banner-media {
    transform: skewX(-10deg);
    overflow: hidden;
    position: absolute;
    height: 100%;
    z-index: -1;
    right: -200px;
    width: calc(52vw + 200px); }
    .banner-one .banner-media img {
      transform: skewX(10deg);
      height: 100%;
      width: calc(100% + 200px);
      object-fit: cover;
      object-position: top;
      margin-left: -200px; }
    .banner-one .banner-media:before {
      z-index: 1; }
    @media only screen and (max-width: 767px) {
      .banner-one .banner-media {
        width: calc(64vw + 200px); } }
    @media only screen and (max-width: 575px) {
      .banner-one .banner-media {
        width: 100%;
        transform: skewX(0deg);
        right: 0; }
        .banner-one .banner-media img {
          transform: skewX(0deg);
          margin-left: 0px;
          width: 100%; } }
  .banner-one .banner-content {
    padding: 60px 0;
    padding-right: 120px; }
    .banner-one .banner-content h1, .banner-one .banner-content .h1 {
      font-size: 78px;
      line-height: 1.1;
      color: #fff;
      text-transform: uppercase; }
      @media only screen and (max-width: 1200px) {
        .banner-one .banner-content h1, .banner-one .banner-content .h1 {
          font-size: 60px; } }
      @media only screen and (max-width: 991px) {
        .banner-one .banner-content h1, .banner-one .banner-content .h1 {
          font-size: 50px; } }
    @media only screen and (max-width: 1400px) {
      .banner-one .banner-content {
        padding-right: 40px; } }
    @media only screen and (max-width: 575px) {
      .banner-one .banner-content {
        padding-left: 15px;
        padding-right: 15px; }
        .banner-one .banner-content h1, .banner-one .banner-content .h1 {
          font-size: 38px; } }
  .banner-one:before {
    z-index: -2; }
  .banner-one:after {
    content: "";
    position: absolute;
    height: 60px;
    background: #fff;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -2; }

.main-slider5 {
  width: 100%; }
  .main-slider5 .banner-inner {
    padding-right: 70px;
    margin-left: calc(calc(100% - 1140px) / 2);
    position: relative;
    padding-left: 15px; }
    @media only screen and (max-width: 1200px) {
      .main-slider5 .banner-inner {
        margin-left: calc(calc(100% - 960px) / 2); } }
    @media only screen and (max-width: 991px) {
      .main-slider5 .banner-inner {
        margin-left: calc(calc(100% - 720px) / 2); } }
    @media only screen and (max-width: 767px) {
      .main-slider5 .banner-inner {
        margin-left: calc(calc(100% - 540px) / 2); } }
    @media only screen and (max-width: 575px) {
      .main-slider5 .banner-inner {
        margin-left: 0;
        padding-right: 0; } }
    .main-slider5 .banner-inner .row {
      width: 100%; }
    .main-slider5 .banner-inner .swiper-pagination {
      top: auto;
      width: 220px;
      height: 3px;
      background: #444444;
      position: relative;
      margin: 0 20px; }
      .main-slider5 .banner-inner .swiper-pagination .swiper-pagination-progressbar-fill {
        background: var(--primary);
        height: 100%;
        width: 100%;
        top: 0;
        position: absolute;
        transform-origin: top left; }
      @media only screen and (max-width: 575px) {
        .main-slider5 .banner-inner .swiper-pagination {
          width: 90px; } }
    .main-slider5 .banner-inner .image-slider__pagination {
      bottom: 50px;
      left: 0;
      position: absolute;
      display: flex;
      align-items: center;
      color: #fff;
      font-family: var(--font-family-title);
      font-size: 20px;
      z-index: 2; }
      @media only screen and (max-width: 991px) {
        .main-slider5 .banner-inner .image-slider__pagination {
          left: 50px;
          bottom: 70px; } }
      @media only screen and (max-width: 575px) {
        .main-slider5 .banner-inner .image-slider__pagination {
          left: 35px;
          bottom: 23px; } }
  .main-slider5 .banner-content {
    padding: 130px 0;
    padding-right: 100px; }
    .main-slider5 .banner-content h1, .main-slider5 .banner-content .h1 {
      color: #fff;
      font-size: 78px;
      line-height: 1.1;
      margin-bottom: 30px; }
    .main-slider5 .banner-content p {
      color: #fff;
      margin-bottom: 30px; }
    @media only screen and (max-width: 1680px) {
      .main-slider5 .banner-content {
        padding-right: 70px; } }
    @media only screen and (max-width: 1400px) {
      .main-slider5 .banner-content h1, .main-slider5 .banner-content .h1 {
        font-size: 62px; } }
    @media only screen and (max-width: 1200px) {
      .main-slider5 .banner-content h1, .main-slider5 .banner-content .h1 {
        font-size: 55px; } }
    @media only screen and (max-width: 991px) {
      .main-slider5 .banner-content {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 30px; } }
    @media only screen and (max-width: 575px) {
      .main-slider5 .banner-content {
        padding-right: 0; }
        .main-slider5 .banner-content h1, .main-slider5 .banner-content .h1 {
          font-size: 35px;
          margin-bottom: 20px; }
        .main-slider5 .banner-content p {
          font-size: 15px; } }
  .main-slider5 .swiper-button {
    position: absolute;
    height: 100%;
    width: 70px;
    top: 0;
    overflow: hidden;
    background: var(--secondary);
    right: 0;
    display: flex;
    flex-direction: column; }
    .main-slider5 .swiper-button .swiper-button-prev,
    .main-slider5 .swiper-button .swiper-button-next {
      height: 50%;
      width: 100%;
      position: unset;
      top: 0;
      right: 0;
      color: #fff;
      font-size: 25px;
      margin-top: 0;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      justify-content: center;
      display: flex;
      align-items: center; }
      .main-slider5 .swiper-button .swiper-button-prev:after,
      .main-slider5 .swiper-button .swiper-button-next:after {
        content: none; }
      .main-slider5 .swiper-button .swiper-button-prev:hover,
      .main-slider5 .swiper-button .swiper-button-next:hover {
        background: var(--primary); }
    @media only screen and (max-width: 575px) {
      .main-slider5 .swiper-button {
        width: auto;
        font-size: 18px;
        height: auto;
        bottom: 15px;
        right: 15px;
        top: auto;
        z-index: 3;
        background: transparent;
        flex-direction: row; }
        .main-slider5 .swiper-button .swiper-button-prev,
        .main-slider5 .swiper-button .swiper-button-next {
          height: 50px;
          width: 50px;
          line-height: 50px;
          margin: 0 5px;
          font-size: 18px;
          background-color: var(--primary); }
          .main-slider5 .swiper-button .swiper-button-prev.swiper-button-disabled,
          .main-slider5 .swiper-button .swiper-button-next.swiper-button-disabled {
            opacity: .8; } }
  .main-slider5 .media-slider {
    margin-top: -45px;
    height: calc(100vh - 190px);
    min-height: 800px;
    position: relative; }
    .main-slider5 .media-slider .dz-media {
      height: 100%; }
      .main-slider5 .media-slider .dz-media img {
        height: 100%;
        object-fit: cover;
        object-position: top; }
    .main-slider5 .media-slider .swiper-container {
      height: 100%; }
    .main-slider5 .media-slider .popup-youtube {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -50px;
      z-index: 3;
      background: var(--primary);
      color: #fff; }
      .main-slider5 .media-slider .popup-youtube:after {
        background: var(--primary); }
    @media only screen and (max-width: 1400px) {
      .main-slider5 .media-slider {
        min-height: 700px; } }
    @media only screen and (max-width: 991px) {
      .main-slider5 .media-slider {
        margin-top: 0; }
        .main-slider5 .media-slider .popup-youtube {
          display: none; } }
    @media only screen and (max-width: 767px) {
      .main-slider5 .media-slider {
        min-height: auto;
        height: 500px; } }
    @media only screen and (max-width: 575px) {
      .main-slider5 .media-slider {
        height: 320px; } }

.move-1 {
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite; }

.move-2 {
  -webkit-animation: move2 5s infinite;
  animation: move2 5s infinite; }

.move-3 {
  -webkit-animation: move3 5s infinite;
  animation: move3 5s infinite; }

@-webkit-keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); }
  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); }
  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); } }

@keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); }
  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); }
  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); } }

@-webkit-keyframes move2 {
  0% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); }
  50% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px); }
  100% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px); } }

@-webkit-keyframes move3 {
  0% {
    transform: translate(0, 0); }
  20% {
    transform: translate(5px, 0); }
  40% {
    transform: translate(5px, 5px); }
  65% {
    transform: translate(0, 5px); }
  65% {
    transform: translate(5px, 0); }
  100% {
    transform: translate(0, 0); } }

.about-style1 .section-head .title {
  margin-bottom: 20px; }

.about-style1 .section-head p {
  max-width: 500px; }

.about-style1 .exp-bx {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  margin-left: -125px;
  width: 400px;
  position: relative; }
  .about-style1 .exp-bx .exp-head {
    padding: 20px; }
  .about-style1 .exp-bx .exp-info {
    padding: 20px;
    background-color: #fff;
    width: 100%; }
  .about-style1 .exp-bx .counter-num {
    align-items: center;
    display: flex;
    margin-bottom: 15px; }
    .about-style1 .exp-bx .counter-num .counter {
      font-size: 62px;
      line-height: 62px;
      margin-bottom: 0;
      color: #fff; }
    .about-style1 .exp-bx .counter-num small, .about-style1 .exp-bx .counter-num .small {
      font-size: 42px;
      color: #fff;
      margin-left: 5px;
      line-height: 42px;
      font-weight: 500; }
  .about-style1 .exp-bx .title {
    color: #fff;
    font-size: 25px;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 32px; }

@media only screen and (max-width: 1199px) {
  .about-style1 .exp-bx {
    margin-left: -185px; } }

@media only screen and (max-width: 991px) {
  .about-style1 .exp-bx {
    margin-left: -65px; } }

@media only screen and (max-width: 767px) {
  .about-style1 .exp-bx {
    margin-left: -155px; } }

@media only screen and (max-width: 575px) {
  .about-style1 .exp-bx {
    margin: 0;
    width: 100%; }
    .about-style1 .exp-bx .exp-head {
      padding: 12px; }
    .about-style1 .exp-bx .exp-info {
      padding: 10px 15px; }
      .about-style1 .exp-bx .exp-info ul li {
        font-size: 15px;
        padding: 4px 0px 4px 25px; }
        .about-style1 .exp-bx .exp-info ul li:before {
          font-size: 14px; }
    .about-style1 .exp-bx .counter-num .counter {
      font-size: 50px;
      line-height: 50px; }
    .about-style1 .exp-bx .counter-num small, .about-style1 .exp-bx .counter-num .small {
      font-size: 32px;
      line-height: 32px; }
    .about-style1 .exp-bx .title {
      font-size: 20px;
      line-height: 25px; } }

.about-style2 .about-thumb {
  position: relative;
  margin-top: -200px; }
  @media only screen and (max-width: 1200px) {
    .about-style2 .about-thumb {
      margin-top: 0; } }
  .about-style2 .about-thumb .popup-youtube {
    position: absolute;
    bottom: -45px;
    left: -45px;
    z-index: 9; }

.about-style2 .section-head {
  margin-right: -5px; }

@media only screen and (max-width: 991px) {
  .about-style2 .about-thumb {
    margin-top: 0;
    height: 500px;
    overflow: hidden; }
    .about-style2 .about-thumb .popup-youtube {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      bottom: auto; } }

.about-style3 .section-head {
  margin-bottom: 20px; }

.about-style3 .about-thumb {
  position: relative;
  padding: 60px 20px 60px 150px; }
  .about-style3 .about-thumb .about-video {
    position: relative; }
    .about-style3 .about-thumb .about-video .popup-youtube {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .about-style3 .about-thumb:before {
    content: "";
    position: absolute;
    right: 100px;
    top: 0px;
    border: 10px solid var(--primary);
    height: 100%;
    width: calc(100% - 100px);
    -webkit-animation: movedelement 7s linear infinite;
    animation: movedelement 7s linear infinite; }

@media only screen and (max-width: 991px) {
  .about-style3 .about-thumb .about-video {
    height: 500px;
    overflow: hidden; }
    .about-style3 .about-thumb .about-video img {
      object-fit: cover;
      height: 100%; } }

@media only screen and (max-width: 767px) {
  .about-style3 .about-thumb {
    padding: 60px 0 60px 150px; } }

@media only screen and (max-width: 575px) {
  .about-style3 .about-thumb {
    padding: 30px 0 30px 30px; }
    .about-style3 .about-thumb .about-video {
      height: 400px; }
    .about-style3 .about-thumb:before {
      border-width: 5px; } }

.bg-particles {
  position: relative; }
  .bg-particles .particles-js-canvas-el {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1; }

@keyframes movedelement {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0); }
  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px); }
  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px); }
  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px); }
  to {
    -webkit-transform: translate(0);
    transform: translate(0); } }

.about-style4 .section-head {
  margin-bottom: 20px; }

.about-style4 .about-thumb {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-base);
  margin-right: 20px; }

.about-style4 blockquote {
  margin: 0 0 30px 30px; }

@media only screen and (max-width: 991px) {
  .about-style4 .about-thumb {
    margin-right: 20px; } }

@media only screen and (max-width: 575px) {
  .about-style4 blockquote {
    margin: 0px 0px 25px 0px; } }

.about-style5 .about-media {
  border-top: 4px solid var(--primary);
  border-bottom: 4px solid var(--primary);
  margin-right: 15px;
  margin-top: -180px; }
  @media only screen and (max-width: 991px) {
    .about-style5 .about-media {
      margin-top: 0; } }

.video-box {
  position: relative; }
  .video-box .popup-youtube {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.about-style6 .about-media {
  border-bottom: 55px solid var(--primary);
  margin-top: -200px;
  margin-bottom: -120px; }
  .about-style6 .about-media .dz-media .popup-youtube {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @media only screen and (max-width: 991px) {
    .about-style6 .about-media {
      margin-top: 0;
      margin-bottom: -50px; } }
  @media only screen and (max-width: 575px) {
    .about-style6 .about-media .counter-style-2 .counter-num {
      margin-bottom: 0; }
      .about-style6 .about-media .counter-style-2 .counter-num small, .about-style6 .about-media .counter-style-2 .counter-num .small,
      .about-style6 .about-media .counter-style-2 .counter-num .counter {
        font-size: 35px; }
    .about-style6 .about-media .counter-style-2 .counter-text {
      font-size: 16px; } }

.about-style7 .about-media {
  padding-left: 65px;
  position: relative;
  padding-bottom: 170px; }
  .about-style7 .about-media .ov-img {
    position: absolute;
    left: 0;
    bottom: 0;
    object-fit: cover;
    padding-right: 140px; }
  @media only screen and (max-width: 575px) {
    .about-style7 .about-media {
      padding-left: 30px;
      padding-bottom: 120px; }
      .about-style7 .about-media .ov-img {
        padding-right: 80px; } }

.about-style8 .dz-media {
  padding-bottom: 100px; }
  .about-style8 .dz-media img {
    border-radius: var(--border-radius-base); }
  .about-style8 .dz-media ul {
    border-radius: var(--border-radius-base);
    position: absolute;
    left: 50px;
    bottom: 0;
    background: var(--primary);
    padding: 20px 30px 20px 30px; }
    .about-style8 .dz-media ul li {
      color: #fff;
      font-family: var(--font-family-base); }
  @media only screen and (max-width: 575px) {
    .about-style8 .dz-media {
      padding-bottom: 0; }
      .about-style8 .dz-media ul {
        position: unset;
        margin-top: 30px; } }

.about-style9 .dz-media img {
  border-radius: var(--border-radius-base); }

.company-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .company-info .users,
  .company-info .brand {
    font-size: 40px;
    margin-bottom: 0;
    margin-right: 20px; }
  .company-info .title {
    margin-bottom: 3px;
    line-height: 1; }
  .company-info span {
    font-size: 13px;
    display: inline-block; }

.about-style10 .list-check-circle {
  background: #f4f4f4;
  height: 100%;
  padding: 50px 20px 50px 20px; }
  .about-style10 .list-check-circle li {
    padding: 8px 5px 8px 30px; }
    .about-style10 .list-check-circle li:before {
      top: 8px; }

.about-style11 .dz-media {
  border-radius: var(--border-radius-base);
  margin-top: 30px;
  margin-left: 30px;
  overflow: visible; }
  .about-style11 .dz-media:before {
    border-radius: inherit; }
  .about-style11 .dz-media img {
    border-radius: var(--border-radius-base); }
  .about-style11 .dz-media .video-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .about-style11 .dz-media .video-btn .popup-youtube {
      margin: auto;
      margin-bottom: 10px; }
    .about-style11 .dz-media .video-btn p {
      margin-bottom: 0;
      color: #fff;
      text-transform: uppercase; }
      @media only screen and (max-width: 575px) {
        .about-style11 .dz-media .video-btn p {
          font-size: 14px; } }
  .about-style11 .dz-media .exp-box {
    border-radius: var(--border-radius-base);
    background: var(--primary);
    position: absolute;
    top: -30px;
    left: -30px;
    width: 150px;
    padding: 30px 20px 30px 20px;
    -webkit-animation: movedelement 7s linear infinite;
    animation: movedelement 7s linear infinite; }
    .about-style11 .dz-media .exp-box .year {
      color: #fff;
      font-size: 55px;
      line-height: 1; }
    .about-style11 .dz-media .exp-box span {
      color: #fff;
      font-size: 25px;
      font-family: var(--font-family-title);
      line-height: 1.3;
      display: inline-block;
      font-weight: 600; }
    @media only screen and (max-width: 575px) {
      .about-style11 .dz-media .exp-box {
        padding: 15px 12px;
        width: 100px; }
        .about-style11 .dz-media .exp-box .year {
          font-size: 35px; }
        .about-style11 .dz-media .exp-box span {
          font-size: 16px; } }

.check-box {
  border-radius: var(--border-radius-base);
  background-color: white;
  position: relative;
  padding: 30px 30px 30px 95px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  .check-box:before {
    content: "\f058";
    font-family: "Font Awesome 5 Free";
    font-size: 45px;
    font-weight: 500;
    top: 50%;
    position: absolute;
    left: 30px;
    color: var(--primary);
    transform: translateY(-50%); }
  .check-box p {
    margin-bottom: 0; }
  .check-box:hover, .check-box.active {
    background: var(--secondary);
    color: #fff; }
    .check-box:hover .title, .check-box.active .title {
      color: #fff; }

.about-style12 .dz-media {
  padding-left: 100px;
  padding-top: 100px;
  margin-left: 75px;
  overflow: visible; }
  .about-style12 .dz-media img {
    border-radius: var(--border-radius-base); }
  .about-style12 .dz-media:after {
    content: "";
    height: calc(100% - 100px);
    width: calc(100% - 100px);
    position: absolute;
    border: 30px solid var(--primary);
    left: 0;
    top: 0;
    border-radius: var(--border-radius-base);
    z-index: -1; }
  .about-style12 .dz-media ul {
    padding: 40px 30px;
    position: absolute;
    bottom: 30px;
    left: -75px;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: var(--border-radius-base);
    z-index: 1; }
    .about-style12 .dz-media ul li {
      font-size: 20px; }
  @media only screen and (max-width: 1200px) {
    .about-style12 .dz-media {
      padding-left: 60px;
      padding-top: 60px;
      margin-left: 40px; }
      .about-style12 .dz-media:after {
        border-width: 20px; }
      .about-style12 .dz-media ul {
        left: -40px; } }
  @media only screen and (max-width: 575px) {
    .about-style12 .dz-media {
      margin-left: 0;
      padding-left: 30px;
      padding-top: 30px; }
      .about-style12 .dz-media:after {
        height: calc(100% - 30px);
        width: calc(100% - 30px);
        border-width: 10px; }
      .about-style12 .dz-media ul {
        position: unset;
        margin-top: 30px; } }

.about-style13 .dz-media {
  padding-top: 100px;
  padding-left: 70px;
  padding-right: 40px;
  overflow: visible; }
  .about-style13 .dz-media .main-img {
    box-shadow: 0px 17px 50px 0px rgba(0, 0, 0, 0.29); }
  .about-style13 .dz-media .bg-img {
    position: absolute;
    height: calc(100% - 100px);
    width: calc(100% - 100px);
    left: 0;
    top: 0;
    z-index: -1; }
    .about-style13 .dz-media .bg-img img {
      object-fit: cover;
      height: 100%;
      width: 100%; }
  @media only screen and (max-width: 991px) {
    .about-style13 .dz-media {
      padding-right: 0; } }
  @media only screen and (max-width: 575px) {
    .about-style13 .dz-media {
      padding-top: 40px;
      padding-left: 40px; }
      .about-style13 .dz-media .bg-img {
        height: calc(100% - 40px);
        width: calc(100% - 40px); } }

.about-style14 .dz-media {
  padding-right: 30px; }
  .about-style14 .dz-media img {
    border-radius: var(--border-radius-base); }
  @media only screen and (max-width: 991px) {
    .about-style14 .dz-media {
      padding-right: 0; } }

.about-style14 .about-content {
  margin-left: -140px;
  position: relative; }
  .about-style14 .about-content .section-head {
    margin-right: 155px; }
  .about-style14 .about-content .info {
    padding-left: 190px;
    position: relative; }
    .about-style14 .about-content .info:after {
      position: absolute;
      content: "";
      height: 1px;
      background: var(--primary);
      width: 122px;
      top: 15px;
      left: 50px; }
  @media only screen and (max-width: 991px) {
    .about-style14 .about-content {
      margin-left: 0; }
      .about-style14 .about-content .section-head {
        margin-right: 0; }
      .about-style14 .about-content .info {
        padding-left: 0; }
        .about-style14 .about-content .info:after {
          content: none; } }

.about-style15 .about-media {
  height: calc(100% - 30px); }
  .about-style15 .about-media img {
    object-fit: cover;
    width: 100%; }
    .about-style15 .about-media img:first-child {
      height: 70%; }
    .about-style15 .about-media img:last-child {
      height: 30%; }

.dz-box {
  position: relative; }

.dz-box.style-1 {
  overflow: hidden;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .dz-box.style-1 .dz-media {
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
    .dz-box.style-1 .dz-media img {
      height: 590px;
      object-fit: cover;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s; }
      @media only screen and (max-width: 1400px) {
        .dz-box.style-1 .dz-media img {
          height: 450px; } }
      @media only screen and (max-width: 575px) {
        .dz-box.style-1 .dz-media img {
          height: 300px; } }
  .dz-box.style-1 .title,
  .dz-box.style-1 .title a {
    color: #fff; }
  .dz-box.style-1 .sub-title {
    font-family: var(--font-family-base); }
  .dz-box.style-1 .dz-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#192436+39,192436+100&0+34,1+100 */
    background: -moz-linear-gradient(top, rgba(25, 36, 54, 0) 34%, rgba(25, 36, 54, 0.08) 39%, #192436 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(25, 36, 54, 0) 34%, rgba(25, 36, 54, 0.08) 39%, #192436 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(25, 36, 54, 0) 34%, rgba(25, 36, 54, 0.08) 39%, #192436 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00192436', endColorstr='#192436',GradientType=0 );
    /* IE6-9 */ }
  .dz-box.style-1:hover .dz-media {
    transform: rotate(-5deg) scale(1.2); }

.swiper-portfolio-1 .swiper-slide {
  margin-top: 70px;
  margin-bottom: 70px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .swiper-portfolio-1 .swiper-slide.swiper-slide-active {
    margin-top: 0;
    margin-bottom: 0; }
    .swiper-portfolio-1 .swiper-slide.swiper-slide-active .dz-media img {
      height: 730px; }
  @media only screen and (max-width: 1400px) {
    .swiper-portfolio-1 .swiper-slide {
      margin-top: 30px;
      margin-bottom: 30px; }
      .swiper-portfolio-1 .swiper-slide.swiper-slide-active .dz-media img {
        height: 510px; } }
  @media only screen and (max-width: 575px) {
    .swiper-portfolio-1 .swiper-slide.swiper-slide-active .dz-media img {
      height: 360px; } }

.dz-box.style-2 {
  overflow: hidden;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .dz-box.style-2 .dz-media {
    background-size: cover;
    display: block;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  .dz-box.style-2 .title,
  .dz-box.style-2 .title a {
    color: #fff; }
  .dz-box.style-2 .sub-title {
    font-family: var(--font-family-base); }
  .dz-box.style-2 .dz-info {
    position: absolute;
    bottom: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    padding: 20px; }
  .dz-box.style-2:hover .dz-media {
    transform: rotate(-5deg) scale(1.2); }

.dz-box.style-3 {
  overflow: hidden; }
  .dz-box.style-3 .dz-media {
    display: block;
    background-size: cover;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  .dz-box.style-3 .dz-info {
    background: #fff;
    padding: 20px 25px 30px;
    position: absolute;
    bottom: -54px;
    left: 0;
    width: 200px;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
  .dz-box.style-3 .title,
  .dz-box.style-3 .title a,
  .dz-box.style-3 .btn-link {
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
  .dz-box.style-3:hover .dz-media {
    transform: rotate(-5deg) scale(1.2); }
  .dz-box.style-3:hover .dz-info {
    background: var(--primary);
    bottom: 0; }
  .dz-box.style-3:hover .title,
  .dz-box.style-3:hover .title a,
  .dz-box.style-3:hover .btn-link {
    color: #fff; }

.dz-box.style-4 {
  position: relative;
  transition: all 0.3s; }
  .dz-box.style-4 .dz-media {
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: transform 0.5s;
    border-radius: var(--border-radius-base); }
    .dz-box.style-4 .dz-media img {
      transition: transform 0.5s;
      border-radius: var(--border-radius-base);
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .dz-box.style-4 .dz-media:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: opacity 0.6s;
      z-index: 0; }
  .dz-box.style-4 .view-btn {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.6s;
    height: 72px;
    width: 72px;
    line-height: 72px;
    text-align: center;
    background: var(--primary);
    color: #fff;
    font-size: 28px; }
  .dz-box.style-4:hover {
    transform: scale(0.93, 0.93); }
    .dz-box.style-4:hover .view-btn {
      opacity: 1; }
    .dz-box.style-4:hover .dz-media img {
      transform: scale(1.17, 1.17); }
    .dz-box.style-4:hover .dz-media:after {
      opacity: 1; }

.dz-box.style-5 {
  border-radius: var(--border-radius-base);
  overflow: hidden; }
  .dz-box.style-5 .dz-media {
    display: block;
    background-size: cover; }
    .dz-box.style-5 .dz-media.height-md {
      height: 550px; }
      @media only screen and (max-width: 575px) {
        .dz-box.style-5 .dz-media.height-md {
          height: 260px; } }
    .dz-box.style-5 .dz-media.height-sm {
      height: 260px; }
  .dz-box.style-5 .overlay-primary:before {
    content: "";
    position: absolute;
    background: var(--primary);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .dz-box.style-5 .popup-youtube,
  .dz-box.style-5 .view-btn {
    height: 60px;
    width: 60px;
    border-radius: 60px;
    line-height: 60px;
    text-align: center;
    background: var(--secondary);
    color: #fff;
    display: inline-block;
    margin: 0 4px; }
    .dz-box.style-5 .popup-youtube i,
    .dz-box.style-5 .view-btn i {
      background: transparent; }
  .dz-box.style-5:hover .overlay-primary:before {
    opacity: 0.8; }

.dz-box.style-6 .dz-media {
  display: block;
  background-size: cover; }

.dz-box.style-6:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--secondary);
  opacity: .4;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }

.dz-box.style-6 .overlay-bx {
  opacity: 1;
  visibility: visible; }
  .dz-box.style-6 .overlay-bx .title,
  .dz-box.style-6 .overlay-bx .title a {
    color: #fff; }

.dz-box.style-6 .info {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  padding: 30px 40px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }

.dz-box.style-6 .view-btn {
  width: 100px;
  height: 100px;
  display: block;
  font-size: 34px;
  line-height: 100px;
  text-align: center;
  background: var(--primary);
  border-radius: 100px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50px 0px 0px -50px;
  transform: scale(0) rotate(360deg);
  -moz-transform: scale(0) rotate(360deg);
  -webkit-transform: scale(0) rotate(360deg);
  -ms-transform: scale(0) rotate(360deg);
  -o-transform: scale(0) rotate(360deg); }
  .dz-box.style-6 .view-btn i {
    vertical-align: middle; }

.dz-box.style-6:hover .view-btn {
  transform: scale(1) rotate(0deg);
  -moz-transform: scale(1) rotate(0deg);
  -webkit-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  -o-transform: scale(1) rotate(0deg); }

.dz-box.style-6:hover:after {
  opacity: .8; }

.testimonial-pic {
  background: #FFF;
  position: relative;
  display: inline-block; }
  .testimonial-pic.radius {
    border-radius: 100%;
    -webkit-border-radius: 100%; }
    .testimonial-pic.radius img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      -webkit-border-radius: 100%; }
  .testimonial-pic.shadow {
    -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
    box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35); }

.quote-left,
.quote-right {
  position: relative; }
  .quote-left:before,
  .quote-right:before {
    font-family: "Flaticon";
    position: absolute;
    z-index: 10;
    height: 36px;
    width: 36px;
    line-height: 36px;
    background-color: var(--primary);
    color: #fff;
    border-radius: 30px;
    text-align: center;
    font-size: 18px; }

.quote-left:before {
  top: 15px;
  content: "\f115";
  left: 0; }

.quote-right:before {
  top: 15px;
  content: "\f11e";
  right: 0; }

.testimonial-text {
  position: relative;
  font-size: 16px;
  font-weight: 400; }
  .testimonial-text p:last-child {
    margin: 0; }

.testimonial-detail {
  padding: 5px; }

.testimonial-position {
  font-size: 15px; }

.testimonial-name,
.testimonial-position {
  display: block; }

.testimonial-bg {
  color: #fff; }

.star-rating li {
  display: inline-block; }

.testimonial-1 {
  display: flex;
  align-items: center; }
  .testimonial-1 .testimonial-pic {
    min-width: 50%;
    height: 330px;
    background: #f0f0f0;
    position: relative;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: 0s .5s;
    -o-transition: 0s .5s;
    transition: 0s .5s; }
    .testimonial-1 .testimonial-pic img {
      width: 350px;
      height: 100%;
      object-fit: cover; }
    .testimonial-1 .testimonial-pic:after, .testimonial-1 .testimonial-pic:before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    .testimonial-1 .testimonial-pic:before {
      background-color: var(--primary);
      z-index: 1;
      -webkit-transform: translateX(-101%);
      -ms-transform: translateX(-101%);
      transform: translateX(-101%); }
    .testimonial-1 .testimonial-pic:after {
      background-color: var(--secondary);
      z-index: 2;
      -webkit-transform: translateX(-101%);
      -ms-transform: translateX(-101%);
      transform: translateX(-101%); }
  .testimonial-1 .testimonial-text {
    margin: 15px 0; }
  .testimonial-1 .testimonial-info {
    z-index: 1;
    position: relative;
    padding: 30px; }
    .testimonial-1 .testimonial-info p {
      font-size: 18px; }
  .testimonial-1 .testimonial-head .sub-title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500; }
  .testimonial-1 .testimonial-head .title {
    text-transform: uppercase;
    font-size: 52px;
    line-height: 52px;
    margin-left: -100px; }
    @media only screen and (max-width: 1200px) {
      .testimonial-1 .testimonial-head .title {
        font-size: 42px;
        line-height: 45px; } }
    @media only screen and (max-width: 991px) {
      .testimonial-1 .testimonial-head .title {
        font-size: 35px;
        line-height: 38px; } }
    @media only screen and (max-width: 575px) {
      .testimonial-1 .testimonial-head .title {
        font-size: 32px;
        line-height: 36px; } }
  @media only screen and (max-width: 767px) {
    .testimonial-1 {
      display: block; }
      .testimonial-1 .testimonial-head .title {
        margin-left: 0; }
      .testimonial-1 .testimonial-info {
        padding: 30px 0; } }

.testimonial-swiper .swiper-slide.swiper-slide-active .testimonial-1 .testimonial-pic {
  opacity: 1; }
  .testimonial-swiper .swiper-slide.swiper-slide-active .testimonial-1 .testimonial-pic:after {
    -webkit-animation: split-box-animation 1.2s 0.2s cubic-bezier(1, 0, 0.54, 0.99) forwards;
    animation: split-box-animation 1.2s 0.2s cubic-bezier(1, 0, 0.54, 0.99) forwards; }
  .testimonial-swiper .swiper-slide.swiper-slide-active .testimonial-1 .testimonial-pic:before {
    -webkit-animation: split-box-animation 1.5s cubic-bezier(0.86, 0, 0.07, 1) forwards;
    animation: split-box-animation 1.5s cubic-bezier(0.86, 0, 0.07, 1) forwards; }

.testimonial-2 {
  position: relative; }
  .testimonial-2:after {
    content: "\f10e";
    position: absolute;
    right: 50px;
    top: 50px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 42px;
    line-height: 65px;
    color: var(--primary); }
  .testimonial-2 .testimonial-pic {
    width: 115px;
    height: 115px;
    border: 0;
    overflow: hidden;
    border-radius: 50%; }
  .testimonial-2 .testimonial-text p {
    font-size: 18px;
    font-style: italic; }
  .testimonial-2 .info {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px; }
    .testimonial-2 .info .testimonial-name {
      margin-bottom: 0;
      margin-right: 10px; }
    .testimonial-2 .info .testimonial-position {
      position: relative;
      padding-left: 15px;
      font-size: 16px;
      font-weight: 500;
      font-family: var(--font-family-title);
      color: #999; }
      .testimonial-2 .info .testimonial-position:before {
        content: "/";
        position: absolute;
        left: 0; }

.testimonial-3 {
  padding: 15px; }
  .testimonial-3 .testimonial-info {
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-bottom: 35px;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    .testimonial-3 .testimonial-info:after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 30px;
      width: 0px;
      height: 0px;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      z-index: 99;
      border-top: 15px solid #fff;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
  .testimonial-3 .testimonial-text p {
    font-size: 18px;
    font-style: italic;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .testimonial-3 .testimonial-footer {
    display: flex;
    align-items: center; }
  .testimonial-3 .testimonial-name {
    margin-bottom: 10px; }
  .testimonial-3 .testimonial-position {
    font-size: 16px; }
  .testimonial-3 .testimonial-pic {
    width: 85px;
    height: 85px;
    border: 0;
    margin-right: 20px; }

.swiper-container .swiper-slide.swiper-slide-next .testimonial-3 .testimonial-info {
  background-color: var(--primary); }
  .swiper-container .swiper-slide.swiper-slide-next .testimonial-3 .testimonial-info p {
    color: #fff; }
  .swiper-container .swiper-slide.swiper-slide-next .testimonial-3 .testimonial-info:after {
    border-top: 15px solid var(--primary); }

.testimonial-4 {
  display: flex; }
  .testimonial-4 .testimonial-pic {
    border: 0px;
    margin-right: 35px;
    height: auto;
    width: auto;
    padding: 20px 20px 0 0; }
    .testimonial-4 .testimonial-pic img {
      min-width: 135px;
      height: 135px;
      border-radius: 50%; }
    .testimonial-4 .testimonial-pic:after {
      content: "\f10e";
      position: absolute;
      right: 0;
      top: 0;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 65px;
      line-height: 65px;
      color: var(--primary); }
  .testimonial-4 .testimonial-text {
    margin-bottom: 15px;
    margin-top: 10px; }
    .testimonial-4 .testimonial-text p {
      font-size: 20px;
      font-style: italic; }
  .testimonial-4 .testimonial-name {
    margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .testimonial-4 .testimonial-text p {
      font-size: 16px; } }
  @media only screen and (max-width: 575px) {
    .testimonial-4 {
      display: block; } }

.swiper-container .testimonial-4 {
  max-width: 850px;
  margin: 0 auto; }
  @media only screen and (max-width: 575px) {
    .swiper-container .testimonial-4 {
      padding-right: 0; } }

.section-head {
  margin-bottom: 40px; }
  @media only screen and (max-width: 1199px) {
    .section-head {
      margin-bottom: 30px; } }
  @media only screen and (max-width: 767px) {
    .section-head {
      margin-bottom: 20px; } }
  .section-head h1, .section-head .h1, .section-head h2, .section-head .h2, .section-head h3, .section-head .h3, .section-head h4, .section-head .h4, .section-head .comment-reply-title,
  .section-head .wp-block-search .wp-block-search__label, .wp-block-search .section-head .wp-block-search__label, .section-head h5, .section-head .h5, .section-head h6, .section-head .h6 {
    font-family: var(--font-family-title); }
  .section-head h2, .section-head .h2 {
    font-size: 3.5rem;
    line-height: 1.2; }
    @media only screen and (max-width: 1400px) {
      .section-head h2, .section-head .h2 {
        font-size: 2.8rem; } }
    @media only screen and (max-width: 991px) {
      .section-head h2, .section-head .h2 {
        font-size: 2.5rem; } }
    @media only screen and (max-width: 767px) {
      .section-head h2, .section-head .h2 {
        font-size: 2rem; } }
  .section-head h3, .section-head .h3 {
    font-size: 3rem;
    line-height: 1.2; }
    @media only screen and (max-width: 1200px) {
      .section-head h3, .section-head .h3 {
        font-size: 2.3rem; } }
    @media only screen and (max-width: 991px) {
      .section-head h3, .section-head .h3 {
        font-size: 1.75rem; } }
  .section-head p {
    font-size: 18px;
    line-height: 1.7; }
    @media only screen and (max-width: 1400px) {
      .section-head p {
        font-size: 16px; } }
  .section-head.text-center {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    /* p{
			max-width:550px;
			margin-left:auto;
			margin-right:auto;
		} */ }
  .section-head .sub-title {
    text-transform: uppercase;
    margin-bottom: 5px; }

.section-head.style-1 .sub-title {
  margin-bottom: 5px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding-right: 50px; }
  .section-head.style-1 .sub-title:before, .section-head.style-1 .sub-title:after {
    content: "";
    height: 2px;
    width: 40px;
    background-color: var(--primary);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none; }
  .section-head.style-1 .sub-title:after {
    right: 0;
    display: block; }
  .section-head.style-1 .sub-title.style-1 {
    font-size: 22px;
    padding-right: 0;
    padding-left: 25px; }
    .section-head.style-1 .sub-title.style-1:after {
      height: 14px;
      width: 14px;
      left: 0; }

.section-head.style-1.text-right .sub-title {
  padding-left: 50px;
  padding-right: 0; }
  .section-head.style-1.text-right .sub-title:before {
    left: 0;
    display: block; }

.section-head.style-1.text-center .sub-title {
  padding: 0 50px; }
  .section-head.style-1.text-center .sub-title:before {
    left: 0;
    display: block; }
  .section-head.style-1.text-center .sub-title:after {
    right: 0;
    display: block; }

.section-head.style-2 h2, .section-head.style-2 .h2 {
  font-size: 2.8125rem; }
  .section-head.style-2 h2 span, .section-head.style-2 .h2 span {
    color: var(--primary); }
  @media only screen and (max-width: 1199px) {
    .section-head.style-2 h2, .section-head.style-2 .h2 {
      font-size: 2.25rem; } }
  @media only screen and (max-width: 767px) {
    .section-head.style-2 h2, .section-head.style-2 .h2 {
      font-size: 1.875rem; } }

.section-head.style-2 .sub-title {
  display: inline-flex;
  align-items: center; }
  .section-head.style-2 .sub-title:before, .section-head.style-2 .sub-title:after {
    content: "";
    height: 1px;
    width: 55px;
    background-color: #000;
    display: inline-block;
    margin-right: 10px; }
    @media only screen and (max-width: 575px) {
      .section-head.style-2 .sub-title:before, .section-head.style-2 .sub-title:after {
        width: 35px; } }
  .section-head.style-2 .sub-title:before {
    margin-right: 10px; }
  .section-head.style-2 .sub-title:after {
    content: none;
    margin-left: 10px; }
  .section-head.style-2 .sub-title.text-primary:before, .section-head.style-2 .sub-title.text-primary:after {
    background-color: var(--primary); }
  .section-head.style-2 .sub-title.text-secondary:before, .section-head.style-2 .sub-title.text-secondary:after {
    background-color: var(--secondary); }
  .section-head.style-2 .sub-title.text-white:before, .section-head.style-2 .sub-title.text-white:after {
    background-color: #fff; }

.section-head.style-2.text-end .sub-title:before {
  content: none; }

.section-head.style-2.text-end .sub-title:after {
  content: ""; }

.icon-bx-xl {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 150px;
  height: 150px;
  line-height: 150px; }
  .icon-bx-xl.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }
  .icon-bx-xl i {
    font-size: 80px;
    vertical-align: middle; }
  .icon-bx-xl img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 80px; }

.icon-bx-lg {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 120px;
  height: 120px;
  line-height: 120px; }
  .icon-bx-lg.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }
  .icon-bx-lg i {
    font-size: 60px;
    vertical-align: middle; }
  .icon-bx-lg img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 55px; }

.icon-bx-md {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  line-height: 100px; }
  .icon-bx-md.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }
  .icon-bx-md i {
    font-size: 45px;
    vertical-align: middle; }
  .icon-bx-md img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 40px; }

.icon-bx-sm {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 67px;
  height: 67px;
  min-width: 67px;
  line-height: 67px; }
  .icon-bx-sm.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }
  .icon-bx-sm i {
    font-size: 30px;
    vertical-align: middle; }
  .icon-bx-sm img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 30px; }

.icon-bx-xs {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 60px;
  height: 60px;
  line-height: 60px; }
  .icon-bx-xs.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%; }
  .icon-bx-xs i {
    font-size: 30px;
    vertical-align: middle; }
  .icon-bx-xs img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 20px; }

.icon-xl {
  display: inline-block;
  text-align: center;
  width: 100px; }
  .icon-xl i {
    vertical-align: middle;
    font-size: 80px; }
  .icon-xl img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 80px; }

.icon-lg {
  display: inline-block;
  text-align: center; }
  .icon-lg i {
    vertical-align: middle;
    font-size: 60px; }
  .icon-lg img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 70px; }

.icon-md {
  display: inline-block;
  text-align: center; }
  .icon-md i {
    vertical-align: middle;
    font-size: 45px; }
  .icon-md img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 50px; }

.icon-sm {
  display: inline-block;
  text-align: center;
  width: 40px; }
  .icon-sm i {
    vertical-align: middle;
    font-size: 30px; }
  .icon-sm img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 30px; }

.icon-xs {
  display: inline-block;
  text-align: center;
  width: 30px; }
  .icon-xs i {
    vertical-align: middle;
    font-size: 20px; }
  .icon-xs img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 20px; }

.icon-xl i,
.icon-lg i,
.icon-md i,
.icon-sm i,
.icon-xs i {
  line-height: 1; }

.icon-bx-wraper {
  position: relative; }
  .icon-bx-wraper .dz-tilte {
    margin-top: 0; }
  .icon-bx-wraper .after-titile-line {
    margin-bottom: 10px; }
  .icon-bx-wraper p:last-child {
    margin: 0; }
  .icon-bx-wraper.center {
    text-align: center; }
  .icon-bx-wraper.left .icon-bx-xl,
  .icon-bx-wraper.left .icon-bx-lg,
  .icon-bx-wraper.left .icon-bx-md,
  .icon-bx-wraper.left .icon-bx-sm,
  .icon-bx-wraper.left .icon-bx-xs {
    float: left;
    margin-right: 20px; }
  .icon-bx-wraper.left .icon-xl,
  .icon-bx-wraper.left .icon-lg,
  .icon-bx-wraper.left .icon-md,
  .icon-bx-wraper.left .icon-sm,
  .icon-bx-wraper.left .icon-xs {
    float: left;
    margin-right: 20px; }
  .icon-bx-wraper.right {
    text-align: right; }
    .icon-bx-wraper.right .icon-bx-xl,
    .icon-bx-wraper.right .icon-bx-lg,
    .icon-bx-wraper.right .icon-bx-md,
    .icon-bx-wraper.right .icon-bx-sm,
    .icon-bx-wraper.right .icon-bx-xs {
      float: right;
      margin-left: 20px; }
    .icon-bx-wraper.right .icon-xl,
    .icon-bx-wraper.right .icon-lg,
    .icon-bx-wraper.right .icon-md,
    .icon-bx-wraper.right .icon-sm,
    .icon-bx-wraper.right .icon-xs {
      float: right;
      margin-left: 10px; }
  .icon-bx-wraper.bx-style-1, .icon-bx-wraper.bx-style-2 {
    border-width: 1px;
    border-style: solid;
    border-color: #ebedf2; }
  .icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .icon-bx-wraper.bx-style-2.center .icon-bx-xl {
    margin-left: -75px; }
  .icon-bx-wraper.bx-style-2.center .icon-bx-lg {
    margin-left: -60px; }
  .icon-bx-wraper.bx-style-2.center .icon-bx-md {
    margin-left: -50px; }
  .icon-bx-wraper.bx-style-2.center .icon-bx-sm {
    margin-left: -40px; }
  .icon-bx-wraper.bx-style-2.center .icon-bx-xs {
    margin-left: -20px; }
  .icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
    position: absolute;
    top: auto;
    left: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
    position: absolute;
    top: auto;
    right: 0;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%); }

.icon-content {
  overflow: hidden; }

[class*="icon-bx-"][class*="bg-"] a,
[class*="icon-bx-"][class*="bg-"] span {
  color: #fff; }

[class*="icon-bx-"].bg-white a {
  color: inherit; }

[class*="icon-bx-"][class*="border-"] {
  display: table;
  line-height: normal;
  margin-left: auto;
  margin-right: auto; }

[class*="icon-bx-"][class*="border-"] .icon-cell {
  display: table-cell;
  vertical-align: middle; }

.dz-box,
.dz-info,
.dz-tilte,
.dz-tilte-inner {
  position: relative; }

.dz-tilte-inner {
  display: inline-block; }

.dz-box[class*="border-"],
.dz-info[class*="border-"] {
  border-color: #eee; }

.dz-info.border-1,
.dz-info.border-2,
.dz-info.border-3,
.dz-info.border-4,
.dz-info.border-5 {
  border-top: none; }

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
  border-style: solid; }

.border-1 {
  border-width: 1px; }

.border-2 {
  border-width: 2px; }

.border-3 {
  border-width: 3px; }

.border-4 {
  border-width: 4px; }

.border-5 {
  border-width: 5px; }

.left-border,
.right-border {
  position: relative; }
  .left-border:before,
  .right-border:before {
    content: "";
    position: absolute;
    top: 5px;
    width: 1px;
    height: 90%;
    background: #CCC; }

.right-border:before {
  right: 0; }

.dz-media,
.dz-post-media {
  position: relative;
  overflow: hidden; }
  .dz-media img,
  .dz-post-media img {
    width: 100%;
    height: auto; }

.dz-box-bg {
  overflow: hidden;
  background-size: cover; }
  .dz-box-bg .btn, .dz-box-bg .wp-block-button__link {
    overflow: unset; }
  .dz-box-bg .glyph-icon {
    font-size: 50px; }
  .dz-box-bg .icon-bx-wraper {
    background-color: #f8f8f8; }
  .dz-box-bg .text-primary,
  .dz-box-bg .icon-content .dz-tilte,
  .dz-box-bg .icon-content p,
  .dz-box-bg .icon-box-btn .btn,
  .dz-box-bg .icon-box-btn .wp-block-button__link {
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  .dz-box-bg.active .icon-bx-wraper {
    background-color: rgba(0, 0, 0, 0.7); }
  .dz-box-bg.active .text-primary,
  .dz-box-bg.active .icon-content .dz-tilte,
  .dz-box-bg.active .icon-content p,
  .dz-box-bg.active .icon-box-btn .btn,
  .dz-box-bg.active .icon-box-btn .wp-block-button__link {
    color: #fff;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  
  .dz-box-bg .icon-box-btn .btn,
  .dz-box-bg .icon-box-btn .wp-block-button__link {
    background-color: #eeeeee;
    padding: 15px 20px;
    text-transform: uppercase;
    font-size: 15px;
    border-radius: 0;
    color: #1abc9c;
    font-weight: 600;
    margin-bottom: 0; }

.icon-bx-wraper.style-1 .icon-bx-sm {
  position: relative;
  z-index: 1; }
  .icon-bx-wraper.style-1 .icon-bx-sm:after {
    content: "";
    position: absolute;
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    background: var(--primary);
    border-radius: inherit;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    z-index: -1;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }

.icon-bx-wraper.style-1:hover .icon-bx-sm:after {
  transform: translate(-50%, -50%) scale(1); }

.icon-bx-wraper.style-1:hover .icon-bx-sm .text-primary {
  color: #fff !important; }

.icon-bx-wraper.style-2 {
  display: flex;
  align-items: center;
  background: #f2f2f2;
  padding: 35px 30px 35px 70px;
  margin-left: 30px;
  z-index: 1; }
  .icon-bx-wraper.style-2 .icon-bx-sm {
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  .icon-bx-wraper.style-2:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-100%);
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    width: 35px;
    height: 100%;
    background: #f8f8f8;
    z-index: -1;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  .icon-bx-wraper.style-2:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: #f8f8f8;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    z-index: -1; }
  .icon-bx-wraper.style-2:hover:after {
    width: 100%; }
  .icon-bx-wraper.style-2:hover .icon-bx-sm {
    background-color: var(--secondary) !important; }
  .icon-bx-wraper.style-2:hover:before {
    width: 0;
    left: 0; }
  @media only screen and (max-width: 575px) {
    .icon-bx-wraper.style-2 {
      padding: 20px 20px 20px 60px; } }

.icon-bx-wraper.style-3 {
  padding: 30px;
  background: #f1f1f1;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .icon-bx-wraper.style-3 p,
  .icon-bx-wraper.style-3 .dz-title,
  .icon-bx-wraper.style-3 .icon-cell {
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  .icon-bx-wraper.style-3:hover {
    background-color: var(--primary); }
    .icon-bx-wraper.style-3:hover p,
    .icon-bx-wraper.style-3:hover .dz-title,
    .icon-bx-wraper.style-3:hover .icon-cell {
      color: #fff; }

.icon-bx-wraper.style-4 [class*="icon-bx-"] {
  display: block;
  background-color: #000;
  text-align: center;
  outline: 2px solid var(--primary);
  outline-offset: 10px;
  margin: 12px 32px 12px 12px; }

.icon-bx-wraper.style-4 .icon-bx-sm img {
  height: 40px; }

.icon-bx-wraper.style-5 {
  margin-top: 20px; }
  .icon-bx-wraper.style-5 [class*="icon-bx-"] {
    position: relative;
    background: linear-gradient(90deg, var(--primary) 50%, transparent 50%), linear-gradient(90deg, var(--primary) 50%, transparent 50%), linear-gradient(0deg, var(--primary) 50%, transparent 50%), linear-gradient(0deg, var(--primary) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 8px 3px, 8px 3px, 3px 8px, 3px 8px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    border-radius: 2px; }
    .icon-bx-wraper.style-5 [class*="icon-bx-"]:after {
      content: attr(data-num);
      position: absolute;
      top: -20px;
      right: -20px;
      background: var(--primary);
      color: #fff;
      font-family: var(--font-family-title);
      text-align: center;
      height: 46px;
      width: 46px;
      line-height: 46px;
      border-radius: 46px; }
  .icon-bx-wraper.style-5 .icon-bx-sm:after {
    top: -10px;
    right: -10px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 16px; }
  .icon-bx-wraper.style-5 .icon-bx-lg img {
    height: 70px; }
  .icon-bx-wraper.style-5.center [class*="icon-bx-"] {
    margin-bottom: 20px; }
  .icon-bx-wraper.style-5:hover [class*="icon-bx-"] {
    -webkit-animation: dash 5s linear infinite;
    animation: dash 5s linear infinite; }
    .icon-bx-wraper.style-5:hover [class*="icon-bx-"] img,
    .icon-bx-wraper.style-5:hover [class*="icon-bx-"] i {
      animation: shake 0.5s;
      animation-iteration-count: infinite;
      display: inline-block; }

.icon-bx-wraper.style-6 {
  padding: 30px;
  background: #fff;
  border-radius: var(--border-radius-base);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  .icon-bx-wraper.style-6:hover, .icon-bx-wraper.style-6.active {
    background: var(--secondary); }
    .icon-bx-wraper.style-6:hover .dz-title,
    .icon-bx-wraper.style-6:hover .dz-title a,
    .icon-bx-wraper.style-6:hover p, .icon-bx-wraper.style-6.active .dz-title,
    .icon-bx-wraper.style-6.active .dz-title a,
    .icon-bx-wraper.style-6.active p {
      color: #fff; }

.icon-bx-wraper.style-7 {
  background: #fff;
  border: 1px solid #737373;
  border-radius: var(--border-radius-base);
  padding: 30px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  .icon-bx-wraper.style-7 .dz-title,
  .icon-bx-wraper.style-7 .dz-title a,
  .icon-bx-wraper.style-7 p,
  .icon-bx-wraper.style-7 a,
  .icon-bx-wraper.style-7 .icon-cell {
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .icon-bx-wraper.style-7:before {
    content: "";
    background: var(--secondary);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .icon-bx-wraper.style-7:hover {
    border-color: var(--primary);
    background: var(--primary); }
    .icon-bx-wraper.style-7:hover:before {
      top: 10px;
      left: 10px; }
    .icon-bx-wraper.style-7:hover .dz-title,
    .icon-bx-wraper.style-7:hover .dz-title a,
    .icon-bx-wraper.style-7:hover p,
    .icon-bx-wraper.style-7:hover a,
    .icon-bx-wraper.style-7:hover .icon-cell {
      color: #fff; }

.icon-bx-wraper.style-8 [class*="icon-bx-"] {
  background: #f5f5f5;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  margin-right: 15px; }
  .icon-bx-wraper.style-8 [class*="icon-bx-"] i {
    font-size: 25px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }

.icon-bx-wraper.style-8 .icon-content {
  padding-top: 5px; }

.icon-bx-wraper.style-8 p {
  font-family: var(--font-family-title); }

.icon-bx-wraper.style-8:hover [class*="icon-bx-"] {
  background: var(--primary); }
  .icon-bx-wraper.style-8:hover [class*="icon-bx-"] i {
    color: #fff; }

.icon-bx-wraper.style-9 {
  display: flex;
  align-items: center; }
  .icon-bx-wraper.style-9 [class*="icon-bx-"] {
    margin: 0;
    margin-right: 20px;
    position: relative; }
    .icon-bx-wraper.style-9 [class*="icon-bx-"]:after {
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      background: var(--primary);
      z-index: -1;
      transform: translate(-50%, -50%); }
  .icon-bx-wraper.style-9:hover [class*="icon-bx-"]:after {
    height: calc(100% + 2px);
    width: calc(100% + 2px); }
  .icon-bx-wraper.style-9:hover [class*="icon-bx-"] a {
    color: #fff; }

.flip-bx {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  perspective: 1000px; }
  .flip-bx .inner {
    -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
    transform: translateY(-50%) translateZ(60px) scale(0.94);
    top: 50%;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 2rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .flip-bx .front,
  .flip-bx .back {
    background-size: cover;
    background-position: center;
    -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-align: center;
    min-height: 370px;
    height: auto;
    color: #fff; }
  .flip-bx .back {
    background: #212529;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
    .flip-bx .back .inner p {
      font-size: 16px;
      color: #fff; }
    .flip-bx .back .inner .site-button-link {
      font-size: 16px; }
  .flip-bx .front {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
    .flip-bx .front:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      opacity: .4;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 4px; }
  .flip-bx:hover .back,
  .flip-bx:hover .front {
    -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1); }
  .flip-bx:hover .back {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  .flip-bx:hover .front {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg); }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg); }
  20% {
    transform: translate(-3px, 0px) rotate(1deg); }
  30% {
    transform: translate(3px, 2px) rotate(0deg); }
  40% {
    transform: translate(1px, -1px) rotate(1deg); }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg); }
  60% {
    transform: translate(-3px, 1px) rotate(0deg); }
  70% {
    transform: translate(3px, 1px) rotate(-1deg); }
  80% {
    transform: translate(-1px, -1px) rotate(1deg); }
  90% {
    transform: translate(1px, 2px) rotate(0deg); }
  100% {
    transform: translate(1px, -2px) rotate(-1deg); } }

@keyframes dash {
  to {
    background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%; } }

.dz-team {
  transition: all 0.5s; }
  .dz-team .dz-name a {
    transition: all 0.5s; }
  .dz-team .dz-content {
    transition: all 0.5s;
    position: relative; }
  .dz-team .dz-media img {
    width: 100%; }
  .dz-team .dz-position {
    display: block; }

.dz-team.style-1 {
  position: relative; }
  .dz-team.style-1 .dz-media img {
    border-radius: 0; }
  .dz-team.style-1 .team-social {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    width: 100%; }
    .dz-team.style-1 .team-social li {
      display: inline-block;
      position: relative;
      opacity: 0;
      transform: translateY(80px); }
      .dz-team.style-1 .team-social li:nth-child(1) {
        transition: 0.2s; }
      .dz-team.style-1 .team-social li:nth-child(2) {
        transition: 0.4s; }
      .dz-team.style-1 .team-social li:nth-child(3) {
        transition: 0.6s; }
      .dz-team.style-1 .team-social li a {
        display: inline-block;
        margin: 0 5px;
        height: 35px;
        line-height: 35px;
        width: 35px;
        border-radius: 35px;
        text-align: center;
        color: #fff; }
  .dz-team.style-1 .dz-position {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 400; }
  .dz-team.style-1 .dz-name {
    margin-bottom: 3px; }
  .dz-team.style-1 .dz-content {
    padding: 20px 20px; }
  .dz-team.style-1:hover .team-social li, .dz-team.style-1.active .team-social li {
    transform: translateY(0px);
    opacity: 1; }

.dz-team.style-2 .dz-position {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  font-family: var(--font-family-base);
  color: #fff; }

.dz-team.style-2 .dz-name {
  color: #fff;
  font-weight: 500;
  margin-bottom: 5px; }

.dz-team.style-2 .dz-media {
  border-radius: 0; }

.dz-team.style-2 .dz-content {
  background-color: var(--primary);
  padding: 20px 40px;
  margin-left: 30px;
  margin-top: -30px;
  margin-right: 50px;
  position: relative;
  z-index: 1; }
  .dz-team.style-2 .dz-content:after {
    content: "";
    height: 100%;
    width: 10px;
    left: -15px;
    top: 0px;
    position: absolute;
    background-color: var(--primary);
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    z-index: -1; }
  .dz-team.style-2 .dz-content:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0px;
    left: 0;
    top: 0;
    background: var(--secondary);
    z-index: -1;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.83, -0.1, 0.2, 1.01);
    transition-delay: 0s; }

.dz-team.style-2 .team-social {
  position: absolute;
  top: 15px;
  right: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .dz-team.style-2 .team-social li {
    display: block;
    margin-bottom: 10px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    transform: translateX(80px); }
    .dz-team.style-2 .team-social li:nth-child(1) {
      transition: 0.2s; }
    .dz-team.style-2 .team-social li:nth-child(2) {
      transition: 0.4s; }
    .dz-team.style-2 .team-social li:nth-child(3) {
      transition: 0.6s; }
    .dz-team.style-2 .team-social li.share {
      height: 35px;
      width: 35px;
      line-height: 35px;
      text-align: center;
      font-size: 16px;
      background-color: var(--secondary);
      display: block;
      border-radius: var(--border-radius-base);
      color: #fff;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      z-index: 1;
      position: relative; }
      .dz-team.style-2 .team-social li.share i {
        position: relative;
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s;
        display: block; }
    .dz-team.style-2 .team-social li a {
      height: 35px;
      width: 35px;
      line-height: 35px;
      text-align: center;
      font-size: 15px;
      background-color: var(--primary);
      display: block;
      border-radius: var(--border-radius-base);
      color: #fff;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s; }
      .dz-team.style-2 .team-social li a:hover {
        background-color: var(--primary); }
      .dz-team.style-2 .team-social li a:after {
        background-color: var(--primary); }

.dz-team.style-2:hover .team-social li {
  margin-bottom: 10px;
  transform: translateX(-15px); }
  .dz-team.style-2:hover .team-social li.share {
    background-color: var(--primary); }
    .dz-team.style-2:hover .team-social li.share i {
      transform: rotate(45deg); }

.dz-team.style-2:hover .dz-content:before {
  width: 100%; }

.blog-swiper {
  margin: -15px;
  width: auto; }
  .blog-swiper .swiper-slide > div {
    margin: 15px;
    height: 100%; }

.dz-card {
  position: relative; }
  .dz-card .dz-info {
    padding: 30px;
    position: relative; }
  .dz-card .dz-title {
    margin-bottom: 15px;
    word-break: break-word; }
  .dz-card.blog-half {
    display: flex; }
    @media only screen and (max-width: 575px) {
      .dz-card.blog-half {
        display: block; } }
    .dz-card.blog-half .dz-info {
      padding: 30px;
      position: relative;
      flex: 1; }
    .dz-card.blog-half .dz-media {
      margin-bottom: 0;
      max-width: 300px;
      min-width: 300px; }
      @media only screen and (max-width: 1199px) {
        .dz-card.blog-half .dz-media {
          max-width: 250px;
          min-width: 250px; } }
      @media only screen and (max-width: 575px) {
        .dz-card.blog-half .dz-media {
          max-width: 100%;
          min-width: 100%; } }
      .dz-card.blog-half .dz-media a {
        display: block;
        height: 100%; }
      .dz-card.blog-half .dz-media img {
        height: 100%;
        object-fit: cover;
        min-height: 235px; }
        @media only screen and (max-width: 575px) {
          .dz-card.blog-half .dz-media img {
            min-height: auto; } }
    .dz-card.blog-half.post-video .post-video-icon {
      width: 60px;
      height: 60px;
      font-size: 20px; }
    .dz-card.blog-half .swiper-container {
      height: 100%; }

.dz-meta {
  margin-bottom: 10px; }
  .dz-meta ul {
    margin: 0;
    padding: 0; }
    .dz-meta ul li {
      display: inline-block;
      color: var(--secondary);
      margin-right: 20px;
      font-weight: 500; }
      .dz-meta ul li:last-child {
        margin-right: 0;
        color: var(--primary); }
      .dz-meta ul li i {
        font-size: 20px;
        margin-right: 2px; }
      .dz-meta ul li a {
        color: var(--secondary); }

.post-video .post-video-icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--primary);
  font-size: 30px;
  margin: 0 auto;
  background-color: #fff;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px; }
  @media only screen and (max-width: 575px) {
    .post-video .post-video-icon {
      height: 50px;
      width: 50px;
      line-height: 50px;
      font-size: 14px; } }
  .post-video .post-video-icon:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border: 1px solid #fff;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 100%;
    animation: animationSignal1;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    -webkit-animation: animationSignal1;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    z-index: -1;
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -moz-transition: all 0.5;
    -o-transition: all 0.5;
    -webkit-transition: all 0.5;
    -ms-transition: all 0.5;
    transition: all 0.5; }
  .post-video .post-video-icon:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border: 1px solid #fff;
    -webkit-border-radius: 100%;
    -khtml-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    animation: animationSignal2;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    -webkit-animation: animationSignal2;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    z-index: -1;
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -moz-transition: all 0.5;
    -o-transition: all 0.5;
    -webkit-transition: all 0.5;
    -ms-transition: all 0.5;
    transition: all 0.5; }

.dz-card.style-1 {
  overflow: hidden;
  border-radius: var(--border-radius-base); }
  .dz-card.style-1 .dz-media img {
    border-radius: var(--border-radius-base); }
  .dz-card.style-1 .dz-info {
    padding: 35px 30px 20px; }
    @media only screen and (max-width: 575px) {
      .dz-card.style-1 .dz-info {
        padding: 35px 30px 15px; } }
  .dz-card.style-1 .dz-title {
    margin-bottom: 12px; }
  .dz-card.style-1 p {
    font-size: 16px;
    margin-bottom: 20px; }
  .dz-card.style-1 .dz-media + .dz-info .dz-meta {
    position: absolute;
    top: -20px;
    left: 30px;
    z-index: 2; }
    @media only screen and (max-width: 575px) {
      .dz-card.style-1 .dz-media + .dz-info .dz-meta {
        left: 15px; } }
  .dz-card.style-1 .dz-meta ul li {
    margin-right: 10px; }
    @media only screen and (max-width: 575px) {
      .dz-card.style-1 .dz-meta ul li {
        margin-right: 5px; } }
  .dz-card.style-1 .post-date,
  .dz-card.style-1 .post-author a {
    background-color: var(--primary);
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-family: var(--font-family-title);
    padding: 8px 18px; }
  .dz-card.style-1.blog-lg .dz-info {
    padding: 40px 35px 30px; }
  @media only screen and (max-width: 767px) {
    .dz-card.style-1.blog-lg .dz-info {
      padding: 35px 30px 20px; } }
  .dz-card.style-1.shadow {
    box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.1) !important; }
    .dz-card.style-1.shadow .dz-media img {
      border-radius: 0; }
    .dz-card.style-1.shadow .btn, .dz-card.style-1.shadow .wp-block-button__link {
      margin-bottom: 10px; }
  .dz-card.style-1.blog-half .dz-meta {
    position: unset !important; }
  .dz-card.style-1.text-white .dz-title a {
    color: #fff; }
    .dz-card.style-1.text-white .dz-title a:hover {
      color: var(--primary); }

.dz-card.style-2 .dz-media img {
  border-radius: var(--border-radius-base); }

.dz-card.style-2 .dz-info {
  padding: 30px 25px 20px; }

.dz-card.style-2 .dz-meta ul li {
  margin-right: 10px; }

.dz-card.style-2 .post-date,
.dz-card.style-2 .post-author a {
  background-color: var(--primary);
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: var(--font-family-title);
  padding: 8px 18px; }

.dz-card.style-2.blog-half {
  align-items: center; }
  .dz-card.style-2.blog-half h2.dz-title, .dz-card.style-2.blog-half .dz-title.h2 {
    font-size: 42px; }
  .dz-card.style-2.blog-half .dz-media {
    max-width: 60%;
    min-width: 60%; }
  .dz-card.style-2.blog-half .dz-info {
    padding: 0 70px 0 0; }
    .dz-card.style-2.blog-half .dz-info p {
      margin-bottom: 30px; }
  .dz-card.style-2.blog-half .dz-media + .dz-info {
    padding: 0 0 0 70px; }
  @media only screen and (max-width: 1199px) {
    .dz-card.style-2.blog-half h2.dz-title, .dz-card.style-2.blog-half .dz-title.h2 {
      font-size: 36px; }
    .dz-card.style-2.blog-half .dz-media {
      max-width: 50%;
      min-width: 50%; }
    .dz-card.style-2.blog-half .dz-info {
      padding: 0 30px 0 0; }
    .dz-card.style-2.blog-half .dz-media + .dz-info {
      padding: 0 0 0 30px; } }
  @media only screen and (max-width: 991px) {
    .dz-card.style-2.blog-half {
      display: block; }
      .dz-card.style-2.blog-half .dz-media {
        max-width: 100%;
        min-width: 100%; }
      .dz-card.style-2.blog-half .dz-info {
        padding: 0 0 30px 0; }
      .dz-card.style-2.blog-half .dz-media + .dz-info {
        padding: 30px 0 0 0; } }
  @media only screen and (max-width: 575px) {
    .dz-card.style-2.blog-half h2.dz-title, .dz-card.style-2.blog-half .dz-title.h2 {
      font-size: 32px; } }

.dz-card.style-2.text-white .dz-title a {
  color: #fff; }

.dz-card.style-3 .dz-media img {
  border-radius: var(--border-radius-base); }

.dz-card.style-3 .dz-info {
  padding: 30px 0 20px; }

.dz-card.style-3 .dz-meta {
  position: absolute;
  bottom: 0;
  left: 10px; }
  .dz-card.style-3 .dz-meta ul li {
    margin-right: 10px; }
    .dz-card.style-3 .dz-meta ul li i {
      font-size: 16px;
      margin-right: 5px; }

.dz-card.style-3 .post-date,
.dz-card.style-3 .post-author a {
  background-color: var(--primary);
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  font-family: var(--font-family-title);
  padding: 8px 18px;
  border-radius: var(--border-radius-base); }

.dz-card.style-3.text-white .dz-title a {
  color: #fff; }

.dz-subscribe.style-1 {
  position: relative; }
  .dz-subscribe.style-1:after {
    content: "";
    height: 50%;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #192436;
    z-index: -1; }
  .dz-subscribe.style-1 .subscribe-inner {
    background-color: var(--primary);
    padding: 60px;
    position: relative;
    margin: 0; }
    .dz-subscribe.style-1 .subscribe-inner .title-head {
      display: flex;
      align-items: center; }
      .dz-subscribe.style-1 .subscribe-inner .title-head .title {
        margin-bottom: 0; }
      .dz-subscribe.style-1 .subscribe-inner .title-head i {
        color: #fff;
        font-size: 50px;
        margin-right: 20px; }
    .dz-subscribe.style-1 .subscribe-inner .form-control, .dz-subscribe.style-1 .subscribe-inner .wp-block-categories-dropdown select, .wp-block-categories-dropdown .dz-subscribe.style-1 .subscribe-inner select,
    .dz-subscribe.style-1 .subscribe-inner .wp-block-archives-dropdown select, .wp-block-archives-dropdown .dz-subscribe.style-1 .subscribe-inner select {
      height: 80px;
      border-radius: 0;
      padding: 10px 80px 10px 25px; }
    .dz-subscribe.style-1 .subscribe-inner .input-group {
      margin-bottom: 0; }
    .dz-subscribe.style-1 .subscribe-inner .input-group-addon {
      position: absolute;
      right: 12px;
      top: 13px;
      z-index: 99; }
      .dz-subscribe.style-1 .subscribe-inner .input-group-addon .btn, .dz-subscribe.style-1 .subscribe-inner .input-group-addon .wp-block-button__link {
        height: 55px;
        width: 55px;
        padding: 0;
        line-height: 55px;
        text-align: center;
        display: block; }
    @media only screen and (max-width: 767px) {
      .dz-subscribe.style-1 .subscribe-inner {
        padding: 30px 15px; } }

.call-box {
  background: #3a3a3a;
  position: relative;
  padding: 15px 30px 15px 130px;
  display: inline-block; }
  .call-box .title {
    margin-bottom: 0;
    color: #fff;
    font-size: 32px; }
  .call-box span {
    color: #bdbdbd;
    font-size: 13px; }
  .call-box .icon {
    height: 100%;
    width: 105px;
    font-size: 40px;
    position: absolute;
    left: 0;
    top: 0;
    background: var(--primary);
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .call-box .icon:before {
      -webkit-animation-name: wobble;
      animation-name: wobble;
      -webkit-animation-duration: 0.8s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      -webkit-transform-origin: 50% 100%; }
  @media only screen and (max-width: 575px) {
    .call-box {
      padding: 15px 30px 15px 100px; }
      .call-box .icon {
        width: 80px;
        font-size: 32px; }
      .call-box .title {
        font-size: 25px; } }

.map-iframe iframe {
  height: 615px; }

.section-1 {
  margin-bottom: -100px;
  padding-bottom: 180px; }
  @media only screen and (max-width: 991px) {
    .section-1 {
      padding-top: 50px;
      padding-bottom: 120px; } }

.dz-section-3 {
  position: relative;
  padding-top: 200px;
  padding-bottom: 350px; }
  .dz-section-3 .bg-img {
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1; }
  @media only screen and (max-width: 1200px) {
    .dz-section-3 {
      padding-top: 120px;
      padding-bottom: 250px; } }

.blog-wrapper {
  margin-top: -150px;
  padding: 100px 100px 100px;
  margin-left: -100px;
  margin-right: -100px; }
  @media only screen and (max-width: 1400px) {
    .blog-wrapper {
      margin-left: 0;
      margin-right: 0;
      padding: 50px; } }
  @media only screen and (max-width: 1200px) {
    .blog-wrapper {
      margin-left: 0;
      margin-right: 0; } }
  @media only screen and (max-width: 1100px) {
    .blog-wrapper {
      margin-left: 0;
      margin-right: 0; } }
  @media only screen and (max-width: 991px) {
    .blog-wrapper .dz-card {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); } }
  @media only screen and (max-width: 575px) {
    .blog-wrapper {
      padding: 25px; }
      .blog-wrapper .dz-card.style-1 .dz-info {
        padding: 35px 15px 15px; } }

.pagination.style-1 {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  justify-content: space-between; }
  .pagination.style-1 li {
    margin: 0; }
    .pagination.style-1 li .page-link {
      padding: 25px 0;
      color: #13212a;
      font-family: var(--font-family-title);
      height: auto;
      line-height: 1;
      font-weight: 700;
      display: inline-flex;
      align-items: center; }
      .pagination.style-1 li .page-link img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: var(--border-radius-base); }
      .pagination.style-1 li .page-link i {
        font-size: 40px; }
      .pagination.style-1 li .page-link.prev img {
        margin-right: 15px; }
      .pagination.style-1 li .page-link.prev i {
        margin-right: 5px; }
      .pagination.style-1 li .page-link.next img {
        margin-left: 15px; }
      .pagination.style-1 li .page-link.next i {
        margin-left: 5px; }
      @media only screen and (max-width: 575px) {
        .pagination.style-1 li .page-link {
          padding: 10px 0; }
          .pagination.style-1 li .page-link img {
            width: 60px;
            height: 60px; } }

.showcase-bottom {
  height: 60px;
  background: var(--secondary);
  padding: 0 55px;
  display: flex;
  align-items: center; }
  .showcase-bottom p {
    color: #fff;
    opacity: 0.8;
    margin: 0;
    text-transform: uppercase;
    font-size: 14px; }
  .showcase-bottom ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: auto; }
    .showcase-bottom ul li {
      display: inline-block; }
      .showcase-bottom ul li a {
        font-size: 14px;
        color: #fff;
        width: 30px;
        height: 30px;
        display: block;
        border: 1px solid rgba(255, 255, 255, 0.2);
        text-align: center;
        line-height: 28px;
        opacity: 0.8; }
        .showcase-bottom ul li a:hover {
          background: #fff;
          color: var(--secondary); }
  @media only screen and (max-width: 575px) {
    .showcase-bottom {
      padding: 0 30px; } }

.film-slider .swiper-slide {
  width: auto; }

.full-vh {
  height: calc(100vh - 225px); }
  .full-vh .dz-box {
    height: calc(100% - 20px); }
    .full-vh .dz-box img,
    .full-vh .dz-box .dz-media {
      height: 100% !important;
      object-fit: cover; }
  @media only screen and (max-width: 991px) {
    .full-vh {
      height: calc(100vh - 148px); } }

.inner-text .title {
  text-decoration: underline;
  font-weight: 500;
  margin-bottom: 10px; }

#home-slider {
  height: calc( 100vh - 165px);
  min-height: 650px;
  width: 100vw;
  position: relative; }
  #home-slider .swiper-container {
    height: 100%;
    width: 100%; }
  #home-slider .swiper-image {
    width: 50%;
    height: 100%;
    background-position: center !important;
    background-size: cover !important; }
  #home-slider .swiper-slide {
    display: flex;
    overflow: hidden;
    background: #fff; }
  #home-slider .swiper-bg {
    position: relative;
    z-index: 1; }
  #home-slider .swiper-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 50px;
    position: relative;
    max-width: 600px;
    margin: auto; }
  #home-slider .swiper-pagination {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 99999;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%); }
    #home-slider .swiper-pagination .swiper-pagination-bullet {
      display: block;
      font-size: 14px;
      color: #000;
      text-align: center;
      height: auto;
      width: auto;
      background: transparent;
      font-weight: 700;
      opacity: 0.3;
      padding: 5px 10px;
      text-shadow: 1px 2px 0px rgba(255, 255, 255, 0.5); }
      #home-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        opacity: 1; }
  @media only screen and (max-width: 991px) {
    #home-slider {
      height: calc( 100vh - 88px); } }
  @media only screen and (max-width: 575px) {
    #home-slider .swiper-slide {
      display: block; }
    #home-slider .swiper-image {
      width: 100%;
      height: auto; }
    #home-slider .swiper-bg {
      height: 350px; }
    #home-slider .swiper-content {
      padding: 25px; } }

.header-box {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 50px 50px 50px;
  margin-bottom: 50px;
  background: rgba(0, 0, 0, 0.01);
  overflow: hidden; }
  .header-box p {
    margin-bottom: 0; }
  .header-box .title {
    font-size: 50px;
    margin-bottom: 12px;
    color: var(--primary); }
  .header-box img {
    margin-bottom: -50px; }
  @media only screen and (max-width: 991px) {
    .header-box {
      padding: 30px; }
      .header-box .title {
        font-size: 35px; }
      .header-box img {
        margin-bottom: -30px; } }
  @media only screen and (max-width: 767px) {
    .header-box img {
      margin-bottom: 30px; } }
  @media only screen and (max-width: 575px) {
    .header-box .title {
      font-size: 28px; } }

.code-box {
  box-shadow: none;
  margin: 0 0 25px;
  padding: 20px;
  color: #fff;
  background-color: #191f23;
  white-space: pre;
  text-shadow: 0 1px 0 #000;
  border-radius: 5px;
  border-bottom: 1px solid #555;
  max-height: 700px; }

#copyButton.btn, #copyButton.wp-block-button__link {
  position: absolute;
  right: 30px;
  top: 15px;
  background: #eee;
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 4px 10px; }

.sort-title {
  font-family: var(--font-family-base) !important;
  color: #121212;
  font-weight: 400;
  position: relative;
  display: inline-block; }
  .sort-title:after, .sort-title:before {
    content: "";
    background: #dddddd;
    height: 1px;
    width: 40px;
    top: 15px;
    position: absolute; }
  .sort-title:after {
    left: -50px; }
  .sort-title:before {
    right: -50px; }

.popover {
  z-index: 999999; }

@media only screen and (max-width: 575px) {
  #rc-imageselect, .g-recaptcha {
    transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    margin-bottom: -25px; } }

.shake {
  -webkit-animation-name: wobble;
  animation-name: wobble;
  -webkit-animation-duration: 0.8s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: 50% 100%; }

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

.overlay-shine .dz-media {
  position: relative;
  overflow: hidden; }
  .overlay-shine .dz-media:before {
    position: absolute;
    top: 0;
    left: -85%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg); }

.overlay-shine:hover .dz-media:before {
  -webkit-animation: shine .75s;
  animation: shine .75s; }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

.split-box {
  position: relative;
  overflow: hidden; }
  .split-box > div {
    opacity: 0;
    -webkit-transition: 0s .8s;
    -o-transition: 0s .8s;
    transition: 0s .8s; }
  .split-box:after, .split-box:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .split-box:before {
    background-color: var(--primary);
    z-index: 1;
    -webkit-transform: translateX(-101%);
    -ms-transform: translateX(-101%);
    transform: translateX(-101%); }
  .split-box:after {
    background-color: var(--secondary);
    z-index: 2;
    -webkit-transform: translateX(-101%);
    -ms-transform: translateX(-101%);
    transform: translateX(-101%); }
  .split-box.split-active > div {
    opacity: 1; }
  .split-box.split-active:after {
    -webkit-animation: split-box-animation 1.2s 0.2s cubic-bezier(1, 0, 0.54, 0.99) forwards;
    animation: split-box-animation 1.2s 0.2s cubic-bezier(1, 0, 0.54, 0.99) forwards; }
  .split-box.split-active:before {
    -webkit-animation: split-box-animation 1.5s cubic-bezier(0.86, 0, 0.07, 1) forwards;
    animation: split-box-animation 1.5s cubic-bezier(0.86, 0, 0.07, 1) forwards; }

@-webkit-keyframes split-box-animation {
  0% {
    -webkit-transform: translateX(-101%);
    transform: translateX(-101%); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    -webkit-transform: translateX(101%);
    transform: translateX(101%); } }

@keyframes split-box-animation {
  0% {
    -webkit-transform: translateX(-101%);
    transform: translateX(-101%); }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    -webkit-transform: translateX(101%);
    transform: translateX(101%); } }

.sechedule-btn {
  border: 0;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  .sechedule-btn:after, .sechedule-btn:before {
    content: "";
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    background: var(--secondary);
    z-index: -1;
    position: absolute;
    height: 20px;
    width: 20px; }
  .sechedule-btn:after {
    clip-path: polygon(0 0, 0 100%, 100% 0);
    top: 0;
    left: 0; }
  .sechedule-btn:before {
    clip-path: polygon(100% 100%, 0 100%, 100% 0);
    bottom: 0;
    right: 0; }
  .sechedule-btn:hover {
    color: #fff;
    background: var(--secondary); }
    .sechedule-btn:hover:after, .sechedule-btn:hover:before {
      height: 100%;
      width: 100%; }

.alert.alert-sm {
  padding: 5px 15px;
  font-size: 12px; }

.alert.alert-lg {
  padding: 25px 15px;
  font-size: 16px; }

.alert.alert-xl {
  padding: 35px 15px;
  font-size: 18px; }

/* .styles_scroll-to-top__2A70v{
	box-shadow: -4px 4px 24px -10px var(--primary) !important;
    background-color: var(--primary)!important;
    border-color: var(--primary);
	width: 60px;
	height: 60px;
	border-radius: 0;
	bottom: 15px;
	color: #fff;
	svg{
		display: none;
	}
	
} */
.service-wrapper {
  z-index: 1;
  position: relative;
  background-size: cover;
  background-position: right; }
  .service-wrapper:after {
    content: "";
    height: 100%;
    width: 40%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #e8e8e8;
    background-image: url("../images/background/bg5.png");
    background-size: cover;
    background-position: bottom; }

.service-box {
  background: #fff;
  padding: 70px; }
  @media only screen and (max-width: 767px) {
    .service-box {
      padding: 40px; } }
  @media only screen and (max-width: 575px) {
    .service-box {
      padding: 25px; } }

.content-box {
  background: #fff;
  box-shadow: 0px 3px 29px 0px rgba(0, 0, 0, 0.07); }
  .content-box .dz-info {
    padding: 30px;
    position: relative; }
    .content-box .dz-info:after {
      content: attr(data-num);
      position: absolute;
      top: 30px;
      right: 30px;
      font-family: var(--font-family-title);
      color: #dcdcdc;
      font-size: 50px;
      line-height: 1;
      font-weight: 300;
      -webkit-transition: all 0.2s;
      -ms-transition: all 0.2s;
      transition: all 0.2s; }
  .content-box:hover .dz-bottom .btn, .content-box:hover .dz-bottom .wp-block-button__link {
    background-color: var(--secondary); }
  .content-box:hover .dz-info:after {
    color: var(--primary);
    font-size: 60px;
    transform: rotate(-10deg); }

.service-wrapper-2 {
  position: relative; }
  .service-wrapper-2:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 29%;
    background: #fff; }

.contact-area1 {
  padding: 50px;
  background: #fff;
  box-shadow: 0px 3px 29px 0px rgba(0, 0, 0, 0.07); }
  @media only screen and (max-width: 575px) {
    .contact-area1 {
      padding: 30px; } }

.jq-tip-dialog {
  position: absolute;
  background: #fff;
  z-index: 999; }

.image-tooltip {
  position: absolute;
  background: var(--secondary);
  padding: 20px;
  z-index: 999;
  width: 0px; }
  .image-tooltip .title {
    color: #fff;
    white-space: nowrap; }
  .image-tooltip .sub-title {
    white-space: nowrap;
    color: var(--primary);
    margin-bottom: 0; }

.exp-row {
  display: flex;
  align-items: center; }
  .exp-row .year {
    color: var(--primary);
    margin-bottom: 0;
    font-size: 60px;
    line-height: 1;
    font-family: var(--font-family-base);
    margin-right: 10px; }
  .exp-row p {
    color: var(--secondary);
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.3;
    font-weight: 600; }
    .exp-row p span {
      display: block;
      font-size: 28px;
      font-weight: 800; }

.content-box2 {
  padding: 30px;
  background-color: white;
  box-shadow: 0px 1px 21px 0px rgba(4, 4, 4, 0.11);
  border-radius: var(--border-radius-base);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  .content-box2 .dz-info {
    margin-bottom: 30px; }
    .content-box2 .dz-info .title {
      line-height: 1.2;
      border-bottom: 1px solid #e9e9e9;
      padding-bottom: 20px;
      margin-bottom: 20px;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
    .content-box2 .dz-info p {
      font-size: 17px;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
  .content-box2 .dz-bottom {
    position: relative;
    z-index: 1; }
    .content-box2 .dz-bottom .btn-link {
      font-size: 16px;
      font-family: var(--font-family-title);
      color: var(--secondary);
      font-weight: 700;
      display: inline-flex;
      background: #fff;
      padding-right: 20px;
      align-items: center;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
      .content-box2 .dz-bottom .btn-link i {
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s;
        height: 20px;
        width: 20px;
        border-radius: 20px;
        line-height: 20px;
        background: var(--primary);
        color: #fff;
        font-size: 10px;
        text-align: center;
        margin-left: 6px; }
    .content-box2 .dz-bottom:after {
      content: "";
      position: absolute;
      left: 0;
      background: #e9e9e9;
      height: 1px;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1; }
  .content-box2:hover {
    background-color: var(--primary); }
    .content-box2:hover .dz-info .title,
    .content-box2:hover .dz-info p {
      color: #fff; }
    .content-box2:hover .dz-bottom .btn-link {
      color: #fff;
      background: var(--primary); }
      .content-box2:hover .dz-bottom .btn-link i {
        background-color: #fff;
        color: var(--primary); }

.about-swiper .swiper-pagination {
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%; }
  .about-swiper .swiper-pagination .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
    border-radius: 12px;
    background: #636363;
    opacity: 1;
    display: inline-block;
    margin: 0 5px;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    .about-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: var(--primary);
      transform: scale(1.5); }

.content-slider {
  margin: 0 -15px; }
  .content-slider .swiper-slide > div {
    margin: 30px 15px; }

.about-wrapper {
  position: relative;
  z-index: 1; }

.bg-about-slider {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1; }
  .bg-about-slider .dz-media {
    height: 100%;
    position: relative;
    z-index: 1; }
    .bg-about-slider .dz-media img {
      height: 100%;
      object-fit: cover; }
    .bg-about-slider .dz-media:after {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: -moz-linear-gradient(left, rgba(25, 36, 54, 0.8) 0%, rgba(25, 36, 54, 0.9) 5%, rgba(25, 36, 54, 0.92) 6%, #192436 30%, rgba(25, 36, 54, 0.92) 33%, rgba(25, 36, 54, 0.22) 59%, rgba(25, 36, 54, 0.87) 79%, #192436 83%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(25, 36, 54, 0.8) 0%, rgba(25, 36, 54, 0.9) 5%, rgba(25, 36, 54, 0.92) 6%, #192436 30%, rgba(25, 36, 54, 0.92) 33%, rgba(25, 36, 54, 0.22) 59%, rgba(25, 36, 54, 0.87) 79%, #192436 83%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(25, 36, 54, 0.8) 0%, rgba(25, 36, 54, 0.9) 5%, rgba(25, 36, 54, 0.92) 6%, #192436 30%, rgba(25, 36, 54, 0.92) 33%, rgba(25, 36, 54, 0.22) 59%, rgba(25, 36, 54, 0.87) 79%, #192436 83%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

.about-pagination {
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  text-align: right; }
  .about-pagination .swiper-pagination-bullet {
    height: auto;
    width: auto;
    border-radius: 0;
    background: transparent;
    opacity: 1;
    display: block;
    color: #fff;
    font-family: var(--font-family-title);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 170px;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: 30px;
    cursor: pointer;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
    .about-pagination .swiper-pagination-bullet span {
      display: inline-block;
      height: 46px;
      width: 46px;
      min-width: 46px;
      line-height: 46px;
      border-radius: var(--border-radius-base);
      text-align: center;
      margin-left: 10px;
      background: rgba(255, 255, 255, 0.2);
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s; }
    .about-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      color: var(--primary); }
      .about-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active span {
        background: var(--primary);
        color: #fff; }
  @media only screen and (max-width: 767px) {
    .about-pagination {
      position: relative;
      top: 0;
      transform: unset;
      display: flex;
      right: auto;
      left: calc(calc(100% - 540px) / 2 + 15px);
      width: 510px;
      margin-top: 40px;
      flex-flow: wrap; }
      .about-pagination .swiper-pagination-bullet {
        text-align: left;
        flex-direction: row-reverse; }
        .about-pagination .swiper-pagination-bullet span {
          margin-left: 0;
          margin-right: 10px; } }
  @media only screen and (max-width: 767px) {
    .about-pagination {
      left: 15px;
      width: calc(100% - 30px); } }

.overlay-effect {
  position: relative; }
  .overlay-effect:before {
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    mix-blend-mode: multiply;
    background: var(--primary); }
  .overlay-effect img {
    width: 100%; }

.contact-area2 {
  padding: 70px;
  position: relative;
  z-index: 1;
  background: #fff;
  margin-top: -220px; }
  .contact-area2 .dz-media {
    padding-top: 15px;
    padding-right: 15px;
    margin-right: 20px; }
    .contact-area2 .dz-media .info {
      background: var(--secondary);
      position: absolute;
      padding: 25px 30px;
      top: 0;
      right: 0;
      width: 300px;
      -webkit-animation: movedelement 7s linear infinite;
      animation: movedelement 7s linear infinite; }
      .contact-area2 .dz-media .info p {
        color: #9b9b9b;
        margin-bottom: 0; }
    @media only screen and (max-width: 991px) {
      .contact-area2 .dz-media {
        margin-right: 0; } }
  .contact-area2:after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border: 5px solid var(--primary); }
  @media only screen and (max-width: 767px) {
    .contact-area2 {
      padding: 30px; } }
  @media only screen and (max-width: 575px) {
    .contact-area2 .dz-media {
      padding: 0; }
      .contact-area2 .dz-media .info {
        position: unset;
        width: 100%; } }

.call-box3 {
  position: relative;
  padding-left: 75px; }
  .call-box3 i {
    height: 64px;
    width: 64px;
    border-radius: 64px;
    line-height: 64px;
    text-align: center;
    background: var(--secondary);
    color: #fff;
    font-size: 24px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  .call-box3 .title {
    font-size: 35px;
    line-height: 1;
    margin-bottom: 0; }
  .call-box3 span {
    color: var(--primary);
    font-size: 16px;
    font-weight: 700; }
  .call-box3:hover i {
    background-color: var(--primary); }
    .call-box3:hover i:before {
      -webkit-animation-name: wobble;
      animation-name: wobble;
      -webkit-animation-duration: 0.8s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      -webkit-transform-origin: 50% 100%; }

.overlay-gradient-sec {
  position: relative;
  z-index: 2; }
  .overlay-gradient-sec:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#192436+0,192436+100&0.81+0,1+100 */
    background: -moz-linear-gradient(top, rgba(25, 36, 54, 0.81) 0%, #192436 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(25, 36, 54, 0.81) 0%, #192436 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(25, 36, 54, 0.81) 0%, #192436 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

.about-wrapper-2 {
  width: 50vw;
  float: right;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: -150px; }
  .about-wrapper-2 .swiper-slide {
    border-bottom: 4px solid var(--primary); }
    .about-wrapper-2 .swiper-slide img {
      width: 100%; }
  .about-wrapper-2 .swiper-button {
    position: absolute;
    bottom: 20px;
    right: 15px; }
  @media only screen and (max-width: 1200px) {
    .about-wrapper-2 {
      margin-bottom: 0; } }
  @media only screen and (max-width: 991px) {
    .about-wrapper-2 {
      width: 100%;
      padding: 0; } }

.dzSubscribe.style-1 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto; }
  .dzSubscribe.style-1 .ft-row {
    position: relative; }
    .dzSubscribe.style-1 .ft-row .form-control, .dzSubscribe.style-1 .ft-row .wp-block-categories-dropdown select, .wp-block-categories-dropdown .dzSubscribe.style-1 .ft-row select,
    .dzSubscribe.style-1 .ft-row .wp-block-archives-dropdown select, .wp-block-archives-dropdown .dzSubscribe.style-1 .ft-row select {
      height: 68px;
      font-size: 18px;
      padding-right: 68px; }
      .dzSubscribe.style-1 .ft-row .form-control::placeholder, .dzSubscribe.style-1 .ft-row .wp-block-categories-dropdown select::placeholder, .wp-block-categories-dropdown .dzSubscribe.style-1 .ft-row select::placeholder,
      .dzSubscribe.style-1 .ft-row .wp-block-archives-dropdown select::placeholder, .wp-block-archives-dropdown .dzSubscribe.style-1 .ft-row select::placeholder {
        font-family: var(--font-family-title); }
    .dzSubscribe.style-1 .ft-row .btn, .dzSubscribe.style-1 .ft-row .wp-block-button__link {
      position: absolute;
      background: var(--primary);
      color: #fff;
      display: inline-block;
      right: 4px;
      top: 4px;
      height: 60px;
      width: 60px;
      text-align: center;
      line-height: 60px;
      border-radius: var(--border-radius-base);
      padding: 0; }
      .dzSubscribe.style-1 .ft-row .btn i, .dzSubscribe.style-1 .ft-row .wp-block-button__link i {
        font-size: 22px; }

.dz-section-2 {
  padding-bottom: 170px; }

.image-slider-wrapper .button-prev,
.image-slider-wrapper .button-next {
  position: absolute;
  top: 50%;
  height: 75px;
  width: 75px;
  line-height: 75px;
  border-radius: 75px;
  text-align: center;
  border: 2px solid #fff;
  color: #fff;
  z-index: 2;
  font-size: 25px; }
  @media only screen and (max-width: 575px) {
    .image-slider-wrapper .button-prev,
    .image-slider-wrapper .button-next {
      height: 55px;
      width: 55px;
      line-height: 55px; } }

.image-slider-wrapper .button-prev {
  left: 30px; }

.image-slider-wrapper .button-next {
  right: 30px; }

.image-box {
  position: relative; }
  .image-box .info {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px 60px 30px 10px; }
    @media only screen and (max-width: 575px) {
      .image-box .info {
        padding: 30px 60px 30px 30px; } }
  .image-box .dz-media {
    overflow: hidden;
    transform: skewX(-10deg);
    height: 500px;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    .image-box .dz-media img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transform: skewX(10deg) scale(1.3); }
    .image-box .dz-media:before {
      content: "";
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      border: 5px solid var(--primary);
      opacity: 0;
      position: absolute;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
    .image-box .dz-media:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
    @media only screen and (max-width: 575px) {
      .image-box .dz-media {
        height: 440px; } }
  .image-box:hover .dz-media:after {
    opacity: .5; }
  .image-box:hover .dz-media:before {
    opacity: 1; }

.best-service-box {
  background: var(--secondary);
  padding: 70px; }
  @media only screen and (max-width: 991px) {
    .best-service-box {
      padding: 30px; } }
  .best-service-box p {
    color: #bababa; }

.bg-half-img {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 35%;
  background-size: cover; }

ul.info-desc li {
  color: var(--primary);
  display: flex;
  border-bottom: 1px solid var(--secondary);
  padding-top: 10px;
  padding-bottom: 10px; }
  ul.info-desc li:before {
    top: 10px; }
  ul.info-desc li:last-child {
    border-bottom: 0; }
  ul.info-desc li span {
    color: #e5e5e5;
    font-weight: 300;
    display: inline-block;
    margin-left: auto;
    width: 50%; }

.dz-section-1 {
  position: relative;
  z-index: 1; }
  .dz-section-1 .bg-img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 730px;
    z-index: -1; }
    @media only screen and (max-width: 991px) {
      .dz-section-1 .bg-img {
        height: 400px; } }
    @media only screen and (max-width: 575px) {
      .dz-section-1 .bg-img {
        height: 300px; } }

.image-box2 {
  position: relative;
  border-radius: var(--border-radius-base);
  overflow: hidden;
  height: 210px;
  margin-top: 30px;
  margin-bottom: 30px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  .image-box2 .dz-media {
    height: 100%; }
    .image-box2 .dz-media img {
      height: 100%;
      object-fit: cover;
      mix-blend-mode: multiply; }
  .image-box2 .info {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
    opacity: 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }

.image-box-slider .swiper-slide.swiper-slide-active .image-box2 {
  background-color: var(--primary);
  height: 270px;
  margin: 0; }
  .image-box-slider .swiper-slide.swiper-slide-active .image-box2 .info {
    opacity: 1; }

.feature-slider-box {
  width: calc(50vw - 123px);
  height: calc(100% - 100px);
  position: absolute;
  margin-top: -120px; }
  .feature-slider-box .swiper-container,
  .feature-slider-box .swiper-slide {
    height: 100%; }
  .feature-slider-box img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media only screen and (max-width: 1200px) {
    .feature-slider-box {
      width: calc(50vw - 104px); } }
  @media only screen and (max-width: 991px) {
    .feature-slider-box {
      position: relative;
      margin: 0;
      width: 100%;
      height: auto; } }

.feature-wrapper {
  position: relative; }
  .feature-wrapper:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
    height: 40px; }
  @media only screen and (max-width: 991px) {
    .feature-wrapper:before {
      content: none; } }

/* Blog single */
.blog-single {
  margin-bottom: 3.125rem; }
  .blog-single .dz-post-meta {
    margin-bottom: 1.25rem; }
  .blog-single .dz-post-text {
    padding-bottom: 30px; }
    .blog-single .dz-post-text:after {
      content: "";
      display: block;
      width: 100%;
      clear: both; }
  .blog-single .dz-post-tags {
    margin-top: 1.25rem; }
  .blog-single .dz-social-icon {
    display: flex;
    align-items: center; }
    .blog-single .dz-social-icon .title {
      margin-right: 18px; }
    .blog-single .dz-social-icon ul li a {
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 16px; }
  .blog-single .dz-post-media {
    border-radius: 0.25rem; }
  .blog-single.dz-card .post-tags {
    margin-left: -5px;
    margin-right: -5px; }
  .blog-single.style-1 .dz-media + .dz-info {
    padding: 40px 0 0; }
  .blog-single.style-1 .dz-info {
    padding: 0 0 30px; }
    .blog-single.style-1 .dz-info p {
      font-size: 18px; }
    .blog-single.style-1 .dz-info .block-quote p {
      font-size: 1.5rem; }
      @media only screen and (max-width: 991px) {
        .blog-single.style-1 .dz-info .block-quote p {
          font-size: 1.125rem; } }

.dz-post-text a {
  text-decoration: underline;
  box-shadow: inset 0 -10px 0 var(--rgba-primary-1); }

.dz-post-text .widget_archive li a,
.dz-post-text .wp-block-latest-posts li a,
.dz-post-text .wp-block-categories-list li a,
.dz-post-text .wp-block-archives-list li a,
.dz-post-text .widget_categories li a,
.dz-post-text blockquote a,
.dz-post-text .wp-block-button__link {
  box-shadow: none;
  text-decoration: none; }

.post-header {
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 575px) {
    .post-header {
      margin-bottom: 1.25rem; } }
  .post-header .dz-media img {
    min-height: 250px;
    object-fit: cover; }
  .post-header .dz-info {
    position: absolute;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: 100px 30px 30px 30px !important;
    width: 100%; }
    @media only screen and (max-width: 575px) {
      .post-header .dz-info {
        padding: 40px 15px 15px 15px !important; } }
    .post-header .dz-info .dz-title {
      color: #fff; }
      @media only screen and (max-width: 575px) {
        .post-header .dz-info .dz-title {
          font-size: 1.125rem; } }
    .post-header .dz-info .dz-meta > ul {
      justify-content: center; }
      .post-header .dz-info .dz-meta > ul > li {
        color: #fff; }

.post-link-in {
  padding: 15px 50px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .post-link-in:hover {
    background: var(--primary);
    color: #fff; }

.side-bar {
  padding-left: 20px; }
  @media only screen and (max-width: 991px) {
    .side-bar {
      padding-left: 0;
      padding-right: 0; } }
  .side-bar.left {
    padding-left: 0;
    padding-right: 20px; }
    @media only screen and (max-width: 991px) {
      .side-bar.left {
        padding-right: 0;
        padding-left: 0; } }

.alignnone {
  margin: 0.3125rem 0 1.563rem 0; }
  .alignnoneimg, .alignnone.wp-caption, .alignnone.is-resized {
    margin: 0.3125rem 0 1.563rem 0; }

.aligncenter {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center; }
  .aligncenterimg, .aligncenter.wp-caption, .aligncenter.is-resized {
    display: block;
    margin: 0.3125rem auto 0.938rem;
    text-align: center; }

.alignright {
  float: right;
  margin: 0.3125rem 0 1.563rem 1.563rem; }
  .alignrightimg, .alignright.wp-caption, .alignright.is-resized {
    margin: 0.3125rem 0 1.563rem 1.563rem;
    float: right; }

.alignleft {
  float: left;
  margin: 0.3125rem 1.563rem 1.563rem 0; }
  .alignleftimg, .alignleft.wp-caption, .alignleft.is-resized {
    margin: 0.3125rem 1.563rem 1.563rem 0;
    float: left; }

.wp-caption {
  max-width: 100%;
  text-align: center; }
  .wp-caption img[class*="wp-image-"] {
    display: block;
    margin: 0; }
  .wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: auto; }
  .wp-caption p.wp-caption-text {
    font-size: 0.813rem;
    line-height: 1.125rem;
    margin: 0;
    padding: 0.625rem 0;
    text-align: left; }

.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
blockquote.wp-block-quote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft,
blockquote {
  padding: 1.25rem 2rem 1.25rem 3rem;
  font-size: 0.938rem;
  color: var(--title);
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  line-height: 1.875rem;
  position: relative;
  clear: both;
  font-weight: 700;
  z-index: 1;
  border: 0; }
  @media only screen and (max-width: 991px) {
    .wp-block-quote.is-large,
    .wp-block-quote.is-style-large,
    blockquote.wp-block-quote,
    blockquote.wp-block-pullquote,
    blockquote.wp-block-pullquote.alignright,
    blockquote.wp-block-pullquote.alignleft,
    blockquote {
      padding: 1.25rem 1.25rem 0.938rem 1.25rem;
      font-size: 0.813rem; } }
  .wp-block-quote.is-large cite,
  .wp-block-quote.is-style-large cite,
  blockquote.wp-block-quote cite,
  blockquote.wp-block-pullquote cite,
  blockquote.wp-block-pullquote.alignright cite,
  blockquote.wp-block-pullquote.alignleft cite,
  blockquote cite {
    font-style: normal;
    position: relative;
    display: block;
    margin-top: -0.3125rem;
    font-weight: 600;
    color: var(--primary);
    line-height: 1.3; }
    .wp-block-quote.is-large cite:before,
    .wp-block-quote.is-style-large cite:before,
    blockquote.wp-block-quote cite:before,
    blockquote.wp-block-pullquote cite:before,
    blockquote.wp-block-pullquote.alignright cite:before,
    blockquote.wp-block-pullquote.alignleft cite:before,
    blockquote cite:before {
      content: "";
      margin-right: 0.625rem;
      width: 15px;
      height: 2px;
      background: var(--primary);
      display: inline-block;
      vertical-align: middle; }
  .wp-block-quote.is-large b, .wp-block-quote.is-large strong, .wp-block-quote.is-large .strong,
  .wp-block-quote.is-style-large b,
  .wp-block-quote.is-style-large strong,
  .wp-block-quote.is-style-large .strong,
  blockquote.wp-block-quote b,
  blockquote.wp-block-quote strong,
  blockquote.wp-block-quote .strong,
  blockquote.wp-block-pullquote b,
  blockquote.wp-block-pullquote strong,
  blockquote.wp-block-pullquote .strong,
  blockquote.wp-block-pullquote.alignright b,
  blockquote.wp-block-pullquote.alignright strong,
  blockquote.wp-block-pullquote.alignright .strong,
  blockquote.wp-block-pullquote.alignleft b,
  blockquote.wp-block-pullquote.alignleft strong,
  blockquote.wp-block-pullquote.alignleft .strong,
  blockquote b,
  blockquote strong,
  blockquote .strong {
    color: inherit; }
  .wp-block-quote.is-large:after,
  .wp-block-quote.is-style-large:after,
  blockquote.wp-block-quote:after,
  blockquote.wp-block-pullquote:after,
  blockquote.wp-block-pullquote.alignright:after,
  blockquote.wp-block-pullquote.alignleft:after,
  blockquote:after {
    content: "";
    width: 5px;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--primary);
    position: absolute;
    box-shadow: 0 0 30px 0 var(--rgba-primary-2); }
  .wp-block-quote.is-large:before,
  .wp-block-quote.is-style-large:before,
  blockquote.wp-block-quote:before,
  blockquote.wp-block-pullquote:before,
  blockquote.wp-block-pullquote.alignright:before,
  blockquote.wp-block-pullquote.alignleft:before,
  blockquote:before {
    content: "";
    z-index: -1;
    background-size: cover;
    left: 20px;
    background-image: var(--quote);
    background-position: center right;
    width: 90px;
    padding: 0;
    opacity: 0.1;
    background-repeat: no-repeat;
    height: 90px;
    top: 0px;
    position: absolute; }
  .wp-block-quote.is-large.wp-block-pullquote.alignleft,
  .wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
  blockquote.wp-block-quote.wp-block-pullquote.alignleft,
  blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
  blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
  blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
  blockquote.wp-block-pullquote.alignleft {
    margin: 0rem 1.563rem 1.563rem 0rem; }
  .wp-block-quote.is-large.wp-block-pullquote.alignright,
  .wp-block-quote.is-style-large.wp-block-pullquote.alignright,
  blockquote.wp-block-quote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignright {
    margin: 0rem 0 1.563rem 1.563rem; }
  .wp-block-quote.is-large.style-1,
  .wp-block-quote.is-style-large.style-1,
  blockquote.wp-block-quote.style-1,
  blockquote.wp-block-pullquote.style-1,
  blockquote.wp-block-pullquote.alignright.style-1,
  blockquote.wp-block-pullquote.alignleft.style-1,
  blockquote.style-1 {
    background: #fff;
    color: var(--primary);
    padding: 25px 40px; }
    .wp-block-quote.is-large.style-1 p,
    .wp-block-quote.is-style-large.style-1 p,
    blockquote.wp-block-quote.style-1 p,
    blockquote.wp-block-pullquote.style-1 p,
    blockquote.wp-block-pullquote.alignright.style-1 p,
    blockquote.wp-block-pullquote.alignleft.style-1 p,
    blockquote.style-1 p {
      font-size: 24px;
      line-height: 1.5;
      color: var(--title); }
    .wp-block-quote.is-large.style-1:before,
    .wp-block-quote.is-style-large.style-1:before,
    blockquote.wp-block-quote.style-1:before,
    blockquote.wp-block-pullquote.style-1:before,
    blockquote.wp-block-pullquote.alignright.style-1:before,
    blockquote.wp-block-pullquote.alignleft.style-1:before,
    blockquote.style-1:before {
      left: 20px;
      background-size: contain;
      width: 80px;
      top: 0;
      transform: rotate(180deg);
      background-image: var(--quote-2); }
    .wp-block-quote.is-large.style-1 cite,
    .wp-block-quote.is-style-large.style-1 cite,
    blockquote.wp-block-quote.style-1 cite,
    blockquote.wp-block-pullquote.style-1 cite,
    blockquote.wp-block-pullquote.alignright.style-1 cite,
    blockquote.wp-block-pullquote.alignleft.style-1 cite,
    blockquote.style-1 cite {
      color: var(--primary); }
      .wp-block-quote.is-large.style-1 cite:before,
      .wp-block-quote.is-style-large.style-1 cite:before,
      blockquote.wp-block-quote.style-1 cite:before,
      blockquote.wp-block-pullquote.style-1 cite:before,
      blockquote.wp-block-pullquote.alignright.style-1 cite:before,
      blockquote.wp-block-pullquote.alignleft.style-1 cite:before,
      blockquote.style-1 cite:before {
        background: var(--primary); }

blockquote.wp-block-pullquote:not(.is-style-solid-color) {
  background: #5608e0; }

.wp-caption-text {
  font-size: 0.875rem;
  line-height: 1.3; }

.dz-card.blog-single .dz-post-text blockquote p,
.dz-page-text blockquote p,
blockquote p {
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--title);
  margin-bottom: 1rem;
  font-weight: 700;
  font-style: italic; }
  .dz-card.blog-single .dz-post-text blockquote p cite,
  .dz-page-text blockquote p cite,
  blockquote p cite {
    margin-top: 20px; }
  @media only screen and (max-width: 991px) {
    .dz-card.blog-single .dz-post-text blockquote p,
    .dz-page-text blockquote p,
    blockquote p {
      font-size: 1.125rem; } }

.dz-page-text {
  padding-bottom: 30px !important; }

.dz-card.blog-single .dz-post-text blockquote.style-1 p,
.dz-page-text blockquote.style-1 p,
blockquote.style-1 p {
  margin-bottom: 1.5rem; }

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto; }

/* Gutenberg Gallery */
/* Gutenberg Css */
/* .dz-card.blog-single,
.dz-card.blog-single + #comment-list */
.min-container,
.dz-page-text,
.dz-page-text + #comment-list,
.dz-page-text + #comment-list + .paginate-links {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.938rem;
  padding-right: 0.938rem; }

.dz-card.blog-single.sidebar + #comment-list {
  padding: 0;
  max-width: 100%; }

.max-container {
  max-width: 93.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3.4375rem;
  padding-right: 3.4375rem; }

.dz-page-text + #comment-list + .paginate-links,
.dz-page-text + #comment-list,
.dz-page-text {
  padding: 0; }

.dz-page-text.sidebar {
  width: 100%;
  padding: 0;
  max-width: 100%; }

.dz-page-text > ol,
.dz-page-text > ul,
.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul {
  list-style: none;
  margin-bottom: 1.875rem; }

.dz-page-text > ol,
.dz-page-text > ul,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul,
.dz-card.blog-single .dz-post-text.text > ol,
.dz-card.blog-single .dz-post-text.text > ul {
  padding-left: 1.25rem; }
  .dz-page-text > ol ol,
  .dz-page-text > ol ul,
  .dz-page-text > ul ol,
  .dz-page-text > ul ul,
  .dz-card.blog-single .dz-post-text > ol ol,
  .dz-card.blog-single .dz-post-text > ol ul,
  .dz-card.blog-single .dz-post-text > ul ol,
  .dz-card.blog-single .dz-post-text > ul ul,
  .dz-card.blog-single .dz-post-text.text > ol ol,
  .dz-card.blog-single .dz-post-text.text > ol ul,
  .dz-card.blog-single .dz-post-text.text > ul ol,
  .dz-card.blog-single .dz-post-text.text > ul ul {
    padding-left: 1.563rem; }

.dz-page-text > ol li,
.dz-page-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text.text > ol li,
.dz-card.blog-single .dz-post-text.text > ul li {
  padding: 0.5rem 0.5rem;
  position: relative;
  list-style: inherit; }

.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery {
  padding: 0;
  list-style: none; }

.dz-page-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li {
  padding: 0;
  list-style: none;
  margin: 0.25rem; }

.dz-page-text > .wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li:before {
  content: none; }

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  margin: 0 0.5rem 0.5rem 0; }

.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
  margin-right: 0.5rem; }

.content-spacious-full .wp-block-gallery {
  margin-left: calc(-12.5rem - (0.313rem/2));
  margin-right: calc(-12.5rem - (0.313rem/2));
  width: calc(100% + 25rem + 0.313rem);
  max-width: initial; }

.alignfull,
.alignwide {
  margin-left: calc(-6.25rem - (0.313rem/2));
  margin-right: calc(-6.25rem - (0.313rem/2));
  width: calc(100% + 12.5rem + 0.313rem);
  max-width: initial; }

.alignfull,
.alignfullwide {
  margin-left: calc(50% - 50vw - (var(--content-share-ml,0px)/2));
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box; }

.dz-page-text h1, .dz-page-text .h1,
.dz-post-text h1,
.dz-post-text .h1,
.thm-unit-test .dz-post-text h1,
.thm-unit-test .dz-post-text .h1 {
  margin-bottom: 1rem;
  font-weight: 700; }

.dz-page-text h2, .dz-page-text .h2,
.dz-post-text h2,
.dz-post-text .h2,
.thm-unit-test .dz-post-text h2,
.thm-unit-test .dz-post-text .h2 {
  margin-bottom: 1rem;
  font-weight: 700; }

.dz-page-text h3, .dz-page-text .h3,
.dz-post-text h3,
.dz-post-text .h3,
.thm-unit-test .dz-post-text h3,
.thm-unit-test .dz-post-text .h3 {
  margin-bottom: 1rem;
  font-weight: 700; }

.dz-page-text h4, .dz-page-text .h4, .dz-page-text .comment-reply-title,
.dz-page-text .wp-block-search .wp-block-search__label, .wp-block-search .dz-page-text .wp-block-search__label,
.dz-post-text h4,
.dz-post-text .h4,
.dz-post-text .comment-reply-title,
.dz-post-text .wp-block-search .wp-block-search__label,
.wp-block-search .dz-post-text .wp-block-search__label,
.thm-unit-test .dz-post-text h4,
.thm-unit-test .dz-post-text .h4,
.thm-unit-test .dz-post-text .comment-reply-title,
.thm-unit-test .dz-post-text .wp-block-search .wp-block-search__label,
.wp-block-search .thm-unit-test .dz-post-text .wp-block-search__label {
  margin-bottom: 0.75rem;
  font-weight: 600; }

.dz-page-text h5, .dz-page-text .h5,
.dz-post-text h5,
.dz-post-text .h5,
.thm-unit-test .dz-post-text h5,
.thm-unit-test .dz-post-text .h5 {
  margin-bottom: 0.75rem; }

.dz-page-text h6, .dz-page-text .h6,
.dz-post-text h6,
.dz-post-text .h6,
.thm-unit-test .dz-post-text h6,
.thm-unit-test .dz-post-text .h6 {
  margin-bottom: 0.625rem; }

.wp-block-code,
pre.wp-block-code,
pre {
  margin: 0 0 1.563rem;
  padding: 1.25rem;
  color: #fff;
  background-color: #212326;
  white-space: pre;
  font-size: 0.938rem;
  border-radius: 0.1875rem; }

.dz-page-text,
.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li,
.dz-card.blog-single .dz-card.blog-single .dz-post-text p:not(.has-text-color):not(.has-text-align-center):not(.has-text-align-left):not(.has-text-align-right) {
  font-size: 1rem;
  line-height: 1.7; }

.wp-block-cover,
.wp-block-cover-image {
  color: #fff; }

.wp-block-cover p:last-child,
.wp-block-cover-image p:last-child {
  margin-bottom: 0; }

.wp-block-quote.has-text-align-right {
  border-right: 0; }

.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li {
  position: relative; }

.dz-page-text ul > li:before,
.dz-page-text ol > li:before,
.dz-card.blog-single .dz-post-text ul > li:before,
.dz-card.blog-single .dz-post-text ol > li:before {
  content: "";
  display: inline-block;
  width: 0.375rem;
  height: 0.375rem;
  background: #181c32;
  left: -0.938rem;
  position: absolute;
  top: 1.063rem;
  border-radius: 4px; }

.dz-page-text ul > li li:before,
.dz-page-text ol > li li:before,
.dz-card.blog-single .dz-post-text ul > li li:before,
.dz-card.blog-single .dz-post-text ol > li li:before {
  content: none; }

.dz-page-text p,
.dz-card.blog-single .dz-post-text p {
  margin-bottom: 1.65rem; }

.paginate-links {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 4rem 0; }

.paginate-links a,
.paginate-links > span {
  margin: 0 0 0 0.625rem;
  position: relative;
  border: 0rem solid #6cc000;
  color: #1f2471;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  text-transform: capitalize;
  transition: all 500ms ease 0s;
  background: #E0E0E0;
  border-radius: 0.1875rem; }

.wp-block-columns {
  margin-bottom: 0; }

/* Gutenberg Gallery */
.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0; }

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 1rem 1rem 0;
  position: relative; }

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
  height: 100%;
  margin: 0; }

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .blocks-gallery-image figure,
  .wp-block-gallery .blocks-gallery-item figure {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start; } }

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%; }

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .blocks-gallery-image img,
  .wp-block-gallery .blocks-gallery-item img {
    width: auto; } }

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3) 60%, transparent);
  bottom: 0;
  color: #fff;
  font-size: 0.813rem;
  max-height: 100%;
  overflow: auto;
  padding: 2.5rem 0.625rem 0.3125rem;
  position: absolute;
  text-align: center;
  width: 100%; }

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
  display: inline; }

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
  width: 100%; }

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .wp-block-gallery.is-cropped .blocks-gallery-item img {
    -o-object-fit: cover;
    flex: 1;
    height: 100%;
    object-fit: cover; } }

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  width: calc(50% - 0.5rem); }

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0; }

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
  margin-right: 0;
  width: 100%; }

@media only screen and (max-width: 64rem) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
    max-width: 100% !important; }
  .alignwide .alignleft img {
    width: 100%; }
  .alignwide .alignleft {
    margin: 0.3125rem 0rem 1.563rem 0;
    float: none; } }

@media (min-width: 37.5rem) {
  .wp-block-gallery.columns-3 .blocks-gallery-image,
  .wp-block-gallery.columns-3 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(33.33333% - 0.666rem); }
  .wp-block-gallery.columns-4 .blocks-gallery-image,
  .wp-block-gallery.columns-4 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(25% - 0.75rem); }
  .wp-block-gallery.columns-5 .blocks-gallery-image,
  .wp-block-gallery.columns-5 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(20% - 0.8rem); }
  .wp-block-gallery.columns-6 .blocks-gallery-image,
  .wp-block-gallery.columns-6 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(16.66667% - 0.833rem); }
  .wp-block-gallery.columns-7 .blocks-gallery-image,
  .wp-block-gallery.columns-7 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(14.28571% - 0.857rem); }
  .wp-block-gallery.columns-8 .blocks-gallery-image,
  .wp-block-gallery.columns-8 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(12.5% - 0.875rem); }
  .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
  .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
  .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
  .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
  .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
  .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
  .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
  .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0; } }

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
  margin-right: 0; }

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
  width: 100%; }

.wp-block-image.alignfullwide img {
  border-radius: 0; }

.wp-block-image img {
  border-radius: 0.625rem; }

.wp-block-cover,
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
  margin-bottom: 1.875rem;
  margin-top: 1.875rem; }

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  max-width: 19.0625rem;
  width: 100%; }

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  display: flex; }

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
  justify-content: center; }

.dz-page-text.sidebar .alignfull,
.dz-page-text.sidebar .alignfullwide,
.dz-page-text.sidebar .alignwide,
.dz-page-text.sidebar .wp-block-gallery,
.blog-single.dz-card.sidebar .alignfullwide,
.blog-single.dz-card.sidebar .alignwide,
.blog-single.dz-card.sidebar .dz-post-text .wp-block-gallery {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  max-width: initial; }

.blog-overlap {
  background: #fff;
  margin-top: -8.75rem;
  padding: 1.25rem 1.25rem 0;
  border-radius: 0.25rem 0.25rem 0rem 0rem; }

.blog-single.dz-card.sidebar {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0; }

.dz-post-text table,
.dz-page-text table,
.wp-block-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  font-size: 1rem;
  margin-bottom: 1.875rem; }

.wp-block-image figcaption,
.dz-post-text figcaption,
.dz-page-text figcaption {
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  margin-top: 0.625rem; }

.dz-post-text table tbody tr:nth-of-type(odd),
.dz-page-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
  background-color: #fafafa; }

.dz-post-text td,
.dz-post-text th,
.dz-page-text td,
.dz-page-text th,
.wp-block-table td,
.wp-block-table th {
  padding: 0.625rem 0.938rem;
  border: 0.0625rem solid #e4e4e4;
  border-right: 0;
  border-left: 0; }

.wp-block-media-text {
  margin-bottom: 30px; }
  .wp-block-media-text .wp-block-media-text__content {
    padding: 0 1.875rem; }

.wp-block-column ol li:before,
.wp-block-column ul li:before {
  content: none !important; }

.wp-block-pullquote {
  padding: 1em 0;
  border-top: 0;
  border-bottom: 0; }
  .wp-block-pullquote cite {
    color: #fff; }
  .wp-block-pullquote.alignright, .wp-block-pullquote.alignleft {
    padding: 0;
    border-top: 0;
    border-bottom: 0; }
    .wp-block-pullquote.alignright blockquote, .wp-block-pullquote.alignleft blockquote {
      margin: 0; }

.dz-post-text .wp-block-cover-image-text,
.dz-post-text .wp-block-cover-text,
.dz-post-text section.wp-block-cover-image h2,
.dz-post-text section.wp-block-cover-image .h2,
.dz-page-text .wp-block-cover-image-text,
.dz-page-text .wp-block-cover-text,
.dz-page-text section.wp-block-cover-image h2,
.dz-page-text section.wp-block-cover-image .h2 {
  color: #fff; }

.dz-post-text .bootstrap-select .dropdown-menu li a,
.dz-page-text .bootstrap-select .dropdown-menu li a {
  box-shadow: none;
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif; }

.dz-post-text .wp-block-calendar a {
  box-shadow: none !important;
  text-decoration: unset; }

.wp-block-search,
.wp-block-archives,
.wp-block-latest-posts,
.wp-block-latest-comments,
.wp-block-categories,
.wp-block-calendar {
  margin-bottom: 2.5rem; }

/* Admin Bar */
.admin-bar .is-fixed .main-bar {
  top: 1.875rem; }

.wp-block-quote.is-style-large cite:before {
  display: none; }

/* Theme Unit Test */
.post-password-form label {
  font-size: 1rem; }

.post-password-form input[type="submit"] {
  padding: 0.625rem 1.563rem;
  background-color: var(--primary);
  border: none;
  height: 2.8125rem;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none; }

.post-password-form input[type="submit"]:hover {
  background-color: var(--primary); }

.post-password-form input[type="password"] {
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da; }

.wp-block-search .wp-block-search__button {
  background: var(--primary);
  border: 0;
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.625rem 1.563rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }

.wp-block-search .wp-block-search__input {
  border: 0.0625rem solid #e1e6eb;
  height: 2.8125rem;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  outline: none; }

.wp-block-tag-cloud a {
  padding: 0.5rem 0.625rem;
  background: var(--primary);
  font-size: 0.75rem;
  display: inline-block;
  margin: 0 0 0.3125rem;
  color: #fff;
  font-weight: 600;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  text-decoration: none;
  box-shadow: none; }
  .wp-block-tag-cloud a:hover {
    background: var(--primary-hover); }

.wp-block-latest-comments {
  padding: 0 !important; }
  .wp-block-latest-comments .avatar {
    width: 3.4375rem;
    height: 3.4375rem;
    border-radius: 3.4375rem; }
  .wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt {
    margin-left: 4.375rem; }
  .wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
    color: var(--title);
    font-family: 'Poppins', sans-serif;
    font-size: 1.063rem;
    font-weight: 500;
    line-height: 1.7;
    margin-bottom: 0.625rem; }
    .wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta a {
      color: var(--title);
      box-shadow: none;
      text-decoration: none; }
  .wp-block-latest-comments img + article .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
    margin-left: 4.375rem; }
  .wp-block-latest-comments .wp-block-latest-comments__comment-meta time {
    display: block;
    width: 100%;
    color: #9fa1a4;
    font-size: 0.813rem;
    font-weight: 400; }
  .wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
    font-size: 1rem !important;
    line-height: 1.5 !important;
    margin-bottom: 0.625rem !important; }
  .wp-block-latest-comments li {
    padding: 0 !important;
    border-bottom: 0.0625rem solid #eee;
    padding-bottom: 0.625rem !important; }
    .wp-block-latest-comments li:before {
      content: none !important; }

.wp-block-latest-comments__comment-avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem; }

.comment-content.dz-page-text {
  max-width: 100%; }

.wp-block-rss {
  padding: 0 !important; }
  .wp-block-rss .wp-block-rss__item {
    padding: 0.3125rem 0 !important;
    border-bottom: 0.0625rem solid #eee; }
    .wp-block-rss .wp-block-rss__item:before {
      content: none !important; }
    .wp-block-rss .wp-block-rss__item a {
      font-family: 'Poppins', sans-serif;
      font-size: 1.125rem;
      box-shadow: unset !important;
      font-weight: 600;
      color: var(--title);
      text-decoration: none; }

@media only screen and (max-width: 75rem) {
  .dz-page-text .wp-block-gallery,
  .dz-card.blog-single .dz-post-text .wp-block-gallery {
    margin-left: calc(-6.25rem - (0.625rem/2));
    margin-right: calc(-6.25rem - (0.625rem/2));
    width: calc(100% + 12.5rem + 0.625rem); } }

@media only screen and (max-width: 61.9375rem) {
  .admin-bar .mo-left .header-nav {
    top: 1.875rem;
    height: calc(100vh - 1.875rem) !important; }
  .dz-page-text .wp-block-gallery,
  .dz-card.blog-single .dz-post-text .wp-block-gallery {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  .dz-page-text,
  .dz-page-text ul li,
  .dz-page-text ol li,
  .dz-page-text p,
  .dz-card.blog-single .dz-post-text,
  .dz-card.blog-single .dz-post-text ul li,
  .dz-card.blog-single .dz-post-text ol li,
  .dz-card.blog-single .dz-post-text p {
    font-size: 1rem; } }

@media only screen and (max-width: 48.875rem) {
  .admin-bar .is-fixed .main-bar {
    top: 2.8125rem; }
  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important; }
  .wp-block-media-text {
    display: block; }
  .wp-block-media-text .wp-block-media-text__media {
    margin-bottom: 0.938rem; }
  .wp-block-media-text .wp-block-media-text__content {
    padding: 0; } }

@media only screen and (max-width: 37.5rem) {
  .admin-bar .is-fixed .main-bar {
    top: 0; }
  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important; }
  .admin-bar .mo-left .is-fixed .header-nav {
    top: 0;
    height: 100vh !important; } }

.post-footer {
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.938rem 0 0; }
  .post-footer .dz-meta .tag-list {
    padding-bottom: 0;
    text-align: left;
    margin: 0;
    padding: 0;
    list-style: none;
    text-transform: capitalize;
    display: inline; }
  .post-footer .dz-meta ul li {
    margin-right: 0.3125rem;
    padding: 0;
    display: inline-block;
    color: #333333;
    font-weight: 500;
    font-size: 0.938rem;
    font-style: italic; }
  .post-footer .post-tag a {
    text-transform: capitalize;
    font-size: 0.938rem;
    color: #999;
    background: transparent;
    padding: 0;
    border: 0;
    line-height: 0.875rem;
    font-weight: 500; }

.share-post li {
  display: inline-block; }

.share-post a {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 2.1875rem;
  border: 0.0625rem solid #eee;
  display: block;
  text-align: center;
  line-height: 2.0625rem;
  color: #181c32; }

.extra-blog {
  margin-bottom: 30px; }

@media only screen and (max-width: 575px) {
  .comment-respond.style-1 .comment-reply-title {
    margin-bottom: 20px; } }

.default-form.comment-respond .comment-reply-title,
.comments-area .comments-title,
.extra-blog .blog-title {
  font-size: 23px;
  font-weight: 800; }

.comments-area .comment-list {
  margin-bottom: 60px;
  padding: 0; }
  @media only screen and (max-width: 767px) {
    .comments-area .comment-list {
      margin-bottom: 40px; } }
  .comments-area .comment-list .default-form {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
    .comments-area .comment-list .default-form small, .comments-area .comment-list .default-form .small {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1px; }
      .comments-area .comment-list .default-form small a, .comments-area .comment-list .default-form .small a {
        color: #e10000; }
  .comments-area .comment-list .dz-page-text {
    padding-bottom: 0 !important; }
  .comments-area .comment-list > .comment .comment-body {
    position: relative;
    padding: 0 0 25px 115px;
    margin-bottom: 30px;
    min-height: 125px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 767px) {
      .comments-area .comment-list > .comment .comment-body {
        padding: 0 0 25px 75px; } }
    @media only screen and (max-width: 575px) {
      .comments-area .comment-list > .comment .comment-body {
        padding: 0 0 20px 75px; } }
    .comments-area .comment-list > .comment .comment-body .comment-author .avatar {
      position: absolute;
      left: 0;
      height: 95px;
      width: 95px;
      border-radius: var(--border-radius-sm); }
      @media only screen and (max-width: 767px) {
        .comments-area .comment-list > .comment .comment-body .comment-author .avatar {
          height: 60px;
          width: 60px; } }
    .comments-area .comment-list > .comment .comment-body .comment-author .fn {
      font-size: 20px;
      line-height: 18px;
      color: var(--title);
      font-weight: 600;
      font-style: normal;
      margin-bottom: 15px;
      display: block;
      padding-top: 10px;
      font-family: var(--font-family-title); }
      @media only screen and (max-width: 767px) {
        .comments-area .comment-list > .comment .comment-body .comment-author .fn {
          padding-top: 0;
          margin-bottom: 10px; } }
    .comments-area .comment-list > .comment .comment-body p {
      font-size: 16px;
      margin-bottom: 10px; }
      @media only screen and (max-width: 767px) {
        .comments-area .comment-list > .comment .comment-body p {
          margin-bottom: 12px; } }
    .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link {
      font-weight: 500;
      text-transform: uppercase;
      font-size: 12px;
      background: var(--primary);
      color: #fff;
      padding: 6px 10px 4px 10px;
      display: inline-block;
      line-height: 1.3;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      position: absolute;
      right: 0;
      top: 0; }
      .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link:hover {
        background: var(--primary-hover); }
      .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link i {
        margin-right: 8px;
        font-size: 14px; }
      @media only screen and (max-width: 767px) {
        .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link {
          position: unset; } }
  .comments-area .comment-list > .comment:last-child {
    border-bottom: 0;
    margin-bottom: 0; }
  .comments-area .comment-list > .comment > .children {
    padding-left: 50px; }
  .comments-area .comment-list .comment-form {
    margin-bottom: 40px; }

.comment-reply-title a {
  font-size: 16px;
  font-weight: 600; }

.comment-respond small, .comment-respond .small {
  font-size: 14px;
  margin-left: 7px; }
  .comment-respond small a, .comment-respond .small a {
    color: #ef060f; }

.comment-respond .comment-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px; }
  .comment-respond .comment-form p {
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 50%; }
    .comment-respond .comment-form p label {
      display: none; }
    .comment-respond .comment-form p.comment-form-author input,
    .comment-respond .comment-form p.comment-form-email input,
    .comment-respond .comment-form p.comment-form-url input,
    .comment-respond .comment-form p textarea {
      height: 60px;
      border: 1px solid;
      border-color: #f3f4f6;
      padding: 15px 20px;
      font-size: 15px;
      font-weight: 400;
      color: #555;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      background: #f3f4f6;
      width: 100%;
      border-radius: var(--border-radius); }
      .comment-respond .comment-form p.comment-form-author input:focus, .comment-respond .comment-form p.comment-form-author input:active,
      .comment-respond .comment-form p.comment-form-email input:focus,
      .comment-respond .comment-form p.comment-form-email input:active,
      .comment-respond .comment-form p.comment-form-url input:focus,
      .comment-respond .comment-form p.comment-form-url input:active,
      .comment-respond .comment-form p textarea:focus,
      .comment-respond .comment-form p textarea:active {
        background: #fff;
        border-color: var(--primary); }
    .comment-respond .comment-form p.form-submit {
      width: 100%; }
    .comment-respond .comment-form p textarea {
      height: 120px !important; }
    .comment-respond .comment-form p.comment-form-url {
      width: 100%; }
  .comment-respond .comment-form .comment-form-comment {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }

@media only screen and (max-width: 575px) {
  .comment-respond .comment-form .comment-form-author,
  .comment-respond .comment-form .comment-form-email,
  .comment-respond .comment-form .comment-form-url {
    width: 100% !important; } }

.dz-page-text [class*="galleryid-"],
.dz-post-text [class*="galleryid-"] {
  clear: both;
  margin: 0 auto;
  overflow: hidden; }

.gallery .gallery-item {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 0.3125rem;
  position: relative;
  box-sizing: border-box; }
  .gallery .gallery-item img {
    float: left;
    padding: 0 0rem;
    width: 100%;
    border: none !important; }

.gallery .gallery-caption {
  font-size: 0.813rem;
  color: #707070;
  display: block;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5;
  padding: 0.5em 0;
  clear: both; }

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none; }

.gallery-columns-2 .gallery-item {
  max-width: 50%; }

.gallery-columns-3 .gallery-item {
  max-width: 33.33%; }

.gallery-columns-4 .gallery-item {
  max-width: 25%; }

.gallery-columns-5 .gallery-item {
  max-width: 20%; }

.gallery-columns-6 .gallery-item {
  max-width: 16.66%; }

.gallery-columns-7 .gallery-item {
  max-width: 14.28%; }

.gallery-columns-8 .gallery-item {
  max-width: 12.5%; }

.gallery-columns-9 .gallery-item {
  max-width: 11.11%; }

.gallery-icon img {
  margin: 0 auto; }

.post-password-form {
  position: relative;
  clear: both; }
  .post-password-form label {
    display: block;
    font-size: 1rem; }
  .post-password-form input[type="password"] {
    width: 100%;
    border: 0.0625rem solid #ebedf2;
    padding: 0.625rem 6.25rem 0.625rem 0.938rem;
    height: 2.8125rem;
    border: 0.0625rem solid #ced4da; }
  .post-password-form input[type="submit"] {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.625rem 1.25rem;
    background: var(--primary);
    color: #FFF;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.875rem;
    outline: none;
    height: 2.8125rem; }
    .post-password-form input[type="submit"]:hover {
      background: var(--primary-hover); }

@media only screen and (max-width: 991px) {
  .side-bar.sticky-top {
    position: unset; } }

.blog-post-banner {
  min-height: 300px;
  padding-top: 70px;
  padding-bottom: 70px; }
  .blog-post-banner .dz-title {
    color: #fff;
    font-size: 32px;
    margin-bottom: 20px; }
  .blog-post-banner .dz-meta {
    color: #fff; }
    .blog-post-banner .dz-meta ul {
      display: flex;
      opacity: 0.7; }
      .blog-post-banner .dz-meta ul li {
        margin-right: 20px; }
        .blog-post-banner .dz-meta ul li a {
          color: #fff; }

.post-outside {
  margin-top: -120px; }

.single-post .main-bar {
  border-bottom: 1px solid #eee; }

.dz-load-more i:before,
.loadmore-btn i:before {
  -webkit-animation: unset !important;
  -moz-animation: unset !important;
  animation: unset !important; }

.dz-share-post {
  border-top: 1px solid #cccccc;
  border-top-style: dashed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0; }
  .dz-share-post .post-tags a {
    border: 2px solid;
    padding: 10px 20px;
    display: inline-block;
    margin: 0 5px 10px;
    font-size: 14px;
    color: inherit;
    line-height: 1.4;
    border-color: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
    .dz-share-post .post-tags a:hover {
      background-color: var(--primary-hover);
      border-color: var(--primary-hover);
      color: #fff; }
  @media only screen and (max-width: 767px) {
    .dz-share-post {
      display: block; }
      .dz-share-post .post-tags {
        margin-top: 20px; } }
  @media only screen and (max-width: 575px) {
    .dz-share-post .post-tags a {
      margin-right: 5px; } }

#comment-list:empty + .paginate-links {
  margin-top: -30px; }

.post-swiper {
  position: relative; }
  .post-swiper .prev-post-swiper-btn,
  .post-swiper .next-post-swiper-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 40px;
    width: 40px;
    border-radius: 50px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    background-color: #fff;
    color: var(--primary);
    z-index: 1;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
    .post-swiper .prev-post-swiper-btn:hover,
    .post-swiper .next-post-swiper-btn:hover {
      background-color: var(--primary);
      color: #fff; }
  .post-swiper .prev-post-swiper-btn {
    left: 10px; }
  .post-swiper .next-post-swiper-btn {
    right: 10px; }

.author-box {
  padding: 35px;
  background: #f3f4f6;
  border-radius: var(--border-radius); }
  .author-box .author-profile-info {
    display: flex; }
    @media only screen and (max-width: 575px) {
      .author-box .author-profile-info {
        display: block;
        text-align: center; } }
    .author-box .author-profile-info .author-profile-pic {
      width: 120px;
      height: 120px;
      overflow: hidden;
      min-width: 120px; }
      @media only screen and (max-width: 575px) {
        .author-box .author-profile-info .author-profile-pic {
          width: 80px;
          height: 80px;
          min-width: 80px;
          margin-bottom: 20px;
          margin-left: auto;
          margin-right: auto; } }
      .author-box .author-profile-info .author-profile-pic img {
        width: 100%; }
    .author-box .author-profile-info .author-profile-content {
      padding-left: 25px; }
      @media only screen and (max-width: 575px) {
        .author-box .author-profile-info .author-profile-content {
          padding-left: 0;
          padding-right: 0; } }
      .author-box .author-profile-info .author-profile-content p {
        margin-bottom: 25px; }
      .author-box .author-profile-info .author-profile-content ul {
        padding: 0;
        margin: 0;
        margin-right: 5px;
        display: table;
        float: left; }
        @media only screen and (max-width: 575px) {
          .author-box .author-profile-info .author-profile-content ul {
            display: inline-block;
            float: none;
            margin-right: 0; } }
        .author-box .author-profile-info .author-profile-content ul li {
          padding: 0;
          margin: 0;
          margin-right: 10px;
          float: left; }
          @media only screen and (max-width: 575px) {
            .author-box .author-profile-info .author-profile-content ul li {
              margin-right: 5px;
              margin-left: 5px; } }
          .author-box .author-profile-info .author-profile-content ul li a {
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            background: var(--primary);
            color: #fff;
            vertical-align: middle;
            display: block;
            -webkit-transition: all 0.8s;
            -ms-transition: all 0.8s;
            transition: all 0.8s;
            border-radius: var(--border-radius-sm); }
            .author-box .author-profile-info .author-profile-content ul li a:hover {
              background: var(--primary-hover); }

.error-page {
  background-color: #000;
  height: 100vh;
  padding: 50px;
  position: relative; }
  .error-page .error-inner {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 600px;
    transform: translate(-50%, -50%);
    padding: 20px; }
    @media only screen and (max-width: 575px) {
      .error-page .error-inner {
        width: 100%; } }
  .error-page .error-head {
    color: #fff;
    margin-bottom: 40px; }
    @media only screen and (max-width: 575px) {
      .error-page .error-head {
        font-size: 20px;
        margin-bottom: 30px; } }
  .error-page:after {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #f00;
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: multiply; }
  .error-page .dz_error {
    position: relative;
    color: #fff;
    font-size: 200px;
    letter-spacing: 28px;
    animation: dzError 1s infinite linear alternate-reverse;
    font-weight: 900;
    line-height: 200px;
    margin: auto; }
    @media only screen and (max-width: 991px) {
      .error-page .dz_error {
        font-size: 150px;
        line-height: 150px; } }
    @media only screen and (max-width: 575px) {
      .error-page .dz_error {
        font-size: 80px;
        line-height: 80px;
        letter-spacing: 5px; } }
    .error-page .dz_error::before, .error-page .dz_error::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%; }
    .error-page .dz_error::before {
      left: 2px;
      text-shadow: -2px 0 #d700a3;
      clip: rect(44px, 450px, 56px, 0);
      animation: dzError1 5s infinite linear alternate-reverse; }
    .error-page .dz_error::after {
      left: -2px;
      text-shadow: -2px 0 #d700a3, 2px 2px #d700a3;
      clip: rect(44px, 450px, 56px, 0);
      animation: dzError2 5s infinite linear alternate-reverse; }

@keyframes dzError1 {
  0% {
    clip: rect(144px, 9999px, 351px, 0);
    transform: skew(0.338deg); }
  2.5% {
    clip: rect(44px, 9999px, 116px, 0);
    transform: skew(0.13deg); }
  5% {
    clip: rect(104px, 9999px, 384px, 0);
    transform: skew(0.24deg); }
  7.5% {
    clip: rect(190px, 9999px, 392px, 0);
    transform: skew(0.5deg); }
  10% {
    clip: rect(249px, 9999px, 293px, 0);
    transform: skew(0.246deg); }
  12.5% {
    clip: rect(263px, 9999px, 373px, 0);
    transform: skew(0.768deg); }
  15% {
    clip: rect(444px, 9999px, 321px, 0);
    transform: skew(0.582deg); }
  17.5% {
    clip: rect(261px, 9999px, 48px, 0);
    transform: skew(0.134deg); }
  20% {
    clip: rect(5px, 9999px, 160px, 0);
    transform: skew(0.342deg); }
  22.5% {
    clip: rect(305px, 9999px, 478px, 0);
    transform: skew(0.178deg); }
  25% {
    clip: rect(313px, 9999px, 349px, 0);
    transform: skew(0.392deg); }
  27.5% {
    clip: rect(152px, 9999px, 352px, 0);
    transform: skew(0.362deg); }
  30% {
    clip: rect(231px, 9999px, 426px, 0);
    transform: skew(0.454deg); }
  32.5% {
    clip: rect(1px, 9999px, 338px, 0);
    transform: skew(0.108deg); }
  35% {
    clip: rect(36px, 9999px, 156px, 0);
    transform: skew(0.114deg); }
  37.5% {
    clip: rect(151px, 9999px, 425px, 0);
    transform: skew(0.584deg); }
  40% {
    clip: rect(88px, 9999px, 115px, 0);
    transform: skew(0.05deg); }
  42.5% {
    clip: rect(438px, 9999px, 278px, 0);
    transform: skew(0.37deg); }
  45% {
    clip: rect(55px, 9999px, 334px, 0);
    transform: skew(0.654deg); }
  47.5% {
    clip: rect(400px, 9999px, 203px, 0);
    transform: skew(0.91deg); }
  50% {
    clip: rect(77px, 9999px, 170px, 0);
    transform: skew(0.42deg); }
  52.5% {
    clip: rect(144px, 9999px, 456px, 0);
    transform: skew(0.022deg); }
  55% {
    clip: rect(321px, 9999px, 106px, 0);
    transform: skew(0.096deg); }
  57.5% {
    clip: rect(5px, 9999px, 496px, 0);
    transform: skew(0.598deg); }
  60% {
    clip: rect(8px, 9999px, 337px, 0);
    transform: skew(0.624deg); }
  62.5% {
    clip: rect(303px, 9999px, 130px, 0);
    transform: skew(0.44deg); }
  65% {
    clip: rect(420px, 9999px, 287px, 0);
    transform: skew(0.056deg); }
  67.5% {
    clip: rect(79px, 9999px, 267px, 0);
    transform: skew(0.76deg); }
  70% {
    clip: rect(246px, 9999px, 378px, 0);
    transform: skew(0.226deg); }
  72.5% {
    clip: rect(34px, 9999px, 111px, 0);
    transform: skew(0.382deg); }
  75% {
    clip: rect(374px, 9999px, 330px, 0);
    transform: skew(0.524deg); }
  77.5% {
    clip: rect(138px, 9999px, 311px, 0);
    transform: skew(0.934deg); }
  80% {
    clip: rect(480px, 9999px, 191px, 0);
    transform: skew(0.954deg); }
  82.5% {
    clip: rect(489px, 9999px, 162px, 0);
    transform: skew(0.506deg); }
  85% {
    clip: rect(211px, 9999px, 163px, 0);
    transform: skew(0.548deg); }
  87.5% {
    clip: rect(267px, 9999px, 62px, 0);
    transform: skew(0.726deg); }
  90% {
    clip: rect(403px, 9999px, 310px, 0);
    transform: skew(0.632deg); }
  92.5% {
    clip: rect(206px, 9999px, 288px, 0);
    transform: skew(0.422deg); }
  95% {
    clip: rect(192px, 9999px, 70px, 0);
    transform: skew(0.316deg); }
  97.5% {
    clip: rect(38px, 9999px, 391px, 0);
    transform: skew(0.736deg); } }

@keyframes dzError2 {
  0% {
    clip: rect(203px, 9999px, 479px, 0);
    transform: skew(0.344deg); }
  2.5% {
    clip: rect(443px, 9999px, 87px, 0);
    transform: skew(0.866deg); }
  5% {
    clip: rect(172px, 9999px, 456px, 0);
    transform: skew(0.278deg); }
  7.5% {
    clip: rect(333px, 9999px, 173px, 0);
    transform: skew(0.528deg); }
  10% {
    clip: rect(311px, 9999px, 155px, 0);
    transform: skew(0.72deg); }
  12.5% {
    clip: rect(300px, 9999px, 320px, 0);
    transform: skew(0.748deg); }
  15% {
    clip: rect(32px, 9999px, 270px, 0);
    transform: skew(0.168deg); }
  17.5% {
    clip: rect(405px, 9999px, 87px, 0);
    transform: skew(0.576deg); }
  20% {
    clip: rect(208px, 9999px, 53px, 0);
    transform: skew(0.718deg); }
  22.5% {
    clip: rect(384px, 9999px, 116px, 0);
    transform: skew(0.536deg); }
  25% {
    clip: rect(103px, 9999px, 280px, 0);
    transform: skew(0.522deg); }
  27.5% {
    clip: rect(407px, 9999px, 297px, 0);
    transform: skew(0.682deg); }
  30% {
    clip: rect(491px, 9999px, 418px, 0);
    transform: skew(0.308deg); }
  32.5% {
    clip: rect(474px, 9999px, 495px, 0);
    transform: skew(0.058deg); }
  35% {
    clip: rect(72px, 9999px, 117px, 0);
    transform: skew(0.228deg); }
  37.5% {
    clip: rect(457px, 9999px, 357px, 0);
    transform: skew(0.262deg); }
  40% {
    clip: rect(436px, 9999px, 377px, 0);
    transform: skew(0.086deg); }
  42.5% {
    clip: rect(315px, 9999px, 372px, 0);
    transform: skew(0.016deg); }
  45% {
    clip: rect(249px, 9999px, 387px, 0);
    transform: skew(0.818deg); }
  47.5% {
    clip: rect(231px, 9999px, 450px, 0);
    transform: skew(0.45deg); }
  50% {
    clip: rect(492px, 9999px, 172px, 0);
    transform: skew(0.156deg); }
  52.5% {
    clip: rect(170px, 9999px, 153px, 0);
    transform: skew(0.752deg); }
  55% {
    clip: rect(170px, 9999px, 319px, 0);
    transform: skew(0.58deg); }
  57.5% {
    clip: rect(29px, 9999px, 139px, 0);
    transform: skew(0.952deg); }
  60% {
    clip: rect(446px, 9999px, 249px, 0);
    transform: skew(0.696deg); }
  62.5% {
    clip: rect(165px, 9999px, 458px, 0);
    transform: skew(0.172deg); }
  65% {
    clip: rect(436px, 9999px, 295px, 0);
    transform: skew(0.766deg); }
  67.5% {
    clip: rect(147px, 9999px, 313px, 0);
    transform: skew(0.474deg); }
  70% {
    clip: rect(388px, 9999px, 142px, 0);
    transform: skew(0.002deg); }
  72.5% {
    clip: rect(234px, 9999px, 473px, 0);
    transform: skew(0.734deg); }
  75% {
    clip: rect(496px, 9999px, 146px, 0);
    transform: skew(0.994deg); }
  77.5% {
    clip: rect(231px, 9999px, 284px, 0);
    transform: skew(0.788deg); }
  80% {
    clip: rect(36px, 9999px, 253px, 0);
    transform: skew(0.33deg); }
  82.5% {
    clip: rect(485px, 9999px, 313px, 0);
    transform: skew(0.386deg); }
  85% {
    clip: rect(99px, 9999px, 415px, 0);
    transform: skew(0.512deg); }
  87.5% {
    clip: rect(352px, 9999px, 403px, 0);
    transform: skew(0.68deg); }
  90% {
    clip: rect(45px, 9999px, 170px, 0);
    transform: skew(0.178deg); }
  92.5% {
    clip: rect(251px, 9999px, 212px, 0);
    transform: skew(0.968deg); }
  95% {
    clip: rect(34px, 9999px, 207px, 0);
    transform: skew(0.298deg); }
  97.5% {
    clip: rect(107px, 9999px, 104px, 0);
    transform: skew(0.954deg); } }

@keyframes dzError {
  0% {
    transform: skew(5deg); }
  10% {
    transform: skew(-4deg); }
  20% {
    transform: skew(3deg); }
  30% {
    transform: skew(5deg); }
  40% {
    transform: skew(3deg); }
  50% {
    transform: skew(-4deg); }
  60% {
    transform: skew(-1deg); }
  70% {
    transform: skew(1deg); }
  80% {
    transform: skew(0deg); }
  90% {
    transform: skew(-2deg); } }

.item-box .item-img {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: var(--border-radius-base); }
  .item-box .item-img img {
    width: 100%; }
  .item-box .item-img .item-info-in {
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%) translateY(70px);
    opacity: 0;
    bottom: 30px;
    width: 100%;
    text-align: center;
    padding: 0 20px;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
    .item-box .item-img .item-info-in li {
      display: inline-block;
      margin: 0 4px; }
      .item-box .item-img .item-info-in li a {
        display: inline-block;
        height: 40px;
        width: 40px;
        font-size: 21px;
        line-height: 46px;
        text-align: center;
        background-color: #fff;
        color: #000;
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s; }
        .item-box .item-img .item-info-in li a:hover {
          background: var(--secondary);
          color: #fff; }
  .item-box .item-img .badge {
    position: absolute;
    top: 30px;
    right: 0px;
    z-index: 1;
    padding: 6px 15px;
    font-size: 14px;
    border-radius: 20px 0 0 20px; }
  .item-box .item-img:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0) 62%, rgba(0, 0, 0, 0.7) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0) 62%, rgba(0, 0, 0, 0.7) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0) 62%, rgba(0, 0, 0, 0.7) 100%);
    opacity: 0;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }

.item-box .item-price {
  font-size: 22px;
  font-weight: 700; }
  .item-box .item-price del {
    opacity: .6; }

.item-box .item-info {
  padding: 15px 25px; }

.item-box .item-title {
  font-size: 20px; }

.item-box:hover .item-img .item-info-in {
  transform: translateX(-50%) translateY(0px);
  opacity: 1; }

.item-box:hover .item-img:after {
  opacity: 1; }

.item-review {
  margin-bottom: 5px; }
  .item-review li {
    display: inline-block; }
    .item-review li i {
      color: #f98e32;
      font-size: 14px; }

.shop-item-tage a {
  font-weight: 600;
  margin-left: 7px; }

.product-brand .search-content {
  padding: 7px 0 7px 25px;
  margin-bottom: 0; }
  .product-brand .search-content .form-check-input {
    border-radius: 0; }

.price-slide #slider-range {
  margin: 0 10px; }

.price-slide .amount {
  border: 0;
  padding-top: 20px;
  color: #777777; }

.price-slide .ui-slider-horizontal .ui-slider-range {
  background: var(--primary); }

.product-item-color input {
  display: none; }

.product-item-color .btn-check:checked + .btn:after, .product-item-color .btn-check:checked + .wp-block-button__link:after {
  opacity: 1; }

.product-item-color .btn, .product-item-color .wp-block-button__link {
  padding: 0;
  height: 35px;
  width: 35px;
  line-height: 35px;
  justify-content: center;
  border-radius: 35px !important;
  margin-right: 6px; }
  .product-item-color .btn:after, .product-item-color .wp-block-button__link:after {
    content: "\f058";
    font-family: 'Font Awesome 5 Free';
    color: #fff;
    font-size: 20px;
    opacity: 0; }

.product-size .btn, .product-size .wp-block-button__link {
  padding: 0;
  height: 45px;
  width: 45px;
  line-height: 45px;
  justify-content: center;
  border-radius: var(--border-radius-base) !important;
  background: #efefef;
  color: var(--primary);
  margin-right: 6px; }

.product-size .btn-check:checked + .btn, .product-size .btn-check:checked + .wp-block-button__link {
  background: var(--primary);
  color: #fff; }

.shop-item-rating {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right; }

.thumb-slider {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0 15px; }
  .thumb-slider .swiper-slide {
    height: auto; }
  @media only screen and (max-width: 991px) {
    .thumb-slider {
      padding-left: 0; } }

.dz-thum-bx .overlay-bx {
  opacity: 1;
  visibility: visible; }
  .dz-thum-bx .overlay-bx .overlay-icon {
    top: 20px;
    transform: translate(0px, 0px);
    height: auto;
    width: auto;
    right: 20px;
    left: auto;
    padding: 0; }
    .dz-thum-bx .overlay-bx .overlay-icon svg {
      width: 35px;
      height: 35px; }
      .dz-thum-bx .overlay-bx .overlay-icon svg path {
        fill: var(--primary); }

.single-image-slider .swiper-button-next,
.single-image-slider .swiper-button-prev,
.single-image-slider .swiper-button-next-related,
.single-image-slider .swiper-button-prev-related,
.related-item-swiper .swiper-button-next,
.related-item-swiper .swiper-button-prev,
.related-item-swiper .swiper-button-next-related,
.related-item-swiper .swiper-button-prev-related {
  height: 50px;
  width: 50px;
  line-height: 50px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1; }
  .single-image-slider .swiper-button-next.swiper-button-disabled,
  .single-image-slider .swiper-button-prev.swiper-button-disabled,
  .single-image-slider .swiper-button-next-related.swiper-button-disabled,
  .single-image-slider .swiper-button-prev-related.swiper-button-disabled,
  .related-item-swiper .swiper-button-next.swiper-button-disabled,
  .related-item-swiper .swiper-button-prev.swiper-button-disabled,
  .related-item-swiper .swiper-button-next-related.swiper-button-disabled,
  .related-item-swiper .swiper-button-prev-related.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none; }
  .single-image-slider .swiper-button-next:after,
  .single-image-slider .swiper-button-prev:after,
  .single-image-slider .swiper-button-next-related:after,
  .single-image-slider .swiper-button-prev-related:after,
  .related-item-swiper .swiper-button-next:after,
  .related-item-swiper .swiper-button-prev:after,
  .related-item-swiper .swiper-button-next-related:after,
  .related-item-swiper .swiper-button-prev-related:after {
    font-family: swiper-icons;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.single-image-slider .swiper-button-next,
.related-item-swiper .swiper-button-next {
  right: 10px; }
  .single-image-slider .swiper-button-next:after,
  .related-item-swiper .swiper-button-next:after {
    content: 'next'; }

.single-image-slider .swiper-button-prev,
.related-item-swiper .swiper-button-prev {
  left: 10px; }
  .single-image-slider .swiper-button-prev:after,
  .related-item-swiper .swiper-button-prev:after {
    content: 'prev'; }

.related-item-swiper .swiper-button-next-related,
.related-item-swiper .swiper-button-prev-related {
  position: absolute;
  top: 38%;
  transform: translateY(-50%);
  z-index: 1; }
  .related-item-swiper .swiper-button-next-related:after,
  .related-item-swiper .swiper-button-prev-related:after {
    font-family: swiper-icons;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .related-item-swiper .swiper-button-next-related.swiper-button-disabled,
  .related-item-swiper .swiper-button-prev-related.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none; }

.related-item-swiper .swiper-button-next-related {
  right: 10px; }
  .related-item-swiper .swiper-button-next-related:after {
    content: 'next'; }

.related-item-swiper .swiper-button-prev-related {
  left: 10px; }
  .related-item-swiper .swiper-button-prev-related:after {
    content: 'prev'; }

.thumb-slider-navigation {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35px;
  left: 0;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  z-index: 1; }
  .thumb-slider-navigation .swiper-button-next-thumb,
  .thumb-slider-navigation .swiper-button-prev-thumb {
    height: 35px;
    width: 48%;
    position: relative;
    background: rgba(0, 0, 0, 0.4);
    color: #fff; }
    .thumb-slider-navigation .swiper-button-next-thumb:after,
    .thumb-slider-navigation .swiper-button-prev-thumb:after {
      font-family: swiper-icons;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(90deg); }
  .thumb-slider-navigation .swiper-button-next-thumb:after {
    content: 'next'; }
  .thumb-slider-navigation .swiper-button-prev-thumb:after {
    content: 'prev'; }
  @media only screen and (max-width: 991px) {
    .thumb-slider-navigation {
      padding-left: 0; } }

.btn-quantity.style-1 {
  width: 130px;
  position: relative; }
  .btn-quantity.style-1 .btn, .btn-quantity.style-1 .wp-block-button__link {
    padding: 0;
    top: 0;
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0; }
  .btn-quantity.style-1 .btn-plus {
    right: 0; }
  .btn-quantity.style-1 .btn-minus {
    left: 0; }
  .btn-quantity.style-1 .input-group {
    position: relative;
    margin-bottom: 0; }
  .btn-quantity.style-1 input {
    background: #f8f8f8 none repeat scroll 0 0;
    border: 0 none;
    font-size: 14px;
    height: 40px;
    text-align: center;
    z-index: 0 !important;
    width: 100%; }
  .btn-quantity.style-1 .bootstrap-touchspin .input-group-btn-vertical {
    position: unset; }
  .btn-quantity.style-1 .input-group-btn-vertical button:first-child,
  .btn-quantity.style-1 .input-group-btn-vertical button:last-child {
    background: #ebebeb none repeat scroll 0 0;
    border: 0 none;
    position: absolute;
    height: 40px;
    justify-content: center;
    top: 0;
    z-index: 0;
    width: 40px;
    padding: 0;
    z-index: 2;
    margin: 0; }
  .btn-quantity.style-1 .input-group-btn-vertical button:first-child {
    right: 0; }
  .btn-quantity.style-1 .input-group-btn-vertical button:last-child {
    left: 0; }

.tabs-site-button .nav-tabs {
  border-top: 1px solid #dee2e6;
  justify-content: center;
  margin-bottom: 40px; }
  .tabs-site-button .nav-tabs a {
    display: inline-block;
    padding: 25px 40px;
    color: #000;
    font-size: 18px;
    position: relative; }
    .tabs-site-button .nav-tabs a:after {
      content: "";
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      background: var(--primary);
      height: 3px;
      position: absolute;
      -webkit-transition: all 0.2s;
      -ms-transition: all 0.2s;
      transition: all 0.2s; }
    .tabs-site-button .nav-tabs a.active:after {
      width: 100%; }
    @media only screen and (max-width: 767px) {
      .tabs-site-button .nav-tabs a {
        padding: 20px 20px;
        font-size: 16px; } }
  .tabs-site-button .nav-tabs .nav-link {
    color: #000;
    font-size: 18px;
    font-weight: 300;
    font-family: inherit;
    border: 0;
    cursor: pointer; }

.order-total-card {
  background: #f5f5f5;
  padding: 30px;
  border-radius: var(--border-radius-base);
  margin-bottom: 30px; }
  .order-total-card .table tr td, .order-total-card .table tr th {
    font-size: 18px; }

.payment-card {
  border-radius: var(--border-radius-base);
  background: #f5f5f5;
  padding: 30px; }
  .payment-card .form-check span {
    background: #fff;
    height: 0px;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    margin-bottom: 0px;
    padding: 0px 20px;
    overflow: hidden;
    display: block; }
  .payment-card .form-check > input:checked + label span {
    padding: 20px 20px;
    height: auto;
    margin-top: 16px;
    margin-bottom: 16px; }

.table.wishlist thead th,
.table.cart thead th {
  font-size: 22px;
  font-weight: 500;
  border-top: 1px solid;
  white-space: nowrap; }
  @media only screen and (max-width: 991px) {
    .table.wishlist thead th,
    .table.cart thead th {
      font-size: 18px; } }

.table.wishlist thead tr th:first-child,
.table.cart thead tr th:first-child {
  width: 110px;
  min-width: 90px; }

.table.wishlist tbody td,
.table.cart tbody td {
  vertical-align: middle;
  font-size: 18px;
  color: #000;
  padding: 20px 15px; }
  @media only screen and (max-width: 991px) {
    .table.wishlist tbody td,
    .table.cart tbody td {
      font-size: 16px; } }

.cart-coupon-wrapper {
  position: relative; }
  .cart-coupon-wrapper .cutter {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%); }
  .cart-coupon-wrapper .form-control, .cart-coupon-wrapper .wp-block-categories-dropdown select, .wp-block-categories-dropdown .cart-coupon-wrapper select,
  .cart-coupon-wrapper .wp-block-archives-dropdown select, .wp-block-archives-dropdown .cart-coupon-wrapper select {
    height: 52px;
    padding: 5px 60px 5px 60px;
    border-style: dotted; }
  .cart-coupon-wrapper .btn, .cart-coupon-wrapper .wp-block-button__link {
    position: absolute;
    height: 44px;
    width: 44px;
    background: var(--secondary);
    color: #fff;
    padding: 0;
    line-height: 45px;
    right: 4px;
    justify-content: center;
    border-radius: var(--border-radius-base);
    top: 4px; }
    .cart-coupon-wrapper .btn:hover i, .cart-coupon-wrapper .wp-block-button__link:hover i {
      -webkit-animation: leftToRight 0.5s forwards;
      -moz-animation: leftToRight 0.5s forwards;
      animation: leftToRight 0.5s forwards; }

.login-wrapper {
  max-width: 560px;
  margin: auto; }
  .login-wrapper h2, .login-wrapper .h2 {
    margin-bottom: 25px; }
  .login-wrapper .login-form {
    padding: 50px 50px;
    background: #f5f5f5; }
    .login-wrapper .login-form .form-control, .login-wrapper .login-form .wp-block-categories-dropdown select, .wp-block-categories-dropdown .login-wrapper .login-form select,
    .login-wrapper .login-form .wp-block-archives-dropdown select, .wp-block-archives-dropdown .login-wrapper .login-form select {
      border-color: #d5d5d5; }
    .login-wrapper .login-form .form-group {
      margin-bottom: 30px; }
    .login-wrapper .login-form label {
      margin-bottom: 15px; }
    @media only screen and (max-width: 575px) {
      .login-wrapper .login-form {
        padding: 30px 30px; } }

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary); }

.about-bx2 {
  min-height: calc(100vh - 150px); }
  .about-bx2 .dz-media {
    height: 100%; }
    .about-bx2 .dz-media img {
      height: 100%;
      object-fit: cover; }
    .about-bx2 .dz-media .info {
      position: absolute;
      bottom: 100px;
      left: 100px;
      z-index: 1; }
      .about-bx2 .dz-media .info .name {
        color: #fff;
        font-size: 40px;
        margin-bottom: 0; }
      .about-bx2 .dz-media .info p {
        color: rgba(255, 255, 255, 0.8);
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 40px; }
    .about-bx2 .dz-media:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff4917+0,000000+100&0+43,0.58+100 */
      background: -moz-linear-gradient(top, rgba(255, 73, 23, 0) 0%, rgba(145, 42, 13, 0) 43%, rgba(0, 0, 0, 0.58) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255, 73, 23, 0) 0%, rgba(145, 42, 13, 0) 43%, rgba(0, 0, 0, 0.58) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255, 73, 23, 0) 0%, rgba(145, 42, 13, 0) 43%, rgba(0, 0, 0, 0.58) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ff4917', endColorstr='#94000000',GradientType=0 );
      /* IE6-9 */ }
    .about-bx2 .dz-media:before {
      position: absolute;
      height: calc(100% - 60px);
      width: calc(100% - 60px);
      border-width: 10px;
      border-style: solid;
      border-radius: inherit;
      border-image: linear-gradient(var(--primary), rgba(0, 0, 0, 0)) 1 100;
      content: "";
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%); }
  .about-bx2 .dz-info {
    padding: 70px 70px; }
    .about-bx2 .dz-info .signature {
      width: 220px; }
  @media only screen and (max-width: 767px) {
    .about-bx2 .dz-media .info {
      bottom: 70px;
      left: 70px; }
      .about-bx2 .dz-media .info .name {
        font-size: 30px; }
      .about-bx2 .dz-media .info p {
        font-size: 18px;
        margin-bottom: 20px; } }
  @media only screen and (max-width: 575px) {
    .about-bx2 .dz-info {
      padding: 30px; }
    .about-bx2 .dz-media:before {
      height: calc(100% - 30px);
      width: calc(100% - 30px); }
    .about-bx2 .dz-media .info {
      bottom: 30px;
      left: 50px; }
      .about-bx2 .dz-media .info .name {
        font-size: 22px; }
      .about-bx2 .dz-media .info p {
        font-size: 16px;
        margin-bottom: 10px; } }

.dz-media-list img {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  object-fit: cover; }

.dz-media-list a:hover h4, .dz-media-list a:hover .h4, .dz-media-list a:hover .comment-reply-title,
.dz-media-list a:hover .wp-block-search .wp-block-search__label, .wp-block-search .dz-media-list a:hover .wp-block-search__label {
  color: var(--primary); }

.worker img {
  border-radius: var(--border-radius-base); }

.history-wrapper {
  position: relative; }
  .history-wrapper .history-wrapper-bx {
    position: relative;
    display: flex;
    flex-flow: wrap;
    padding-top: 150px;
    padding-bottom: 70px; }
    .history-wrapper .history-wrapper-bx .year {
      height: 100px;
      width: 100px;
      line-height: 100px;
      border-radius: 100px;
      border: 2px solid rgba(0, 0, 0, 0.18);
      border-style: dashed;
      box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.04), 0 0 0 16px rgba(0, 0, 0, 0.02);
      color: black;
      font-size: 26px;
      font-weight: 500;
      text-align: center;
      background: #fff;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%); }
    .history-wrapper .history-wrapper-bx .history-box {
      position: relative;
      width: 50%; }
      .history-wrapper .history-wrapper-bx .history-box:after {
        height: 10px;
        width: 10px;
        border-radius: 10px;
        border: 2px solid #000;
        content: "";
        position: absolute;
        background: #fff;
        z-index: 1; }
      .history-wrapper .history-wrapper-bx .history-box:before {
        position: absolute;
        content: "";
        transform: rotate(45deg);
        border-width: 15px;
        border-style: solid; }
      .history-wrapper .history-wrapper-bx .history-box .inner-content {
        background: #f5f5f5;
        padding: 30px 30px; }
      .history-wrapper .history-wrapper-bx .history-box:nth-child(even) {
        padding-right: 40px;
        padding-top: 40px;
        text-align: right; }
        .history-wrapper .history-wrapper-bx .history-box:nth-child(even):before {
          right: 25px;
          top: 60px;
          border-color: #f5f5f5 #f5f5f5 transparent transparent; }
        .history-wrapper .history-wrapper-bx .history-box:nth-child(even):after {
          right: -5px;
          top: 70px; }
      .history-wrapper .history-wrapper-bx .history-box:nth-child(odd) {
        padding-left: 40px;
        padding-bottom: 40px; }
        .history-wrapper .history-wrapper-bx .history-box:nth-child(odd):before {
          left: 25px;
          top: 20px;
          border-color: transparent transparent #f5f5f5 #f5f5f5; }
        .history-wrapper .history-wrapper-bx .history-box:nth-child(odd):after {
          left: -5px;
          top: 30px; }
  .history-wrapper:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    background: #000;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
  @media only screen and (max-width: 767px) {
    .history-wrapper .history-wrapper-bx {
      padding-left: 35px;
      padding-top: 100px;
      padding-bottom: 0; }
      .history-wrapper .history-wrapper-bx .history-box {
        padding-left: 40px;
        padding-bottom: 40px; }
        .history-wrapper .history-wrapper-bx .history-box:nth-child(even) {
          padding-right: 0;
          padding-top: 0;
          text-align: left; }
          .history-wrapper .history-wrapper-bx .history-box:nth-child(even):before {
            right: auto;
            left: 25px;
            top: 50px;
            border-color: transparent transparent #f5f5f5 #f5f5f5; }
          .history-wrapper .history-wrapper-bx .history-box:nth-child(even):after {
            right: auto;
            left: -5px;
            top: 60px; }
        .history-wrapper .history-wrapper-bx .history-box:nth-child(odd) {
          padding-left: 40px;
          padding-bottom: 40px; }
          .history-wrapper .history-wrapper-bx .history-box:nth-child(odd):before {
            left: 25px;
            top: 50px;
            border-color: transparent transparent #f5f5f5 #f5f5f5; }
          .history-wrapper .history-wrapper-bx .history-box:nth-child(odd):after {
            left: -5px;
            top: 60px; }
      .history-wrapper .history-wrapper-bx .year {
        height: 70px;
        width: 70px;
        line-height: 70px;
        border-radius: 70px;
        font-size: 18px;
        left: 0;
        transform: translateX(0px); }
      .history-wrapper .history-wrapper-bx .history-box {
        width: 100%; }
    .history-wrapper:after {
      left: 35px;
      transform: translateX(0px); } }
  @media only screen and (max-width: 575px) {
    .history-wrapper .history-wrapper-bx {
      padding-left: 0; }
      .history-wrapper .history-wrapper-bx .year {
        left: 50%;
        transform: translateX(-50%); }
    .history-wrapper:after {
      left: 0;
      height: calc(100% - 160px); } }

.dez-coming-soon {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  z-index: 1;
  background-color: var(--primary); }
  .dez-coming-soon .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    mix-blend-mode: multiply; }
  .dez-coming-soon .dzSubscribe {
    margin-top: 40px;
    margin-bottom: 70px;
    padding: 0 15px; }
    .dez-coming-soon .dzSubscribe p {
      font-size: 14px; }
    .dez-coming-soon .dzSubscribe .ft-row {
      position: relative; }
      .dez-coming-soon .dzSubscribe .ft-row .btn, .dez-coming-soon .dzSubscribe .ft-row .wp-block-button__link {
        position: absolute;
        padding: 0;
        height: 56px;
        width: 56px;
        line-height: 56px;
        border-radius: 56px;
        text-align: center;
        background: var(--primary);
        color: #fff;
        top: 5px;
        right: 5px; }
      .dez-coming-soon .dzSubscribe .ft-row .form-control, .dez-coming-soon .dzSubscribe .ft-row .wp-block-categories-dropdown select, .wp-block-categories-dropdown .dez-coming-soon .dzSubscribe .ft-row select,
      .dez-coming-soon .dzSubscribe .ft-row .wp-block-archives-dropdown select, .wp-block-archives-dropdown .dez-coming-soon .dzSubscribe .ft-row select {
        height: 66px;
        border-radius: 66px !important;
        padding: 10px 70px 10px 30px; }
    @media only screen and (max-width: 575px) {
      .dez-coming-soon .dzSubscribe {
        margin-top: 25px;
        margin-bottom: 30px; } }

.dez-coming-soon .container {
  position: relative; }

.dez-coming-bx {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  @media only screen and (max-width: 991px) {
    .dez-coming-bx {
      display: block;
      height: auto;
      padding-bottom: 30px; } }

.countdown-timer {
  padding-bottom: 30px; }

.clock-item {
  width: 155px;
  position: relative;
  margin: 0 10px; }

.countdown-timer .clock .text {
  position: absolute;
  top: 0;
  width: 100%; }

.countdown-timer .clock .type-time {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 0; }

.countdown-timer .clock .kineticjs-content {
  position: relative; }

.countdown-timer .clock .kineticjs-content:after {
  content: "";
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  z-index: -1; }

.countdown-timer .clock .val {
  font-size: 70px;
  color: #fff;
  width: 100%;
  line-height: 155px;
  text-align: center;
  margin-bottom: 15px; }

.countdown-timer .clock {
  display: flex; }

.bottom-left, .bottom-right, .posi-center, .top-left, .top-right {
  position: fixed;
  z-index: 2;
  display: block; }

.bottom-right {
  bottom: 60px;
  right: 60px; }

.bottom-left {
  bottom: 60px;
  left: 60px; }

.top-right {
  top: 60px;
  right: 60px; }

.top-left {
  top: 60px;
  left: 60px; }

.style-3 .dez-title {
  font-size: 60px;
  line-height: 70px;
  color: #011463;
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 800; }

.dez-social-icon {
  display: inline-block;
  margin: 0 0 10px 0;
  padding: 0;
  text-align: center; }
  .dez-social-icon li {
    display: inline-block; }
    .dez-social-icon li a {
      display: inline-block;
      width: 48px;
      height: 48px;
      line-height: 48px;
      border-radius: 48px;
      font-size: 18px;
      background: #fff;
      transition: all .5s;
      -moz-transition: all .5s;
      -webkit-transition: all .5s;
      -ms-transition: all .5s;
      -o-transition: all .5s; }

/* ===============================
	Coming Soon 3
================================ */
.style-3 .sy-box {
  background: #031322;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.style-3 .sy-pager {
  display: none; }

.style-3 .dez-title {
  color: #fff; }

.style-3 .dez-social-icon li a {
  color: var(--primary);
  margin-right: 5px; }

.style-3 .copyright-text {
  color: #fff; }

.style-3 .sy-slide > img {
  height: 120%;
  object-fit: cover;
  min-height: 120vh; }

@media only screen and (max-width: 1400px) {
  .bottom-left {
    bottom: 40px;
    left: 40px; }
  .bottom-right {
    bottom: 40px;
    right: 40px; }
  .top-left {
    top: 40px;
    left: 40px; }
  .top-right {
    top: 40px;
    right: 40px; }
  .clock-item {
    width: 130px; }
  .countdown-timer .clock .val {
    font-size: 50px;
    line-height: 130px; } }

@media only screen and (max-width: 991px) {
  .posi-center,
  .center-md,
  .posi-center,
  .bottom-right,
  .bottom-left,
  .top-right,
  .top-left {
    position: unset; }
  .style-3 .top-left,
  .style-3 .bottom-left {
    width: 100%;
    text-align: center; }
  .style-3 .bottom-left {
    padding-bottom: 30px; }
  .style-3 .countdown-timer {
    margin-bottom: 35px; }
  .style-3 .dez-social-icon li a {
    margin-left: 3px;
    margin-right: 3px; }
  .style-3 .center-md .bottom-right {
    position: unset; }
  .countdown-timer .clock {
    justify-content: center; }
  .style-3.raincanvas .dez-coming-bx {
    position: relative;
    z-index: 2; }
  .style-3 .dez-title {
    font-size: 40px;
    line-height: 55px; }
  .clock-item {
    width: 120px; }
  .style-3 .center-md {
    min-height: calc(100vh - 115px);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 0; } }

@media only screen and (max-width: 576px) {
  .clock-item {
    width: 70px; }
  .countdown-timer .clock .val {
    line-height: 70px;
    font-size: 30px;
    margin-bottom: 5px;
    font-weight: 600; }
  .style-3 .dez-title {
    font-size: 28px;
    line-height: 36px; }
  .countdown-timer .clock .type-time {
    letter-spacing: 1px;
    font-size: 11px;
    line-height: 18px; } }

.contact-wraper1 {
  margin-bottom: 60px;
  position: relative;
  background-position: -260px center; }
  .contact-wraper1 .contact-info {
    padding: 80px 30px; }
  .contact-wraper1 .contact-area1 {
    margin-top: -180px;
    margin-bottom: -100px; }
  .contact-wraper1:before {
    content: "";
    height: 100%;
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background: -moz-linear-gradient(left, rgba(20, 20, 20, 0) 0%, #141414 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(20, 20, 20, 0) 0%, #141414 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(20, 20, 20, 0) 0%, #141414 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
  .contact-wraper1:after {
    content: "";
    height: 100%;
    width: 50%;
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1; }
  @media only screen and (max-width: 1199px) {
    .contact-wraper1 .contact-info {
      padding: 50px 0px; } }
  @media only screen and (max-width: 991px) {
    .contact-wraper1 {
      background-position: center;
      background-size: cover;
      margin-bottom: 0; }
      .contact-wraper1:after, .contact-wraper1:before {
        content: none; }
      .contact-wraper1 .contact-area1 {
        margin-top: 0;
        margin-bottom: 0; }
      .contact-wraper1 .contact-info {
        padding: 50px 0px 20px; } }

.app-form-wraper {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  padding: 50px;
  background-color: #fff;
  /* Form Wizard */
  /* Form group */
  /* Book Time */ }
  .app-form-wraper.card {
    background: transparent;
    box-shadow: none;
    border-radius: 0; }
    .app-form-wraper.card .card-body {
      border-radius: 0; }
    .app-form-wraper.card .card-footer {
      border-top: 0;
      border-radius: 0; }
  .app-form-wraper .dz-info li {
    display: inline-block;
    margin-right: 20px;
    font-size: 14px; }
    .app-form-wraper .dz-info li i {
      color: #ff5317;
      font-size: 18px;
      margin-right: 10px; }
  .app-form-wraper .dz-social li {
    font-size: 16px; }
    .app-form-wraper .dz-social li a {
      padding: 3px 8px; }
  .app-form-wraper .form-group {
    margin-bottom: 30px; }
  .app-form-wraper .form-wizard {
    border: 0; }
    .app-form-wraper .form-wizard > .nav {
      box-shadow: unset !important; }
      .app-form-wraper .form-wizard > .nav li .nav-link {
        padding: 12px 0;
        color: #000;
        margin: 0 5px; }
        .app-form-wraper .form-wizard > .nav li .nav-link .number {
          display: block;
          height: 50px;
          width: 50px;
          margin: 0 auto 15px;
          border-radius: 500%;
          background-color: var(--title);
          line-height: 50px;
          color: #fff;
          font-weight: 700;
          z-index: 2;
          position: relative;
          -webkit-transition: all 0.8s;
          -ms-transition: all 0.8s;
          transition: all 0.8s; }
        .app-form-wraper .form-wizard > .nav li .nav-link .title {
          font-family: var(--font-family-title);
          color: var(--title);
          font-weight: 500; }
        .app-form-wraper .form-wizard > .nav li .nav-link:after {
          height: 2px;
          width: 100%;
          left: 50%;
          background-color: var(--title) !important;
          top: 36px; }
        .app-form-wraper .form-wizard > .nav li .nav-link.active .number, .app-form-wraper .form-wizard > .nav li .nav-link.done .number {
          background-color: var(--primary);
          box-shadow: 0 0px 0 10px var(--rgba-primary-3); }
        .app-form-wraper .form-wizard > .nav li .nav-link.active:after, .app-form-wraper .form-wizard > .nav li .nav-link.done:after {
          background-color: var(--primary) !important; }
      .app-form-wraper .form-wizard > .nav li:last-child .nav-link:after {
        display: none; }
    .app-form-wraper .form-wizard .tab-content {
      margin: 30px 0 0;
      overflow: unset; }
  .app-form-wraper .form-group {
    margin-bottom: 30px; }
  .app-form-wraper .book-time {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px; }
    .app-form-wraper .book-time li {
      width: 25%;
      padding: 0 5px; }
      .app-form-wraper .book-time li .btn, .app-form-wraper .book-time li .wp-block-button__link {
        border-radius: 4px;
        background-color: #f5f5f5;
        color: #000;
        margin-bottom: 10px;
        display: block; }
        .app-form-wraper .book-time li .btn input, .app-form-wraper .book-time li .wp-block-button__link input {
          position: absolute;
          opacity: 0; }
        .app-form-wraper .book-time li .btn.active, .app-form-wraper .book-time li .active.wp-block-button__link {
          background-color: #ff5317;
          color: #fff; }
        .app-form-wraper .book-time li .btn.active-time, .app-form-wraper .book-time li .active-time.wp-block-button__link {
          background-color: #ff5317;
          font-weight: 700;
          color: #fff; }
      .app-form-wraper .book-time li input {
        display: none; }
      .app-form-wraper .book-time li input:checked ~ label {
        background-color: var(--primary) !important;
        color: #fff !important; }
  .app-form-wraper .check-date {
    margin: 0 -5px;
    flex-flow: wrap; }
    .app-form-wraper .check-date li {
      display: inline-block;
      width: 70px;
      text-align: center;
      padding: 5px 5px; }
    .app-form-wraper .check-date label {
      border: 0px;
      position: relative;
      padding: 10px 10px 35px 10px;
      text-transform: capitalize;
      background-color: var(--secondary);
      display: block;
      color: #fff;
      cursor: pointer; }
      .app-form-wraper .check-date label input {
        display: none; }
      .app-form-wraper .check-date label.active:after {
        background: #ff5317; }
      .app-form-wraper .check-date label:after {
        content: "\f00c";
        height: 25px;
        width: 25px;
        font-family: "Font Awesome 5 Free";
        background: var(--title);
        border-radius: 50px;
        position: absolute;
        bottom: 8px;
        color: #fff;
        left: 50%;
        transform: translateX(-50%);
        line-height: 25px;
        font-size: 10px;
        font-weight: 700; }
    .app-form-wraper .check-date .form-check {
      padding-left: 0;
      margin-bottom: 0; }
    .app-form-wraper .check-date .form-check-input {
      display: none; }
    .app-form-wraper .check-date input:checked ~ label {
      background-color: var(--primary); }
  .app-form-wraper .form-group label {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 16px; }
  .app-form-wraper .form-group .form-label {
    color: var(--title);
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 10px;
    display: block; }
  .app-form-wraper .sw-theme-default .toolbar > .btn, .app-form-wraper .sw-theme-default .toolbar > .wp-block-button__link {
    border-radius: 0;
    padding: 12px 30px;
    background-color: var(--primary);
    color: #fff;
    border: 0; }
    .app-form-wraper .sw-theme-default .toolbar > .btn:hover, .app-form-wraper .sw-theme-default .toolbar > .wp-block-button__link:hover {
      color: #fff; }
  .app-form-wraper .toolbar {
    padding: 0;
    text-align: center !important;
    margin-top: 20px; }
  .app-form-wraper .successful-box h2, .app-form-wraper .successful-box .h2 {
    font-size: 60px;
    line-height: 100px;
    margin-bottom: 0;
    font-weight: 500; }
  .app-form-wraper .successful-box .successful-check {
    width: 150px;
    height: 150px;
    border: 7px solid #00bb27;
    font-size: 70px;
    color: #00bb27;
    border-radius: 200px;
    line-height: 155px;
    margin: auto; }
  .app-form-wraper .dz-content-box {
    height: 100%;
    padding: 50px 50px 30px; }
  @media only screen and (max-width: 767px) {
    .app-form-wraper {
      padding: 30px; }
      .app-form-wraper .form-wizard > .nav {
        flex-direction: row !important; } }
  @media only screen and (max-width: 575px) {
    .app-form-wraper .form-wizard > .nav {
      flex-direction: column !important; }
    .app-form-wraper .book-time li {
      width: 50%; }
    .app-form-wraper .form-wizard > .nav {
      display: flex;
      justify-content: center;
      flex-direction: row !important; }
      .app-form-wraper .form-wizard > .nav li {
        width: 33.33%;
        flex-basis: unset; }
        .app-form-wraper .form-wizard > .nav li .nav-link::after {
          content: none; } }

.under-construct {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
  display: flex;
  background-color: #fff;
  overflow: hidden;
  padding-left: 0; }
  @media only screen and (max-width: 991px) {
    .under-construct {
      flex-wrap: wrap; } }
  .under-construct .made-by {
    font-weight: 600;
    color: #fff;
    text-decoration: underline; }
  .under-construct .dz-title {
    font-size: 60px;
    line-height: 1.2;
    color: #fff;
    margin-bottom: 20px;
    font-family: var(--font-family-title);
    letter-spacing: 10px; }
    @media only screen and (max-width: 1400px) {
      .under-construct .dz-title {
        font-size: 50px;
        line-height: 60px; } }
    @media only screen and (max-width: 1199px) {
      .under-construct .dz-title {
        font-size: 40px;
        line-height: 50px; } }
    @media only screen and (max-width: 575px) {
      .under-construct .dz-title {
        font-size: 28px;
        line-height: 35px;
        margin-bottom: 15px;
        letter-spacing: 3px; } }
  .under-construct .dz-under-bx {
    padding-left: 80px;
    padding-right: 80px;
    flex: 0 0 50%;
    max-width: 50%;
    align-items: center;
    display: flex; }
    @media only screen and (max-width: 1400px) {
      .under-construct .dz-under-bx {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (max-width: 991px) {
      .under-construct .dz-under-bx {
        flex: 0 0 100%;
        max-width: 100%;
        align-items: center;
        display: flex;
        padding: 60px 20px;
        text-align: center;
        justify-content: center; } }
    @media only screen and (max-width: 575px) {
      .under-construct .dz-under-bx {
        padding: 20px 10px; } }
  .under-construct .logo {
    margin-bottom: 20px;
    width: 200px;
    padding: 5px; }
    .under-construct .logo svg,
    .under-construct .logo img {
      width: 100%; }
    @media only screen and (max-width: 991px) {
      .under-construct .logo {
        margin-bottom: 20px;
        width: 180px;
        margin-left: auto;
        margin-right: auto; } }
  @media only screen and (max-width: 991px) {
    .under-construct .bottom-left {
      padding-bottom: 30px; }
    .under-construct .center-md {
      min-height: calc(100vh - 100px);
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 60px 0; }
    .under-construct .dz-under-bx {
      display: block;
      padding: 0; } }
  .under-construct .copyright-text {
    color: #fff;
    margin: 0;
    font-size: 16px; }
  .under-construct .dz-social-icon {
    margin-bottom: 15px; }
    .under-construct .dz-social-icon li {
      display: inline-block;
      margin-right: 5px; }
      .under-construct .dz-social-icon li a {
        margin-right: 0px;
        width: 40px;
        height: 40px;
        background: var(--primary);
        line-height: 40px;
        text-align: center;
        border-radius: var(--border-radius-lg);
        color: #fff; }
        .under-construct .dz-social-icon li a:hover {
          background: var(--hover-primary); }
  @media only screen and (max-width: 991px) {
    .under-construct .center-md {
      display: block; } }
  @media only screen and (max-width: 767px) {
    .under-construct .center-md {
      padding: 30px 0; } }
  .under-construct .mining-worker {
    width: 60%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1; }
    @media only screen and (max-width: 767px) {
      .under-construct .mining-worker {
        opacity: 0; } }
    .under-construct .mining-worker img {
      width: 12%;
      position: absolute; }
      .under-construct .mining-worker img:nth-child(1) {
        right: 25%;
        top: 0%;
        animation: dzMove1 8s linear infinite; }
      .under-construct .mining-worker img:nth-child(2) {
        left: 25%;
        top: 35%;
        animation: dzMove1 7s linear infinite; }
      .under-construct .mining-worker img:nth-child(3) {
        left: 0%;
        top: 25%;
        animation: dzMove1 9s linear infinite; }
      .under-construct .mining-worker img:nth-child(4) {
        bottom: 18%;
        right: 5%;
        animation: dzMove1 8.5s linear infinite; }
      .under-construct .mining-worker img:nth-child(5) {
        bottom: 2%;
        right: 35%;
        animation: dzMove1 8s linear infinite; }
      .under-construct .mining-worker img:nth-child(6) {
        left: 20%;
        top: 5%;
        animation: dzMove1 9s linear infinite; }
  @media only screen and (max-width: 991px) {
    .under-construct .bottom-left {
      padding-bottom: 0; }
    .under-construct .bottom-right {
      padding-bottom: 20px; } }
  .under-construct .train-run {
    width: 80%;
    position: absolute;
    bottom: 10%;
    transform: rotate(-35deg) translate(20%, 0);
    right: 0;
    z-index: -1; }
    @media only screen and (max-width: 991px) {
      .under-construct .train-run {
        width: 150%;
        bottom: 0; } }
    .under-construct .train-run .main-train {
      -webkit-animation: 30s scroll infinite linear;
      -moz-animation: 30s scroll infinite linear;
      -o-animation: 30s scroll infinite linear;
      -ms-animation: 30s scroll infinite linear;
      animation: 30s scroll infinite linear;
      background-repeat: repeat-x;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      background-size: cover; }
    .under-construct .train-run .train {
      opacity: 0; }
    .under-construct .train-run .track {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; }

@-webkit-keyframes scroll {
  100% {
    background-position: -3000px 0px; } }

/* Position */
.posi-center,
.bottom-right,
.bottom-left,
.top-right,
.top-left {
  position: fixed;
  z-index: 2;
  display: block; }
  @media only screen and (max-width: 991px) {
    .posi-center,
    .bottom-right,
    .bottom-left,
    .top-right,
    .top-left {
      position: unset; } }

.bottom-right {
  bottom: 60px;
  right: 60px; }

.bottom-left {
  bottom: 60px;
  left: 60px; }

.top-right {
  top: 60px;
  right: 60px; }

.top-left {
  top: 60px;
  left: 60px; }

.logo-header {
  display: table;
  float: left;
  vertical-align: middle;
  padding: 0;
  color: #EFBB20;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 179px;
  height: 100px;
  position: relative;
  z-index: 9; }
  @media only screen and (max-width: 991px) {
    .logo-header {
      width: 180px;
      height: 80px; } }
  @media only screen and (max-width: 575px) {
    .logo-header {
      width: 180px; } }
  .logo-header a {
    display: table-cell;
    vertical-align: middle; }
  .logo-header img {
    height: 100px;
    max-width: 179px;
    object-fit: contain;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
    @media only screen and (max-width: 991px) {
      .logo-header img {
        max-width: 140px;
        height: 80px; } }
    @media only screen and (max-width: 575px) {
      .logo-header img {
        max-width: 120px; } }
  .logo-header span {
    font-size: 20px;
    letter-spacing: 20px; }

.top-bar {
  background-color: #fff;
  color: #212529;
  padding: 12px 0;
  position: relative;
  border-bottom: 1px solid #d7d7d7; }
  .top-bar .dz-topbar-left {
    float: left; }
    .top-bar .dz-topbar-left li {
      padding-right: 20px; }
  .top-bar .dz-topbar-right {
    float: right; }
    .top-bar .dz-topbar-right li {
      padding-left: 15px; }
  .top-bar .dz-social li a {
    color: inherit; }
  .top-bar .dz-topbar-inner {
    margin-left: -15px;
    margin-right: -15px; }
  .top-bar .dz-topbar-center,
  .top-bar .dz-topbar-left,
  .top-bar .dz-topbar-right {
    padding-left: 15px;
    padding-right: 15px; }
    .top-bar .dz-topbar-center ul,
    .top-bar .dz-topbar-left ul,
    .top-bar .dz-topbar-right ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .top-bar .dz-topbar-center ul li,
      .top-bar .dz-topbar-left ul li,
      .top-bar .dz-topbar-right ul li {
        display: inline-block;
        font-size: 15px; }
      .top-bar .dz-topbar-center ul i,
      .top-bar .dz-topbar-left ul i,
      .top-bar .dz-topbar-right ul i {
        margin-right: 5px;
        color: var(--primary); }
    .top-bar .dz-topbar-center .dz-social-icon li,
    .top-bar .dz-topbar-left .dz-social-icon li,
    .top-bar .dz-topbar-right .dz-social-icon li {
      padding-left: 0; }
  @media only screen and (max-width: 991px) {
    .top-bar {
      display: none; } }

.dz-quik-search {
  background: rgba(33, 37, 41, 0.95);
  position: fixed;
  height: 100%;
  width: 100%;
  top: -100%;
  left: 0;
  padding: 0px 30px;
  z-index: 999;
  overflow: hidden;
  display: none;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .dz-quik-search form {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    position: relative;
    top: 50%;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    opacity: 1;
    transform: translateY(-50%); }
  .dz-quik-search .form-control, .dz-quik-search .wp-block-categories-dropdown select, .wp-block-categories-dropdown .dz-quik-search select,
  .dz-quik-search .wp-block-archives-dropdown select, .wp-block-archives-dropdown .dz-quik-search select {
    padding: 15px 60px 15px 15px;
    width: 100%;
    height: 90px;
    border: none;
    background: 0 0;
    color: #fff;
    font-size: 20px;
    border-bottom: 2px solid #fff;
    border-radius: 0; }
    .dz-quik-search .form-control::-webkit-input-placeholder, .dz-quik-search .wp-block-categories-dropdown select::-webkit-input-placeholder, .wp-block-categories-dropdown .dz-quik-search select::-webkit-input-placeholder,
    .dz-quik-search .wp-block-archives-dropdown select::-webkit-input-placeholder, .wp-block-archives-dropdown .dz-quik-search select::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.8); }
    .dz-quik-search .form-control:-ms-input-placeholder, .dz-quik-search .wp-block-categories-dropdown select:-ms-input-placeholder, .wp-block-categories-dropdown .dz-quik-search select:-ms-input-placeholder,
    .dz-quik-search .wp-block-archives-dropdown select:-ms-input-placeholder, .wp-block-archives-dropdown .dz-quik-search select:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.8); }
    .dz-quik-search .form-control::placeholder, .dz-quik-search .wp-block-categories-dropdown select::placeholder, .wp-block-categories-dropdown .dz-quik-search select::placeholder,
    .dz-quik-search .wp-block-archives-dropdown select::placeholder, .wp-block-archives-dropdown .dz-quik-search select::placeholder {
      color: rgba(255, 255, 255, 0.8); }
  .dz-quik-search span {
    position: absolute;
    right: 15px;
    top: 50%;
    margin: -15px 0;
    height: 25px;
    font-size: 20px;
    cursor: pointer; }
  .dz-quik-search.On {
    top: 0; }

.site-header {
  position: relative;
  z-index: 9999; }
  .site-header .main-bar {
    background: #fff;
    width: 100%;
    position: relative;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05); }
  .site-header .container {
    position: relative; }
  .site-header .extra-nav {
    float: right;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 9;
    height: 100px;
    padding-left: 30px; }
    @media only screen and (max-width: 991px) {
      .site-header .extra-nav {
        height: 80px; } }
    @media only screen and (max-width: 1680px) {
      .site-header .extra-nav {
        margin-left: 10px; } }
    .site-header .extra-nav #quik-search-btn {
      color: #fff;
      cursor: pointer;
      display: inline-block;
      margin-right: 0px;
      border: 0;
      width: 60px;
      height: 60px;
      background: #1b1b1b;
      line-height: 60px;
      text-align: center;
      font-size: 20px;
      border-radius: 60px; }
      @media only screen and (max-width: 991px) {
        .site-header .extra-nav #quik-search-btn {
          width: 50px;
          height: 50px;
          line-height: 50px; } }
    @media only screen and (max-width: 575px) {
      .site-header .extra-nav .btn, .site-header .extra-nav .wp-block-button__link {
        padding-left: 15px;
        padding-right: 15px; } }
    .site-header .extra-nav ul li {
      display: inline-block; }
      .site-header .extra-nav ul li .icon {
        padding: 0;
        background: transparent;
        min-width: 40px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        border-radius: 4px; }

.navbar-toggler {
  border: 0 solid #efbb20;
  font-size: 16px;
  line-height: 24px;
  margin: 40px 0 39px 15px;
  padding: 0;
  float: right;
  outline: none !important; }
  @media only screen and (max-width: 991px) {
    .navbar-toggler {
      margin: 15px 0 15px 15px; } }
  @media only screen and (max-width: 575px) {
    .navbar-toggler {
      margin: 15px ​0 15px 15p; } }

.navicon {
  width: 50px;
  height: 50px;
  background: var(--primary);
  box-shadow: 0 5px 15px -10px var(--primary);
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  border-radius: 0; }
  .navicon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 1px;
    opacity: 1;
    background: #fff;
    left: 10px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
    .navicon span:nth-child(1) {
      top: 15px;
      width: 30px; }
    .navicon span:nth-child(2) {
      top: 23.5px;
      width: 25px; }
    .navicon span:nth-child(3) {
      top: 33px;
      width: 20px; }
  .navicon.open span:nth-child(1) {
    top: 25px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg); }
  .navicon.open span:nth-child(2) {
    opacity: 0;
    left: -60px; }
  .navicon.open span:nth-child(3) {
    top: 25px;
    width: 30px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg); }

.header-tb .navicon span {
  background: #fff; }

.header-nav {
  padding: 0; }
  @media only screen and (max-width: 991px) {
    .header-nav {
      overflow-y: scroll;
      position: fixed;
      width: 60px;
      left: -280px;
      height: 100vh !important;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      top: 0;
      background-color: #fff;
      margin: 0;
      z-index: 99; } }
  @media only screen and (max-width: 991px) {
    .header-nav.show {
      left: -1px;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s;
      margin: 0;
      width: 280px; } }
  .header-nav .logo-header {
    display: none; }
    @media only screen and (max-width: 991px) {
      .header-nav .logo-header {
        padding: 30px 20px;
        float: none;
        height: auto;
        width: 240px; } }
  .header-nav .nav {
    float: right;
    padding: 0;
    font-size: 0;
    position: unset; }
    .header-nav .nav > li {
      margin: 0px;
      font-weight: 400;
      position: relative;
      z-index: 4; }
      .header-nav .nav > li.active > a {
        color: var(--primary) !important; }
      .header-nav .nav > li.has-mega-menu {
        position: inherit; }
      @media only screen and (max-width: 991px) {
        .header-nav .nav > li {
          border-bottom: 1px solid #eee;
          width: 100%; } }
      .header-nav .nav > li.sub-menu-down > a:after, .header-nav .nav > li.has-mega-menu > a:after {
        content: "\f078";
        font-size: 9px;
        margin-left: 5px;
        margin-top: 0;
        vertical-align: middle;
        font-weight: 900;
        font-family: "Font Awesome 5 Free"; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li.sub-menu-down > a:after, .header-nav .nav > li.has-mega-menu > a:after {
            content: "\f054";
            background-color: var(--primary);
            color: #fff;
            height: 30px;
            line-height: 30px;
            right: 0;
            text-align: center;
            width: 30px;
            z-index: 3;
            float: right;
            font-size: 14px;
            margin: 0; } }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li.sub-menu-down > a:after:before, .header-nav .nav > li.has-mega-menu > a:after:before {
            content: "\f054"; } }
      @media only screen and (max-width: 991px) {
        .header-nav .nav > li.sub-menu-down.open > a:after, .header-nav .nav > li.has-mega-menu.open > a:after {
          content: "\f078";
          background: #333; } }
      .header-nav .nav > li > a {
        color: #071c35;
        font-size: 18px;
        padding: 42px 15px;
        font-family: var(--font-family-title);
        cursor: pointer;
        font-weight: 500;
        display: inline-block;
        position: relative;
        border-width: 0 0 0 1px;
        text-transform: capitalize;
        line-height: 1.1; }
        @media only screen and (max-width: 1199px) {
          .header-nav .nav > li > a {
            padding-left: 8px;
            padding-right: 8px; } }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li > a {
            padding: 8px 0;
            display: block;
            line-height: 30px;
            font-size: 16px; } }
        .header-nav .nav > li > a span {
          display: inline-block; }
      .header-nav .nav > li .mega-menu,
      .header-nav .nav > li .sub-menu {
        background-color: #fff;
        display: block;
        left: 0;
        list-style: none;
        opacity: 0;
        padding: 5px 0;
        position: absolute;
        visibility: hidden;
        width: 220px;
        z-index: 10;
        margin-top: 20px;
        box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.1);
        text-align: left;
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li .mega-menu,
          .header-nav .nav > li .sub-menu {
            display: none;
            position: static;
            visibility: visible;
            width: auto;
            background: transparent;
            box-shadow: none;
            margin: 0;
            border-top: 1px solid #eee;
            padding: 10px 0;
            -webkit-transition: none;
            -ms-transition: none;
            transition: none; }
            .header-nav .nav > li .mega-menu > li > a i,
            .header-nav .nav > li .sub-menu > li > a i {
              color: #fff !important;
              height: 30px;
              line-height: 30px;
              right: 0;
              text-align: center;
              width: 30px;
              z-index: 3;
              font-size: 14px !important;
              margin: 0 !important;
              background: #aaa; } }
        @media only screen and (max-width: 767px) {
          .header-nav .nav > li .mega-menu,
          .header-nav .nav > li .sub-menu {
            transition: none; } }
        .header-nav .nav > li .mega-menu li,
        .header-nav .nav > li .sub-menu li {
          position: relative; }
          .header-nav .nav > li .mega-menu li a,
          .header-nav .nav > li .sub-menu li a {
            color: #212529;
            display: block;
            font-size: 15px;
            padding: 12px 25px;
            line-height: 1.3;
            text-transform: capitalize;
            position: relative;
            transition: all 0.15s linear;
            -webkit-transition: all 0.15s linear;
            -moz-transition: all 0.15s linear;
            -o-transition: all 0.15s linear;
            font-weight: 500; }
            @media only screen and (max-width: 991px) {
              .header-nav .nav > li .mega-menu li a,
              .header-nav .nav > li .sub-menu li a {
                padding: 2px 0 1px 16px;
                display: block;
                line-height: 30px;
                position: relative; }
                .header-nav .nav > li .mega-menu li a:after,
                .header-nav .nav > li .sub-menu li a:after {
                  content: "\f054";
                  font-family: "Font Awesome 5 Free";
                  width: auto;
                  height: auto;
                  background: 0 0 !important;
                  top: 3px;
                  color: #262F5A;
                  font-size: 12px;
                  position: absolute;
                  left: 0;
                  font-weight: 700; } }
          .header-nav .nav > li .mega-menu li:hover > a,
          .header-nav .nav > li .sub-menu li:hover > a {
            color: var(--title);
            background-color: #f1f1f1; }
            @media only screen and (max-width: 991px) {
              .header-nav .nav > li .mega-menu li:hover > a,
              .header-nav .nav > li .sub-menu li:hover > a {
                padding: 2px 0 1px 16px;
                background: transparent; } }
        .header-nav .nav > li .mega-menu li > .sub-menu,
        .header-nav .nav > li .sub-menu li > .sub-menu {
          left: 220px;
          -webkit-transition: all 0.8s;
          -ms-transition: all 0.8s;
          transition: all 0.8s; }
          @media only screen and (max-width: 991px) {
            .header-nav .nav > li .mega-menu li > .sub-menu,
            .header-nav .nav > li .sub-menu li > .sub-menu {
              display: none; } }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li .mega-menu .mega-menu,
          .header-nav .nav > li .mega-menu .sub-menu,
          .header-nav .nav > li .sub-menu .mega-menu,
          .header-nav .nav > li .sub-menu .sub-menu {
            border-top: 0;
            padding-left: 20px; }
            .header-nav .nav > li .mega-menu .mega-menu li a:after,
            .header-nav .nav > li .mega-menu .sub-menu li a:after,
            .header-nav .nav > li .sub-menu .mega-menu li a:after,
            .header-nav .nav > li .sub-menu .sub-menu li a:after {
              width: 5px;
              content: "";
              height: 5px;
              background: #262F5A !important;
              border-radius: 4px;
              top: 13px; } }
      .header-nav .nav > li .sub-menu li > a > i {
        color: inherit;
        display: block;
        float: right;
        font-size: 18px;
        opacity: 1;
        margin-top: 0px; }
      .header-nav .nav > li .mega-menu {
        display: flex;
        right: 0px;
        width: 100%;
        z-index: 9;
        padding: 0 calc((100% - 1200px) /2); }
        .header-nav .nav > li .mega-menu > li {
          display: block;
          padding: 30px 20px 25px;
          position: relative;
          vertical-align: top;
          width: 25%; }
          @media only screen and (max-width: 991px) {
            .header-nav .nav > li .mega-menu > li {
              width: 100%;
              padding: 0; } }
          .header-nav .nav > li .mega-menu > li > a {
            color: #181c32;
            display: block;
            padding: 0 25px;
            font-size: 16px;
            font-weight: 600;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 15px; }
            .header-nav .nav > li .mega-menu > li > a > i {
              font-size: 14px;
              margin-right: 5px;
              text-align: center;
              width: 15px; }
            .header-nav .nav > li .mega-menu > li > a:after {
              content: none; }
            @media only screen and (max-width: 991px) {
              .header-nav .nav > li .mega-menu > li > a {
                display: none; } }
          .header-nav .nav > li .mega-menu > li ul {
            list-style: none;
            margin: 10px 0 0 0;
            padding: 0;
            width: 100%; }
            @media only screen and (max-width: 991px) {
              .header-nav .nav > li .mega-menu > li ul {
                margin: 0; } }
          .header-nav .nav > li .mega-menu > li:last-child:after {
            display: none; }
          .header-nav .nav > li .mega-menu > li:hover > a {
            background-color: transparent;
            color: #181c32;
            padding: 0 25px 15px; }
          .header-nav .nav > li .mega-menu > li li i {
            font-size: 14px;
            margin-right: 5px;
            text-align: center;
            width: 15px; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li .mega-menu {
            display: none;
            padding: 10px 0; } }
      .header-nav .nav > li.active > a {
        color: var(--primary); }
      .header-nav .nav > li:hover > a {
        color: var(--primary); }
        @media only screen and (min-width: 991px) {
          .header-nav .nav > li:hover > a span {
            -webkit-animation: upToDown 0.3s forwards;
            -moz-animation: upToDown 0.3s forwards;
            animation: upToDown 0.3s forwards; } }
      .header-nav .nav > li:hover > .mega-menu,
      .header-nav .nav > li:hover > .sub-menu {
        opacity: 1;
        visibility: visible;
        margin-top: 0;
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li:hover > .mega-menu,
          .header-nav .nav > li:hover > .sub-menu {
            -webkit-transition: none;
            -ms-transition: none;
            transition: none; } }
        .header-nav .nav > li:hover > .mega-menu li:hover > .sub-menu,
        .header-nav .nav > li:hover > .sub-menu li:hover > .sub-menu {
          opacity: 1;
          visibility: visible;
          margin-top: 0;
          top: -1px; }
      .header-nav .nav > li.open .mega-menu,
      .header-nav .nav > li.open .sub-menu {
        opacity: 1;
        -webkit-transition: none;
        -ms-transition: none;
        transition: none; }
        @media only screen and (max-width: 991px) {
          .header-nav .nav > li.open .mega-menu,
          .header-nav .nav > li.open .sub-menu {
            display: block; } }
      .header-nav .nav > li.open .open.menu-item-has-children > a:before {
        transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg); }
      .header-nav .nav > li.open .open .mega-menu,
      .header-nav .nav > li.open .open .sub-menu {
        display: block;
        opacity: 1;
        -webkit-transition: none;
        -ms-transition: none;
        transition: none; }
    @media only screen and (max-width: 1400px) {
      .header-nav .nav > li:nth-last-child(3) .sub-menu .sub-menu,
      .header-nav .nav > li:nth-last-child(2) .sub-menu .sub-menu,
      .header-nav .nav > li:last-child .sub-menu .sub-menu {
        left: -220px; } }
    @media only screen and (max-width: 1199px) {
      .header-nav .nav > li:last-child .sub-menu {
        left: auto;
        right: 0; } }
    @media only screen and (max-width: 991px) {
      .header-nav .nav {
        float: none;
        padding: 0 20px; } }
  .header-nav .dz-social-icon {
    display: none;
    text-align: center; }
    .header-nav .dz-social-icon li {
      display: inline-block;
      margin: 0; }
    .header-nav .dz-social-icon a {
      width: 40px;
      height: 40px;
      padding: 0;
      color: var(--primary);
      border: 1px solid #eee;
      line-height: 38px;
      text-align: center;
      border-radius: 40px; }
    @media only screen and (max-width: 991px) {
      .header-nav .dz-social-icon {
        display: block;
        padding-top: 30px;
        padding-bottom: 20px; } }

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  .header-transparent .top-bar,
  .header-transparent .main-bar {
    background: transparent; }
  .header-transparent .container-fluid, .header-transparent .container-sm, .header-transparent .container-md, .header-transparent .container-lg, .header-transparent .container-xl {
    padding-left: 80px;
    padding-right: 80px; }
    @media only screen and (max-width: 1199px) {
      .header-transparent .container-fluid, .header-transparent .container-sm, .header-transparent .container-md, .header-transparent .container-lg, .header-transparent .container-xl {
        padding-left: 15px;
        padding-right: 15px; } }
  .header-transparent .side-menu-btn .menu-icon-in span {
    background-color: #fff; }
  .header-transparent .is-fixed .side-menu-btn .menu-icon-in span {
    background-color: #fff; }
  @media only screen and (min-width: 991px) {
    .header-transparent .nav > li > a {
      color: #fff; } }
  .header-transparent .navbar-toggler span {
    background: #fff; }
  .header-transparent .is-fixed .nav > li > a {
    color: #181c32; }
  .header-transparent .is-fixed .navbar-toggler span {
    background: #fff; }
  .header-transparent .top-bar {
    border-color: #fff; }
    .header-transparent .top-bar .dz-topbar-left li,
    .header-transparent .top-bar .dz-topbar-left i,
    .header-transparent .top-bar .dz-topbar-right li,
    .header-transparent .top-bar .dz-topbar-right i {
      color: #fff; }

.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: all 0.5s; }

@media only screen and (max-width: 991px) {
  .mo-left .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100vh !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99; }
    .mo-left .header-nav.nav-dark {
      background-color: #202020; }
    .mo-left .header-nav.show {
      left: -1px;
      transition: all 0.8s;
      -webkit-transition: all 0.8s;
      -moz-transition: all 0.8s;
      -o-transition: all 0.8s;
      margin: 0;
      width: 280px; }
    .mo-left .header-nav .logo-header {
      display: block;
      float: none;
      height: auto !important;
      max-width: 100%;
      padding: 25px 20px;
      width: 100% !important; }
      .mo-left .header-nav .logo-header img {
        max-width: unset;
        width: 120px;
        height: 60px;
        vertical-align: middle; }
    .mo-left .header-nav li.open a {
      position: relative; }
  .mo-left .navbar-toggler.open {
    z-index: 99; }
    .mo-left .navbar-toggler.open:after {
      background-color: rgba(0, 0, 0, 0.6);
      content: "";
      left: 0;
      position: fixed;
      right: -20px;
      top: -63px;
      transform: scale(100);
      -o-transform: scale(100);
      -moz-transform: scale(100);
      -webkit-transform: scale(100);
      width: 100%;
      z-index: -1;
      transition: all 0.5s;
      transform-origin: top right;
      margin: 0 0px 0px 20px;
      -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
      box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6); }
    .mo-left .navbar-toggler.open span {
      background: #fff; } }

.text-black .nav > li > a {
  color: #181c32; }

.text-black .navbar-toggler span {
  background: #181c32; }

/* Start Header is-fixed Animation */
.main-bar {
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -khtml-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s; }

.is-fixed .main-bar {
  -webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown; }

@-moz-keyframes headerSlideDown {
  0% {
    margin-top: -150px; }
  100% {
    margin-top: 0; } }

@-ms-keyframes headerSlideDown {
  0% {
    margin-top: -150px; }
  100% {
    margin-top: 0; } }

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px; }
  100% {
    margin-top: 0; } }

@keyframes headerSlideDown {
  0% {
    margin-top: -150px; }
  100% {
    margin-top: 0; } }

/* Header is-fixed Animation End */
.header-tabs-wraper .header-style-bx {
  display: flex;
  width: 100%; }
  .header-tabs-wraper .header-style-bx .header-style-tabs {
    width: 15%;
    position: relative;
    z-index: 1;
    padding: 20px 0 20px 20px;
    background: #f1f1f1; }
    .header-tabs-wraper .header-style-bx .header-style-tabs .header-tabs {
      padding: 15px 25px; }
      .header-tabs-wraper .header-style-bx .header-style-tabs .header-tabs:hover {
        padding: 15px 25px; }
      .header-tabs-wraper .header-style-bx .header-style-tabs .header-tabs:after {
        content: none; }
      .header-tabs-wraper .header-style-bx .header-style-tabs .header-tabs.active {
        color: #000;
        background: #fff; }
  @media only screen and (max-width: 991px) {
    .header-tabs-wraper .header-style-bx {
      display: block; }
      .header-tabs-wraper .header-style-bx .header-style-tabs {
        width: 100%;
        padding: 15px;
        display: none; } }

.header-tabs-wraper .header-style-content {
  width: 85%;
  background: #fff; }
  .header-tabs-wraper .header-style-content .menu-list {
    display: flex;
    text-align: left; }
    .header-tabs-wraper .header-style-content .menu-list > li {
      width: 100%;
      padding: 0 10px; }
      .header-tabs-wraper .header-style-content .menu-list > li .title {
        display: block;
        font-size: 16px;
        font-weight: 600;
        color: #000;
        padding: 10px 25px 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .header-tabs-wraper .header-style-content .menu-list > li > ul {
        padding: 12px 0; }
        .header-tabs-wraper .header-style-content .menu-list > li > ul > li i {
          margin-right: 5px; }
      .header-tabs-wraper .header-style-content .menu-list > li:last-child {
        border-right: 0; }
      @media only screen and (max-width: 991px) {
        .header-tabs-wraper .header-style-content .menu-list > li {
          border-right: 0;
          padding: 0; }
          .header-tabs-wraper .header-style-content .menu-list > li > ul {
            padding: 0; }
          .header-tabs-wraper .header-style-content .menu-list > li .title {
            display: none; } }
  @media only screen and (max-width: 991px) {
    .header-tabs-wraper .header-style-content {
      width: 100%; }
      .header-tabs-wraper .header-style-content .menu-list {
        display: block; } }

.header-tabs-wraper .header-tabs-content {
  display: none; }
  .header-tabs-wraper .header-tabs-content.show {
    display: block; }
  @media only screen and (max-width: 991px) {
    .header-tabs-wraper .header-tabs-content {
      display: block; } }

.site-header.style-1 {
  padding-bottom: 8px; }
  @media only screen and (max-width: 991px) {
    .site-header.style-1 .dz-social-icon ul {
      justify-content: center;
      width: 100%; }
    .site-header.style-1 .dz-social-icon a {
      color: #fff; } }
  .site-header.style-1 .is-fixed .main-bar {
    background: var(--primary); }
  .site-header.style-1:before, .site-header.style-1:after {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1; }
  .site-header.style-1:before {
    background-color: var(--primary);
    width: 100px;
    left: calc(calc(100% - 1140px) / 2 + 280px);
    transform: skewX(20deg); }
    @media only screen and (max-width: 1199px) {
      .site-header.style-1:before {
        left: calc(calc(100% - 960px) / 2 + 280px); } }
    @media only screen and (max-width: 991px) {
      .site-header.style-1:before {
        left: calc(calc(100% - 720px) / 2 + 280px); } }
    @media only screen and (max-width: 767px) {
      .site-header.style-1:before {
        left: calc(calc(100% - 540px) / 2 + 280px); } }
    @media only screen and (max-width: 575px) {
      .site-header.style-1:before {
        left: 210px; } }
  .site-header.style-1:after {
    background-color: var(--primary);
    width: calc(calc(100% - 1140px) / 2 + 800px);
    right: 0; }
    @media only screen and (max-width: 1199px) {
      .site-header.style-1:after {
        width: calc(calc(100% - 960px) / 2 + 635px); } }
    @media only screen and (max-width: 991px) {
      .site-header.style-1:after {
        width: calc(calc(100% - 720px) / 2 + 420px); } }
    @media only screen and (max-width: 767px) {
      .site-header.style-1:after {
        width: calc(calc(100% - 540px) / 2 + 230px); } }
    @media only screen and (max-width: 575px) {
      .site-header.style-1:after {
        width: calc(100% - 230px); } }
  .site-header.style-1 .main-bar-wraper:after {
    content: "";
    height: 100%;
    background-color: #2d384a;
    width: calc(calc(100% - 1140px) / 2 + 300px);
    left: -40px;
    top: 0;
    position: absolute;
    transform: skewX(20deg); }
    @media only screen and (max-width: 1199px) {
      .site-header.style-1 .main-bar-wraper:after {
        width: calc(calc(100% - 960px) / 2 + 300px); } }
    @media only screen and (max-width: 991px) {
      .site-header.style-1 .main-bar-wraper:after {
        width: calc(calc(100% - 720px) / 2 + 300px); } }
    @media only screen and (max-width: 767px) {
      .site-header.style-1 .main-bar-wraper:after {
        width: calc(calc(100% - 540px) / 2 + 300px); } }
  .site-header.style-1 .top-bar {
    background-color: transparent;
    border-bottom: 0;
    z-index: 2; }
    .site-header.style-1 .top-bar .dz-topbar-inner {
      margin-left: calc(calc(100% - 1140px) / 2 + 295px); }
      @media only screen and (max-width: 1199px) {
        .site-header.style-1 .top-bar .dz-topbar-inner {
          margin-left: 0; } }
    .site-header.style-1 .top-bar p {
      font-size: 15px;
      margin-bottom: 0; }
    .site-header.style-1 .top-bar .dz-topbar-right li, .site-header.style-1 .top-bar .dz-topbar-right p,
    .site-header.style-1 .top-bar .dz-topbar-left li,
    .site-header.style-1 .top-bar .dz-topbar-left p {
      font-weight: 700; }
  .site-header.style-1 .main-bar:before, .site-header.style-1 .main-bar:after {
    content: "";
    height: 100%;
    position: absolute;
    z-index: 3;
    top: 0;
    background-color: #192436; }
  .site-header.style-1 .main-bar:before {
    border-left: 3px solid var(--primary);
    width: 90px;
    transform: skew(20deg);
    left: calc(calc(100% - 1140px) / 2 + 220px); }
  .site-header.style-1 .main-bar:after {
    width: calc(calc(100% - 1140px) / 2 + 870px);
    right: 0; }
  @media only screen and (max-width: 1199px) {
    .site-header.style-1 .main-bar:before {
      left: calc(calc(100% - 960px) / 2 + 220px); }
    .site-header.style-1 .main-bar:after {
      width: calc(calc(100% - 960px) / 2 + 690px); } }
  @media only screen and (max-width: 991px) {
    .site-header.style-1 .main-bar:before {
      left: calc(calc(100% - 720px) / 2 + 220px); }
    .site-header.style-1 .main-bar:after {
      width: calc(calc(100% - 720px) / 2 + 470px); } }
  @media only screen and (max-width: 767px) {
    .site-header.style-1 .main-bar:before {
      left: calc(calc(100% - 540px) / 2 + 220px); }
    .site-header.style-1 .main-bar:after {
      width: calc(calc(100% - 540px) / 2 + 300px); } }
  @media only screen and (max-width: 575px) {
    .site-header.style-1 .main-bar:before {
      left: 175px; }
    .site-header.style-1 .main-bar:after {
      width: calc(100% - 200px); } }
  .site-header.style-1 .navicon {
    z-index: 5; }
  @media only screen and (min-width: 991px) {
    .site-header.style-1 .header-nav {
      z-index: 4;
      padding-left: 130px; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    .site-header.style-1 .header-nav {
      padding-left: 60px; } }
  @media only screen and (min-width: 991px) {
    .site-header.style-1 .nav > li > a {
      color: #fff; } }

.site-header.style-2.header-transparent + .page-content .dz-bnr-inr .dz-bnr-inr-entry {
  padding-top: 100px; }

.site-header.style-2.header-transparent .main-bar .container > .logo-dark,
.site-header.style-2.header-transparent .main-bar .container-fluid > .logo-dark,
.site-header.style-2.header-transparent .main-bar .container-sm > .logo-dark,
.site-header.style-2.header-transparent .main-bar .container-md > .logo-dark,
.site-header.style-2.header-transparent .main-bar .container-lg > .logo-dark,
.site-header.style-2.header-transparent .main-bar .container-xl > .logo-dark {
  display: none; }

.site-header.style-2.header-transparent .is-fixed .main-bar .container > .logo-dark,
.site-header.style-2.header-transparent .is-fixed .main-bar .container-fluid > .logo-dark,
.site-header.style-2.header-transparent .is-fixed .main-bar .container-sm > .logo-dark,
.site-header.style-2.header-transparent .is-fixed .main-bar .container-md > .logo-dark,
.site-header.style-2.header-transparent .is-fixed .main-bar .container-lg > .logo-dark,
.site-header.style-2.header-transparent .is-fixed .main-bar .container-xl > .logo-dark {
  display: block; }

.site-header.style-2.header-transparent .is-fixed .main-bar .container > .logo-white,
.site-header.style-2.header-transparent .is-fixed .main-bar .container-fluid > .logo-white,
.site-header.style-2.header-transparent .is-fixed .main-bar .container-sm > .logo-white,
.site-header.style-2.header-transparent .is-fixed .main-bar .container-md > .logo-white,
.site-header.style-2.header-transparent .is-fixed .main-bar .container-lg > .logo-white,
.site-header.style-2.header-transparent .is-fixed .main-bar .container-xl > .logo-white {
  display: none; }

.site-header.style-2.header-transparent .main-bar.show .container > .logo-dark,
.site-header.style-2.header-transparent .main-bar.show .container-fluid > .logo-dark,
.site-header.style-2.header-transparent .main-bar.show .container-sm > .logo-dark,
.site-header.style-2.header-transparent .main-bar.show .container-md > .logo-dark,
.site-header.style-2.header-transparent .main-bar.show .container-lg > .logo-dark,
.site-header.style-2.header-transparent .main-bar.show .container-xl > .logo-dark {
  display: block; }

.site-header.style-2.header-transparent .main-bar.show .container > .logo-white,
.site-header.style-2.header-transparent .main-bar.show .container-fluid > .logo-white,
.site-header.style-2.header-transparent .main-bar.show .container-sm > .logo-white,
.site-header.style-2.header-transparent .main-bar.show .container-md > .logo-white,
.site-header.style-2.header-transparent .main-bar.show .container-lg > .logo-white,
.site-header.style-2.header-transparent .main-bar.show .container-xl > .logo-white {
  display: none; }

.site-header.style-2 .main-bar {
  background-color: transparent;
  box-shadow: none; }

.site-header.style-2 .is-fixed .extra-nav {
  height: 100px; }
  @media only screen and (max-width: 1400px) {
    .site-header.style-2 .is-fixed .extra-nav {
      height: 80px; } }

@media only screen and (min-width: 991px) {
  .site-header.style-2 .is-fixed .nav {
    height: 80px; } }

@media only screen and (min-width: 1400px) {
  .site-header.style-2 .is-fixed .nav {
    height: 100px; } }

.site-header.style-2 .is-fixed .logo-header {
  height: 100px; }
  .site-header.style-2 .is-fixed .logo-header img {
    height: 100px; }
  @media only screen and (max-width: 1400px) {
    .site-header.style-2 .is-fixed .logo-header {
      height: 80px; }
      .site-header.style-2 .is-fixed .logo-header img {
        height: 80px; } }

@media only screen and (max-width: 991px) {
  .site-header.style-2 .is-fixed .extra-nav {
    height: 80px; }
  .site-header.style-2 .is-fixed .logo-header {
    height: 80px; }
    .site-header.style-2 .is-fixed .logo-header img {
      height: 80px; }
  .site-header.style-2 .is-fixed .navbar-toggler {
    margin: 15px 0 15px 15px; } }

@media only screen and (max-width: 991px) {
  .site-header.style-2 .navbar-toggler {
    margin: 30px 0 30px 15px; }
  .site-header.style-2 .dz-social-icon ul {
    justify-content: center; }
  .site-header.style-2 .dz-social-icon a {
    color: #fff; } }

@media only screen and (max-width: 575px) {
  .site-header.style-2 .navbar-toggler {
    margin: 15px 0 15px 15px; } }

.site-header.style-2 .extra-nav {
  height: 150px;
  padding-left: 50px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  @media only screen and (max-width: 1200px) {
    .site-header.style-2 .extra-nav {
      padding-left: 30px; } }
  @media only screen and (max-width: 991px) {
    .site-header.style-2 .extra-nav {
      height: 110px; } }
  @media only screen and (max-width: 575px) {
    .site-header.style-2 .extra-nav {
      height: 80px;
      padding-left: 0; } }
  .site-header.style-2 .extra-nav ul {
    display: flex;
    align-items: center; }

.site-header.style-2 .container-fluid, .site-header.style-2 .container-sm, .site-header.style-2 .container-md, .site-header.style-2 .container-lg, .site-header.style-2 .container-xl {
  padding-left: 130px;
  padding-right: 130px; }
  @media only screen and (max-width: 1400px) {
    .site-header.style-2 .container-fluid, .site-header.style-2 .container-sm, .site-header.style-2 .container-md, .site-header.style-2 .container-lg, .site-header.style-2 .container-xl {
      padding-left: 60px;
      padding-right: 60px; } }
  @media only screen and (max-width: 575px) {
    .site-header.style-2 .container-fluid, .site-header.style-2 .container-sm, .site-header.style-2 .container-md, .site-header.style-2 .container-lg, .site-header.style-2 .container-xl {
      padding-left: 15px;
      padding-right: 15px; } }

@media only screen and (min-width: 991px) {
  .site-header.style-2 .nav {
    height: 150px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; } }

.site-header.style-2 .logo-header {
  height: 150px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .site-header.style-2 .logo-header img {
    height: 150px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
    @media only screen and (max-width: 1400px) {
      .site-header.style-2 .logo-header img {
        max-width: 140px; } }
  @media only screen and (max-width: 991px) {
    .site-header.style-2 .logo-header {
      height: 120px; }
      .site-header.style-2 .logo-header img {
        height: 120px; } }
  @media only screen and (max-width: 575px) {
    .site-header.style-2 .logo-header {
      height: 75px; }
      .site-header.style-2 .logo-header img {
        height: 75px; } }

.site-header.style-2 .is-fixed .main-bar {
  background: #fff;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05); }

@media only screen and (max-width: 991px) {
  .site-header.style-2 .header-nav .logo-header img {
    height: 60px; } }

@media only screen and (min-width: 991px) {
  .site-header.style-2 .header-nav .nav > li {
    padding: 10px 25px; }
    .site-header.style-2 .header-nav .nav > li.active > a:before, .site-header.style-2 .header-nav .nav > li:hover > a:before {
      width: 100%; }
    .site-header.style-2 .header-nav .nav > li > a {
      padding: 32px 0; }
      .site-header.style-2 .header-nav .nav > li > a:before {
        content: "";
        position: absolute;
        bottom: 15px;
        left: 50%;
        height: 2px;
        width: 0;
        margin-left: 0;
        background: var(--primary);
        transform: translateX(-50%);
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s; } }
  @media only screen and (min-width: 991px) and (max-width: 1200px) {
    .site-header.style-2 .header-nav .nav > li {
      padding: 10px; } }

@media only screen and (max-width: 1400px) {
  .site-header.style-2 .header-nav .nav > li > a {
    padding: 20px 0; } }

@media only screen and (max-width: 991px) {
  .site-header.style-2 .header-nav .nav > li > a {
    padding: 8px 0px; } }

.site-header.style-2 .menu-btn {
  height: 70px;
  width: 70px;
  background: var(--primary);
  display: inline-block;
  position: relative;
  margin-left: 30px;
  vertical-align: middle; }
  .site-header.style-2 .menu-btn span {
    background: #fff;
    left: 18px;
    display: block;
    position: absolute;
    height: 2px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
    .site-header.style-2 .menu-btn span:nth-child(1) {
      top: 22px;
      width: 35px; }
    .site-header.style-2 .menu-btn span:nth-child(2) {
      top: 32.5px;
      width: 25px; }
    .site-header.style-2 .menu-btn span:nth-child(3) {
      top: 44px;
      width: 35px; }
  @media only screen and (max-width: 1400px) {
    .site-header.style-2 .menu-btn {
      height: 60px;
      width: 60px; }
      .site-header.style-2 .menu-btn span {
        left: 12px; }
        .site-header.style-2 .menu-btn span:nth-child(1) {
          top: 18px; }
        .site-header.style-2 .menu-btn span:nth-child(2) {
          top: 28.5px; }
        .site-header.style-2 .menu-btn span:nth-child(3) {
          top: 40px; } }
  @media only screen and (max-width: 1200px) {
    .site-header.style-2 .menu-btn {
      margin-left: 30px; } }
  @media only screen and (max-width: 991px) {
    .site-header.style-2 .menu-btn {
      display: none; } }
  .site-header.style-2 .menu-btn.open span:nth-child(1) {
    top: 33px;
    transform: rotate(45deg); }
  .site-header.style-2 .menu-btn.open span:nth-child(2) {
    right: 40px;
    opacity: 0; }
  .site-header.style-2 .menu-btn.open span:nth-child(3) {
    top: 33px;
    width: 35px;
    transform: rotate(-45deg); }

.site-header.style-3 .logo-header {
  background-color: var(--primary);
  padding: 0px 40px 0 40px;
  margin-right: 60px; }
  .site-header.style-3 .logo-header img {
    max-width: 150px; }
  @media only screen and (max-width: 1400px) {
    .site-header.style-3 .logo-header {
      margin-right: 40px; } }
  @media only screen and (max-width: 575px) {
    .site-header.style-3 .logo-header {
      padding: 0px 15px 0 15px;
      width: 120px;
      margin-right: 0; }
      .site-header.style-3 .logo-header img {
        max-width: 120px; } }

.site-header.style-3 .extra-nav {
  padding-right: 80px;
  margin-left: 0;
  padding-left: 0; }
  .site-header.style-3 .extra-nav .extra-cell {
    height: 100%;
    display: flex;
    align-items: center; }
  @media only screen and (max-width: 1200px) {
    .site-header.style-3 .extra-nav {
      padding-right: 0; } }
  @media only screen and (max-width: 991px) {
    .site-header.style-3 .extra-nav {
      padding-right: 10px; } }

@media only screen and (max-width: 991px) {
  .site-header.style-3 .navbar-toggler {
    margin: 15px; }
  .site-header.style-3 .dz-social-icon ul {
    margin: 0;
    justify-content: center; }
    .site-header.style-3 .dz-social-icon ul a {
      color: #fff; } }

.site-header.style-3 .header-nav .logo-header {
  background-color: transparent;
  padding: 30px; }

@media only screen and (min-width: 991px) {
  .site-header.style-3 .header-nav .nav > li:hover > a:before, .site-header.style-3 .header-nav .nav > li.active > a:before {
    width: 100%; }
  .site-header.style-3 .header-nav .nav > li > a {
    padding: 40px 15px; }
    .site-header.style-3 .header-nav .nav > li > a:before {
      content: "";
      position: absolute;
      height: 2px;
      background: var(--primary);
      width: 0;
      -webkit-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
      transform: translateX(-50%);
      top: 0;
      left: 50%; } }
  @media only screen and (min-width: 991px) and (max-width: 1400px) {
    .site-header.style-3 .header-nav .nav > li > a {
      padding: 40px 10px; } }

.site-header.style-3 .top-bar .help-text {
  font-size: 14px;
  margin-bottom: 0;
  color: #7a8aa6; }
  .site-header.style-3 .top-bar .help-text strong {
    color: #1c1c1c;
    text-transform: uppercase;
    font-family: var(--font-family-title);
    font-weight: 700;
    padding-right: 10px; }

.site-header.style-3 .container-fluid, .site-header.style-3 .container-sm, .site-header.style-3 .container-md, .site-header.style-3 .container-lg, .site-header.style-3 .container-xl {
  padding-left: 0;
  padding-right: 0; }

.search-inhead {
  position: relative;
  margin-left: 30px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  height: 100%;
  background: #f2f2f2;
  border-right: 1px solid #d7d7d7;
  margin-right: 30px;
  padding-right: 30px; }
  @media only screen and (min-width: 1280px) {
    .search-inhead .dz-quik-search {
      position: relative;
      display: block !important;
      top: 0;
      background: transparent;
      max-width: 200px;
      padding: 0; }
      .search-inhead .dz-quik-search .form-control, .search-inhead .dz-quik-search .wp-block-categories-dropdown select, .wp-block-categories-dropdown .search-inhead .dz-quik-search select,
      .search-inhead .dz-quik-search .wp-block-archives-dropdown select, .wp-block-archives-dropdown .search-inhead .dz-quik-search select {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        border: 0; }
        .search-inhead .dz-quik-search .form-control::placeholder, .search-inhead .dz-quik-search .wp-block-categories-dropdown select::placeholder, .wp-block-categories-dropdown .search-inhead .dz-quik-search select::placeholder,
        .search-inhead .dz-quik-search .wp-block-archives-dropdown select::placeholder, .wp-block-archives-dropdown .search-inhead .dz-quik-search select::placeholder {
          color: #1c1c1c; }
      .search-inhead .dz-quik-search span {
        display: none; }
    .search-inhead #quik-search-btn {
      position: absolute;
      right: 30px;
      top: 50%;
      color: #1c1c1c !important;
      background: #fff !important;
      transform: translateY(-50%);
      z-index: 9999;
      font-size: 18px !important; } }
  @media only screen and (max-width: 1200px) {
    .search-inhead {
      margin-right: 0;
      padding-right: 20px;
      border-right: 0; } }
  @media only screen and (max-width: 575px) {
    .search-inhead {
      background: transparent;
      padding-right: 0; } }

.site-header.style-4 .extra-nav .extra-cell {
  height: 100%;
  display: flex;
  align-items: center; }

.site-header.style-4 .container-fluid, .site-header.style-4 .container-sm, .site-header.style-4 .container-md, .site-header.style-4 .container-lg, .site-header.style-4 .container-xl {
  padding-left: 0;
  padding-right: 0; }

.site-header.style-4 .logo-header {
  padding-left: 70px;
  padding-right: 40px; }

.site-header.style-4 .main-bar .container > .logo-white,
.site-header.style-4 .main-bar .container-fluid > .logo-white,
.site-header.style-4 .main-bar .container-sm > .logo-white,
.site-header.style-4 .main-bar .container-md > .logo-white,
.site-header.style-4 .main-bar .container-lg > .logo-white,
.site-header.style-4 .main-bar .container-xl > .logo-white {
  display: none; }

@media only screen and (max-width: 991px) {
  .site-header.style-4 .logo-header {
    padding-left: 30px;
    padding-right: 30px;
    width: 120px; }
    .site-header.style-4 .logo-header img {
      max-width: 120px; }
  .site-header.style-4 .navbar-toggler {
    margin: 15px; }
  .site-header.style-4 .dz-social-icon ul {
    margin: 0;
    justify-content: center; }
    .site-header.style-4 .dz-social-icon ul a {
      color: #fff; } }

@media only screen and (max-width: 575px) {
  .site-header.style-4 .logo-header {
    padding-left: 15px;
    padding-right: 15px; } }

@media only screen and (min-width: 991px) {
  .site-header.style-4 .header-nav .nav > li > a {
    padding: 40px 15px; } }
  @media only screen and (min-width: 991px) and (max-width: 1400px) {
    .site-header.style-4 .header-nav .nav > li > a {
      padding: 40px 10px; } }

.site-header.style-4 .search-inhead {
  background: #f2f2f2;
  margin-right: 0;
  border-right: 0;
  padding-left: 25px; }
  @media only screen and (min-width: 1280px) {
    .site-header.style-4 .search-inhead .form-control, .site-header.style-4 .search-inhead .wp-block-categories-dropdown select, .wp-block-categories-dropdown .site-header.style-4 .search-inhead select,
    .site-header.style-4 .search-inhead .wp-block-archives-dropdown select, .wp-block-archives-dropdown .site-header.style-4 .search-inhead select {
      border: 0; } }
  @media only screen and (max-width: 1200px) {
    .site-header.style-4 .search-inhead {
      padding-right: 25px; } }
  @media only screen and (max-width: 991px) {
    .site-header.style-4 .search-inhead {
      background: transparent;
      padding-right: 10px; } }
  @media only screen and (max-width: 575px) {
    .site-header.style-4 .search-inhead {
      padding-left: 0;
      padding-right: 0; } }

.site-header.style-4 .btn-quote {
  height: 100%;
  font-size: 18px;
  padding: 15px 40px; }

@media only screen and (min-width: 1280px) {
  .site-header.style-4 .extra-nav #quik-search-btn {
    height: 55px;
    width: 55px;
    line-height: 55px;
    background: #fff !important; } }

.site-header.style-5 + .page-content .dz-bnr-inr {
  overflow: unset; }
  .site-header.style-5 + .page-content .dz-bnr-inr .container {
    display: block; }
  .site-header.style-5 + .page-content .dz-bnr-inr .dz-bnr-inr-entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 45px); }
    @media only screen and (max-width: 991px) {
      .site-header.style-5 + .page-content .dz-bnr-inr .dz-bnr-inr-entry {
        height: 100%; } }

.site-header.style-5 .middle-bar .middle-area {
  display: flex;
  align-items: center;
  justify-content: space-between; }

@media only screen and (max-width: 991px) {
  .site-header.style-5 .middle-bar {
    display: none; } }

.site-header.style-5 .middle-bar .logo-white {
  display: none; }

.site-header.style-5 .middle-bar .logo-dark {
  display: block; }

.site-header.style-5 .logo-header img {
  max-width: 150px; }
  @media only screen and (max-width: 575px) {
    .site-header.style-5 .logo-header img {
      max-width: 120px; } }

@media only screen and (max-width: 991px) {
  .site-header.style-5 .dz-social-icon ul {
    margin: 0;
    justify-content: center; }
    .site-header.style-5 .dz-social-icon ul a {
      color: #fff; } }

@media only screen and (min-width: 991px) {
  .site-header.style-5 .main-bar {
    background: transparent;
    box-shadow: none;
    margin-bottom: -45px; }
    .site-header.style-5 .main-bar .container {
      background: var(--secondary);
      border-radius: var(--border-radius-base); }
    .site-header.style-5 .main-bar .extra-nav {
      height: 90px; } }

@media only screen and (max-width: 991px) {
  .site-header.style-5 .main-bar {
    background: var(--secondary); } }

@media only screen and (min-width: 991px) {
  .site-header.style-5 .header-nav .nav > li > a {
    color: #fff;
    padding: 35px 15px; }
  .site-header.style-5 .header-nav .nav > li:hover > a, .site-header.style-5 .header-nav .nav > li.active > a {
    color: var(--primary); } }

.site-header.style-5 .top-bar {
  background: #ededed;
  border-bottom: 0;
  padding: 0; }
  .site-header.style-5 .top-bar .dz-topbar-left ul li,
  .site-header.style-5 .top-bar .dz-topbar-right ul li,
  .site-header.style-5 .top-bar .dz-topbar-center ul li {
    font-size: 16px;
    font-weight: 700; }
  .site-header.style-5 .top-bar .dz-topbar-left ul li {
    border-right: 1px solid #e3e3e3;
    margin-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px; }
    .site-header.style-5 .top-bar .dz-topbar-left ul li:last-child {
      border-right: 0; }
  .site-header.style-5 .top-bar .dz-social {
    display: flex; }
    .site-header.style-5 .top-bar .dz-social li {
      border-left: 1px solid #e3e3e3;
      padding: 0; }
      .site-header.style-5 .top-bar .dz-social li a {
        padding: 14px 12px; }
        .site-header.style-5 .top-bar .dz-social li a:hover {
          color: var(--primary); }
      .site-header.style-5 .top-bar .dz-social li:last-child {
        border-right: 1px solid #e3e3e3; }

.site-header.style-5 .service-info {
  margin-right: 30px; }
  .site-header.style-5 .service-info span {
    font-size: 14px;
    color: #020e28;
    text-transform: uppercase;
    font-weight: 700; }
  .site-header.style-5 .service-info .title {
    font-size: 22px;
    color: var(--primary);
    font-family: var(--font-family-base);
    margin-bottom: 0; }

.site-header.style-5 .extra-nav #quik-search-btn {
  height: 48px;
  width: 48px;
  line-height: 48px;
  background: #ededed;
  color: #020e28; }

.site-header.header-sidenav-1.header-transparent .main-bar {
  background: transparent; }

.site-header.header-sidenav-1.header-transparent + .page-content .dz-bnr-inr .dz-bnr-inr-entry {
  padding-top: 100px; }

.site-header.header-sidenav-1.header-transparent .nav > li > a {
  color: var(--secondary); }

.site-header.header-sidenav-1 .main-bar {
  background: var(--secondary); }

.site-header.header-sidenav-1 .is-fixed .header-content-bx {
  height: auto; }

.site-header.header-sidenav-1 .is-fixed .main-bar {
  background: var(--secondary); }

.site-header.header-sidenav-1 .header-content-bx > .logo-header.logo-dark {
  display: none; }

.site-header.header-sidenav-1 .main-bar.show .header-content-bx > .logo-header.logo-dark {
  display: block; }

.site-header.header-sidenav-1 .main-bar.show .header-content-bx > .logo-header.logo-white {
  display: none; }

.site-header.header-sidenav-1 .header-nav .nav > li .sub-menu li > .sub-menu {
  left: auto;
  margin: 0;
  padding: 0;
  top: auto; }

@media only screen and (max-width: 991px) {
  .site-header.header-sidenav-1 .header-nav .nav > li .sub-menu, .site-header.header-sidenav-1 .header-nav .nav > li .mega-menu {
    display: block;
    border-top: 0; }
    .site-header.header-sidenav-1 .header-nav .nav > li .sub-menu.sub-menu-open, .site-header.header-sidenav-1 .header-nav .nav > li .mega-menu.sub-menu-open {
      border-top: 1px solid #eee; } }

.site-header.header-sidenav-1 .header-nav .nav .sub-menu {
  left: auto;
  top: auto;
  height: 0;
  padding: 0;
  overflow: hidden;
  transition: height 0.5s ease !important; }
  .site-header.header-sidenav-1 .header-nav .nav .sub-menu.sub-menu-open {
    padding: 10px 0;
    height: auto; }

@media only screen and (min-width: 991px) {
  .site-header.header-sidenav-1 .header-nav .nav .sub-menu-down > a:after {
    content: "\f078";
    vertical-align: middle;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    position: absolute;
    right: 20px;
    margin-left: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s; }
  .site-header.header-sidenav-1 .header-nav .nav .sub-menu-down > a.dz-open:after {
    transform: translateY(-50%) rotate(-90deg); } }

@media only screen and (max-width: 767px) {
  .site-header.header-sidenav-1 .header-nav .nav .sub-menu-down .sub-menu-down > a:before {
    content: "\f078";
    vertical-align: middle;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
    background-color: var(--rgba-primary-1);
    color: var(--primary);
    height: 30px;
    line-height: 30px;
    right: 0px;
    text-align: center;
    width: 30px;
    z-index: 3;
    float: right;
    font-size: 14px;
    margin: 0px; }
  .site-header.header-sidenav-1 .header-nav .nav .sub-menu-down .sub-menu-down > a.dz-open:before {
    transform: rotate(-90deg); } }

.site-header.header-sidenav-1 .nav-elements span {
  font-size: 18px;
  margin-right: 10px; }

.site-header.header-sidenav-1 .header-content-bx {
  display: flex;
  height: 140px;
  align-items: center;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  @media only screen and (max-width: 1024px) {
    .site-header.header-sidenav-1 .header-content-bx {
      justify-content: space-between; } }
  @media only screen and (max-width: 1400px) {
    .site-header.header-sidenav-1 .header-content-bx {
      height: 100px; } }
  @media only screen and (max-width: 575px) {
    .site-header.header-sidenav-1 .header-content-bx {
      height: 80px; } }

@media only screen and (max-width: 575px) {
  .site-header.header-sidenav-1 .logo-header img {
    max-width: 110px; } }

.site-header.header-sidenav-1 .container-fluid, .site-header.header-sidenav-1 .container-sm, .site-header.header-sidenav-1 .container-md, .site-header.header-sidenav-1 .container-lg, .site-header.header-sidenav-1 .container-xl {
  padding-left: 75px;
  padding-right: 75px; }
  @media only screen and (max-width: 1400px) {
    .site-header.header-sidenav-1 .container-fluid, .site-header.header-sidenav-1 .container-sm, .site-header.header-sidenav-1 .container-md, .site-header.header-sidenav-1 .container-lg, .site-header.header-sidenav-1 .container-xl {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 575px) {
    .site-header.header-sidenav-1 .container-fluid, .site-header.header-sidenav-1 .container-sm, .site-header.header-sidenav-1 .container-md, .site-header.header-sidenav-1 .container-lg, .site-header.header-sidenav-1 .container-xl {
      padding-left: 15px;
      padding-right: 15px; } }

.site-header.header-sidenav-1 .extra-nav {
  padding-left: 80px; }
  @media only screen and (max-width: 1200px) {
    .site-header.header-sidenav-1 .extra-nav {
      padding-left: 15px; } }

.site-header.header-sidenav-1 .menu-btn {
  height: 55px;
  width: 55px;
  background: var(--primary);
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  @media only screen and (max-width: 1199px) {
    .site-header.header-sidenav-1 .menu-btn {
      height: 50px;
      width: 50px; } }
  .site-header.header-sidenav-1 .menu-btn span {
    background: #fff;
    right: 10px;
    display: block;
    position: absolute;
    height: 2px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
    .site-header.header-sidenav-1 .menu-btn span:nth-child(1) {
      top: 15px;
      width: 35px; }
    .site-header.header-sidenav-1 .menu-btn span:nth-child(2) {
      top: 50%;
      margin-top: -1px;
      width: 30px; }
    .site-header.header-sidenav-1 .menu-btn span:nth-child(3) {
      bottom: 15px;
      width: 25px; }
    @media only screen and (max-width: 1199px) {
      .site-header.header-sidenav-1 .menu-btn span {
        right: 8px; } }
  .site-header.header-sidenav-1 .menu-btn.open span:nth-child(1) {
    top: 50%;
    transform: rotate(45deg); }
  .site-header.header-sidenav-1 .menu-btn.open span:nth-child(2) {
    right: 40px;
    opacity: 0; }
  .site-header.header-sidenav-1 .menu-btn.open span:nth-child(3) {
    top: 50%;
    bottom: auto;
    width: 35px;
    transform: rotate(-45deg); }
  @media only screen and (max-width: 575px) {
    .site-header.header-sidenav-1 .menu-btn {
      height: 50px;
      width: 50px; }
      .site-header.header-sidenav-1 .menu-btn span {
        height: 2px;
        right: 10px; }
        .site-header.header-sidenav-1 .menu-btn span:nth-child(1) {
          top: 15px; }
        .site-header.header-sidenav-1 .menu-btn span:nth-child(2) {
          top: 25.5px; }
        .site-header.header-sidenav-1 .menu-btn span:nth-child(3) {
          top: 35px; }
      .site-header.header-sidenav-1 .menu-btn.open span:nth-child(1) {
        top: 25px; }
      .site-header.header-sidenav-1 .menu-btn.open span:nth-child(3) {
        top: 25px; } }

.service-list {
  margin-left: auto; }
  .service-list ul {
    display: flex;
    flex-flow: wrap; }
    .service-list ul li {
      display: inline-block;
      position: relative;
      padding: 0 0 0 70px;
      margin-left: 30px; }
      .service-list ul li .title {
        color: #fff;
        font-size: 18px; }
      .service-list ul li span {
        color: var(--primary); }
      .service-list ul li i {
        height: 55px;
        width: 55px;
        border-radius: 55px;
        line-height: 50px;
        text-align: center;
        color: var(--primary);
        border: 2px solid var(--primary);
        position: absolute;
        left: 0;
        top: 0;
        font-size: 26px; }
        .service-list ul li i:after {
          content: "";
          position: absolute;
          height: calc(100% + 2px);
          width: calc(100% + 2px);
          background: var(--primary);
          border-radius: inherit;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0);
          z-index: -1;
          -webkit-transition: all 0.5s;
          -ms-transition: all 0.5s;
          transition: all 0.5s; }
      .service-list ul li:hover i {
        color: #fff; }
        .service-list ul li:hover i:after {
          transform: translate(-50%, -50%) scale(1); }
  @media only screen and (max-width: 1200px) {
    .service-list ul li {
      margin-left: 20px;
      padding: 0px 0px 0px 52px; }
      .service-list ul li .title {
        font-size: 17px; }
      .service-list ul li i {
        height: 45px;
        width: 45px;
        border-radius: 55px;
        line-height: 40px; } }
  @media only screen and (max-width: 1024px) {
    .service-list {
      display: none; } }

.full-sidenav {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 150px;
  padding-bottom: 50px;
  display: flex !important;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  .full-sidenav.show {
    visibility: visible;
    opacity: 1;
    align-items: center; }
    .full-sidenav.show .flsbg .bg-wrap {
      transform: translateY(0); }
  .full-sidenav .container {
    height: 100%;
    overflow-y: auto; }
    .full-sidenav .container::-webkit-scrollbar-track {
      background: #e9e9e9;
      border-radius: 8px; }
    .full-sidenav .container::-webkit-scrollbar {
      width: 8px; }
    .full-sidenav .container::-webkit-scrollbar-thumb {
      background-color: #a8a8a8;
      border-radius: 8px; }
  .full-sidenav .row {
    width: 100%;
    height: 100%; }
  .full-sidenav .social-menu li {
    display: inline-block;
    margin-right: 10px; }
    .full-sidenav .social-menu li a {
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 40px;
      border-radius: 40px;
      background: var(--rgba-primary-1);
      display: block; }
      .full-sidenav .social-menu li a:hover {
        background: var(--primary);
        color: #fff; }
  .full-sidenav .service-list {
    display: block; }
    .full-sidenav .service-list ul {
      display: block; }
      .full-sidenav .service-list ul li {
        display: block;
        margin-bottom: 40px;
        margin-left: 0; }
        .full-sidenav .service-list ul li .title {
          color: var(--title); }
  .full-sidenav.header-nav .nav > li .mega-menu, .full-sidenav.header-nav .nav > li .sub-menu {
    opacity: 1; }
  @media only screen and (min-width: 991px) {
    .full-sidenav .navbar-nav {
      display: block;
      float: unset; }
      .full-sidenav .navbar-nav li a {
        display: block;
        padding: 18px 10px;
        font-size: 34px; }
      .full-sidenav .navbar-nav > li .mega-menu > li > a {
        color: var(--secondary) !important; }
      .full-sidenav .navbar-nav > li .sub-menu,
      .full-sidenav .navbar-nav > li .mega-menu {
        position: relative;
        visibility: visible;
        width: 100%;
        margin: 0;
        opacity: 1;
        box-shadow: none;
        transition: none !important;
        display: block;
        padding: 6px 0; }
        .full-sidenav .navbar-nav > li .sub-menu > li,
        .full-sidenav .navbar-nav > li .mega-menu > li {
          width: 100%;
          padding: 0; }
          .full-sidenav .navbar-nav > li .sub-menu > li ul,
          .full-sidenav .navbar-nav > li .mega-menu > li ul {
            margin: 10px 0px 20px; }
        .full-sidenav .navbar-nav > li .sub-menu li a,
        .full-sidenav .navbar-nav > li .mega-menu li a {
          font-size: 22px;
          padding: 8px 20px 8px 20px;
          color: #7e7e7e; }
          .full-sidenav .navbar-nav > li .sub-menu li a:after,
          .full-sidenav .navbar-nav > li .mega-menu li a:after {
            top: 22px; }
        .full-sidenav .navbar-nav > li .sub-menu li:hover a,
        .full-sidenav .navbar-nav > li .mega-menu li:hover a {
          padding: 8px 20px 8px 20px; }
    .full-sidenav .header-style-bx {
      display: block; }
      .full-sidenav .header-style-bx .header-style-tabs {
        width: 100%;
        padding: 10px 10px; }
        .full-sidenav .header-style-bx .header-style-tabs ul {
          display: flex; }
          .full-sidenav .header-style-bx .header-style-tabs ul li {
            width: 50%; }
            .full-sidenav .header-style-bx .header-style-tabs ul li a {
              text-align: center; }
    .full-sidenav .header-tabs-wraper .header-style-content .menu-list {
      display: block; }
      .full-sidenav .header-tabs-wraper .header-style-content .menu-list > li .title {
        font-size: 24px;
        padding: 10px 20px; }
      .full-sidenav .header-tabs-wraper .header-style-content .menu-list > li i {
        margin-right: 10px; } }
  @media only screen and (max-width: 991px) {
    .full-sidenav {
      width: 300px;
      left: -300px;
      padding-top: 0;
      padding-bottom: 30px;
      background: #fff;
      display: block !important;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s; }
      .full-sidenav.show {
        left: 0; }
      .full-sidenav .service-list {
        display: none; }
      .full-sidenav .navbar-nav li a {
        padding: 10px 0;
        font-size: 20px; }
      .full-sidenav .row {
        margin: 0; }
      .full-sidenav .col-lg-6 {
        padding: 0; }
      .full-sidenav .social-menu {
        margin-top: 25px;
        text-align: center; }
      .full-sidenav .container {
        height: auto;
        overflow-y: hidden; }
      .full-sidenav .nav {
        padding: 0; }
      .full-sidenav .flsbg {
        display: none; }
      .full-sidenav .navbar-nav > li > a.dz-open:after {
        content: "\f054" !important;
        background-color: var(--primary) !important; }
      .full-sidenav .navbar-nav > li > a:after {
        content: "\f078" !important;
        background-color: #333 !important; }
      .full-sidenav .navbar-nav > li .sub-menu .menu-item-has-children > a.dz-open:before {
        content: "\f078"; } }

.flsbg {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -2;
  display: flex; }
  .flsbg .bg-wrap {
    display: block;
    overflow: hidden;
    width: 33.333333%;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    position: relative;
    background-color: transparent;
    pointer-events: none;
    border: 1px solid #fff;
    margin: -1px;
    box-sizing: content-box; }
    .flsbg .bg-wrap:nth-child(1) {
      transition: 0.2s; }
    .flsbg .bg-wrap:nth-child(2) {
      transition: 0.4s; }
    .flsbg .bg-wrap:nth-child(3) {
      transition: 0.6s; }
    .flsbg .bg-wrap span {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      z-index: -2;
      background: #fff; }

.dz-bnr-inr {
  background: var(--gradient-sec);
  height: 600px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 100%; }
  @media only screen and (max-width: 991px) {
    .dz-bnr-inr {
      height: 300px; } }
  @media only screen and (max-width: 575px) {
    .dz-bnr-inr {
      height: 230px; } }
  .dz-bnr-inr .container {
    display: table;
    height: 100%; }
  .dz-bnr-inr .dz-bnr-inr-entry {
    height: 400px;
    vertical-align: middle;
    display: table-cell; }
    @media only screen and (max-width: 991px) {
      .dz-bnr-inr .dz-bnr-inr-entry {
        height: 300px; } }
    @media only screen and (max-width: 767px) {
      .dz-bnr-inr .dz-bnr-inr-entry {
        text-align: center; }
        .dz-bnr-inr .dz-bnr-inr-entry .breadcrumb-row {
          display: inline-block; } }
    @media only screen and (max-width: 575px) {
      .dz-bnr-inr .dz-bnr-inr-entry {
        height: 230px; } }
  .dz-bnr-inr h1, .dz-bnr-inr .h1 {
    font-weight: 700;
    font-size: 70px;
    margin-bottom: 10px;
    color: #fff;
    text-transform: capitalize; }
    @media only screen and (max-width: 991px) {
      .dz-bnr-inr h1, .dz-bnr-inr .h1 {
        font-size: 42px; } }
    @media only screen and (max-width: 767px) {
      .dz-bnr-inr h1, .dz-bnr-inr .h1 {
        font-size: 32px; } }
  .dz-bnr-inr.dz-bnr-inr-lg {
    height: 800px; }
    .dz-bnr-inr.dz-bnr-inr-lg .dz-bnr-inr-entry {
      height: 100%;
      padding-bottom: 0; }
    @media only screen and (max-width: 991px) {
      .dz-bnr-inr.dz-bnr-inr-lg {
        height: 460px; }
        .dz-bnr-inr.dz-bnr-inr-lg .dz-bnr-inr-entry {
          height: 460px;
          padding-bottom: 50px; } }
    @media only screen and (max-width: 767px) {
      .dz-bnr-inr.dz-bnr-inr-lg {
        height: 350px; }
        .dz-bnr-inr.dz-bnr-inr-lg .dz-bnr-inr-entry {
          height: 350px;
          padding-bottom: 0; } }
    @media only screen and (max-width: 575px) {
      .dz-bnr-inr.dz-bnr-inr-lg {
        height: 300px; }
        .dz-bnr-inr.dz-bnr-inr-lg .dz-bnr-inr-entry {
          height: 300px; } }
  .dz-bnr-inr.dz-bnr-inr-sm {
    height: 450px; }
    .dz-bnr-inr.dz-bnr-inr-sm .dz-bnr-inr-entry {
      height: 450px; }
    .dz-bnr-inr.dz-bnr-inr-sm h1, .dz-bnr-inr.dz-bnr-inr-sm .h1 {
      font-size: 60px; }
    @media only screen and (max-width: 991px) {
      .dz-bnr-inr.dz-bnr-inr-sm h1, .dz-bnr-inr.dz-bnr-inr-sm .h1 {
        font-size: 42px; } }
    @media only screen and (max-width: 767px) {
      .dz-bnr-inr.dz-bnr-inr-sm {
        height: 350px; }
        .dz-bnr-inr.dz-bnr-inr-sm .dz-bnr-inr-entry {
          height: 350px; }
        .dz-bnr-inr.dz-bnr-inr-sm h1, .dz-bnr-inr.dz-bnr-inr-sm .h1 {
          font-size: 32px; } }

.breadcrumb-row ul {
  display: inline-block; }
  .breadcrumb-row ul li {
    padding: 0;
    margin-right: 3px;
    display: inline-block;
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    font-family: var(--font-family-title); }
    .breadcrumb-row ul li.active {
      color: #fff; }
    .breadcrumb-row ul li a {
      color: #fff; }
  .breadcrumb-row ul .breadcrumb-item + .breadcrumb-item::before {
    content: "\f101";
    color: #fff;
    font-weight: 700;
    font-family: 'Line Awesome Free';
    font-size: 15px;
    padding-right: 10px;
    top: 5px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .breadcrumb-row ul .breadcrumb-item + .breadcrumb-item::before {
        top: 2px; } }
  @media only screen and (max-width: 767px) {
    .breadcrumb-row ul {
      padding: 5px 20px; }
      .breadcrumb-row ul li {
        font-size: 15px; } }

.site-header.style-1.header-transparent + .page-content .dz-bnr-inr .dz-bnr-inr-entry {
  padding-top: 160px; }
  @media only screen and (max-width: 991px) {
    .site-header.style-1.header-transparent + .page-content .dz-bnr-inr .dz-bnr-inr-entry {
      padding-top: 90px; } }

.dz-bnr-inr.style-2 {
  overflow: visible; }
  .dz-bnr-inr.style-2 h1, .dz-bnr-inr.style-2 .h1 {
    font-size: 80px;
    margin-bottom: 10px;
    line-height: 1.2;
    color: #fff;
    text-transform: capitalize; }
    @media only screen and (max-width: 991px) {
      .dz-bnr-inr.style-2 h1, .dz-bnr-inr.style-2 .h1 {
        font-size: 42px; } }
    @media only screen and (max-width: 767px) {
      .dz-bnr-inr.style-2 h1, .dz-bnr-inr.style-2 .h1 {
        font-size: 32px; } }
  .dz-bnr-inr.style-2 p {
    color: #fff;
    font-size: 18px;
    max-width: 560px; }
    @media only screen and (max-width: 575px) {
      .dz-bnr-inr.style-2 p {
        font-size: 14px; } }
  .dz-bnr-inr.style-2:after {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    height: 100%;
    width: 60%;
    background-color: #fff;
    opacity: .15;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%); }
  .dz-bnr-inr.style-2 .breadcrumb-row {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px; }
    .dz-bnr-inr.style-2 .breadcrumb-row ul {
      background: var(--primary);
      padding: 0;
      display: inline-block;
      padding: 15px 30px;
      margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .dz-bnr-inr.style-2 .breadcrumb-row {
        padding: 5px 20px; }
        .dz-bnr-inr.style-2 .breadcrumb-row li {
          font-size: 15px; } }
    @media only screen and (max-width: 991px) {
      .dz-bnr-inr.style-2 .breadcrumb-row {
        left: 15px;
        right: 15px;
        transform: translateX(0px); } }
  @media only screen and (max-width: 575px) {
    .dz-bnr-inr.style-2 {
      height: 300px; } }

.dz-bnr-inr.style-3:after {
  position: absolute;
  z-index: -2;
  content: "";
  height: 100%;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background: -moz-linear-gradient(left, var(--primary) 0%, rgba(125, 185, 232, 0) 100%);
  background: -webkit-linear-gradient(left, var(--primary) 0%, rgba(125, 185, 232, 0) 100%);
  background: linear-gradient(to right, var(--primary) 0%, rgba(125, 185, 232, 0) 100%); }

.dz-bnr-inr.style-3:before {
  height: 70px;
  width: 100%;
  background-color: var(--secondary);
  position: absolute;
  z-index: -1;
  content: "";
  bottom: 0px;
  left: 0px; }

.dz-bnr-inr.style-3 .sub-title {
  font-size: 20px;
  font-weight: 400;
  color: #fff; }

.dz-bnr-inr.style-3 h1, .dz-bnr-inr.style-3 .h1 {
  line-height: 1;
  margin-bottom: 70px; }

.dz-bnr-inr.style-3 .dz-bnr-inr-entry {
  vertical-align: bottom; }

.dz-bnr-inr.style-3 .breadcrumb-row {
  margin-bottom: 5px; }

.dz-bnr-inr.style-4 {
  text-align: center; }
  .dz-bnr-inr.style-4:after {
    content: "";
    height: calc(100% - 160px);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url("../images/pattern1.png");
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0; }
    @media only screen and (max-width: 991px) {
      .dz-bnr-inr.style-4:after {
        height: calc(100% - 90px); } }
  .dz-bnr-inr.style-4 h1, .dz-bnr-inr.style-4 .h1 {
    color: #fff; }
  .dz-bnr-inr.style-4 .breadcrumb-row {
    position: relative;
    left: 0;
    transform: unset;
    bottom: 0; }
    .dz-bnr-inr.style-4 .breadcrumb-row ul {
      padding: 0;
      background: transparent; }
      .dz-bnr-inr.style-4 .breadcrumb-row ul li {
        font-weight: 500;
        color: #fff; }
        .dz-bnr-inr.style-4 .breadcrumb-row ul li a {
          color: #fff; }

.dz-bnr-inr.style-5 {
  text-align: center; }
  .dz-bnr-inr.style-5 p {
    margin-left: auto;
    margin-right: auto;
    max-width: 560px;
    color: #fff; }
  .dz-bnr-inr.style-5:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    clip-path: unset;
    background-image: url("../images/pattern2.png");
    width: 100%;
    height: 100%;
    background-size: cover;
    opacity: 1;
    background-color: transparent; }
  .dz-bnr-inr.style-5 .breadcrumb-row ul {
    background: var(--primary);
    padding: 0;
    display: inline-block;
    padding: 15px 30px; }
  @media only screen and (max-width: 991px) {
    .dz-bnr-inr.style-5 {
      height: 400px; } }
  @media only screen and (max-width: 575px) {
    .dz-bnr-inr.style-5 {
      height: 350px; } }

.page-title {
  padding: 30px 0; }
  .page-title.dashboard {
    background: transparent;
    margin-left: 75px;
    padding: 20px 0 0px; }
  .page-title .page-title-content p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px; }
    .page-title .page-title-content p span {
      font-weight: 500;
      color: var(--primary); }

.breadcrumbs {
  display: flex;
  justify-content: flex-end; }
  @media only screen and (max-width: 575px) {
    .breadcrumbs {
      justify-content: flex-start; } }
  .breadcrumbs li a {
    color: #AEAED5;
    display: inline-block;
    margin-left: 15px;
    font-size: 14px; }
  .breadcrumbs li:first-child a {
    margin-left: 0px; }
  .breadcrumbs li.active a {
    color: var(--primary); }

.bottom {
  background: #1b2a4e; }
  @media only screen and (max-width: 575px) {
    .bottom {
      padding-bottom: 0; } }
  .bottom .bottom-logo p {
    line-height: 30px;
    font-weight: 400;
    color: #888888; }
  @media only screen and (max-width: 991px) {
    .bottom .bottom-widget {
      margin-bottom: 30px; } }
  .bottom .bottom-widget .widget-title {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px;
    text-transform: uppercase; }
  .bottom .bottom-widget ul li a {
    color: #888888;
    font-weight: 400;
    margin-bottom: 10px;
    display: inline-block; }

footer {
  color: rgba(255, 255, 255, 0.6); }
  footer.site-footer {
    --title: #fff; }
  footer strong {
    color: #fff; }
  footer .footer-logo {
    margin-bottom: 20px;
    width: 140px; }
    footer .footer-logo img {
      max-width: 100%; }
  footer p {
    font-size: inherit;
    color: inherit; }
  footer .widget-logo ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    footer .widget-logo ul li {
      display: inline-block;
      flex: 50%;
      padding: 0 15px 15px 0px; }
  footer .widget_about p {
    margin-bottom: 20px; }
  footer .footer-title {
    margin: 0px 0px 30px;
    font-weight: 500;
    position: relative;
    color: #fff;
    line-height: 1.2; }
    footer .footer-title .dz-separator {
      position: absolute;
      bottom: 0;
      left: 0; }
  footer .widget:hover .footer-title span:after {
    width: 5px; }
  footer .widget:hover .footer-title span:before {
    width: 55px; }
  footer .footer-top {
    padding: 70px 0 20px; }
    @media only screen and (max-width: 767px) {
      footer .footer-top {
        padding: 50px 0 0; } }
  footer .footer-bottom {
    background-color: #36404a;
    padding: 15px 0; }
    footer .footer-bottom .copyright-text {
      color: rgba(255, 255, 255, 0.4);
      font-size: 14px;
      font-weight: 400; }
    footer .footer-bottom .dz-social-icon ul li a {
      width: 30px;
      height: 30px;
      line-height: 30px; }
      footer .footer-bottom .dz-social-icon ul li a:hover {
        background-color: #fff;
        color: #192436 !important;
        border-color: #fff; }
    @media only screen and (max-width: 991px) {
      footer .footer-bottom {
        text-align: center !important; }
        footer .footer-bottom .text-left,
        footer .footer-bottom .text-right {
          text-align: center !important; }
        footer .footer-bottom .text-right {
          margin-top: 10px; } }
  footer .widget-link li {
    display: inline-block;
    text-transform: uppercase;
    margin-left: 20px; }
    @media only screen and (max-width: 767px) {
      footer .widget-link li {
        margin-left: 8px;
        margin-right: 7px; } }
  footer .widget-link a {
    color: #fff; }
  footer .widget_services ul li a {
    color: inherit;
    display: block;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  footer .widget_services.style-1 ul li a {
    padding: 5px 0 5px 15px; }
    footer .widget_services.style-1 ul li a:before {
      content: "\f105";
      font-family: fontawesome;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: var(--primary); }
  footer .wp-block-latest-posts li,
  footer .wp-block-categories-list li,
  footer .wp-block-archives-list li,
  footer .widget_categories ul li,
  footer .widget_archive ul li,
  footer .widget_meta ul li,
  footer .widget_pages ul li,
  footer .widget_recent_comments ul li,
  footer .widget_nav_menu li,
  footer .widget_recent_entries ul li,
  footer .widget_services ul li {
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s; }
  footer .widget_rss ul li {
    --title: #fff;
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1); }
  footer .wp-block-calendar table caption,
  footer .wp-block-calendar table tbody {
    color: #fff; }
  footer .wp-block-tag-cloud a,
  footer .widget_tag_cloud .tagcloud a {
    border-color: rgba(255, 255, 255, 0.1); }
  footer .wp-block-search,
  footer .wp-block-archives,
  footer .wp-block-latest-posts,
  footer .wp-block-latest-comments,
  footer .wp-block-categories,
  footer .wp-block-calendar {
    margin-bottom: 0; }
  footer .dz-meta ul li span,
  footer .dz-meta ul li a {
    color: var(--primary); }
  footer .dz-social-icon ul li {
    padding: 0 10px 0 0px; }

.widget_getintuch ul li {
  position: relative;
  margin-bottom: 15px;
  padding-left: 35px;
  color: inherit; }
  .widget_getintuch ul li:last-child {
    margin-bottom: 0; }
  @media only screen and (max-width: 1199px) {
    .widget_getintuch ul li {
      margin-bottom: 20px;
      padding-left: 50px; } }
  .widget_getintuch ul li h5, .widget_getintuch ul li .h5 {
    font-size: 20px;
    line-height: 1.33;
    margin-bottom: 5px; }
    @media only screen and (max-width: 1199px) {
      .widget_getintuch ul li h5, .widget_getintuch ul li .h5 {
        font-size: 18px;
        line-height: 1.2; } }
  .widget_getintuch ul li i {
    position: absolute;
    font-size: 24px;
    line-height: 1;
    left: 0;
    top: 8px;
    color: var(--primary); }
    @media only screen and (max-width: 1199px) {
      .widget_getintuch ul li i {
        font-size: 35px;
        top: 6px; } }

.contact-ft-1 {
  text-align: center;
  margin-bottom: 40px; }
  .contact-ft-1 i {
    color: var(--primary);
    font-size: 50px;
    line-height: 1;
    margin-bottom: 15px;
    display: inline-block; }
  .contact-ft-1 h4, .contact-ft-1 .h4, .contact-ft-1 .comment-reply-title,
  .contact-ft-1 .wp-block-search .wp-block-search__label, .wp-block-search .contact-ft-1 .wp-block-search__label {
    color: #fff;
    text-decoration: underline; }
  .contact-ft-1 h5, .contact-ft-1 .h5 {
    color: #fff;
    font-weight: 400; }

.list-column ul {
  display: flex;
  flex-wrap: wrap; }
  .list-column ul li {
    flex: 0 0 50%;
    max-width: 50%; }

.fb-link {
  list-style: none;
  margin: 0;
  padding: 0; }
  .fb-link li {
    display: inline-block; }
    .fb-link li a {
      color: #fff;
      position: relative; }
      .fb-link li a:after {
        content: "";
        background: var(--primary);
        width: 5px;
        height: 5px; }

.footer-link li {
  display: inline-block;
  position: relative;
  padding: 0 25px 0 20px; }
  .footer-link li a {
    color: inherit; }
  .footer-link li:before {
    content: "";
    height: 8px;
    width: 8px;
    background-color: var(--primary);
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 8px;
    transform: translateY(-50%); }

.widget_time ul li {
  display: block;
  margin-bottom: 5px; }
  .widget_time ul li label {
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 0;
    margin-right: 3px; }

.dz-subscription .form-control, .dz-subscription .wp-block-categories-dropdown select, .wp-block-categories-dropdown .dz-subscription select,
.dz-subscription .wp-block-archives-dropdown select, .wp-block-archives-dropdown .dz-subscription select {
  font-size: 18px; }

.site-footer.style-1 {
  background-color: #192436; }
  .site-footer.style-1 .footer-top {
    padding: 80px 0px 40px; }
  .site-footer.style-1 .footer-bottom {
    background-color: transparent;
    padding: 0; }
    .site-footer.style-1 .footer-bottom .ft-inner {
      border-top: 2px solid rgba(255, 255, 255, 0.1);
      padding: 25px 0; }
  .site-footer.style-1 .recent-posts-entry .widget-post-bx .widget-post ul {
    margin-bottom: 10px; }
  .site-footer.style-1 .dz-social-icon ul li a {
    color: #fff !important; }
    .site-footer.style-1 .dz-social-icon ul li a:hover {
      background-color: #fff;
      color: #192436 !important;
      border-color: #fff; }
  .site-footer.style-1 .widget_services > ul {
    margin-top: -.5rem; }
  .site-footer.style-1 .widget_about {
    padding-right: 10px; }

.call-box2 {
  color: var(--primary);
  position: relative;
  font-size: 20px;
  padding: 7px 10px 7px 55px;
  font-family: var(--font-family-title);
  font-weight: 500; }
  .call-box2 span {
    position: absolute;
    top: 0;
    left: 0;
    height: 45px;
    width: 45px;
    border-radius: 45px;
    line-height: 45px;
    text-align: center;
    color: var(--primary);
    background: #fff;
    font-weight: 800; }
    .call-box2 span:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      border: 1px solid #fff;
      -webkit-border-radius: 50%;
      -khtml-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 100%;
      animation: animationSignal1;
      animation-iteration-count: infinite;
      animation-duration: 3s;
      -webkit-animation: animationSignal1;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-duration: 3s;
      z-index: 1;
      transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      -moz-transition: all 0.5;
      -o-transition: all 0.5;
      -webkit-transition: all 0.5;
      -ms-transition: all 0.5;
      transition: all 0.5; }
    .call-box2 span:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      border: 1px solid #fff;
      -webkit-border-radius: 100%;
      -khtml-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
      border-radius: 100%;
      animation: animationSignal2;
      animation-iteration-count: infinite;
      animation-duration: 3s;
      -webkit-animation: animationSignal2;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-duration: 3s;
      z-index: 1;
      transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      -moz-transition: all 0.5;
      -o-transition: all 0.5;
      -webkit-transition: all 0.5;
      -ms-transition: all 0.5;
      transition: all 0.5; }

.site-footer.style-2 .footer-top {
  background-color: #0f0f0f;
  background-size: cover;
  background-position: center;
  padding-bottom: 0;
  margin-bottom: -45px;
  padding-top: 80px; }
  @media only screen and (max-width: 767px) {
    .site-footer.style-2 .footer-top {
      padding-top: 50px; } }

.site-footer.style-2 .footer-bottom {
  background-color: #000000;
  padding-top: 70px;
  padding-bottom: 30px; }

.site-footer.style-2 .footer-info {
  background: var(--primary);
  position: relative; }
  .site-footer.style-2 .footer-info .info-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    padding-right: 30px; }
    @media only screen and (max-width: 1200px) {
      .site-footer.style-2 .footer-info .info-right {
        padding-bottom: 0px; }
        .site-footer.style-2 .footer-info .info-right .btn, .site-footer.style-2 .footer-info .info-right .wp-block-button__link {
          display: none; } }
    @media only screen and (max-width: 991px) {
      .site-footer.style-2 .footer-info .info-right {
        padding-left: 30px; } }
    @media only screen and (max-width: 575px) {
      .site-footer.style-2 .footer-info .info-right {
        display: block;
        padding-left: 15px;
        padding-right: 15px; } }
  .site-footer.style-2 .footer-info .dz-media {
    height: 100%; }
    .site-footer.style-2 .footer-info .dz-media img {
      height: 100%;
      object-fit: cover;
      width: 100%; }
    .site-footer.style-2 .footer-info .dz-media .popup-youtube {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 50px;
      width: 50px;
      line-height: 50px;
      color: #fff;
      border-width: 3px;
      font-size: 18px;
      transform: translate(-50%, -50%); }
  .site-footer.style-2 .footer-info .service-info {
    display: flex; }
    .site-footer.style-2 .footer-info .service-info p {
      margin-bottom: 0;
      color: #fff; }
    .site-footer.style-2 .footer-info .service-info li {
      padding: 17px 20px;
      border-right: 2px solid #f14306; }
      .site-footer.style-2 .footer-info .service-info li:last-child {
        border-right: 0;
        padding-right: 0; }
      .site-footer.style-2 .footer-info .service-info li:first-child {
        padding-left: 0; }
    @media only screen and (max-width: 575px) {
      .site-footer.style-2 .footer-info .service-info {
        display: block; }
        .site-footer.style-2 .footer-info .service-info li {
          border: 0;
          border-bottom: 2px solid #f14306;
          padding: 15px 0; } }
  .site-footer.style-2 .footer-info .title {
    color: #fff;
    margin-bottom: 0;
    font-weight: 500; }

.site-footer.style-2 .dzSubscribe {
  background: #2c2c2c;
  padding: 30px; }
  .site-footer.style-2 .dzSubscribe span {
    font-size: 11px; }
  .site-footer.style-2 .dzSubscribe .ft-row {
    position: relative; }
    .site-footer.style-2 .dzSubscribe .ft-row .form-control, .site-footer.style-2 .dzSubscribe .ft-row .wp-block-categories-dropdown select, .wp-block-categories-dropdown .site-footer.style-2 .dzSubscribe .ft-row select,
    .site-footer.style-2 .dzSubscribe .ft-row .wp-block-archives-dropdown select, .wp-block-archives-dropdown .site-footer.style-2 .dzSubscribe .ft-row select {
      border-radius: 0;
      height: 50px;
      padding-right: 50px; }
    .site-footer.style-2 .dzSubscribe .ft-row .btn, .site-footer.style-2 .dzSubscribe .ft-row .wp-block-button__link {
      padding: 0;
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      right: 0;
      justify-content: center;
      color: var(--primary); }
      .site-footer.style-2 .dzSubscribe .ft-row .btn:hover, .site-footer.style-2 .dzSubscribe .ft-row .wp-block-button__link:hover {
        color: #fff;
        background: var(--primary); }

.ft-clients {
  background-color: #fff;
  border-bottom: 10px solid var(--primary);
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 1;
  position: relative; }

.ft-clients-area {
  position: relative; }
  .ft-clients-area:before {
    content: "";
    position: absolute;
    height: 50%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #fff; }
  .ft-clients-area.bg-gray {
    background-color: transparent; }
    .ft-clients-area.bg-gray:before {
      background-color: #f7f9fb; }

.footer-logo-head {
  border-bottom: 1px dashed #959595;
  margin-bottom: 50px;
  padding-bottom: 20px; }
  @media only screen and (max-width: 575px) {
    .footer-logo-head {
      margin-bottom: 30px;
      padding-bottom: 40px; } }

.site-footer.style-3 {
  position: relative;
  z-index: 1;
  overflow: hidden; }
  .site-footer.style-3 .footer-top {
    padding-top: 100px;
    padding-bottom: 40px;
    position: relative;
    z-index: 1; }
    .site-footer.style-3 .footer-top:after {
      content: "";
      height: 220px;
      width: 220px;
      position: absolute;
      bottom: 0;
      left: 100px;
      background-image: url("../images/pattern3.png");
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: -1;
      -webkit-animation: move1 5s infinite;
      animation: move1 5s infinite; }
    .site-footer.style-3 .footer-top:before {
      content: "";
      height: 197px;
      width: 197px;
      position: absolute;
      right: 100px;
      top: 50px;
      background-image: url("../images/pattern4.png");
      background-repeat: no-repeat;
      background-size: 100%;
      z-index: -1;
      -webkit-animation: move2 5s infinite;
      animation: move2 5s infinite; }
    @media only screen and (max-width: 1200px) {
      .site-footer.style-3 .footer-top {
        padding-top: 80px;
        padding-bottom: 20px; } }
    @media only screen and (max-width: 767px) {
      .site-footer.style-3 .footer-top {
        padding-top: 50px;
        padding-bottom: 20px; } }
  .site-footer.style-3 .widget_services ul {
    margin-top: -.5rem; }
  .site-footer.style-3 .footer-title {
    position: relative;
    padding-bottom: 0;
    margin-bottom: 30px; }
  .site-footer.style-3 .recent-posts-entry .widget-post-bx .widget-post .title a {
    color: white; }
    .site-footer.style-3 .recent-posts-entry .widget-post-bx .widget-post .title a:hover {
      color: var(--primary); }
  .site-footer.style-3 .footer-bottom {
    border: 0;
    background-color: transparent;
    border-top: 1px solid #32434f;
    border-style: dashed; }
    @media only screen and (max-width: 767px) {
      .site-footer.style-3 .footer-bottom .copyright-text {
        margin-bottom: 10px;
        display: block; } }
  .site-footer.style-3 .footer-link li {
    color: rgba(255, 255, 255, 0.8); }
  .site-footer.style-3:after {
    content: "";
    position: absolute;
    top: -200px;
    left: -200px;
    z-index: -1;
    opacity: .7;
    height: 500px;
    width: 500px;
    background: radial-gradient(at center center, var(--rgba-primary-2) 30%, var(--rgba-primary-2) 35%, rgba(255, 73, 23, 0) 76%, rgba(255, 73, 23, 0) 96%); }
  .site-footer.style-3:before {
    content: "";
    position: absolute;
    bottom: -200px;
    right: -200px;
    z-index: -1;
    opacity: .7;
    height: 500px;
    width: 500px;
    background: radial-gradient(at center center, var(--rgba-primary-2) 30%, var(--rgba-primary-2) 35%, rgba(255, 73, 23, 0) 76%, rgba(255, 73, 23, 0) 96%); }

.site-footer.style-4 .footer-icon-wraper {
  border: 0;
  border-bottom: 1px solid #43507d;
  border-style: dashed;
  margin-bottom: 40px;
  padding-bottom: 10px; }

.site-footer.style-4 .widget_gallery li img {
  height: 90px;
  object-fit: cover; }

.site-footer.style-4 .input-group input {
  background: transparent; }
  .site-footer.style-4 .input-group input::placeholder {
    color: #aab4ba; }

.site-footer.style-4 .input-group .form-control, .site-footer.style-4 .input-group .wp-block-categories-dropdown select, .wp-block-categories-dropdown .site-footer.style-4 .input-group select,
.site-footer.style-4 .input-group .wp-block-archives-dropdown select, .wp-block-archives-dropdown .site-footer.style-4 .input-group select {
  height: 50px;
  color: inherit; }

.site-footer.style-4 .input-group .input-group-addon {
  position: absolute;
  top: 5px;
  right: 5px; }
  .site-footer.style-4 .input-group .input-group-addon .btn, .site-footer.style-4 .input-group .input-group-addon .wp-block-button__link {
    padding: 0;
    width: 40px;
    height: 40px;
    z-index: 5; }
    .site-footer.style-4 .input-group .input-group-addon .btn i, .site-footer.style-4 .input-group .input-group-addon .wp-block-button__link i {
      font-size: 16px; }

.site-footer.style-4 .footer-top {
  background: var(--secondary); }

.site-footer.style-4 .footer-bottom {
  background: #0f1724; }

.site-footer.style-4 .widget_services ul {
  margin-top: -.5rem; }

.site-footer.style-4 .footer-title {
  position: relative;
  padding-bottom: 0;
  margin-bottom: 30px; }

.site-footer.style-4 .recent-posts-entry .widget-post-bx .widget-post .title a {
  color: white; }

.site-footer.style-4 .footer-link li {
  color: rgba(255, 255, 255, 0.8); }

.site-footer.style-4 .footer-bottom {
  color: rgba(255, 255, 255, 0.8);
  text-align: center; }
  .site-footer.style-4 .footer-bottom a {
    color: var(--primary); }

.site-footer.style-4 .ft-subscribe {
  margin-bottom: 30px;
  border: 1px solid #9da2a6;
  border-radius: var(--border-radius-base);
  padding: 30px 40px; }
  .site-footer.style-4 .ft-subscribe .ft-row {
    display: flex; }
    .site-footer.style-4 .ft-subscribe .ft-row .form-control, .site-footer.style-4 .ft-subscribe .ft-row .wp-block-categories-dropdown select, .wp-block-categories-dropdown .site-footer.style-4 .ft-subscribe .ft-row select,
    .site-footer.style-4 .ft-subscribe .ft-row .wp-block-archives-dropdown select, .wp-block-archives-dropdown .site-footer.style-4 .ft-subscribe .ft-row select {
      flex: 1;
      margin-right: 15px; }
  @media only screen and (max-width: 575px) {
    .site-footer.style-4 .ft-subscribe {
      padding: 30px 30px; }
      .site-footer.style-4 .ft-subscribe .ft-row {
        display: block;
        text-align: center; }
        .site-footer.style-4 .ft-subscribe .ft-row .form-control, .site-footer.style-4 .ft-subscribe .ft-row .wp-block-categories-dropdown select, .wp-block-categories-dropdown .site-footer.style-4 .ft-subscribe .ft-row select,
        .site-footer.style-4 .ft-subscribe .ft-row .wp-block-archives-dropdown select, .wp-block-archives-dropdown .site-footer.style-4 .ft-subscribe .ft-row select {
          margin-bottom: 20px; } }

.site-footer.style-4 .dz-social-icon ul li a:hover {
  background-color: #fff;
  color: #192436 !important;
  border-color: #fff; }

.site-footer img {
  border-radius: var(--border-radius-base); }

.site-footer.style-5 .footer-top {
  background: var(--secondary); }

.site-footer.style-5 .footer-bottom {
  background: #0f1724; }

.site-footer.style-5 .widget_services ul {
  margin-top: -.5rem; }

.site-footer.style-5 .footer-title {
  position: relative;
  padding-bottom: 0;
  margin-bottom: 30px; }

.site-footer.style-5 .recent-posts-entry .widget-post-bx .widget-post .title a {
  color: white; }

.site-footer.style-5 .footer-link li {
  color: rgba(255, 255, 255, 0.8); }

.site-footer.style-5 .footer-bottom {
  color: rgba(255, 255, 255, 0.8);
  text-align: center; }
  .site-footer.style-5 .footer-bottom a {
    color: var(--primary); }

.site-footer.style-5 .ft-subscribe {
  margin-bottom: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 30px 40px; }
  .site-footer.style-5 .ft-subscribe .ft-row {
    display: flex; }
    .site-footer.style-5 .ft-subscribe .ft-row .form-control, .site-footer.style-5 .ft-subscribe .ft-row .wp-block-categories-dropdown select, .wp-block-categories-dropdown .site-footer.style-5 .ft-subscribe .ft-row select,
    .site-footer.style-5 .ft-subscribe .ft-row .wp-block-archives-dropdown select, .wp-block-archives-dropdown .site-footer.style-5 .ft-subscribe .ft-row select {
      flex: 1;
      margin-right: 15px;
      border-radius: 0 !important; }
  @media only screen and (max-width: 575px) {
    .site-footer.style-5 .ft-subscribe {
      padding: 30px 30px; }
      .site-footer.style-5 .ft-subscribe .ft-row {
        display: block;
        text-align: center; }
        .site-footer.style-5 .ft-subscribe .ft-row .form-control, .site-footer.style-5 .ft-subscribe .ft-row .wp-block-categories-dropdown select, .wp-block-categories-dropdown .site-footer.style-5 .ft-subscribe .ft-row select,
        .site-footer.style-5 .ft-subscribe .ft-row .wp-block-archives-dropdown select, .wp-block-archives-dropdown .site-footer.style-5 .ft-subscribe .ft-row select {
          margin-bottom: 20px; }
      .site-footer.style-5 .ft-subscribe .footer-title {
        margin-bottom: 20px; } }

.site-footer.style-5 .dz-social-icon ul li a:hover {
  background-color: #fff;
  color: #192436 !important;
  border-color: #fff; }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .header-nav .nav > li.sub-menu-down > a:after, [dir='rtl'] .header-nav .nav > li.has-mega-menu > a:after {
    content: "\f053"; } }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .header-nav .nav > li.sub-menu-down.open > a:after, [dir='rtl'] .header-nav .nav > li.has-mega-menu.open > a:after {
    content: "\f078"; } }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .header-nav .nav > li .mega-menu li a:after,
  [dir='rtl'] .header-nav .nav > li .sub-menu li a:after {
    content: "\f053"; } }

[dir='rtl'] .header-nav .nav > li .sub-menu.open > a > i:before {
  content: "\f078"; }

[dir='rtl'] .header-nav .nav > li .sub-menu > li.open > a i:before {
  content: "\f107"; }

[dir='rtl'] .site-header.style-1:before {
  left: auto;
  right: calc(calc(100% - 1140px) / 2 + 280px);
  transform: skewX(-20deg); }
  @media only screen and (max-width: 1199px) {
    [dir='rtl'] .site-header.style-1:before {
      left: auto;
      right: calc(calc(100% - 960px) / 2 + 280px); } }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .site-header.style-1:before {
      left: auto;
      right: calc(calc(100% - 720px) / 2 + 280px); } }
  @media only screen and (max-width: 767px) {
    [dir='rtl'] .site-header.style-1:before {
      left: auto;
      right: calc(calc(100% - 540px) / 2 + 280px); } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .site-header.style-1:before {
      left: auto;
      right: 210px; } }

[dir='rtl'] .site-header.style-1:after {
  right: auto;
  left: 0; }

[dir='rtl'] .site-header.style-1 .main-bar-wraper:after {
  left: auto;
  right: -40px;
  transform: skewX(-20deg); }

[dir='rtl'] .site-header.style-1 .top-bar .dz-topbar-inner {
  margin-left: 0;
  margin-right: calc(calc(100% - 1140px) / 2 + 295px); }
  @media only screen and (max-width: 1199px) {
    [dir='rtl'] .site-header.style-1 .top-bar .dz-topbar-inner {
      margin-right: 0; } }

[dir='rtl'] .site-header.style-1 .main-bar:before {
  transform: skew(-20deg);
  border-left: 0;
  border-right: 3px solid var(--primary);
  left: auto;
  right: calc(calc(100% - 1140px) / 2 + 220px); }

[dir='rtl'] .site-header.style-1 .main-bar:after {
  right: auto;
  left: 0; }

@media only screen and (max-width: 1199px) {
  [dir='rtl'] .site-header.style-1 .main-bar:before {
    left: auto;
    right: calc(calc(100% - 960px) / 2 + 220px); } }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .site-header.style-1 .main-bar:before {
    left: auto;
    right: calc(calc(100% - 720px) / 2 + 220px); } }

@media only screen and (max-width: 767px) {
  [dir='rtl'] .site-header.style-1 .main-bar:before {
    left: auto;
    right: calc(calc(100% - 540px) / 2 + 220px); } }

@media only screen and (max-width: 575px) {
  [dir='rtl'] .site-header.style-1 .main-bar:before {
    left: auto;
    right: 175px; } }

@media only screen and (min-width: 991px) {
  [dir='rtl'] .site-header.style-1 .header-nav {
    padding-left: 0;
    padding-right: 130px; } }
  @media only screen and (min-width: 991px) and (max-width: 1199px) {
    [dir='rtl'] .site-header.style-1 .header-nav {
      padding-left: 0;
      padding-right: 60px; } }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .site-header.style-2 .is-fixed .navbar-toggler {
    margin: 15px 15px 15px 0; } }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .site-header.style-2 .navbar-toggler {
    margin: 30px 15px 30px 0; } }

@media only screen and (max-width: 575px) {
  [dir='rtl'] .site-header.style-2 .navbar-toggler {
    margin: 15px 15px 15px 0; } }

[dir='rtl'] .site-header.style-2 .extra-nav {
  padding-left: 0;
  padding-right: 50px; }
  @media only screen and (max-width: 1200px) {
    [dir='rtl'] .site-header.style-2 .extra-nav {
      padding-left: 0;
      padding-right: 30px; } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .site-header.style-2 .extra-nav {
      padding-right: 0; } }

@media only screen and (min-width: 991px) {
  [dir='rtl'] .site-header.style-2 .header-nav .nav > li > a:before {
    left: auto;
    right: 50%;
    margin-right: 0;
    transform: translateX(50%); } }

[dir='rtl'] .site-header.style-2 .menu-btn {
  margin-left: 0;
  margin-right: 30px; }
  [dir='rtl'] .site-header.style-2 .menu-btn span {
    left: auto;
    right: 18px; }
  @media only screen and (max-width: 1400px) {
    [dir='rtl'] .site-header.style-2 .menu-btn span {
      left: auto;
      right: 12px; } }
  @media only screen and (max-width: 1200px) {
    [dir='rtl'] .site-header.style-2 .menu-btn {
      margin-left: auto;
      margin-right: 30px; } }
  [dir='rtl'] .site-header.style-2 .menu-btn.open span:nth-child(1) {
    transform: rotate(-45deg); }
  [dir='rtl'] .site-header.style-2 .menu-btn.open span:nth-child(3) {
    transform: rotate(45deg); }

[dir='rtl'] .site-header.style-3 .logo-header {
  margin-right: 0;
  margin-left: 60px; }
  @media only screen and (max-width: 1400px) {
    [dir='rtl'] .site-header.style-3 .logo-header {
      margin-right: auto;
      margin-left: 40px; } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .site-header.style-3 .logo-header {
      margin-left: 0; } }

[dir='rtl'] .site-header.style-3 .extra-nav {
  padding-left: 80px;
  margin-right: 0;
  padding-right: 0; }
  @media only screen and (max-width: 1200px) {
    [dir='rtl'] .site-header.style-3 .extra-nav {
      padding-left: 0; } }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .site-header.style-3 .extra-nav {
      padding-left: 10px; } }

[dir='rtl'] .site-header.style-3 .top-bar .help-text strong {
  padding-left: 10px; }

[dir='rtl'] .search-inhead {
  margin-left: 0;
  margin-right: 30px;
  padding-left: 0;
  padding-right: 20px;
  border-right: 0;
  border-left: 1px solid #d7d7d7;
  margin-right: 0;
  margin-left: 30px;
  padding-left: 30px;
  padding-right: 0; }
  @media only screen and (min-width: 1280px) {
    [dir='rtl'] .search-inhead #quik-search-btn {
      right: auto;
      left: 30px;
      transform: translateY(-50%); } }
  @media only screen and (max-width: 1200px) {
    [dir='rtl'] .search-inhead {
      margin-left: 0;
      padding-right: 20px;
      padding-left: 20px;
      border-left: 0; } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .search-inhead {
      padding-left: 0; } }

[dir='rtl'] .site-header.style-4 .logo-header {
  padding-right: 70px;
  padding-left: 40px; }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .site-header.style-4 .logo-header {
      padding-right: 15px;
      padding-left: 15px; } }

[dir='rtl'] .site-header.style-4 .search-inhead {
  margin-left: 0;
  border-left: 0;
  padding-right: 25px;
  padding-left: 0; }
  @media only screen and (max-width: 1200px) {
    [dir='rtl'] .site-header.style-4 .search-inhead {
      padding-right: 0;
      padding-left: 25px; } }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .site-header.style-4 .search-inhead {
      padding-right: 0;
      padding-left: 10px; } }

[dir='rtl'] .site-header.style-5 .top-bar .dz-topbar-left ul li {
  border-right: 0;
  border-left: 1px solid #e3e3e3;
  margin-right: 0;
  margin-left: 20px; }
  [dir='rtl'] .site-header.style-5 .top-bar .dz-topbar-left ul li:last-child {
    border-left: 0; }

[dir='rtl'] .site-header.style-5 .top-bar .dz-social li {
  border-left: 0;
  border-right: 1px solid #e3e3e3; }
  [dir='rtl'] .site-header.style-5 .top-bar .dz-social li:last-child {
    border-left: 1px solid #e3e3e3; }

[dir='rtl'] .site-header.style-5 .service-info {
  margin-right: 0;
  margin-left: 30px; }

[dir='rtl'] .site-header.header-sidenav-1 .header-nav .nav > li .sub-menu li > .sub-menu {
  right: auto;
  left: 0; }

[dir='rtl'] .site-header.header-sidenav-1 .header-nav .nav .sub-menu {
  right: auto;
  left: 0; }

@media only screen and (min-width: 767px) {
  [dir='rtl'] .site-header.header-sidenav-1 .header-nav .nav .sub-menu-down > a:after {
    right: auto;
    left: 20px;
    margin-right: 10px;
    margin-left: 0;
    transform: translateY(-50%); }
  [dir='rtl'] .site-header.header-sidenav-1 .header-nav .nav .sub-menu-down > a.dz-open:after {
    transform: translateY(-50%) rotate(90deg); } }

@media only screen and (max-width: 767px) {
  [dir='rtl'] .site-header.header-sidenav-1 .header-nav .nav .sub-menu-down .sub-menu-down > a:before {
    content: "\f078";
    right: auto;
    left: 0px;
    float: left; }
  [dir='rtl'] .site-header.header-sidenav-1 .header-nav .nav .sub-menu-down .sub-menu-down > a.dz-open:before {
    transform: rotate(90deg); } }

[dir='rtl'] .site-header.header-sidenav-1 .nav-elements span {
  margin-right: 0;
  float: right;
  margin-left: 10px;
  margin-top: 5px; }

[dir='rtl'] .site-header.header-sidenav-1 .extra-nav {
  padding-left: 0;
  padding-right: 80px; }
  @media only screen and (max-width: 1200px) {
    [dir='rtl'] .site-header.header-sidenav-1 .extra-nav {
      padding-left: 0;
      padding-right: 15px; } }

[dir='rtl'] .site-header.header-sidenav-1 .menu-btn span {
  right: auto;
  left: 9px; }

[dir='rtl'] .site-header.header-sidenav-1 .menu-btn.open span:nth-child(1) {
  transform: rotate(-45deg); }

[dir='rtl'] .site-header.header-sidenav-1 .menu-btn.open span:nth-child(2) {
  right: auto;
  left: 40px; }

[dir='rtl'] .site-header.header-sidenav-1 .menu-btn.open span:nth-child(3) {
  transform: rotate(45deg); }

@media only screen and (max-width: 575px) {
  [dir='rtl'] .site-header.header-sidenav-1 .menu-btn span {
    right: auto;
    left: 10px; } }

[dir='rtl'] .service-list {
  margin-right: auto;
  margin-left: 0; }
  [dir='rtl'] .service-list ul li {
    padding: 0 70px 0 0;
    margin-left: 0;
    margin-right: 30px; }
    [dir='rtl'] .service-list ul li i {
      left: auto;
      right: 0; }
  @media only screen and (max-width: 1200px) {
    [dir='rtl'] .service-list ul li {
      margin-left: auto;
      margin-right: 20p;
      padding: 0px 52px 0px 0; } }

[dir='rtl'] .full-sidenav {
  left: auto;
  right: 0; }
  [dir='rtl'] .full-sidenav .social-menu li {
    margin-right: 0;
    margin-left: 10px; }
  [dir='rtl'] .full-sidenav .service-list ul li {
    margin-right: 0; }
  @media only screen and (min-width: 991px) {
    [dir='rtl'] .full-sidenav .header-tabs-wraper .header-style-content .menu-list > li i {
      margin-left: 10px; } }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .full-sidenav {
      left: auto;
      right: -300px; }
      [dir='rtl'] .full-sidenav.show {
        left: auto;
        right: 0; } }

[dir='rtl'] .flsbg {
  left: auto;
  right: 0; }
  [dir='rtl'] .flsbg .bg-wrap span {
    left: auto;
    right: 0; }

[dir='rtl'] .logo-header {
  float: right; }

[dir='rtl'] .top-bar .dz-topbar-left {
  float: right; }
  [dir='rtl'] .top-bar .dz-topbar-left li {
    padding-right: 0;
    padding-left: 20px; }

[dir='rtl'] .top-bar .dz-topbar-right {
  float: left; }
  [dir='rtl'] .top-bar .dz-topbar-right li {
    padding-left: 0;
    padding-right: 15px; }

[dir='rtl'] .top-bar .dz-topbar-center ul i,
[dir='rtl'] .top-bar .dz-topbar-left ul i,
[dir='rtl'] .top-bar .dz-topbar-right ul i {
  margin-right: 0;
  margin-left: 5px; }

[dir='rtl'] .top-bar .dz-topbar-center .dz-social-icon li,
[dir='rtl'] .top-bar .dz-topbar-left .dz-social-icon li,
[dir='rtl'] .top-bar .dz-topbar-right .dz-social-icon li {
  padding-right: 0; }

[dir='rtl'] .dz-quik-search {
  left: auto;
  right: 0; }
  [dir='rtl'] .dz-quik-search .form-control, [dir='rtl'] .dz-quik-search .wp-block-categories-dropdown select, .wp-block-categories-dropdown [dir='rtl'] .dz-quik-search select,
  [dir='rtl'] .dz-quik-search .wp-block-archives-dropdown select, .wp-block-archives-dropdown [dir='rtl'] .dz-quik-search select {
    padding: 15px 15px 15px 60px; }
  [dir='rtl'] .dz-quik-search span {
    right: auto;
    left: 15px; }

[dir='rtl'] .site-header .extra-nav {
  float: left;
  padding-left: 0;
  padding-right: 30px; }
  @media only screen and (max-width: 1680px) {
    [dir='rtl'] .site-header .extra-nav {
      margin-left: 0;
      margin-right: 10px; } }
  [dir='rtl'] .site-header .extra-nav #quik-search-btn {
    margin-left: 0px;
    border-radius: 60px; }

[dir='rtl'] .navbar-toggler {
  margin: 40px 15px 39px 0;
  float: left; }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .navbar-toggler {
      margin: 15px 15px 15px 0; } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .navbar-toggler {
      margin: 15px ​15px 15px 0; } }

[dir='rtl'] .navicon.open span:nth-child(1) {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg); }

[dir='rtl'] .navicon.open span:nth-child(2) {
  left: auto;
  right: -60px; }

[dir='rtl'] .navicon.open span:nth-child(3) {
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg); }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .header-nav {
    left: auto;
    right: -280px; } }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .header-nav.show {
    left: auto;
    right: -1px;
    margin: 0; } }

[dir='rtl'] .header-nav .nav > li.sub-menu-down > a:after, [dir='rtl'] .header-nav .nav > li.has-mega-menu > a:after {
  margin-left: 0;
  margin-right: 5px;
  display: inline-block; }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .header-nav .nav > li.sub-menu-down > a:after, [dir='rtl'] .header-nav .nav > li.has-mega-menu > a:after {
      right: auto;
      left: 0;
      float: left; } }

[dir='rtl'] .header-nav .nav > li .mega-menu,
[dir='rtl'] .header-nav .nav > li .sub-menu {
  left: auto;
  right: 0;
  text-align: right; }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .header-nav .nav > li .mega-menu > li > a i,
    [dir='rtl'] .header-nav .nav > li .sub-menu > li > a i {
      right: auto;
      left: 0; } }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .header-nav .nav > li .mega-menu li a,
    [dir='rtl'] .header-nav .nav > li .sub-menu li a {
      padding: 2px 16px 1px 0; }
      [dir='rtl'] .header-nav .nav > li .mega-menu li a:after,
      [dir='rtl'] .header-nav .nav > li .sub-menu li a:after {
        left: auto;
        right: 0; } }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .header-nav .nav > li .mega-menu li:hover > a,
    [dir='rtl'] .header-nav .nav > li .sub-menu li:hover > a {
      padding: 2px 16px 1px 0; } }
  [dir='rtl'] .header-nav .nav > li .mega-menu li > .sub-menu,
  [dir='rtl'] .header-nav .nav > li .sub-menu li > .sub-menu {
    left: auto;
    right: 220px; }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .header-nav .nav > li .mega-menu .mega-menu,
    [dir='rtl'] .header-nav .nav > li .mega-menu .sub-menu,
    [dir='rtl'] .header-nav .nav > li .sub-menu .mega-menu,
    [dir='rtl'] .header-nav .nav > li .sub-menu .sub-menu {
      padding-left: 0;
      padding-right: 20px; } }

[dir='rtl'] .header-nav .nav > li .sub-menu li > a > i {
  float: left; }

[dir='rtl'] .header-nav .nav > li .mega-menu {
  right: auto;
  left: 0px; }
  [dir='rtl'] .header-nav .nav > li .mega-menu > li > a > i {
    margin-right: 0;
    margin-left: 5px; }
  [dir='rtl'] .header-nav .nav > li .mega-menu > li li i {
    margin-right: 0;
    margin-left: 5px; }

@media only screen and (max-width: 1400px) {
  [dir='rtl'] .header-nav .nav > li:nth-last-child(3) .sub-menu .sub-menu,
  [dir='rtl'] .header-nav .nav > li:nth-last-child(2) .sub-menu .sub-menu,
  [dir='rtl'] .header-nav .nav > li:last-child .sub-menu .sub-menu {
    left: auto;
    right: -220px; } }

@media only screen and (max-width: 1199px) {
  [dir='rtl'] .header-nav .nav > li:last-child .sub-menu {
    left: 0;
    right: auto; } }

[dir='rtl'] .header-transparent {
  left: auto;
  right: 0; }

[dir='rtl'] .is-fixed .main-bar {
  left: auto;
  right: 0; }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .mo-left .header-nav {
    left: auto;
    right: -280px; }
    [dir='rtl'] .mo-left .header-nav.show {
      left: auto;
      right: -1px; }
  [dir='rtl'] .mo-left .navbar-toggler.open:after {
    left: -20px;
    right: 0;
    transform-origin: top left;
    margin: 0 20px 0px 0; } }

[dir='rtl'] .header-tabs-wraper .header-style-bx .header-style-tabs {
  padding: 20px 20px 20px 0; }

[dir='rtl'] .header-tabs-wraper .header-style-content .menu-list {
  text-align: right; }
  [dir='rtl'] .header-tabs-wraper .header-style-content .menu-list > li > ul > li i {
    margin-right: 0;
    margin-left: 5px;
    float: right; }
  [dir='rtl'] .header-tabs-wraper .header-style-content .menu-list > li:last-child {
    border-left: 0; }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .header-tabs-wraper .header-style-content .menu-list > li {
      border-left: 0; } }

[dir='rtl'] {
  /* .swiper-portfolio-1 {
        .swiper-slide{
            margin-top: 70px;
            margin-bottom: 70px;
            @include transitionMedium;
            &.swiper-slide-active{
                margin-top: 70px;
                margin-bottom: 70px;
                .dz-media img{	
                    height:590px;
                }
            }
            &.swiper-slide-duplicate-prev{
                margin-top:0;
                margin-bottom:0;
                .dz-media img{	
                    height:730px;
                }
            }
            @include respond ('laptop'){
                margin-top: 30px;
                margin-bottom: 30px;
                &.swiper-slide-active{
                    margin-top: 30px;
                    margin-bottom: 30px;
                    .dz-media img{	
                        height:450px;
                    }
                }
                &.swiper-slide-next{
                    .dz-media img{	
                        height:510px;
                    }
                }
            }
            @include respond ('phone'){
                &.swiper-slide-active{
                    .dz-media img{	
                        height:360px;
                    }
                }
                &.swiper-slide-duplicate-prev{
                    .dz-media img{	
                        height:360px;
                    }
                }
            }
        }
    } */
  /* ===============================
        Coming Soon 3
    ================================ */ }
  [dir='rtl'] .swiper-container {
    direction: ltr; }
    [dir='rtl'] .swiper-container .swiper-slide {
      direction: rtl; }
  [dir='rtl'] .lg-outer {
    direction: ltr; }
  [dir='rtl'] .alert[class*=alert-] i {
    margin-right: auto;
    margin-left: 8px; }
  [dir='rtl'] .alert[class*=alert-] ul {
    padding-left: 0;
    padding-right: 25px; }
  [dir='rtl'] .alert-dismissible .btn-close {
    left: 0;
    right: auto; }
  [dir='rtl'] .dz-divider.icon-left {
    margin-left: 0;
    margin-right: 40px; }
    [dir='rtl'] .dz-divider.icon-left i {
      left: auto;
      right: -40px; }
  [dir='rtl'] .dz-divider.icon-right {
    margin-left: 40px;
    margin-right: 0; }
    [dir='rtl'] .dz-divider.icon-right i {
      left: -40px;
      right: auto; }
  [dir='rtl'] .site-filters li a {
    margin: 0 0 5px 5px; }
  [dir='rtl'] .toggle-switch:before {
    left: auto;
    right: 3px; }
  .toggle-checkbox:checked + [dir='rtl'] .toggle-switch:before {
    left: auto;
    right: 26px; }
  [dir='rtl'] .file-upload-wrapper:after {
    left: auto;
    right: 0; }
  [dir='rtl'] .file-upload-wrapper:before {
    left: 0;
    right: auto;
    border-radius: 0 0 5px 5px; }
  [dir='rtl'] .ui-datepicker-header a.ui-datepicker-prev {
    left: auto;
    right: 0; }
  [dir='rtl'] .ui-datepicker-header a.ui-datepicker-next {
    right: auto;
    left: 0; }
  [dir='rtl'] .form-select .dropdown-menu ul li a img, [dir='rtl'] select .dropdown-menu ul li a img {
    margin-right: 0;
    margin-left: 5px; }
  [dir='rtl'] .form-select.btn-group .dropdown-toggle .caret, [dir='rtl'] select.btn-group .dropdown-toggle .caret {
    right: auto;
    left: 5px; }
  [dir='rtl'] .dz-img-effect.side-pan:hover img {
    margin-left: auto;
    margin-right: -11%; }
  [dir='rtl'] .dz-subscribe.style-1:after {
    left: auto;
    right: 0px; }
  [dir='rtl'] .dz-subscribe.style-1 .subscribe-inner .title-head i {
    margin-right: 0;
    margin-left: 20px; }
  [dir='rtl'] .dz-subscribe.style-1 .subscribe-inner .form-control, [dir='rtl'] .dz-subscribe.style-1 .subscribe-inner .wp-block-categories-dropdown select, .wp-block-categories-dropdown [dir='rtl'] .dz-subscribe.style-1 .subscribe-inner select,
  [dir='rtl'] .dz-subscribe.style-1 .subscribe-inner .wp-block-archives-dropdown select, .wp-block-archives-dropdown [dir='rtl'] .dz-subscribe.style-1 .subscribe-inner select {
    padding: 10px 25px 10px 80px; }
  [dir='rtl'] .dz-subscribe.style-1 .subscribe-inner .input-group-addon {
    right: auto;
    left: 12px; }
  [dir='rtl'] .call-box {
    padding: 15px 130px 15px 30px; }
    [dir='rtl'] .call-box .icon {
      left: auto;
      right: 0; }
    @media only screen and (max-width: 575px) {
      [dir='rtl'] .call-box {
        padding: 15px 100px 15px 30px; } }
  [dir='rtl'] .dz-section-3 .bg-img {
    left: auto;
    right: 0; }
  [dir='rtl'] .pagination.style-1 li.prev img {
    margin-right: 0;
    margin-left: 15px; }
  [dir='rtl'] .pagination.style-1 li.prev i {
    margin-right: 0;
    margin-left: 5px; }
  [dir='rtl'] .pagination.style-1 li.next img {
    margin-left: 0;
    margin-right: 15px; }
  [dir='rtl'] .pagination.style-1 li.next i {
    margin-left: 0;
    margin-right: 5px; }
  [dir='rtl'] .showcase-bottom ul {
    margin-left: 0;
    margin-right: auto; }
  [dir='rtl'] #home-slider .swiper-pagination {
    left: 0;
    right: auto; }
  [dir='rtl'] .sort-title:after {
    left: auto;
    right: -50px; }
  [dir='rtl'] .sort-title:before {
    right: auto;
    left: -50px; }
  [dir='rtl'] .popover {
    z-index: 999999; }
  [dir='rtl'] .split-box > div:after, [dir='rtl'] .split-box > div:before {
    left: auto;
    right: 0; }
  [dir='rtl'] .sechedule-btn:after {
    left: auto;
    right: 0;
    clip-path: polygon(0 0, 100% 100%, 100% 0%); }
  [dir='rtl'] .sechedule-btn:before {
    right: auto;
    left: 0;
    clip-path: polygon(100% 100%, 0 100%, 0% 0); }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] #rc-imageselect, [dir='rtl'] .g-recaptcha {
      transform-origin: top right;
      -moz-transform-origin: top right;
      -o-transform-origin: top right;
      -webkit-transform-origin: top right; } }
  [dir='rtl'] .service-wrapper {
    background-position: right; }
    [dir='rtl'] .service-wrapper:after {
      left: auto;
      right: 0; }
  [dir='rtl'] .content-box .dz-info:after {
    right: auto;
    left: 30px; }
  [dir='rtl'] .service-wrapper-2:after {
    left: auto;
    right: 0; }
  [dir='rtl'] .exp-row .year {
    margin-right: 0;
    margin-left: 10px; }
  [dir='rtl'] .content-box2 .dz-bottom .btn-link {
    padding-right: 0;
    padding-left: 20px; }
    [dir='rtl'] .content-box2 .dz-bottom .btn-link i {
      margin-left: 0;
      margin-right: 6px; }
  [dir='rtl'] .content-box2 .dz-bottom:after {
    left: auto;
    right: 0; }
  [dir='rtl'] .bg-about-slider {
    left: auto;
    right: 0; }
    [dir='rtl'] .bg-about-slider .dz-media:after {
      left: auto;
      right: 0;
      background: linear-gradient(to left, rgba(25, 36, 54, 0.8) 0%, rgba(25, 36, 54, 0.9) 5%, rgba(25, 36, 54, 0.92) 6%, #192436 30%, rgba(25, 36, 54, 0.92) 33%, rgba(25, 36, 54, 0.22) 59%, rgba(25, 36, 54, 0.87) 79%, #192436 83%); }
  [dir='rtl'] .about-pagination {
    right: auto;
    left: 100px;
    text-align: left; }
    [dir='rtl'] .about-pagination .swiper-pagination-bullet span {
      margin-left: 0;
      margin-right: 10px; }
    @media only screen and (max-width: 767px) {
      [dir='rtl'] .about-pagination {
        left: auto;
        right: calc(calc(100% - 540px) / 2 + 15px); }
        [dir='rtl'] .about-pagination .swiper-pagination-bullet {
          text-align: right; }
          [dir='rtl'] .about-pagination .swiper-pagination-bullet span {
            margin-right: 0;
            margin-left: 10px; } }
    @media only screen and (max-width: 767px) {
      [dir='rtl'] .about-pagination {
        right: 15px;
        width: calc(100% - 30px); } }
  [dir='rtl'] .overlay-effect:before {
    left: auto;
    right: 0; }
  [dir='rtl'] .contact-area2 .dz-media {
    padding-right: 0;
    padding-left: 15px;
    margin-right: 0;
    margin-left: 20px; }
    [dir='rtl'] .contact-area2 .dz-media .info {
      left: 0;
      right: auto; }
    @media only screen and (max-width: 991px) {
      [dir='rtl'] .contact-area2 .dz-media {
        margin-left: 0;
        margin-right: auto; } }
  [dir='rtl'] .contact-area2:after {
    left: auto;
    right: 0; }
  [dir='rtl'] .call-box3 {
    padding-left: 0;
    padding-right: 75px; }
    [dir='rtl'] .call-box3 i {
      left: auto;
      right: 0; }
  [dir='rtl'] .overlay-gradient-sec:before {
    left: auto;
    right: 0; }
  [dir='rtl'] .about-wrapper-2 {
    float: left; }
    [dir='rtl'] .about-wrapper-2 .swiper-button {
      right: auto;
      left: 15px; }
  [dir='rtl'] .dzSubscribe.style-1 .ft-row .form-control, [dir='rtl'] .dzSubscribe.style-1 .ft-row .wp-block-categories-dropdown select, .wp-block-categories-dropdown [dir='rtl'] .dzSubscribe.style-1 .ft-row select,
  [dir='rtl'] .dzSubscribe.style-1 .ft-row .wp-block-archives-dropdown select, .wp-block-archives-dropdown [dir='rtl'] .dzSubscribe.style-1 .ft-row select {
    padding-right: 20px;
    padding-left: 68px; }
  [dir='rtl'] .dzSubscribe.style-1 .ft-row .btn, [dir='rtl'] .dzSubscribe.style-1 .ft-row .wp-block-button__link {
    right: auto;
    left: 4px; }
  [dir='rtl'] .image-slider-wrapper .button-prev {
    left: auto;
    right: 30px; }
  [dir='rtl'] .image-slider-wrapper .button-next {
    right: auto;
    left: 30px; }
  [dir='rtl'] .image-box .info {
    left: auto;
    right: 0;
    padding: 30px 10px 30px 60px; }
    @media only screen and (max-width: 575px) {
      [dir='rtl'] .image-box .info {
        padding: 30px 30px 30px 60px; } }
  [dir='rtl'] .image-box .dz-media {
    transform: skewX(10deg); }
    [dir='rtl'] .image-box .dz-media img {
      transform: skewX(-10deg) scale(1.3); }
    [dir='rtl'] .image-box .dz-media:before {
      left: auto;
      right: 0; }
    [dir='rtl'] .image-box .dz-media:after {
      left: auto;
      right: 0; }
  [dir='rtl'] .bg-half-img {
    left: auto;
    right: 0; }
  [dir='rtl'] ul.info-desc li span {
    margin-right: auto;
    margin-left: 0; }
  [dir='rtl'] .image-box2 .info {
    left: auto;
    right: 0; }
  [dir='rtl'] .feature-wrapper {
    position: relative; }
    [dir='rtl'] .feature-wrapper:before {
      left: auto;
      right: 0; }
  [dir='rtl'] .scroltop {
    left: 15px;
    right: auto; }
  @media only screen and (min-width: 1280px) {
    [dir='rtl'] .swiper-portfolio-1 .swiper-slide {
      margin-top: 70px;
      margin-bottom: 70px; }
      [dir='rtl'] .swiper-portfolio-1 .swiper-slide.swiper-slide-active {
        margin-top: 70px;
        margin-bottom: 70px; }
        [dir='rtl'] .swiper-portfolio-1 .swiper-slide.swiper-slide-active .dz-media img {
          height: 590px; }
      [dir='rtl'] .swiper-portfolio-1 .swiper-slide.swiper-slide-duplicate-prev {
        margin-top: 0;
        margin-bottom: 0; }
        [dir='rtl'] .swiper-portfolio-1 .swiper-slide.swiper-slide-duplicate-prev .dz-media img {
          height: 730px; } }
  @media only screen and (max-width: 1400px) {
    [dir='rtl'] .swiper-portfolio-1 .swiper-slide {
      margin-top: 30px;
      margin-bottom: 30px; }
      [dir='rtl'] .swiper-portfolio-1 .swiper-slide.swiper-slide-active {
        margin-top: 30px;
        margin-bottom: 30px; }
        [dir='rtl'] .swiper-portfolio-1 .swiper-slide.swiper-slide-active .dz-media img {
          height: 450px; }
      [dir='rtl'] .swiper-portfolio-1 .swiper-slide.swiper-slide-duplicate-prev {
        margin-top: 0;
        margin-bottom: 0; }
        [dir='rtl'] .swiper-portfolio-1 .swiper-slide.swiper-slide-duplicate-prev .dz-media img {
          height: 510px; } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .swiper-portfolio-1 .swiper-slide.swiper-slide {
      margin: 0 !important; }
      [dir='rtl'] .swiper-portfolio-1 .swiper-slide.swiper-slide .dz-media img {
        height: 360px !important; } }
  [dir='rtl'] .app-form-wraper {
    /* Form Wizard */ }
    [dir='rtl'] .app-form-wraper .dz-info li {
      margin-right: auto;
      margin-left: 20px; }
      [dir='rtl'] .app-form-wraper .dz-info li i {
        margin-right: auto;
        margin-left: 10px; }
    [dir='rtl'] .app-form-wraper .form-wizard > .nav {
      box-shadow: unset !important; }
      [dir='rtl'] .app-form-wraper .form-wizard > .nav li .nav-link:after {
        left: auto;
        right: 50%; }
    [dir='rtl'] .app-form-wraper .check-date label input {
      display: none; }
    [dir='rtl'] .app-form-wraper .check-date label.active:after {
      background: var(--primary); }
    [dir='rtl'] .app-form-wraper .check-date .form-check {
      padding-right: 0; }
  [dir='rtl'] .dez-coming-soon .bg-img {
    left: auto;
    right: 0; }
  [dir='rtl'] .dez-coming-soon .dzSubscribe .ft-row .btn, [dir='rtl'] .dez-coming-soon .dzSubscribe .ft-row .wp-block-button__link {
    right: auto;
    left: 5px; }
  [dir='rtl'] .dez-coming-soon .dzSubscribe .ft-row .form-control, [dir='rtl'] .dez-coming-soon .dzSubscribe .ft-row .wp-block-categories-dropdown select, .wp-block-categories-dropdown [dir='rtl'] .dez-coming-soon .dzSubscribe .ft-row select,
  [dir='rtl'] .dez-coming-soon .dzSubscribe .ft-row .wp-block-archives-dropdown select, .wp-block-archives-dropdown [dir='rtl'] .dez-coming-soon .dzSubscribe .ft-row select {
    padding: 10px 30px 10px 70px; }
  [dir='rtl'] .bottom-right {
    right: auto;
    left: 60px; }
  [dir='rtl'] .bottom-left {
    left: auto;
    right: 60px; }
  [dir='rtl'] .top-right {
    right: auto;
    left: 60px; }
  [dir='rtl'] .top-left {
    left: auto;
    right: 60px; }
  [dir='rtl'] .style-3 .dez-social-icon li a {
    margin-right: 0;
    margin-left: 5px; }
  @media only screen and (max-width: 1400px) {
    [dir='rtl'] .bottom-left {
      right: 40px;
      left: auto; }
    [dir='rtl'] .bottom-right {
      left: 40px;
      right: auto; }
    [dir='rtl'] .top-left {
      left: auto;
      right: 40px; }
    [dir='rtl'] .top-right {
      right: auto;
      left: 40px; } }
  [dir='rtl'] .history-wrapper .history-wrapper-bx .year {
    left: auto;
    right: 50%;
    transform: translateX(50%); }
  [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box:nth-child(even) {
    padding-right: 0;
    padding-left: 40px;
    text-align: left; }
    [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box:nth-child(even):before {
      right: auto;
      left: 25px;
      transform: rotate(-135deg); }
    [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box:nth-child(even):after {
      right: auto;
      left: -5px; }
  [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box:nth-child(odd) {
    padding-left: 0;
    padding-right: 40px; }
    [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box:nth-child(odd):before {
      transform: rotate(-135deg);
      left: auto;
      right: 25px; }
    [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box:nth-child(odd):after {
      left: auto;
      right: -5px; }
  [dir='rtl'] .history-wrapper:after {
    left: auto;
    right: 50%;
    transform: translateX(50%); }
  @media only screen and (max-width: 767px) {
    [dir='rtl'] .history-wrapper .history-wrapper-bx {
      padding-left: 0;
      padding-right: 35px; }
      [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box {
        padding-left: 0;
        padding-right: 40px; }
        [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box:nth-child(even) {
          padding-left: 0;
          text-align: right;
          padding-right: 40px; }
          [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box:nth-child(even):before {
            right: 25px;
            left: auto; }
          [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box:nth-child(even):after {
            right: -5px;
            left: auto; }
        [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box:nth-child(odd) {
          padding-left: 0;
          padding-right: 40px; }
          [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box:nth-child(odd):before {
            left: auto;
            right: 25px; }
          [dir='rtl'] .history-wrapper .history-wrapper-bx .history-box:nth-child(odd):after {
            left: auto;
            right: -5px; }
      [dir='rtl'] .history-wrapper .history-wrapper-bx .year {
        left: auto;
        right: 0;
        transform: translateX(0px); }
    [dir='rtl'] .history-wrapper:after {
      left: auto;
      right: 35px;
      transform: translateX(0px); } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .history-wrapper .history-wrapper-bx {
      padding-right: 0; }
      [dir='rtl'] .history-wrapper .history-wrapper-bx .year {
        left: auto;
        right: 50%;
        transform: translateX(50%); }
    [dir='rtl'] .history-wrapper:after {
      left: auto;
      right: 0; } }
  [dir='rtl'] #loading-area {
    left: auto;
    right: 0;
    direction: ltr; }
    [dir='rtl'] #loading-area.loading-page-1:before, [dir='rtl'] #loading-area.loading-page-1:after {
      left: auto;
      right: 0; }
    [dir='rtl'] #loading-area.loading-page-1 .line:after {
      left: auto;
      right: 0; }

@keyframes dzLoad4 {
  0% {
    left: auto;
    right: 0; }
  100% {
    left: auto;
    right: 235px; } }

@keyframes dzLoad1 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes dzLoad2 {
  0% {
    transform: rotate(45deg); }
  100% {
    transform: rotate(405deg); } }

@keyframes dzLoad3 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes rotation {
  100% {
    transform: rotate(360deg); } }

@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg); }
  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg); } }

@keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }
  [dir='rtl'] .modal-header .btn-close {
    margin: -0.5rem auto -0.5rem -0.5rem; }
  [dir='rtl'] .inquiry-modal .btn-close {
    right: auto;
    left: -40px;
    margin: -0.5rem -0.5rem -0.5rem auto; }
  [dir='rtl'] .contact-wraper1 {
    background-position: 260px center; }
    [dir='rtl'] .contact-wraper1:before {
      left: auto;
      right: 0;
      background: -moz-linear-gradient(right, rgba(20, 20, 20, 0) 0%, #141414 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(right, rgba(20, 20, 20, 0) 0%, #141414 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to left, rgba(20, 20, 20, 0) 0%, #141414 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
    [dir='rtl'] .contact-wraper1:after {
      right: auto;
      left: 0; }
  [dir='rtl'] .under-construct {
    direction: ltr;
    padding-right: 0; }
    [dir='rtl'] .under-construct .dz-content.top-left {
      top: 60px;
      left: 60px;
      right: auto; }
    [dir='rtl'] .under-construct .bottom-left {
      bottom: 60px;
      left: 60px;
      right: auto; }
    [dir='rtl'] .under-construct .dz-social-icon li {
      margin-right: 5px; }
      [dir='rtl'] .under-construct .dz-social-icon li a {
        margin-right: 0px; }
    [dir='rtl'] .under-construct .mining-worker {
      right: 0; }
    [dir='rtl'] .under-construct .train-run {
      right: 0; }
      [dir='rtl'] .under-construct .train-run .main-train {
        left: 0; }
  [dir='rtl'] .alert-dismissible {
    padding-left: 3rem;
    padding-right: 1rem; }
  [dir='rtl'] .btn-bottom-left .swiper-button {
    display: flex;
    flex-direction: row-reverse; }
  [dir='rtl'] .swiper-button-prev-portfolio i:before,
  [dir='rtl'] .swiper-button-next-portfolio i:before {
    transform: rotate(180deg);
    display: block; }
  [dir='rtl'] .boxed .main-slider3 {
    padding: 0 0 35px 35px; }

[dir='rtl'] .toggle-switch:before {
  left: auto;
  right: 3px; }

.toggle-checkbox:checked + [dir='rtl'] .toggle-switch:before {
  left: auto;
  right: 26px; }

[dir='rtl'] .toggle-label {
  margin-left: 0;
  margin-right: 15px; }

[dir='rtl'] .file-upload-wrapper:after {
  left: auto;
  right: 0; }

[dir='rtl'] .file-upload-wrapper:before {
  right: auto;
  left: 0; }

[dir='rtl'] .file-upload-wrapper input {
  left: auto;
  right: 0; }

[dir='rtl'] .input-group-prepend {
  left: auto;
  right: 10px;
  margin-right: auto;
  margin-left: 10px;
  border-right: 0;
  border-left: 1px solid #9192a4; }

[dir='rtl'] .input-group-prepend + .form-control, [dir='rtl'] .wp-block-categories-dropdown .input-group-prepend + select, .wp-block-categories-dropdown [dir='rtl'] .input-group-prepend + select,
[dir='rtl'] .wp-block-archives-dropdown .input-group-prepend + select, .wp-block-archives-dropdown [dir='rtl'] .input-group-prepend + select,
[dir='rtl'] .input-group-prepend + .form-control + .bootstrap-select,
[dir='rtl'] .wp-block-categories-dropdown .input-group-prepend + select + .bootstrap-select,
.wp-block-categories-dropdown [dir='rtl'] .input-group-prepend + select + .bootstrap-select,
[dir='rtl'] .wp-block-archives-dropdown .input-group-prepend + select + .bootstrap-select,
.wp-block-archives-dropdown [dir='rtl'] .input-group-prepend + select + .bootstrap-select,
[dir='rtl'] .input-group-prepend + .form-control + .custom-file-label,
[dir='rtl'] .wp-block-categories-dropdown .input-group-prepend + select + .custom-file-label,
.wp-block-categories-dropdown [dir='rtl'] .input-group-prepend + select + .custom-file-label,
[dir='rtl'] .wp-block-archives-dropdown .input-group-prepend + select + .custom-file-label,
.wp-block-archives-dropdown [dir='rtl'] .input-group-prepend + select + .custom-file-label {
  padding: 10px 65px 10px 20px; }

[dir='rtl'] .ui-datepicker-header a.ui-datepicker-prev {
  left: auto;
  right: 0; }

[dir='rtl'] .ui-datepicker-header a.ui-datepicker-next {
  right: auto;
  left: 0; }

[dir='rtl'] .form-select .dropdown-menu ul li a img, [dir='rtl'] select .dropdown-menu ul li a img {
  margin-right: auto;
  margin-left: 5px; }

[dir='rtl'] .form-select.btn-group .dropdown-toggle .caret, [dir='rtl'] select.btn-group .dropdown-toggle .caret {
  right: auto;
  left: 5px; }

[dir='rtl'] .form-check {
  padding-right: 1.5rem;
  padding-left: 0; }
  [dir='rtl'] .form-check .form-check-input {
    float: right !important;
    margin-right: -1.5rem !important;
    margin-left: 0 !important; }

[dir='rtl'] .text-start {
  text-align: right !important; }

[dir='rtl'] .text-end {
  text-align: left !important; }

[dir='rtl'] .float-start {
  float: right !important; }

[dir='rtl'] .float-end {
  float: left !important; }

@media (min-width: 768px) {
  [dir='rtl'] .float-md-end {
    float: left !important; }
  [dir='rtl'] .text-md-end {
    text-align: left !important; } }

@media (min-width: 992px) {
  [dir='rtl'] .text-lg-start {
    text-align: right !important; }
  [dir='rtl'] .text-lg-end {
    text-align: left !important; } }

@media (min-width: 768px) {
  [dir='rtl'] .text-md-start {
    text-align: right !important; }
  [dir='rtl'] .text-md-end {
    text-align: left !important; } }

@media (min-width: 576px) {
  [dir='rtl'] .text-sm-end {
    text-align: left !important; }
  [dir='rtl'] .text-sm-start {
    text-align: right !important; } }

[dir='rtl'] .ms-1 {
  margin-right: 0.25rem !important; }

[dir='rtl'] .ms-2 {
  margin-right: 0.5rem !important; }

[dir='rtl'] .ms-3 {
  margin-right: 1rem !important; }

[dir='rtl'] .ms-4 {
  margin-right: 1.5rem !important; }

[dir='rtl'] .ms-5 {
  margin-right: 3rem !important; }

[dir='rtl'] .me-1 {
  margin-left: 0.25rem !important; }

[dir='rtl'] .me-2 {
  margin-left: 0.5rem !important; }

[dir='rtl'] .me-3 {
  margin-left: 1rem !important; }

[dir='rtl'] .me-4 {
  margin-left: 1.5rem !important; }

[dir='rtl'] .me-5 {
  margin-left: 3rem !important; }

[dir='rtl'] .p-l0 {
  padding-right: 0px; }

[dir='rtl'] .p-r0 {
  padding-left: 0px; }

[dir='rtl'] .m-l0 {
  margin-right: 0px; }

[dir='rtl'] .m-r0 {
  margin-left: 0px; }

[dir='rtl'] .p-l5 {
  padding-right: 5px; }

[dir='rtl'] .p-r5 {
  padding-left: 5px; }

[dir='rtl'] .m-l5 {
  margin-right: 5px; }

[dir='rtl'] .m-r5 {
  margin-left: 5px; }

[dir='rtl'] .p-l10 {
  padding-right: 10px; }

[dir='rtl'] .p-r10 {
  padding-left: 10px; }

[dir='rtl'] .m-l10 {
  margin-right: 10px; }

[dir='rtl'] .m-r10 {
  margin-left: 10px; }

[dir='rtl'] .p-l15 {
  padding-right: 15px; }

[dir='rtl'] .p-r15 {
  padding-left: 15px; }

[dir='rtl'] .m-l15 {
  margin-right: 15px; }

[dir='rtl'] .m-r15 {
  margin-left: 15px; }

[dir='rtl'] .p-l20 {
  padding-right: 20px; }

[dir='rtl'] .p-r20 {
  padding-left: 20px; }

[dir='rtl'] .m-l20 {
  margin-right: 20px; }

[dir='rtl'] .m-r20 {
  margin-left: 20px; }

[dir='rtl'] .p-l25 {
  padding-right: 25px; }

[dir='rtl'] .p-r25 {
  padding-left: 25px; }

[dir='rtl'] .m-l25 {
  margin-right: 25px; }

[dir='rtl'] .m-r25 {
  margin-left: 25px; }

[dir='rtl'] .p-l30 {
  padding-right: 30px; }

[dir='rtl'] .p-r30 {
  padding-left: 30px; }

[dir='rtl'] .m-l30 {
  margin-right: 30px; }

[dir='rtl'] .m-r30 {
  margin-left: 30px; }

[dir='rtl'] .p-l35 {
  padding-right: 35px; }

[dir='rtl'] .p-r35 {
  padding-left: 35px; }

[dir='rtl'] .m-l35 {
  margin-right: 35px; }

[dir='rtl'] .m-r35 {
  margin-left: 35px; }

[dir='rtl'] .p-l40 {
  padding-right: 40px; }

[dir='rtl'] .p-r40 {
  padding-left: 40px; }

[dir='rtl'] .m-l40 {
  margin-right: 40px; }

[dir='rtl'] .m-r40 {
  margin-left: 40px; }

[dir='rtl'] .p-l45 {
  padding-right: 45px; }

[dir='rtl'] .p-r45 {
  padding-left: 45px; }

[dir='rtl'] .m-l45 {
  margin-right: 45px; }

[dir='rtl'] .m-r45 {
  margin-left: 45px; }

[dir='rtl'] .p-l50 {
  padding-right: 50px; }

[dir='rtl'] .p-r50 {
  padding-left: 50px; }

[dir='rtl'] .m-l50 {
  margin-right: 50px; }

[dir='rtl'] .m-r50 {
  margin-left: 50px; }

[dir='rtl'] .p-l55 {
  padding-right: 55px; }

[dir='rtl'] .p-r55 {
  padding-left: 55px; }

[dir='rtl'] .m-l55 {
  margin-right: 55px; }

[dir='rtl'] .m-r55 {
  margin-left: 55px; }

[dir='rtl'] .p-l60 {
  padding-right: 60px; }

[dir='rtl'] .p-r60 {
  padding-left: 60px; }

[dir='rtl'] .m-l60 {
  margin-right: 60px; }

[dir='rtl'] .m-r60 {
  margin-left: 60px; }

[dir='rtl'] .p-l65 {
  padding-right: 65px; }

[dir='rtl'] .p-r65 {
  padding-left: 65px; }

[dir='rtl'] .m-l65 {
  margin-right: 65px; }

[dir='rtl'] .m-r65 {
  margin-left: 65px; }

[dir='rtl'] .p-l70 {
  padding-right: 70px; }

[dir='rtl'] .p-r70 {
  padding-left: 70px; }

[dir='rtl'] .m-l70 {
  margin-right: 70px; }

[dir='rtl'] .m-r70 {
  margin-left: 70px; }

[dir='rtl'] .p-l75 {
  padding-right: 75px; }

[dir='rtl'] .p-r75 {
  padding-left: 75px; }

[dir='rtl'] .m-l75 {
  margin-right: 75px; }

[dir='rtl'] .m-r75 {
  margin-left: 75px; }

[dir='rtl'] .p-l80 {
  padding-right: 80px; }

[dir='rtl'] .p-r80 {
  padding-left: 80px; }

[dir='rtl'] .m-l80 {
  margin-right: 80px; }

[dir='rtl'] .m-r80 {
  margin-left: 80px; }

[dir='rtl'] .p-l85 {
  padding-right: 85px; }

[dir='rtl'] .p-r85 {
  padding-left: 85px; }

[dir='rtl'] .m-l85 {
  margin-right: 85px; }

[dir='rtl'] .m-r85 {
  margin-left: 85px; }

[dir='rtl'] .p-l90 {
  padding-right: 90px; }

[dir='rtl'] .p-r90 {
  padding-left: 90px; }

[dir='rtl'] .m-l90 {
  margin-right: 90px; }

[dir='rtl'] .m-r90 {
  margin-left: 90px; }

[dir='rtl'] .p-l95 {
  padding-right: 95px; }

[dir='rtl'] .p-r95 {
  padding-left: 95px; }

[dir='rtl'] .m-l95 {
  margin-right: 95px; }

[dir='rtl'] .m-r95 {
  margin-left: 95px; }

[dir='rtl'] .p-l100 {
  padding-right: 100px; }

[dir='rtl'] .p-r100 {
  padding-left: 100px; }

[dir='rtl'] .m-l100 {
  margin-right: 100px; }

[dir='rtl'] .m-r100 {
  margin-left: 100px; }

@media only screen and (max-width: 1200px) {
  [dir='rtl'] .m-lg-l20 {
    margin-right: 20px; }
  [dir='rtl'] .m-lg-r20 {
    margin-left: 20px; } }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .m-md-r0 {
    margin-left: 0; }
  [dir='rtl'] .p-md-r0 {
    padding-left: 0; } }

@media only screen and (max-width: 576px) {
  [dir='rtl'] .p-sm-r0 {
    padding-left: 0; } }

[dir='rtl'] .overlay-gadient-white:after {
  left: auto;
  right: 0;
  background: -moz-linear-gradient(right, rgba(255, 255, 255, 0.31) 0%, rgba(255, 255, 255, 0.34) 1%, white 25%);
  background: -webkit-linear-gradient(right, rgba(255, 255, 255, 0.31) 0%, rgba(255, 255, 255, 0.34) 1%, white 25%);
  background: linear-gradient(to left, rgba(255, 255, 255, 0.31) 0%, rgba(255, 255, 255, 0.34) 1%, white 25%); }

[dir='rtl'] .overlay-gadient-sec:after {
  left: auto;
  right: 0;
  background: -moz-linear-gradient(right, rgba(25, 36, 54, 0.31) 0%, rgba(25, 36, 54, 0.34) 1%, #192436 25%);
  background: -webkit-linear-gradient(right, rgba(25, 36, 54, 0.31) 0%, rgba(25, 36, 54, 0.34) 1%, #192436 25%);
  background: linear-gradient(to left, rgba(25, 36, 54, 0.31) 0%, rgba(25, 36, 54, 0.34) 1%, #192436 25%); }

[dir='rtl'] .recent-posts-entry .widget-post-bx .widget-post .dz-info {
  padding-left: 0;
  padding-right: 14px; }

[dir='rtl'] .widget_getintuch ul li {
  padding-left: 0;
  padding-right: 35px; }
  [dir='rtl'] .widget_getintuch ul li i {
    right: 0;
    left: auto; }
  @media only screen and (max-width: 1199px) {
    [dir='rtl'] .widget_getintuch ul li {
      padding-left: 0;
      padding-right: 50px; } }

[dir='rtl'] .widget_getintuch.style-1 ul li {
  padding-left: 0;
  padding-right: 45px; }

[dir='rtl'] footer .wp-block-latest-posts li,
[dir='rtl'] footer .wp-block-categories-list li,
[dir='rtl'] footer .wp-block-archives-list li,
[dir='rtl'] footer .widget_categories ul li,
[dir='rtl'] footer .widget_archive ul li,
[dir='rtl'] footer .widget_meta ul li,
[dir='rtl'] footer .widget_pages ul li,
[dir='rtl'] footer .widget_recent_comments ul li,
[dir='rtl'] footer .widget_nav_menu li,
[dir='rtl'] footer .widget_recent_entries ul li,
[dir='rtl'] footer .widget_services ul li {
  right: auto;
  left: 0; }

[dir='rtl'] .widget-title {
  /* padding-left: 0;
		padding-right: 15px; */ }
  [dir='rtl'] .widget-title .dz-separator {
    left: auto;
    right: 0; }

[dir='rtl'] .search-bx .form-control, [dir='rtl'] .search-bx .wp-block-categories-dropdown select, .wp-block-categories-dropdown [dir='rtl'] .search-bx select,
[dir='rtl'] .search-bx .wp-block-archives-dropdown select, .wp-block-archives-dropdown [dir='rtl'] .search-bx select {
  padding: 6px 30px 6px 62px; }

[dir='rtl'] .search-bx .input-group-btn {
  left: 6px;
  right: auto; }

[dir='rtl'] .download-file {
  background-image: var(--gradient);
  color: #fff;
  padding: 25px 20px 20px 25px; }
  [dir='rtl'] .download-file ul li a {
    text-align: right; }
    [dir='rtl'] .download-file ul li a i:after, [dir='rtl'] .download-file ul li a i:before {
      left: auto;
      right: 0; }

[dir='rtl'] .widget_contact:after {
  left: auto;
  right: 0; }

[dir='rtl'] .list-2 li {
  float: right; }

[dir='rtl'] .wp-block-latest-posts li li:before,
[dir='rtl'] .wp-block-categories-list li li:before,
[dir='rtl'] .wp-block-archives-list li li:before,
[dir='rtl'] .widget_categories ul li li:before,
[dir='rtl'] .widget_archive ul li li:before,
[dir='rtl'] .widget_meta ul li li:before,
[dir='rtl'] .widget_pages ul li li:before,
[dir='rtl'] .widget_recent_comments ul li li:before,
[dir='rtl'] .widget_nav_menu ul li li:before,
[dir='rtl'] .widget_recent_entries ul li li:before,
[dir='rtl'] .widget_services ul li li:before {
  left: auto;
  right: 0; }

[dir='rtl'] .wp-block-latest-posts ul,
[dir='rtl'] .wp-block-categories-list ul,
[dir='rtl'] .wp-block-archives-list ul,
[dir='rtl'] .widget_categories ul ul,
[dir='rtl'] .widget_archive ul ul,
[dir='rtl'] .widget_meta ul ul,
[dir='rtl'] .widget_pages ul ul,
[dir='rtl'] .widget_recent_comments ul ul,
[dir='rtl'] .widget_nav_menu ul ul,
[dir='rtl'] .widget_recent_entries ul ul,
[dir='rtl'] .widget_services ul ul {
  padding-left: 0;
  padding-right: 1.125rem;
  margin-left: 0;
  margin-right: -0.9375rem; }

[dir='rtl'] .widget_archive ul li,
[dir='rtl'] .wp-block-categories-list li,
[dir='rtl'] .wp-block-archives-list li,
[dir='rtl'] .wp-block-latest-posts li,
[dir='rtl'] .widget_categories ul li {
  text-align: left;
  padding: 0.5rem 1.5rem 0.5rem 0rem !important; }

[dir='rtl'] .widget_archive li a,
[dir='rtl'] .wp-block-latest-posts li a,
[dir='rtl'] .wp-block-categories-list li a,
[dir='rtl'] .wp-block-archives-list li a,
[dir='rtl'] .widget_categories li a {
  float: right;
  text-align: right; }

[dir='rtl'] .widget_recent_comments ul li {
  padding-left: 0;
  padding-right: 1.875rem; }
  [dir='rtl'] .widget_recent_comments ul li:before {
    left: auto;
    right: 0; }

[dir='rtl'] .widget_gallery li {
  float: right; }
  [dir='rtl'] .widget_gallery li:nth-child(3n-3) {
    margin-right: auto;
    margin-left: 0; }

[dir='rtl'] .recent-posts-entry .widget-post-bx .widget-post .dz-info {
  padding-left: 0;
  padding-right: 14px; }

[dir='rtl'] .service_menu_nav ul li a:before {
  right: auto;
  left: 50px;
  transform: translate(-50%, -50%) rotateY(180deg); }

[dir='rtl'] .service_menu_nav ul li a:after {
  left: auto;
  right: 0; }

[dir='rtl'] .service_menu_nav ul li:hover a, [dir='rtl'] .service_menu_nav ul li.current-menu-item a, [dir='rtl'] .service_menu_nav ul li.active a {
  padding-left: 0;
  padding-right: 20px; }
  [dir='rtl'] .service_menu_nav ul li:hover a:before, [dir='rtl'] .service_menu_nav ul li.current-menu-item a:before, [dir='rtl'] .service_menu_nav ul li.active a:before {
    right: auto;
    left: 30px; }
    @media only screen and (max-width: 1199px) {
      [dir='rtl'] .service_menu_nav ul li:hover a:before, [dir='rtl'] .service_menu_nav ul li.current-menu-item a:before, [dir='rtl'] .service_menu_nav ul li.active a:before {
        right: auto;
        left: 5px; } }
    @media only screen and (max-width: 575px) {
      [dir='rtl'] .service_menu_nav ul li:hover a:before, [dir='rtl'] .service_menu_nav ul li.current-menu-item a:before, [dir='rtl'] .service_menu_nav ul li.active a:before {
        left: 30px; } }

[dir='rtl'] .post-tags strong {
  margin-right: 0;
  margin-left: 10px; }

[dir='rtl'] .post-tags a {
  margin-left: 10px;
  margin-right: 0; }

[dir='rtl'] .dz-share-post .post-tags a {
  margin: 0 5px 10px; }

[dir='rtl'] .dz-share-post .dz-social-icon ul li {
  margin: 0; }

[dir='rtl'] .pagination li .prev,
[dir='rtl'] .pagination li .next {
  transform: rotateY(180deg); }

[dir='rtl'] .pagination.style-1 li .page-link {
  transform: rotateY(0); }
  [dir='rtl'] .pagination.style-1 li .page-link i {
    transform: rotateY(180deg); }
  [dir='rtl'] .pagination.style-1 li .page-link.prev img {
    margin-right: 0;
    margin-left: 15px; }
  [dir='rtl'] .pagination.style-1 li .page-link.prev i {
    margin-right: 0;
    margin-left: 5px; }
  [dir='rtl'] .pagination.style-1 li .page-link.next img {
    margin-left: 0;
    margin-right: 15px; }
  [dir='rtl'] .pagination.style-1 li .page-link.next i {
    margin-left: 0;
    margin-right: 5px; }

[dir='rtl'] .widget_categories .cat-item:before {
  content: "\f0d9";
  left: auto;
  right: 0; }

[dir='rtl'] .btn-social i {
  margin-right: 0;
  margin-left: 8px; }

[dir='rtl'] .about-style1 .exp-bx {
  margin-left: 0;
  margin-right: -125px; }
  [dir='rtl'] .about-style1 .exp-bx .counter-num small, [dir='rtl'] .about-style1 .exp-bx .counter-num .small {
    margin-left: 0;
    margin-right: 5px; }

@media only screen and (max-width: 1199px) {
  [dir='rtl'] .about-style1 .exp-bx {
    margin-left: 0;
    margin-right: -185px; } }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .about-style1 .exp-bx {
    margin-left: 0;
    margin-right: -65px; } }

@media only screen and (max-width: 767px) {
  [dir='rtl'] .about-style1 .exp-bx {
    margin-left: 0;
    margin-right: -155px; } }

@media only screen and (max-width: 575px) {
  [dir='rtl'] .about-style1 .exp-bx {
    margin: 0; }
    [dir='rtl'] .about-style1 .exp-bx .exp-info ul li {
      padding: 4px 25px 4px 0px; } }

[dir='rtl'] .about-style2 .about-thumb .popup-youtube {
  left: auto;
  right: -45px; }

[dir='rtl'] .about-style2 .section-head {
  margin-right: 0;
  margin-left: -5px; }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .about-style2 .about-thumb .popup-youtube {
    left: 50%;
    right: auto; } }

[dir='rtl'] .about-style3 .about-thumb {
  padding: 60px 150px 60px 20px; }
  [dir='rtl'] .about-style3 .about-thumb:before {
    left: 100px;
    right: auto; }

@media only screen and (max-width: 767px) {
  [dir='rtl'] .about-style3 .about-thumb {
    padding: 60px 150px 60px 0; } }

@media only screen and (max-width: 575px) {
  [dir='rtl'] .about-style3 .about-thumb {
    padding: 30px 30px 30px 0; } }

[dir='rtl'] .bg-particles .particles-js-canvas-el {
  left: auto;
  right: 0; }

[dir='rtl'] .about-style4 .about-thumb {
  margin-right: 0;
  margin-left: 20px; }

[dir='rtl'] .about-style4 blockquote {
  margin: 0 30px 30px 0; }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .about-style4 .about-thumb {
    margin-right: 0;
    margin-left: 20px; } }

[dir='rtl'] .about-style5 .about-media {
  margin-right: 0;
  margin-left: 15px; }

[dir='rtl'] .about-style6 .about-media .dz-media .popup-youtube {
  right: auto;
  left: 0; }

[dir='rtl'] .about-style7 .about-media {
  padding-left: 0;
  padding-right: 65px; }
  [dir='rtl'] .about-style7 .about-media .ov-img {
    left: auto;
    right: 0;
    padding-right: 0;
    padding-left: 140px; }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .about-style7 .about-media {
      padding-left: 0;
      padding-right: 30px; }
      [dir='rtl'] .about-style7 .about-media .ov-img {
        padding-right: 0;
        padding-left: 80px; } }

[dir='rtl'] .about-style8 .dz-media ul {
  left: auto;
  right: 50px; }

[dir='rtl'] .company-info .users,
[dir='rtl'] .company-info .brand {
  margin-right: 0;
  margin-left: 20px; }

[dir='rtl'] .about-style10 .list-check-circle li {
  padding: 8px 30px 8px 5px; }

[dir='rtl'] .about-style11 .dz-media {
  margin-left: 0;
  margin-right: 30px;
  overflow: visible; }
  [dir='rtl'] .about-style11 .dz-media .exp-box {
    left: auto;
    right: -30px; }

[dir='rtl'] .check-box {
  padding: 30px 95px 30px 30px; }
  [dir='rtl'] .check-box:before {
    left: auto;
    right: 30px; }

[dir='rtl'] .about-style12 .dz-media {
  padding-left: 0;
  padding-right: 100px;
  margin-left: 0;
  margin-right: 75px; }
  [dir='rtl'] .about-style12 .dz-media:after {
    left: auto;
    right: 0; }
  [dir='rtl'] .about-style12 .dz-media ul {
    left: auto;
    right: -75px; }
  @media only screen and (max-width: 1200px) {
    [dir='rtl'] .about-style12 .dz-media {
      padding-left: 0;
      padding-right: 60px;
      margin-left: 0;
      margin-right: 40px; }
      [dir='rtl'] .about-style12 .dz-media ul {
        left: auto;
        right: -40px; } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .about-style12 .dz-media {
      margin-right: 0;
      padding-right: 30px;
      padding-left: 0; } }

[dir='rtl'] .about-style13 .dz-media {
  padding-right: 70px;
  padding-left: 40px; }
  [dir='rtl'] .about-style13 .dz-media .bg-img {
    left: auto;
    right: 0; }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .about-style13 .dz-media {
      padding-left: 0; } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .about-style13 .dz-media {
      padding-right: 40px; } }

[dir='rtl'] .about-style14 .dz-media {
  padding-left: 30px;
  padding-right: 0; }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .about-style14 .dz-media {
      padding-left: 0; } }

[dir='rtl'] .about-style14 .about-content {
  margin-left: 0;
  margin-right: -140px; }
  [dir='rtl'] .about-style14 .about-content .section-head {
    margin-right: 0;
    margin-left: 155px; }
  [dir='rtl'] .about-style14 .about-content .info {
    padding-left: 0;
    padding-right: 190px; }
    [dir='rtl'] .about-style14 .about-content .info:after {
      left: auto;
      right: 50px; }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .about-style14 .about-content {
      margin-left: 0; }
      [dir='rtl'] .about-style14 .about-content .section-head {
        margin-left: 0;
        margin-right: 155px; }
      [dir='rtl'] .about-style14 .about-content .info {
        padding-left: 0; }
        [dir='rtl'] .about-style14 .about-content .info:after {
          content: none; } }

[dir='rtl'] .about-bx2 .dz-media .info {
  left: auto;
  right: 100px; }

[dir='rtl'] .about-bx2 .dz-media:after {
  left: auto;
  right: 0; }

@media only screen and (max-width: 767px) {
  [dir='rtl'] .about-bx2 .dz-media .info {
    left: auto;
    right: 70px; } }

@media only screen and (max-width: 575px) {
  [dir='rtl'] .about-bx2 .dz-media .info {
    left: auto;
    right: 50px; } }

[dir='rtl'] .icon-bx-wraper.left .icon-bx-xl,
[dir='rtl'] .icon-bx-wraper.left .icon-bx-lg,
[dir='rtl'] .icon-bx-wraper.left .icon-bx-md,
[dir='rtl'] .icon-bx-wraper.left .icon-bx-sm,
[dir='rtl'] .icon-bx-wraper.left .icon-bx-xs {
  float: right;
  margin-left: 20px;
  margin-right: 0; }

[dir='rtl'] .icon-bx-wraper.left .icon-xl,
[dir='rtl'] .icon-bx-wraper.left .icon-lg,
[dir='rtl'] .icon-bx-wraper.left .icon-md,
[dir='rtl'] .icon-bx-wraper.left .icon-sm,
[dir='rtl'] .icon-bx-wraper.left .icon-xs {
  float: right;
  margin-right: 0;
  margin-left: 20px; }

[dir='rtl'] .icon-bx-wraper.right {
  text-align: left; }
  [dir='rtl'] .icon-bx-wraper.right .icon-bx-xl,
  [dir='rtl'] .icon-bx-wraper.right .icon-bx-lg,
  [dir='rtl'] .icon-bx-wraper.right .icon-bx-md,
  [dir='rtl'] .icon-bx-wraper.right .icon-bx-sm,
  [dir='rtl'] .icon-bx-wraper.right .icon-bx-xs {
    float: left;
    margin-left: 0;
    margin-right: 20px; }
  [dir='rtl'] .icon-bx-wraper.right .icon-xl,
  [dir='rtl'] .icon-bx-wraper.right .icon-lg,
  [dir='rtl'] .icon-bx-wraper.right .icon-md,
  [dir='rtl'] .icon-bx-wraper.right .icon-sm,
  [dir='rtl'] .icon-bx-wraper.right .icon-xs {
    float: left;
    margin-left: 0;
    margin-right: 10px; }

[dir='rtl'] .icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
  left: auto;
  right: 50%; }

[dir='rtl'] .icon-bx-wraper.bx-style-2.center .icon-bx-xl {
  margin-left: 0;
  margin-right: -75px; }

[dir='rtl'] .icon-bx-wraper.bx-style-2.center .icon-bx-lg {
  margin-left: 0;
  margin-right: -60px; }

[dir='rtl'] .icon-bx-wraper.bx-style-2.center .icon-bx-md {
  margin-left: 0;
  margin-right: -50px; }

[dir='rtl'] .icon-bx-wraper.bx-style-2.center .icon-bx-sm {
  margin-left: 0;
  margin-right: -40px; }

[dir='rtl'] .icon-bx-wraper.bx-style-2.center .icon-bx-xs {
  margin-left: 0;
  margin-right: -20px; }

[dir='rtl'] .icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
  left: auto;
  right: 0; }

[dir='rtl'] .icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
  right: auto;
  left: 0; }

[dir='rtl'] .right-border:before {
  right: auto;
  left: 0; }

[dir='rtl'] .icon-bx-wraper.style-2 {
  padding: 35px 70px 35px 30px;
  margin-left: 0;
  margin-right: 30px; }
  [dir='rtl'] .icon-bx-wraper.style-2 .icon-bx-sm {
    left: auto;
    right: -30px; }
  [dir='rtl'] .icon-bx-wraper.style-2:before {
    left: auto;
    right: 50%;
    transform: translateX(100%) rotate(180deg); }
  [dir='rtl'] .icon-bx-wraper.style-2:after {
    right: auto;
    left: 0; }
  [dir='rtl'] .icon-bx-wraper.style-2:hover:before {
    left: auto;
    right: 0; }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .icon-bx-wraper.style-2 {
      padding: 20px 60px 20px 20px; } }

[dir='rtl'] .icon-bx-wraper.style-4 [class*="icon-bx-"] {
  margin: 12px 12px 12px 32px; }

[dir='rtl'] .icon-bx-wraper.style-5 [class*="icon-bx-"]:after {
  right: auto;
  left: -20px; }

[dir='rtl'] .icon-bx-wraper.style-5 .icon-bx-sm:after {
  right: auto;
  left: -10px; }

[dir='rtl'] .icon-bx-wraper.style-7:before {
  left: auto;
  right: 0; }

[dir='rtl'] .icon-bx-wraper.style-7:hover:before {
  left: auto;
  right: 10px; }

[dir='rtl'] .icon-bx-wraper.style-8 [class*="icon-bx-"] {
  margin-right: 0;
  margin-left: 15px; }

[dir='rtl'] .icon-bx-wraper.style-9 {
  display: flex;
  align-items: center; }
  [dir='rtl'] .icon-bx-wraper.style-9 [class*="icon-bx-"] {
    margin-right: 0;
    margin-left: 20px; }

[dir='rtl'] .flip-bx {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  perspective: 1000px; }
  [dir='rtl'] .flip-bx .inner {
    -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
    transform: translateY(-50%) translateZ(60px) scale(0.94);
    left: auto;
    right: 0; }
  [dir='rtl'] .flip-bx .back {
    left: auto;
    right: 0; }
  [dir='rtl'] .flip-bx .front:after {
    left: auto;
    right: 0; }

[dir='rtl'] .main-slider1 {
  padding: 140px 0 0 140px; }
  @media only screen and (max-width: 1400px) {
    [dir='rtl'] .main-slider1 {
      padding: 100px 0px 0px 100px; } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .main-slider1 {
      padding: 80px 0px 0px 0px; } }
  [dir='rtl'] .main-slider1 .banner-inner {
    border-left: 4px solid var(--primary);
    border-right: 0; }
    [dir='rtl'] .main-slider1 .banner-inner .banner-content {
      left: auto;
      right: calc((100% - 970px) / 2); }
      @media only screen and (max-width: 1200px) {
        [dir='rtl'] .main-slider1 .banner-inner .banner-content {
          left: auto;
          right: calc((100% - 805px) / 2); } }
      @media only screen and (max-width: 991px) {
        [dir='rtl'] .main-slider1 .banner-inner .banner-content {
          left: auto;
          right: calc((100% - 590px) / 2); } }
      @media only screen and (max-width: 767px) {
        [dir='rtl'] .main-slider1 .banner-inner .banner-content {
          left: auto;
          right: 0; } }
  [dir='rtl'] .main-slider1 .image-slider__pagination {
    right: auto;
    left: -80px; }
    @media only screen and (max-width: 1400px) {
      [dir='rtl'] .main-slider1 .image-slider__pagination {
        right: auto;
        left: -100px; } }
  [dir='rtl'] .main-slider1 .swiper-button {
    right: auto;
    left: 0; }
  [dir='rtl'] .main-slider1 .slider-thumbs-wraper {
    left: auto;
    right: 0; }

[dir='rtl'] .main-slider2 .swiper-slide:after {
  left: auto;
  right: 0;
  background: linear-gradient(to left, rgba(25, 25, 25, 0.9) 40%, rgba(125, 185, 232, 0) 100%); }

@media only screen and (max-width: 575px) {
  [dir='rtl'] .main-slider2 .banner-inner .banner-content p {
    padding-left: 0px; } }

[dir='rtl'] .main-slider2 .swiper-button {
  left: auto;
  right: 0; }

[dir='rtl'] .main-slider3 {
  padding: 0 0 80px 80px; }
  @media only screen and (max-width: 1199px) {
    [dir='rtl'] .main-slider3 {
      padding: 0px; } }
  [dir='rtl'] .main-slider3:after {
    left: auto;
    right: 0; }
  [dir='rtl'] .main-slider3 .banner-inner .swiper-slide {
    background-position: center right; }
    [dir='rtl'] .main-slider3 .banner-inner .swiper-slide:after {
      left: auto;
      right: 0; }
  [dir='rtl'] .main-slider3 .slider-thumbs-wraper {
    right: auto;
    left: 100px; }
    @media only screen and (max-width: 575px) {
      [dir='rtl'] .main-slider3 .slider-thumbs-wraper {
        left: auto;
        right: 15px; } }
  [dir='rtl'] .main-slider3 .slider-thumbs .dz-info {
    padding-left: 0;
    padding-right: 20px; }
  [dir='rtl'] .main-slider3 .banner-inner .banner-content {
    padding-left: 0;
    padding-right: 55px; }
    [dir='rtl'] .main-slider3 .banner-inner .banner-content p {
      padding-right: 0;
      padding-left: 150px; }
      @media only screen and (max-width: 767px) {
        [dir='rtl'] .main-slider3 .banner-inner .banner-content p {
          padding-left: 0px; } }
    @media only screen and (max-width: 575px) {
      [dir='rtl'] .main-slider3 .banner-inner .banner-content {
        padding-left: 0;
        padding-right: 15px;
        margin-bottom: 30px; } }
  [dir='rtl'] .main-slider3 .swiper-button {
    left: auto;
    right: 0; }

[dir='rtl'] .banner-one .banner-inner {
  margin-right: auto;
  margin-left: 0; }

[dir='rtl'] .banner-one .video-bx .video-text {
  margin-left: 0;
  margin-right: 30px; }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .banner-one .video-bx .video-text {
      margin-left: 0;
      margin-right: 10px; } }

[dir='rtl'] .banner-one .banner-media {
  right: auto;
  left: -200px;
  transform: skewX(10deg); }
  [dir='rtl'] .banner-one .banner-media img {
    margin-left: 0;
    margin-right: -200px;
    transform: skewX(-10deg); }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .banner-one .banner-media {
      right: auto;
      left: 0;
      transform: skewX(0deg); }
      [dir='rtl'] .banner-one .banner-media img {
        margin-right: 0px; } }

[dir='rtl'] .banner-one .banner-content {
  padding-right: 0;
  padding-left: 120px; }
  @media only screen and (max-width: 1400px) {
    [dir='rtl'] .banner-one .banner-content {
      padding-right: 0;
      padding-left: 40px; } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .banner-one .banner-content {
      padding-right: 15px;
      padding-left: 15px; } }

[dir='rtl'] .banner-one:after {
  left: auto;
  right: 0; }

[dir='rtl'] .main-slider5 .banner-inner {
  padding-right: 0;
  padding-left: 70px;
  margin-left: 0;
  margin-right: calc(calc(100% - 1140px) / 2); }
  @media only screen and (max-width: 1200px) {
    [dir='rtl'] .main-slider5 .banner-inner {
      margin-left: 0;
      margin-right: calc(calc(100% - 960px) / 2); } }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .main-slider5 .banner-inner {
      margin-left: 0;
      margin-right: calc(calc(100% - 720px) / 2); } }
  @media only screen and (max-width: 767px) {
    [dir='rtl'] .main-slider5 .banner-inner {
      margin-left: 0;
      margin-right: calc(calc(100% - 540px) / 2); } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .main-slider5 .banner-inner {
      margin-right: 0;
      padding-left: 0; } }
  [dir='rtl'] .main-slider5 .banner-inner .image-slider__pagination {
    left: auto;
    right: 0; }
    @media only screen and (max-width: 991px) {
      [dir='rtl'] .main-slider5 .banner-inner .image-slider__pagination {
        left: auto;
        right: 50px; } }
    @media only screen and (max-width: 575px) {
      [dir='rtl'] .main-slider5 .banner-inner .image-slider__pagination {
        left: auto;
        right: 35px; } }
    [dir='rtl'] .main-slider5 .banner-inner .image-slider__pagination .swiper-pagination-progressbar-fill {
      transform-origin: right top !important; }

[dir='rtl'] .main-slider5 .banner-content {
  padding-right: 0;
  padding-left: 100px; }
  @media only screen and (max-width: 1680px) {
    [dir='rtl'] .main-slider5 .banner-content {
      padding-right: 0;
      padding-left: 70px; } }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .main-slider5 .banner-content {
      padding-right: 0;
      padding-left: 30px; } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .main-slider5 .banner-content {
      padding-left: 0;
      padding-right: 15px; } }

[dir='rtl'] .main-slider5 .swiper-button {
  right: auto;
  left: 0;
  flex-direction: column; }
  [dir='rtl'] .main-slider5 .swiper-button .swiper-button-prev,
  [dir='rtl'] .main-slider5 .swiper-button .swiper-button-next {
    right: auto;
    left: 0; }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .main-slider5 .swiper-button {
      flex-direction: row-reverse;
      right: auto;
      left: 15px;
      top: auto; } }

[dir='rtl'] .main-slider5 .media-slider .popup-youtube {
  left: auto;
  right: -50px; }

[dir='rtl'] .dz-box.style-1 .dz-info {
  left: auto;
  right: 0; }

[dir='rtl'] .dz-box.style-1:hover .dz-media {
  transform: rotate(5deg) scale(1.2); }

[dir='rtl'] .dz-box.style-2 .dz-info {
  left: auto;
  right: 0; }

[dir='rtl'] .dz-box.style-2:hover .dz-media {
  transform: rotate(5deg) scale(1.2); }

[dir='rtl'] .dz-box.style-3 .dz-info {
  left: auto;
  right: 0; }

[dir='rtl'] .dz-box.style-3:hover .dz-media {
  transform: rotate(5deg) scale(1.2); }

[dir='rtl'] .dz-box.style-4 .dz-media:after {
  left: auto;
  right: 0; }

[dir='rtl'] .dz-box.style-5 .overlay-primary:before {
  left: auto;
  right: 0; }

[dir='rtl'] .dz-box.style-6:after {
  left: auto;
  right: 0; }

[dir='rtl'] .dz-box.style-6 .info {
  left: auto;
  right: 0;
  text-align: right; }

[dir='rtl'] .dz-box.style-6 .view-btn {
  margin: -50px -50px 0px 0; }

[dir='rtl'] .section-head.style-1 .sub-title {
  padding-left: 50px;
  padding-right: 0; }
  [dir='rtl'] .section-head.style-1 .sub-title:after {
    right: auto;
    left: 0; }
  [dir='rtl'] .section-head.style-1 .sub-title.style-1 {
    padding-right: 25px;
    padding-left: 0; }
    [dir='rtl'] .section-head.style-1 .sub-title.style-1:after {
      left: auto;
      right: 0; }

[dir='rtl'] .section-head.style-1.text-right .sub-title {
  padding-right: 50px;
  padding-left: 0; }
  [dir='rtl'] .section-head.style-1.text-right .sub-title:before {
    left: auto;
    right: 0; }
  [dir='rtl'] .section-head.style-1.text-right .sub-title:after {
    display: none; }

[dir='rtl'] .section-head.style-1.text-center .sub-title {
  padding-right: 50px; }
  [dir='rtl'] .section-head.style-1.text-center .sub-title:before {
    left: auto;
    right: 0; }
  [dir='rtl'] .section-head.style-1.text-center .sub-title:after {
    right: auto;
    left: 0; }

[dir='rtl'] .section-head.style-2 .sub-title:before, [dir='rtl'] .section-head.style-2 .sub-title:after {
  margin-right: 0;
  margin-left: 10px; }

[dir='rtl'] .section-head.style-2 .sub-title:before {
  margin-right: 0;
  margin-left: 10px; }

[dir='rtl'] .section-head.style-2 .sub-title:after {
  margin-left: 0;
  margin-right: 10px; }

[dir='rtl'] .dz-team.style-1:hover .team-social li, [dir='rtl'] .dz-team.style-1.active .team-social li {
  transform: translateY(0px);
  opacity: 1; }

[dir='rtl'] .dz-team.style-2 .dz-content {
  margin-right: 30px;
  margin-left: 50px; }
  [dir='rtl'] .dz-team.style-2 .dz-content:after {
    left: auto;
    right: -15px; }
  [dir='rtl'] .dz-team.style-2 .dz-content:before {
    left: auto;
    right: 0; }

[dir='rtl'] .dz-team.style-2 .team-social {
  left: 0;
  right: auto; }
  [dir='rtl'] .dz-team.style-2 .team-social li {
    transform: translateX(-80px); }

[dir='rtl'] .dz-team.style-2:hover .team-social li {
  transform: translateX(15px); }

[dir='rtl'] .testimonial-1 .testimonial-pic:after, [dir='rtl'] .testimonial-1 .testimonial-pic:before {
  left: auto;
  right: 0; }

[dir='rtl'] .testimonial-1 .testimonial-head .title {
  margin-left: 0;
  margin-right: -100px; }

@media only screen and (max-width: 767px) {
  [dir='rtl'] .testimonial-1 {
    display: block; }
    [dir='rtl'] .testimonial-1 .testimonial-head .title {
      margin-right: 0; } }

[dir='rtl'] .testimonial-2:after {
  right: auto;
  left: 50px;
  transform: rotateY(185deg); }

[dir='rtl'] .testimonial-2 .info .testimonial-name {
  margin-right: 0;
  margin-left: 10px; }

[dir='rtl'] .testimonial-2 .info .testimonial-position {
  padding-left: 0;
  padding-right: 15px; }
  [dir='rtl'] .testimonial-2 .info .testimonial-position:before {
    left: auto;
    right: 0; }

[dir='rtl'] .testimonial-3 .testimonial-info:after {
  left: auto;
  right: 30px; }

[dir='rtl'] .testimonial-3 .testimonial-pic {
  margin-right: 0;
  margin-left: 20px; }

[dir='rtl'] .testimonial-4 .testimonial-pic {
  margin-right: 0;
  margin-left: 35px;
  padding: 20px 0 0 20px; }
  [dir='rtl'] .testimonial-4 .testimonial-pic:after {
    right: auto;
    left: 0;
    transform: rotateY(185deg); }

[dir='rtl'] .swiper-container .testimonial-4 {
  padding-right: 0;
  padding-left: 50px; }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .swiper-container .testimonial-4 {
      padding-left: 0; } }

[dir='rtl'] .list-circle li,
[dir='rtl'] .list-angle-double li,
[dir='rtl'] .list-square li,
[dir='rtl'] .list-square-check li,
[dir='rtl'] .list-check-circle li,
[dir='rtl'] .list-check li,
[dir='rtl'] .list-plus-circle li,
[dir='rtl'] .list-arrow-left-circle li,
[dir='rtl'] .list-arrow-right-circle li {
  padding: 5px 30px 5px 5px; }
  [dir='rtl'] .list-circle li:before,
  [dir='rtl'] .list-angle-double li:before,
  [dir='rtl'] .list-square li:before,
  [dir='rtl'] .list-square-check li:before,
  [dir='rtl'] .list-check-circle li:before,
  [dir='rtl'] .list-check li:before,
  [dir='rtl'] .list-plus-circle li:before,
  [dir='rtl'] .list-arrow-left-circle li:before,
  [dir='rtl'] .list-arrow-right-circle li:before {
    left: auto;
    right: 0; }

[dir='rtl'] ul.list-circle li {
  padding: 5px 22px 5px 5px; }

[dir='rtl'] ul.list-arrow-right-circle li:before {
  padding-left: 0;
  padding-right: 1px; }

[dir='rtl'] ul.list-arrow-left-circle li:before {
  padding-right: 0;
  padding-left: 1px; }

[dir='rtl'] .list-square-check li {
  padding: 10px 60px 10px 15px; }

[dir='rtl'] .list-number-circle li {
  padding: 0px 70px 0px 15px; }
  [dir='rtl'] .list-number-circle li:after {
    left: auto;
    right: 0; }

[dir='rtl'] .list-square-box li {
  padding: 0px 25px 0px 0;
  margin-bottom: 30px; }
  [dir='rtl'] .list-square-box li:after {
    left: auto;
    right: 0; }

[dir='rtl'] .list-check-box li {
  padding: 20px 70px 20px 10px;
  margin-right: 0;
  margin-left: 40px; }
  [dir='rtl'] .list-check-box li:after {
    left: 0;
    right: 30px; }
  [dir='rtl'] .list-check-box li:hover {
    margin-left: 0; }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .list-check-box li {
      padding: 20px 55px 20px 10px; }
      [dir='rtl'] .list-check-box li:after {
        left: auto;
        right: 20px; } }

[dir='rtl'] .site-footer.style-1.widget_services li a:after {
  left: auto;
  right: 0; }

[dir='rtl'] .site-footer.style-1 .widget_about {
  padding-right: 0;
  padding-left: 10px; }

[dir='rtl'] .call-box2 {
  padding: 7px 55px 7px 10px; }
  [dir='rtl'] .call-box2 span {
    left: auto;
    right: 0; }
    [dir='rtl'] .call-box2 span:before {
      left: auto;
      right: 0; }
    [dir='rtl'] .call-box2 span:after {
      left: auto;
      right: 0; }

[dir='rtl'] .site-footer.style-2 .footer-info .info-right {
  padding-right: 0;
  padding-left: 30px; }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .site-footer.style-2 .footer-info .info-right {
      padding-left: 0;
      padding-right: 30px; } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .site-footer.style-2 .footer-info .info-right {
      padding-left: 30px;
      padding-right: 30px; } }

[dir='rtl'] .site-footer.style-2 .footer-info .service-info li {
  border-right: 0;
  border-left: 2px solid rgba(255, 255, 255, 0.2); }
  [dir='rtl'] .site-footer.style-2 .footer-info .service-info li:last-child {
    border-left: 0;
    padding-left: 0;
    padding-right: 20px; }
  [dir='rtl'] .site-footer.style-2 .footer-info .service-info li:first-child {
    padding-right: 0;
    padding-left: 20px; }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .site-footer.style-2 .footer-info .service-info li {
      border-left: 0; }
      [dir='rtl'] .site-footer.style-2 .footer-info .service-info li:last-child {
        padding-right: 0; } }

[dir='rtl'] .site-footer.style-2 .dzSubscribe .ft-row .form-control, [dir='rtl'] .site-footer.style-2 .dzSubscribe .ft-row .wp-block-categories-dropdown select, .wp-block-categories-dropdown [dir='rtl'] .site-footer.style-2 .dzSubscribe .ft-row select,
[dir='rtl'] .site-footer.style-2 .dzSubscribe .ft-row .wp-block-archives-dropdown select, .wp-block-archives-dropdown [dir='rtl'] .site-footer.style-2 .dzSubscribe .ft-row select {
  padding-right: 20px;
  padding-left: 50px; }

[dir='rtl'] .site-footer.style-2 .dzSubscribe .ft-row .btn, [dir='rtl'] .site-footer.style-2 .dzSubscribe .ft-row .wp-block-button__link {
  right: auto;
  left: 0; }
  [dir='rtl'] .site-footer.style-2 .dzSubscribe .ft-row .btn i.fa-arrow-right:before, [dir='rtl'] .site-footer.style-2 .dzSubscribe .ft-row .wp-block-button__link i.fa-arrow-right:before {
    content: "\f060"; }

[dir='rtl'] .ft-clients-area:before {
  left: auto;
  right: 0; }

[dir='rtl'] .site-footer.style-3 .footer-top:after {
  left: auto;
  right: 100px; }

[dir='rtl'] .site-footer.style-3 .footer-top:before {
  right: auto;
  left: 100px; }

[dir='rtl'] .site-footer.style-3:after {
  left: auto;
  right: -200px; }

[dir='rtl'] .site-footer.style-3:before {
  right: auto;
  left: -200px; }

[dir='rtl'] .site-footer.style-4 .input-group .input-group-addon {
  right: auto;
  left: 5px; }

[dir='rtl'] .site-footer.style-4 .ft-subscribe .ft-row .form-control, [dir='rtl'] .site-footer.style-4 .ft-subscribe .ft-row .wp-block-categories-dropdown select, .wp-block-categories-dropdown [dir='rtl'] .site-footer.style-4 .ft-subscribe .ft-row select,
[dir='rtl'] .site-footer.style-4 .ft-subscribe .ft-row .wp-block-archives-dropdown select, .wp-block-archives-dropdown [dir='rtl'] .site-footer.style-4 .ft-subscribe .ft-row select {
  margin-right: 0;
  margin-left: 15px; }

[dir='rtl'] .site-footer.style-5 .ft-subscribe .ft-row .form-control, [dir='rtl'] .site-footer.style-5 .ft-subscribe .ft-row .wp-block-categories-dropdown select, .wp-block-categories-dropdown [dir='rtl'] .site-footer.style-5 .ft-subscribe .ft-row select,
[dir='rtl'] .site-footer.style-5 .ft-subscribe .ft-row .wp-block-archives-dropdown select, .wp-block-archives-dropdown [dir='rtl'] .site-footer.style-5 .ft-subscribe .ft-row select {
  margin-right: 0;
  margin-left: 15px; }

[dir='rtl'] footer .widget-logo ul li {
  padding: 0 0 15px 15px; }

[dir='rtl'] footer .footer-title .dz-separator {
  left: auto;
  right: 0; }

[dir='rtl'] footer .widget-link li {
  margin-left: 0;
  margin-right: 20px; }
  @media only screen and (max-width: 767px) {
    [dir='rtl'] footer .widget-link li {
      margin-right: 8px;
      margin-left: 7px; } }

[dir='rtl'] footer .widget_services.style-1 ul li a {
  padding: 5px 15px 5px 0; }
  [dir='rtl'] footer .widget_services.style-1 ul li a:before {
    left: auto;
    right: 0; }

[dir='rtl'] .widget_getintuch ul li {
  padding-left: 0;
  padding-right: 35px; }
  @media only screen and (max-width: 1199px) {
    [dir='rtl'] .widget_getintuch ul li {
      padding-left: 0;
      padding-right: 50px; } }
  [dir='rtl'] .widget_getintuch ul li i {
    left: auto;
    right: 0; }

[dir='rtl'] .footer-link li {
  padding: 0 20px 0 25px; }
  [dir='rtl'] .footer-link li:before {
    left: auto;
    right: 0; }

[dir='rtl'] .widget_time ul li label {
  margin-right: 0;
  margin-left: 3px; }

[dir='rtl'] .DZ-theme-btn,
[dir='rtl'] .theme-btn {
  left: auto;
  right: 10px; }
  [dir='rtl'] .DZ-theme-btn:focus span, [dir='rtl'] .DZ-theme-btn:active span, [dir='rtl'] .DZ-theme-btn:hover span,
  [dir='rtl'] .theme-btn:focus span,
  [dir='rtl'] .theme-btn:active span,
  [dir='rtl'] .theme-btn:hover span {
    padding-left: 0;
    padding-right: 10px; }

[dir='rtl'] .at-expanding-share-button[data-position=bottom-left] {
  right: 10px;
  left: auto; }

[dir='rtl'] .breadcrumb-row ul li {
  margin-right: 0px;
  margin-left: 3px;
  padding-right: 0.5rem;
  padding-left: 0; }

[dir='rtl'] .breadcrumb-row ul .breadcrumb-item + .breadcrumb-item::before {
  content: "\f100";
  padding-right: 0;
  padding-left: 10px;
  float: right; }

[dir='rtl'] .dz-bnr-inr.style-2:after {
  right: auto;
  left: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 100%); }

[dir='rtl'] .dz-bnr-inr.style-3:after {
  left: auto;
  right: 0px;
  background: linear-gradient(to left, var(--primary) 0%, rgba(125, 185, 232, 0) 100%); }

[dir='rtl'] .dz-bnr-inr.style-3:before {
  left: auto;
  right: 0px; }

[dir='rtl'] .dz-bnr-inr.style-4:after {
  left: auto;
  right: 0; }

[dir='rtl'] .dz-bnr-inr.style-4 .breadcrumb-row {
  left: auto;
  right: 0; }

[dir='rtl'] .dz-bnr-inr.style-5:after {
  left: auto;
  right: 0; }

[dir='rtl'] .accordion-button {
  text-align: right; }

[dir='rtl'] .accordion.dz-accordion1 .accordion-header .accordion-button {
  padding: 20px 35px 20px 65px; }
  [dir='rtl'] .accordion.dz-accordion1 .accordion-header .accordion-button .toggle-close {
    right: auto;
    left: 25px;
    transform: rotate(90deg); }
  [dir='rtl'] .accordion.dz-accordion1 .accordion-header .accordion-button.collapsed .toggle-close {
    transform: rotate(180deg); }

@media only screen and (max-width: 575px) {
  [dir='rtl'] .accordion.dz-accordion1 .accordion-header .accordion-button {
    padding: 15px 15px 15px 65px; } }

[dir='rtl'] .accordion.dz-accordion2 .accordion-header .accordion-button {
  border-radius: 4px 0 0 4px;
  padding: 22px 35px 22px 65px;
  text-align: right; }
  [dir='rtl'] .accordion.dz-accordion2 .accordion-header .accordion-button .toggle-close {
    right: auto;
    left: 25px;
    transform: rotate(-90deg); }
    [dir='rtl'] .accordion.dz-accordion2 .accordion-header .accordion-button .toggle-close:after {
      left: auto;
      right: 0; }
    [dir='rtl'] .accordion.dz-accordion2 .accordion-header .accordion-button .toggle-close:before {
      content: "\f053"; }
    @media only screen and (max-width: 575px) {
      [dir='rtl'] .accordion.dz-accordion2 .accordion-header .accordion-button .toggle-close {
        right: auto;
        left: 20px; } }
  [dir='rtl'] .accordion.dz-accordion2 .accordion-header .accordion-button.collapsed .toggle-close {
    transform: rotate(0deg); }

@media only screen and (max-width: 575px) {
  [dir='rtl'] .accordion.dz-accordion2 .accordion-header .accordion-button {
    padding: 15px 15px 15px 65px; } }

[dir='rtl'] .bs-popover-start > .popover-arrow, [dir='rtl'] .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
[dir='rtl'] .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: auto;
  left: calc(-0.5rem - 1px); }
  [dir='rtl'] .bs-popover-start > .popover-arrow:before, [dir='rtl'] .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, [dir='rtl'] .bs-popover-start > .popover-arrow:after, [dir='rtl'] .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after,
  [dir='rtl'] .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before,
  [dir='rtl'] .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
    right: auto;
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #ffffff; }

[dir='rtl'] .bs-popover-end > .popover-arrow, [dir='rtl'] .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
[dir='rtl'] .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: auto;
  right: calc(-0.5rem - 1px); }
  [dir='rtl'] .bs-popover-end > .popover-arrow:after, [dir='rtl'] .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after,
  [dir='rtl'] .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
    left: auto;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-right-color: transparent;
    border-left-color: #ffffff;
    right: 2px; }
  [dir='rtl'] .bs-popover-end > .popover-arrow:before, [dir='rtl'] .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before,
  [dir='rtl'] .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
    left: auto;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-right-color: transparent;
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }

[dir='rtl'] .item-box .item-img .badge {
  left: 0px;
  right: auto;
  border-radius: 0 20px 20px 0; }

[dir='rtl'] .item-box .item-img:after {
  left: auto;
  right: 0; }

[dir='rtl'] .shop-item-tage a {
  margin-left: 0;
  margin-right: 7px; }

[dir='rtl'] .product-brand .search-content {
  padding: 7px 25px 7px 0; }

[dir='rtl'] .product-item-color .btn, [dir='rtl'] .product-item-color .wp-block-button__link {
  margin-right: 0;
  margin-left: 6px; }

[dir='rtl'] .product-size .btn, [dir='rtl'] .product-size .wp-block-button__link {
  margin-left: 6px;
  margin-right: 0; }

[dir='rtl'] .shop-item-rating {
  left: 0;
  right: auto;
  text-align: right; }

[dir='rtl'] .shop-item-tage {
  display: inline-flex; }

[dir='rtl'] .dz-thum-bx .overlay-bx .overlay-icon {
  right: auto;
  left: 20px; }

[dir='rtl'] .related-item-swiper .swiper-button-prev-related,
[dir='rtl'] .related-item-swiper .swiper-button-next-related {
  transform: translateY(-50%) rotate(180deg); }

[dir='rtl'] .related-item-swiper .swiper-button-next-related {
  left: 10px;
  right: auto; }

[dir='rtl'] .related-item-swiper .swiper-button-prev-related {
  left: auto;
  right: 10px; }

[dir='rtl'] .thumb-slider-navigation {
  left: auto;
  right: 0; }

[dir='rtl'] .btn-quantity.style-1 .input-group-btn-vertical button:first-child {
  right: auto;
  left: 0; }

[dir='rtl'] .btn-quantity.style-1 .input-group-btn-vertical button:last-child {
  left: auto;
  right: 0; }

[dir='rtl'] .cart-coupon-wrapper .cutter {
  left: auto;
  right: 25px; }

[dir='rtl'] .cart-coupon-wrapper .btn, [dir='rtl'] .cart-coupon-wrapper .wp-block-button__link {
  right: auto;
  left: 4px; }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .thumb-slider,
  [dir='rtl'] .thumb-slider-navigation {
    padding-left: 15px;
    padding-right: 0; } }

[dir='rtl'] .btn i.fa-long-arrow-alt-right:before, [dir='rtl'] .wp-block-button__link i.fa-long-arrow-alt-right:before {
  content: "\f30a"; }

[dir='rtl'] .btn:not(.btn-icon):hover [class*="right"], [dir='rtl'] .wp-block-button__link:not(.btn-icon):hover [class*="right"] {
  -webkit-animation: upToLeft 0.5s forwards;
  -moz-animation: upToLeft 0.5s forwards;
  animation: upToLeft 0.5s forwards; }

[dir='rtl'] .btn.btn-icon, [dir='rtl'] .btn-icon.wp-block-button__link {
  padding: 15px 25px 15px 65px; }
  [dir='rtl'] .btn.btn-icon i, [dir='rtl'] .btn-icon.wp-block-button__link i {
    right: auto;
    left: 5px;
    transform: translateY(-50%) rotate(180deg); }
    @media only screen and (max-width: 575px) {
      [dir='rtl'] .btn.btn-icon i, [dir='rtl'] .btn-icon.wp-block-button__link i {
        right: auto;
        left: 4px; } }
  [dir='rtl'] .btn.btn-icon.btn-sm, [dir='rtl'] .btn-group-sm > .btn.btn-icon, [dir='rtl'] .btn-group-sm > .btn-icon.wp-block-button__link, [dir='rtl'] .btn-icon.btn-sm.wp-block-button__link {
    padding: 12px 25px 12px 60px; }
  [dir='rtl'] .btn.btn-icon.btn-lg, [dir='rtl'] .btn-group-lg > .btn.btn-icon, [dir='rtl'] .btn-group-lg > .btn-icon.wp-block-button__link, [dir='rtl'] .btn-icon.btn-lg.wp-block-button__link {
    padding: 20px 40px 20px 85px; }

[dir='rtl'] .btn.btnstyle1, [dir='rtl'] .btnstyle1.wp-block-button__link {
  padding: 14px 35px 20px 39px;
  margin: 6px 6px 0 0; }
  [dir='rtl'] .btn.btnstyle1:after, [dir='rtl'] .btnstyle1.wp-block-button__link:after {
    left: auto;
    right: -6px; }
  [dir='rtl'] .btn.btnstyle1:hover, [dir='rtl'] .btnstyle1.wp-block-button__link:hover {
    margin: 0 0 6px 6px;
    padding: 20px 39px 14px 35px; }
    [dir='rtl'] .btn.btnstyle1:hover:after, [dir='rtl'] .btnstyle1.wp-block-button__link:hover:after {
      left: auto;
      right: 6px; }
  [dir='rtl'] .btn.btnstyle1.btn-sm, [dir='rtl'] .btn-group-sm > .btn.btnstyle1, [dir='rtl'] .btn-group-sm > .btnstyle1.wp-block-button__link, [dir='rtl'] .btnstyle1.btn-sm.wp-block-button__link {
    padding: 8px 20px 14px 24px; }
    [dir='rtl'] .btn.btnstyle1.btn-sm:hover, [dir='rtl'] .btn-group-sm > .btn.btnstyle1:hover, [dir='rtl'] .btn-group-sm > .btnstyle1.wp-block-button__link:hover, [dir='rtl'] .btnstyle1.btn-sm.wp-block-button__link:hover {
      padding: 14px 24px 8px 20px; }

[dir='rtl'] .comments-area .comment-list > .comment .comment-body {
  padding: 0 110px 0 0; }
  [dir='rtl'] .comments-area .comment-list > .comment .comment-body .comment-author .avatar {
    left: auto;
    right: 0; }
  [dir='rtl'] .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link {
    right: auto;
    left: 0; }
    [dir='rtl'] .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link i {
      margin-right: 0;
      font-size: 14px;
      margin-left: 8px; }
  @media only screen and (max-width: 767px) {
    [dir='rtl'] .comments-area .comment-list > .comment .comment-body {
      padding: 0 75px 0 0; } }

[dir='rtl'] .comments-area .comment-list > .comment > .children {
  padding-left: 0;
  padding-right: 50px; }

[dir='rtl'] .dz-share-post ul li {
  margin-right: 0;
  margin-left: 5px; }

[dir='rtl'] .post-tags strong {
  margin-right: 0;
  margin-left: 10px; }

[dir='rtl'] .dz-page-text ul > li:before,
[dir='rtl'] .dz-page-text ol > li:before,
[dir='rtl'] .dz-card.blog-single .dz-post-text ul > li:before,
[dir='rtl'] .dz-card.blog-single .dz-post-text ol > li:before {
  left: auto;
  right: -0.938rem; }

[dir='rtl'] .dz-page-text > ol,
[dir='rtl'] .dz-page-text > ul,
[dir='rtl'] .dz-card.blog-single .dz-post-text > ol,
[dir='rtl'] .dz-card.blog-single .dz-post-text > ul,
[dir='rtl'] .dz-card.blog-single .dz-post-text.text > ol,
[dir='rtl'] .dz-card.blog-single .dz-post-text.text > ul {
  padding-left: 0;
  padding-right: 1.25rem; }

[dir='rtl'] .wp-block-quote.is-large cite:before,
[dir='rtl'] .wp-block-quote.is-style-large cite:before,
[dir='rtl'] blockquote.wp-block-quote cite:before,
[dir='rtl'] blockquote.wp-block-pullquote cite:before,
[dir='rtl'] blockquote.wp-block-pullquote.alignright cite:before,
[dir='rtl'] blockquote.wp-block-pullquote.alignleft cite:before,
[dir='rtl'] blockquote cite:before {
  margin-left: 0.625rem;
  margin-right: 0; }

[dir='rtl'] .wp-block-quote.is-large,
[dir='rtl'] .wp-block-quote.is-style-large,
[dir='rtl'] blockquote.wp-block-quote,
[dir='rtl'] blockquote.wp-block-pullquote,
[dir='rtl'] blockquote.wp-block-pullquote.alignright,
[dir='rtl'] blockquote.wp-block-pullquote.alignleft,
[dir='rtl'] blockquote {
  padding: 1.25rem 3rem 1.25rem 2rem; }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .wp-block-quote.is-large,
    [dir='rtl'] .wp-block-quote.is-style-large,
    [dir='rtl'] blockquote.wp-block-quote,
    [dir='rtl'] blockquote.wp-block-pullquote,
    [dir='rtl'] blockquote.wp-block-pullquote.alignright,
    [dir='rtl'] blockquote.wp-block-pullquote.alignleft,
    [dir='rtl'] blockquote {
      padding: 1.25rem 1.25rem 0.938rem 1.25rem; } }
  [dir='rtl'] .wp-block-quote.is-large cite:before,
  [dir='rtl'] .wp-block-quote.is-style-large cite:before,
  [dir='rtl'] blockquote.wp-block-quote cite:before,
  [dir='rtl'] blockquote.wp-block-pullquote cite:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright cite:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft cite:before,
  [dir='rtl'] blockquote cite:before {
    margin-right: 0;
    margin-left: 0.625rem; }
  [dir='rtl'] .wp-block-quote.is-large:after,
  [dir='rtl'] .wp-block-quote.is-style-large:after,
  [dir='rtl'] blockquote.wp-block-quote:after,
  [dir='rtl'] blockquote.wp-block-pullquote:after,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright:after,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft:after,
  [dir='rtl'] blockquote:after {
    left: auto;
    right: 0; }
  [dir='rtl'] .wp-block-quote.is-large:before,
  [dir='rtl'] .wp-block-quote.is-style-large:before,
  [dir='rtl'] blockquote.wp-block-quote:before,
  [dir='rtl'] blockquote.wp-block-pullquote:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft:before,
  [dir='rtl'] blockquote:before {
    left: auto;
    right: 20px; }
  [dir='rtl'] .wp-block-quote.is-large.wp-block-pullquote.alignleft,
  [dir='rtl'] .wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
  [dir='rtl'] blockquote.wp-block-quote.wp-block-pullquote.alignleft,
  [dir='rtl'] blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft {
    margin: 0rem 0rem 1.563rem 1.563rem; }
  [dir='rtl'] .wp-block-quote.is-large.wp-block-pullquote.alignright,
  [dir='rtl'] .wp-block-quote.is-style-large.wp-block-pullquote.alignright,
  [dir='rtl'] blockquote.wp-block-quote.wp-block-pullquote.alignright,
  [dir='rtl'] blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright {
    margin: 0rem 1.563rem 1.563rem 0; }
  [dir='rtl'] .wp-block-quote.is-large.style-1:before,
  [dir='rtl'] .wp-block-quote.is-style-large.style-1:before,
  [dir='rtl'] blockquote.wp-block-quote.style-1:before,
  [dir='rtl'] blockquote.wp-block-pullquote.style-1:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright.style-1:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft.style-1:before,
  [dir='rtl'] blockquote.style-1:before {
    right: auto;
    left: 70px; }
  [dir='rtl'] .wp-block-quote.is-large.style-2,
  [dir='rtl'] .wp-block-quote.is-style-large.style-2,
  [dir='rtl'] blockquote.wp-block-quote.style-2,
  [dir='rtl'] blockquote.wp-block-pullquote.style-2,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright.style-2,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft.style-2,
  [dir='rtl'] blockquote.style-2 {
    padding: 10px 25px 10px 0; }
  [dir='rtl'] .wp-block-quote.is-large.style-3:before,
  [dir='rtl'] .wp-block-quote.is-style-large.style-3:before,
  [dir='rtl'] blockquote.wp-block-quote.style-3:before,
  [dir='rtl'] blockquote.wp-block-pullquote.style-3:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright.style-3:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft.style-3:before,
  [dir='rtl'] blockquote.style-3:before {
    left: auto;
    right: 0; }
  [dir='rtl'] .wp-block-quote.is-large.style-4:before,
  [dir='rtl'] .wp-block-quote.is-style-large.style-4:before,
  [dir='rtl'] blockquote.wp-block-quote.style-4:before,
  [dir='rtl'] blockquote.wp-block-pullquote.style-4:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright.style-4:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft.style-4:before,
  [dir='rtl'] blockquote.style-4:before {
    left: auto;
    right: 30%; }

[dir='rtl'] .widget_tag_cloud .tagcloud a {
  margin: 0 0 15px 15px; }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .widget_tag_cloud .tagcloud a {
      margin: 0 0 10px 10px; } }

[dir='rtl'] .dz-page-text > .wp-block-gallery,
[dir='rtl'] .dz-card.blog-single .dz-post-text > .wp-block-gallery,
[dir='rtl'] .dz-card.blog-single .dz-post-text.text > .wp-block-gallery {
  padding: 0; }

[dir='rtl'] .search-bx button {
  border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm); }

[dir='rtl'] .search-bx .form-control, [dir='rtl'] .search-bx .wp-block-categories-dropdown select, .wp-block-categories-dropdown [dir='rtl'] .search-bx select,
[dir='rtl'] .search-bx .wp-block-archives-dropdown select, .wp-block-archives-dropdown [dir='rtl'] .search-bx select,
[dir='rtl'] .search-bx .wp-block-categories-dropdown select,
[dir='rtl'] .wp-block-categories-dropdown .search-bx select,
[dir='rtl'] .search-bx .wp-block-archives-dropdown select,
[dir='rtl'] .wp-block-archives-dropdown .search-bx select,
[dir='rtl'] .search-bx .comment-respond .comment-form p.comment-form-author input,
[dir='rtl'] .comment-respond .comment-form p.comment-form-author .search-bx input,
[dir='rtl'] .search-bx .comment-respond .comment-form p.comment-form-email input,
[dir='rtl'] .comment-respond .comment-form p.comment-form-email .search-bx input,
[dir='rtl'] .search-bx .comment-respond .comment-form p.comment-form-url input,
[dir='rtl'] .comment-respond .comment-form p.comment-form-url .search-bx input,
[dir='rtl'] .search-bx .comment-respond .comment-form p textarea,
[dir='rtl'] .comment-respond .comment-form p .search-bx textarea {
  border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0 !important; }

[dir='rtl'] .dz-meta ul li {
  margin-left: 20px;
  margin-right: 0; }
  [dir='rtl'] .dz-meta ul li:last-child {
    margin-left: 0; }
  [dir='rtl'] .dz-meta ul li i {
    margin-left: 2px;
    margin-right: 0; }

[dir='rtl'] .post-video .post-video-icon {
  padding-left: 0;
  padding-right: 5px; }
  [dir='rtl'] .post-video .post-video-icon:before, [dir='rtl'] .post-video .post-video-icon:after {
    left: auto;
    right: 0; }

[dir='rtl'] .dz-card.style-1 .dz-media + .dz-info .dz-meta {
  left: auto;
  right: 30px; }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .dz-card.style-1 .dz-media + .dz-info .dz-meta {
      left: auto;
      right: 15px; } }

[dir='rtl'] .dz-card.style-1 .dz-meta ul li {
  margin-right: 0;
  margin-left: 10px; }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .dz-card.style-1 .dz-meta ul li {
      margin-right: 0;
      margin-left: 5px; } }

[dir='rtl'] .dz-card.style-2 .dz-meta ul li {
  margin-right: 0;
  margin-left: 10px; }

[dir='rtl'] .dz-card.style-2.blog-half .dz-info {
  padding: 0 0 0 70px; }

[dir='rtl'] .dz-card.style-2.blog-half .dz-media + .dz-info {
  padding: 0 70px 0 0; }

@media only screen and (max-width: 1199px) {
  [dir='rtl'] .dz-card.style-2.blog-half .dz-info {
    padding: 0 0 0 30px; }
  [dir='rtl'] .dz-card.style-2.blog-half .dz-media + .dz-info {
    padding: 0 30px 0 0; } }

@media only screen and (max-width: 991px) {
  [dir='rtl'] .dz-card.style-2.blog-half .dz-info {
    padding: 0 0 0 30px; } }

[dir='rtl'] .dz-card.style-3 .dz-meta {
  left: auto;
  right: 10px; }
  [dir='rtl'] .dz-card.style-3 .dz-meta ul li {
    margin-right: 0;
    margin-left: 10px; }
    [dir='rtl'] .dz-card.style-3 .dz-meta ul li i {
      margin-right: 0;
      margin-left: 5px; }

[dir='rtl'] .dz-meta ul li {
  margin-right: 0;
  margin-left: 20px; }
  [dir='rtl'] .dz-meta ul li:last-child {
    margin-left: 0; }
  [dir='rtl'] .dz-meta ul li i {
    margin-right: 0;
    margin-left: 2px; }

[dir='rtl'] .post-video .post-video-icon {
  padding-left: 0;
  padding-right: 5px; }
  [dir='rtl'] .post-video .post-video-icon:before {
    left: auto;
    right: 0; }
  [dir='rtl'] .post-video .post-video-icon:after {
    left: auto;
    right: 0; }

[dir='rtl'] .author-box .author-profile-info .author-profile-content {
  padding-left: 0;
  padding-right: 25px; }

[dir='rtl'] .blog-single .dz-social-icon .title {
  margin-right: 0;
  margin-left: 18px; }

[dir='rtl'] .comment-respond .comment-form p {
  width: 100%; }

[dir='rtl'] .post-link-in {
  left: auto;
  right: 0; }

[dir='rtl'] .side-bar {
  padding-left: 0;
  padding-right: 20px; }
  @media only screen and (max-width: 991px) {
    [dir='rtl'] .side-bar {
      padding-left: 0;
      padding-right: 0; } }
  [dir='rtl'] .side-bar.left {
    padding-left: 20px;
    padding-right: 0; }
    @media only screen and (max-width: 991px) {
      [dir='rtl'] .side-bar.left {
        padding-right: 0;
        padding-left: 0; } }

[dir='rtl'] .alignright {
  float: left; }
  [dir='rtl'] .alignrightimg, [dir='rtl'] .alignright.wp-caption, [dir='rtl'] .alignright.is-resized {
    float: left; }

[dir='rtl'] .alignleft {
  float: right;
  margin: 0.3125rem 0 1.563rem 1.563rem; }
  [dir='rtl'] .alignleftimg, [dir='rtl'] .alignleft.wp-caption, [dir='rtl'] .alignleft.is-resized {
    margin: 0.3125rem 0 1.563rem 1.563rem;
    float: right; }

[dir='rtl'] .wp-caption p.wp-caption-text {
  text-align: right; }

[dir='rtl'] .wp-block-quote.is-large,
[dir='rtl'] .wp-block-quote.is-style-large,
[dir='rtl'] blockquote.wp-block-quote,
[dir='rtl'] blockquote.wp-block-pullquote,
[dir='rtl'] blockquote.wp-block-pullquote.alignright,
[dir='rtl'] blockquote.wp-block-pullquote.alignleft,
[dir='rtl'] blockquote {
  padding: 1.25rem 3rem 1.25rem 2rem; }
  [dir='rtl'] .wp-block-quote.is-large cite:before,
  [dir='rtl'] .wp-block-quote.is-style-large cite:before,
  [dir='rtl'] blockquote.wp-block-quote cite:before,
  [dir='rtl'] blockquote.wp-block-pullquote cite:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright cite:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft cite:before,
  [dir='rtl'] blockquote cite:before {
    margin-right: 0;
    margin-left: 0.625rem; }
  [dir='rtl'] .wp-block-quote.is-large:after,
  [dir='rtl'] .wp-block-quote.is-style-large:after,
  [dir='rtl'] blockquote.wp-block-quote:after,
  [dir='rtl'] blockquote.wp-block-pullquote:after,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright:after,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft:after,
  [dir='rtl'] blockquote:after {
    left: auto;
    right: 0; }
  [dir='rtl'] .wp-block-quote.is-large:before,
  [dir='rtl'] .wp-block-quote.is-style-large:before,
  [dir='rtl'] blockquote.wp-block-quote:before,
  [dir='rtl'] blockquote.wp-block-pullquote:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft:before,
  [dir='rtl'] blockquote:before {
    left: auto;
    right: 20px; }
  [dir='rtl'] .wp-block-quote.is-large.wp-block-pullquote.alignleft,
  [dir='rtl'] .wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
  [dir='rtl'] blockquote.wp-block-quote.wp-block-pullquote.alignleft,
  [dir='rtl'] blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft {
    margin: 0rem 0 1.563rem 1.563rem; }
  [dir='rtl'] .wp-block-quote.is-large.wp-block-pullquote.alignright,
  [dir='rtl'] .wp-block-quote.is-style-large.wp-block-pullquote.alignright,
  [dir='rtl'] blockquote.wp-block-quote.wp-block-pullquote.alignright,
  [dir='rtl'] blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright {
    margin: 0rem 1.563rem 1.563rem 0; }
  [dir='rtl'] .wp-block-quote.is-large.style-1:before,
  [dir='rtl'] .wp-block-quote.is-style-large.style-1:before,
  [dir='rtl'] blockquote.wp-block-quote.style-1:before,
  [dir='rtl'] blockquote.wp-block-pullquote.style-1:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignright.style-1:before,
  [dir='rtl'] blockquote.wp-block-pullquote.alignleft.style-1:before,
  [dir='rtl'] blockquote.style-1:before {
    left: auto;
    right: 20px; }

[dir='rtl'] .dz-page-text > ol,
[dir='rtl'] .dz-page-text > ul,
[dir='rtl'] .dz-card.blog-single .dz-post-text > ol,
[dir='rtl'] .dz-card.blog-single .dz-post-text > ul,
[dir='rtl'] .dz-card.blog-single .dz-post-text.text > ol,
[dir='rtl'] .dz-card.blog-single .dz-post-text.text > ul {
  padding-left: 0;
  padding-right: 1.25rem; }
  [dir='rtl'] .dz-page-text > ol ol,
  [dir='rtl'] .dz-page-text > ol ul,
  [dir='rtl'] .dz-page-text > ul ol,
  [dir='rtl'] .dz-page-text > ul ul,
  [dir='rtl'] .dz-card.blog-single .dz-post-text > ol ol,
  [dir='rtl'] .dz-card.blog-single .dz-post-text > ol ul,
  [dir='rtl'] .dz-card.blog-single .dz-post-text > ul ol,
  [dir='rtl'] .dz-card.blog-single .dz-post-text > ul ul,
  [dir='rtl'] .dz-card.blog-single .dz-post-text.text > ol ol,
  [dir='rtl'] .dz-card.blog-single .dz-post-text.text > ol ul,
  [dir='rtl'] .dz-card.blog-single .dz-post-text.text > ul ol,
  [dir='rtl'] .dz-card.blog-single .dz-post-text.text > ul ul {
    padding-left: 0;
    padding-right: 1.563rem; }

[dir='rtl'] .wp-block-gallery .blocks-gallery-image,
[dir='rtl'] .wp-block-gallery .blocks-gallery-item {
  margin: 0 0 0.5rem 0.5rem; }

[dir='rtl'] .wp-block-gallery.columns-4 .blocks-gallery-image,
[dir='rtl'] .wp-block-gallery.columns-4 .blocks-gallery-item,
[dir='rtl'] .wp-block-gallery.columns-3 .blocks-gallery-image,
[dir='rtl'] .wp-block-gallery.columns-3 .blocks-gallery-item {
  margin-right: 0;
  margin-left: 0.5rem; }

[dir='rtl'] .alignfull,
[dir='rtl'] .alignfullwide {
  margin-left: 0;
  margin-right: calc(50% - 50vw - (var(--content-share-ml,0px)/2)); }

[dir='rtl'] .wp-block-quote.has-text-align-right {
  border-left: 0; }

[dir='rtl'] .dz-page-text ul > li:before,
[dir='rtl'] .dz-page-text ol > li:before,
[dir='rtl'] .dz-card.blog-single .dz-post-text ul > li:before,
[dir='rtl'] .dz-card.blog-single .dz-post-text ol > li:before {
  left: auto;
  right: -0.938rem; }

[dir='rtl'] .wp-block-gallery .blocks-gallery-image,
[dir='rtl'] .wp-block-gallery .blocks-gallery-item {
  margin: 0 0 1rem 1rem; }

[dir='rtl'] .wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
[dir='rtl'] .wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
  margin-left: 0; }

[dir='rtl'] .wp-block-gallery.columns-1 .blocks-gallery-image,
[dir='rtl'] .wp-block-gallery.columns-1 .blocks-gallery-item {
  margin-left: 0; }

@media (min-width: 37.5rem) {
  [dir='rtl'] .wp-block-gallery.columns-3 .blocks-gallery-image,
  [dir='rtl'] .wp-block-gallery.columns-3 .blocks-gallery-item {
    margin-right: 0;
    margin-left: 1rem;
    width: calc(33.33333% - 0.666rem); }
  [dir='rtl'] .wp-block-gallery.columns-4 .blocks-gallery-image,
  [dir='rtl'] .wp-block-gallery.columns-4 .blocks-gallery-item {
    margin-right: 0;
    margin-left: 1rem; }
  [dir='rtl'] .wp-block-gallery.columns-5 .blocks-gallery-image,
  [dir='rtl'] .wp-block-gallery.columns-5 .blocks-gallery-item {
    margin-right: 0;
    margin-left: 1rem; }
  [dir='rtl'] .wp-block-gallery.columns-6 .blocks-gallery-image,
  [dir='rtl'] .wp-block-gallery.columns-6 .blocks-gallery-item {
    margin-right: 0;
    margin-left: 1rem; }
  [dir='rtl'] .wp-block-gallery.columns-7 .blocks-gallery-image,
  [dir='rtl'] .wp-block-gallery.columns-7 .blocks-gallery-item {
    margin-right: 0;
    margin-left: 1rem; }
  [dir='rtl'] .wp-block-gallery.columns-8 .blocks-gallery-image,
  [dir='rtl'] .wp-block-gallery.columns-8 .blocks-gallery-item {
    margin-right: 0;
    margin-left: 1rem; }
  [dir='rtl'] .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
  [dir='rtl'] .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
  [dir='rtl'] .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
  [dir='rtl'] .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
  [dir='rtl'] .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
  [dir='rtl'] .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
  [dir='rtl'] .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
  [dir='rtl'] .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
  [dir='rtl'] .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
  [dir='rtl'] .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
  [dir='rtl'] .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
  [dir='rtl'] .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
  [dir='rtl'] .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
  [dir='rtl'] .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
  [dir='rtl'] .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
  [dir='rtl'] .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-left: 0; } }

[dir='rtl'] .wp-block-gallery .blocks-gallery-image:last-child,
[dir='rtl'] .wp-block-gallery .blocks-gallery-item:last-child {
  margin-left: 0; }

[dir='rtl'] .blog-overlap {
  border-radius: 0.25rem 0 0rem 0.25rem; }

[dir='rtl'] .wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt {
  margin-left: 0;
  margin-right: 4.375rem; }

[dir='rtl'] .wp-block-latest-comments img + article .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
  margin-left: 0;
  margin-right: 4.375rem; }

[dir='rtl'] .post-footer .dz-meta .tag-list {
  text-align: right; }

[dir='rtl'] .post-footer .dz-meta ul li {
  margin-right: 0;
  margin-left: 0.3125rem; }

[dir='rtl'] .comments-area .comment-list > .comment .comment-body {
  padding: 0 115px 25px 0; }
  @media only screen and (max-width: 767px) {
    [dir='rtl'] .comments-area .comment-list > .comment .comment-body {
      padding: 0 75px 25px 0; } }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .comments-area .comment-list > .comment .comment-body {
      padding: 0 75px 20px 0; } }
  [dir='rtl'] .comments-area .comment-list > .comment .comment-body .comment-author .avatar {
    left: auto;
    right: 0; }
  [dir='rtl'] .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link {
    right: auto;
    left: 0; }
    [dir='rtl'] .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link i {
      margin-right: 0;
      margin-left: 8px; }

[dir='rtl'] .comments-area .comment-list > .comment > .children {
  padding-left: 0;
  padding-right: 50px; }

[dir='rtl'] .comment-respond small, [dir='rtl'] .comment-respond .small {
  margin-left: 0;
  margin-right: 7px; }

@media only screen and (max-width: 575px) {
  [dir='rtl'] .dz-share-post .post-tags a {
    margin-right: 0;
    margin-left: 5px; } }

[dir='rtl'] .post-swiper .prev-post-swiper-btn {
  left: auto;
  right: 10px; }

[dir='rtl'] .post-swiper .next-post-swiper-btn {
  right: auto;
  left: 10px; }

[dir='rtl'] .author-box .author-profile-info .author-profile-content {
  padding-left: 0;
  padding-right: 25px; }
  @media only screen and (max-width: 575px) {
    [dir='rtl'] .author-box .author-profile-info .author-profile-content {
      padding-left: 0;
      padding-right: 0; } }
  [dir='rtl'] .author-box .author-profile-info .author-profile-content ul {
    float: right; }
    @media only screen and (max-width: 575px) {
      [dir='rtl'] .author-box .author-profile-info .author-profile-content ul {
        margin-left: 0;
        float: none; } }
    [dir='rtl'] .author-box .author-profile-info .author-profile-content ul li {
      margin-right: 0;
      margin-left: 10px;
      float: right; }
      @media only screen and (max-width: 575px) {
        [dir='rtl'] .author-box .author-profile-info .author-profile-content ul li {
          margin-right: 5px;
          margin-left: 5px; } }

@media only screen and (max-width: 575px) {
  [dir='rtl'] .blog-single.dz-card .post-tags {
    margin-left: 0;
    margin-right: 0; } }

[dir='rtl'] .counter-style-1 .counter-num small, [dir='rtl'] .counter-style-1 .counter-num .small {
  margin-left: 0;
  margin-right: 5px; }

[dir='rtl'] .counter-style-2 .counter-num small, [dir='rtl'] .counter-style-2 .counter-num .small {
  margin-left: 0;
  margin-right: 5px; }

[dir='rtl'] .counter-style-3 .icon-bx {
  margin: 12px 12px 12px 32px; }

[dir='rtl'] .counter-style-3 .counter-num small, [dir='rtl'] .counter-style-3 .counter-num .small {
  margin-left: 0;
  margin-right: 5px; }

[dir='rtl'] .slider-btn-1 .swiper-button {
  right: auto;
  left: 0; }

[dir='rtl'] .blockquote.style-1 {
  box-shadow: unset;
  padding: 60px 30px 5px 5px; }
  [dir='rtl'] .blockquote.style-1:before {
    left: auto;
    right: 30px; }
  [dir='rtl'] .blockquote.style-1 cite {
    margin-left: 0;
    margin-right: 6px; }
    [dir='rtl'] .blockquote.style-1 cite:before {
      margin-left: -5px;
      margin-right: 5px; }

[dir='rtl'] .no-col-gap .pricingtable-wrapper {
  margin-left: 0;
  margin-right: -1px; }

[dir='rtl'] .pricingtable-toggle .custom-control-label {
  margin: 0 50px 0 35px; }

[dir='rtl'] .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx .ext {
  margin-left: 0;
  margin-right: 12px; }

[dir='rtl'] .pricingtable-wrapper.style-1 .pricingtable-features {
  text-align: right; }
  [dir='rtl'] .pricingtable-wrapper.style-1 .pricingtable-features li {
    padding: 5px 35px 5px 0; }
    [dir='rtl'] .pricingtable-wrapper.style-1 .pricingtable-features li:after {
      left: auto;
      right: 0; }

[dir='rtl'] .nav-tabs.style-1 .nav-item {
  border-left: 1px solid #dee2e6;
  border-right: 0;
  width: 100%; }
  [dir='rtl'] .nav-tabs.style-1 .nav-item:last-child {
    border-left: 0; }

#bg {
  background-attachment: fixed;
  background-size: cover; }

.main-bar-wraper.sticky-no .main-bar {
  position: relative; }

.boxed .page-wraper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

.boxed .footer-fixed .site-footer {
  left: 50%;
  width: 1200px;
  margin: 0 -600px; }

.boxed .is-fixed .main-bar {
  transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -webkit-transition: none;
  -o-transition: none;
  position: unset; }

@media only screen and (min-width: 1199px) {
  .boxed .site-header.style-2 .container-fluid, .boxed .site-header.style-2 .container-sm, .boxed .site-header.style-2 .container-md, .boxed .site-header.style-2 .container-lg, .boxed .site-header.style-2 .container-xl,
  .boxed .site-header.style-2 .container-sm,
  .boxed .site-header.style-2 .container-md,
  .boxed .site-header.style-2 .container-lg,
  .boxed .site-header.style-2 .container-xl {
    padding-left: 30px;
    padding-right: 30px; } }

.boxed .search-inhead {
  display: none; }

.boxed .main-slider3 {
  padding: 0px 35px 30px 0px; }

.boxed .site-header.style-3 .extra-nav {
  padding-right: 30px; }

.boxed .site-header.style-4 .logo-header {
  padding-left: 30px;
  padding-right: 30px; }

.boxed .banner-one .banner-inner {
  width: 80%; }

.boxed .main-slider5 .banner-content h1, .boxed .main-slider5 .banner-content .h1,
.boxed .main-slider5 .banner-content .h1 {
  font-size: 65px; }

.boxed #home-slider {
  width: 100%; }

@media only screen and (min-width: 1680px) {
  .boxed .header-nav .nav > li > a {
    padding-left: 15px;
    padding-right: 15px; } }

@media only screen and (min-width: 1680px) {
  .boxed .silder-one .silder-content {
    padding: 40px; } }

@media only screen and (min-width: 1680px) {
  .boxed .silder-one .silder-content .title {
    margin-bottom: 20px;
    font-size: 60px; } }

@media only screen and (min-width: 1680px) {
  .boxed .silder-one .silder-content .sub-title {
    margin-bottom: 10px; } }

@media only screen and (min-width: 1200px) {
  .boxed .silder-two .title {
    font-size: 45px; }
  .boxed .silder-two .silder-content {
    width: 60%;
    padding-right: 80px;
    padding-left: 40px; }
  .boxed .silder-two .silder-img {
    width: calc(40% + 55px); } }

@media only screen and (min-width: 1680px) {
  .boxed .container-fluid, .boxed .container-sm, .boxed .container-md, .boxed .container-lg, .boxed .container-xl {
    padding-left: 15px;
    padding-right: 15px; } }

@media only screen and (min-width: 1680px) {
  .boxed .under-construct {
    padding: 50px; } }

@media only screen and (min-width: 1680px) {
  .boxed .under-construct .dz-content .dz-title {
    font-size: 70px; } }

@media only screen and (min-width: 1680px) {
  .boxed .under-construct .dz-content p {
    font-size: 28px; } }

/* Frame */
.frame {
  padding: 30px; }
  .frame .page-wraper {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: auto !important; }
  @media only screen and (max-width: 1680px) {
    .frame .site-header.style-1 .extra-icon-box {
      display: none; } }
  .frame #home-slider {
    width: 100%; }
  .frame .is-fixed .main-bar {
    left: 0;
    width: 100%;
    margin: 0;
    position: relative; }
  .frame button.scroltop {
    right: 50px;
    bottom: 50px; }

@media only screen and (max-width: 1024px) {
  .frame {
    padding: 0 !important; }
  .frame button.scroltop {
    right: 15px;
    bottom: 15px; } }

.theme-sharped {
  --border-radius-base: 0;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none; }

.theme-rounded {
  --border-radius-base: 10px;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none; }

[data-theme-color="color_1"] {
  --gradient: linear-gradient( to right, rgb(255,64,62) 0%, rgb(255,129,27) 100%);
  --gradient-sec: linear-gradient( to right, rgb(57,26,166) 0%, rgb(6,163,245) 100%);
  --primary: #fe4c1c;
  --secondary: #192436;
  --primary-hover: #ec471b;
  --primary-dark: #ea4619;
  --rgba-primary-1: rgba(254,76,28,0.1);
  --rgba-primary-2: rgba(254,76,28,0.2);
  --rgba-primary-3: rgba(254,76,28,0.3);
  --rgba-primary-4: rgba(254,76,28,0.4);
  --rgba-primary-5: rgba(254,76,28,0.5);
  --rgba-primary-6: rgba(254,76,28,0.6);
  --rgba-primary-7: rgba(254,76,28,0.7);
  --rgba-primary-8: rgba(254,76,28,0.8);
  --rgba-primary-9: rgba(254,76,28,0.9);
  --title: #071c35; }

[data-theme-color="color_2"] {
  --primary: #2eb999;
  --secondary: #192436;
  --primary-hover: #27ad8e;
  --primary-dark: #d48828;
  --rgba-primary-1: rgba(32,40,113,0.1);
  --rgba-primary-2: rgba(32,40,113,0.2);
  --rgba-primary-3: rgba(32,40,113,0.3);
  --rgba-primary-4: rgba(32,40,113,0.4);
  --rgba-primary-5: rgba(32,40,113,0.5);
  --rgba-primary-6: rgba(32,40,113,0.6);
  --rgba-primary-7: rgba(32,40,113,0.7);
  --rgba-primary-8: rgba(32,40,113,0.8);
  --rgba-primary-9: rgba(32,40,113,0.9);
  --title: #212529;
  --quote: url(../images/quote-2.png); }

[data-theme-color="color_3"] {
  --primary: #26AA5D;
  --secondary: #192436;
  --primary-hover: #00c75d;
  --primary-dark: #01a74f;
  --rgba-primary-1: rgba(0,214,100,0.1);
  --rgba-primary-2: rgba(0,214,100,0.2);
  --rgba-primary-3: rgba(0,214,100,0.3);
  --rgba-primary-4: rgba(0,214,100,0.4);
  --rgba-primary-5: rgba(0,214,100,0.5);
  --rgba-primary-6: rgba(0,214,100,0.6);
  --rgba-primary-7: rgba(0,214,100,0.7);
  --rgba-primary-8: rgba(0,214,100,0.8);
  --rgba-primary-9: rgba(0,214,100,0.9);
  --title: #212529;
  --quote: url(../images/quote-3.png); }

[data-theme-color="color_4"] {
  --primary: #e5588d;
  --secondary: #192436;
  --primary-hover: #da5b8b;
  --primary-dark: #d45987;
  --rgba-primary-1: rgba(229,88,141,0.1);
  --rgba-primary-2: rgba(229,88,141,0.2);
  --rgba-primary-3: rgba(229,88,141,0.3);
  --rgba-primary-4: rgba(229,88,141,0.4);
  --rgba-primary-5: rgba(229,88,141,0.5);
  --rgba-primary-6: rgba(229,88,141,0.6);
  --rgba-primary-7: rgba(229,88,141,0.7);
  --rgba-primary-8: rgba(229,88,141,0.8);
  --rgba-primary-9: rgba(229,88,141,0.9);
  --title: #212529;
  --quote: url(../images/quote-4.png); }

[data-theme-color="color_5"] {
  --primary: #FB751B;
  --secondary: #192436;
  --primary-hover: #e36917;
  --primary-dark: #c95d15;
  --rgba-primary-1: rgba(251,117,27,0.1);
  --rgba-primary-2: rgba(251,117,27,0.2);
  --rgba-primary-3: rgba(251,117,27,0.3);
  --rgba-primary-4: rgba(251,117,27,0.4);
  --rgba-primary-5: rgba(251,117,27,0.5);
  --rgba-primary-6: rgba(251,117,27,0.6);
  --rgba-primary-7: rgba(251,117,27,0.7);
  --rgba-primary-8: rgba(251,117,27,0.8);
  --rgba-primary-9: rgba(251,117,27,0.9);
  --title: #071c35;
  --quote: url(../images/quote-5.png); }

[data-theme-color="color_6"] {
  --primary: #e4484e;
  --secondary: #192436;
  --primary-hover: #d72c33;
  --primary-dark: #c92027;
  --rgba-primary-1: rgba(228,72,78,0.1);
  --rgba-primary-2: rgba(228,72,78,0.2);
  --rgba-primary-3: rgba(228,72,78,0.3);
  --rgba-primary-4: rgba(228,72,78,0.4);
  --rgba-primary-5: rgba(228,72,78,0.5);
  --rgba-primary-6: rgba(228,72,78,0.6);
  --rgba-primary-7: rgba(228,72,78,0.7);
  --rgba-primary-8: rgba(228,72,78,0.8);
  --rgba-primary-9: rgba(228,72,78,0.9);
  --title: #071c35;
  --quote: url(../images/quote-6.png); }

[data-theme-color="color_7"] {
  --primary: #977fff;
  --secondary: #192436;
  --primary-hover: #5739db;
  --primary-dark: #391faf;
  --rgba-primary-1: rgba(151,127,255,0.1);
  --rgba-primary-2: rgba(151,127,255,0.2);
  --rgba-primary-3: rgba(151,127,255,0.3);
  --rgba-primary-4: rgba(151,127,255,0.4);
  --rgba-primary-5: rgba(151,127,255,0.5);
  --rgba-primary-6: rgba(151,127,255,0.6);
  --rgba-primary-7: rgba(151,127,255,0.7);
  --rgba-primary-8: rgba(151,127,255,0.8);
  --rgba-primary-9: rgba(151,127,255,0.9);
  --title: #212529;
  --quote: url(../images/quote-7.png); }

[data-theme-color="color_8"] {
  --primary: #eb8153;
  --secondary: #192436;
  --primary-hover: #cf6233;
  --primary-dark: #b54f23;
  --rgba-primary-1: rgba(235,129,83,0.1);
  --rgba-primary-2: rgba(235,129,83,0.2);
  --rgba-primary-3: rgba(235,129,83,0.3);
  --rgba-primary-4: rgba(235,129,83,0.4);
  --rgba-primary-5: rgba(235,129,83,0.5);
  --rgba-primary-6: rgba(235,129,83,0.6);
  --rgba-primary-7: rgba(235,129,83,0.7);
  --rgba-primary-8: rgba(235,129,83,0.8);
  --rgba-primary-9: rgba(235,129,83,0.9);
  --title: #212529;
  --quote: url(../images/quote-8.png); }

[data-theme-color="color_9"] {
  --primary: #ff8f16;
  --secondary: #192436;
  --primary-hover: #e37f13;
  --primary-dark: #cb700e;
  --rgba-primary-1: rgba(255,143,22,0.1);
  --rgba-primary-2: rgba(255,143,22,0.2);
  --rgba-primary-3: rgba(255,143,22,0.3);
  --rgba-primary-4: rgba(255,143,22,0.4);
  --rgba-primary-5: rgba(255,143,22,0.5);
  --rgba-primary-6: rgba(255,143,22,0.6);
  --rgba-primary-7: rgba(255,143,22,0.7);
  --rgba-primary-8: rgba(255,143,22,0.8);
  --rgba-primary-9: rgba(255,143,22,0.9);
  --title: #212529;
  --quote: url(../images/quote-9.png); }

/*=== Switcher ===*/
.layout-dark {
  --dark: #0e1828;
  --dark-card: #152236;
  --title:#fff;
  color: rgba(255, 255, 255, 0.6);
  background-color: var(--dark); }
  .layout-dark .site-header.style-2.header-transparent .main-bar {
    background: transparent; }
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar .container > .logo-dark,
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar .container-fluid > .logo-dark,
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar .container-sm > .logo-dark,
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar .container-md > .logo-dark,
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar .container-lg > .logo-dark,
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar .container-xl > .logo-dark {
    display: none; }
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar .container > .logo-white,
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar .container-fluid > .logo-white,
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar .container-sm > .logo-white,
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar .container-md > .logo-white,
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar .container-lg > .logo-white,
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar .container-xl > .logo-white {
    display: block; }
  .layout-dark .site-header.style-2.header-transparent .main-bar.show .container > .logo-dark,
  .layout-dark .site-header.style-2.header-transparent .main-bar.show .container-fluid > .logo-dark,
  .layout-dark .site-header.style-2.header-transparent .main-bar.show .container-sm > .logo-dark,
  .layout-dark .site-header.style-2.header-transparent .main-bar.show .container-md > .logo-dark,
  .layout-dark .site-header.style-2.header-transparent .main-bar.show .container-lg > .logo-dark,
  .layout-dark .site-header.style-2.header-transparent .main-bar.show .container-xl > .logo-dark {
    display: none; }
  .layout-dark .site-header.style-2.header-transparent .main-bar.show .container > .logo-white,
  .layout-dark .site-header.style-2.header-transparent .main-bar.show .container-fluid > .logo-white,
  .layout-dark .site-header.style-2.header-transparent .main-bar.show .container-sm > .logo-white,
  .layout-dark .site-header.style-2.header-transparent .main-bar.show .container-md > .logo-white,
  .layout-dark .site-header.style-2.header-transparent .main-bar.show .container-lg > .logo-white,
  .layout-dark .site-header.style-2.header-transparent .main-bar.show .container-xl > .logo-white {
    display: block; }
  .layout-dark .site-header.style-4 .main-bar .container > .logo-white,
  .layout-dark .site-header.style-4 .main-bar .container-fluid > .logo-white,
  .layout-dark .site-header.style-4 .main-bar .container-sm > .logo-white,
  .layout-dark .site-header.style-4 .main-bar .container-md > .logo-white,
  .layout-dark .site-header.style-4 .main-bar .container-lg > .logo-white,
  .layout-dark .site-header.style-4 .main-bar .container-xl > .logo-white {
    display: block; }
  .layout-dark .site-header.style-4 .main-bar .container > .logo-dark,
  .layout-dark .site-header.style-4 .main-bar .container-fluid > .logo-dark,
  .layout-dark .site-header.style-4 .main-bar .container-sm > .logo-dark,
  .layout-dark .site-header.style-4 .main-bar .container-md > .logo-dark,
  .layout-dark .site-header.style-4 .main-bar .container-lg > .logo-dark,
  .layout-dark .site-header.style-4 .main-bar .container-xl > .logo-dark {
    display: none; }
  .layout-dark .site-header.style-5 .middle-bar .logo-white {
    display: block; }
  .layout-dark .site-header.style-5 .middle-bar .logo-dark {
    display: none; }
  .layout-dark .history-wrapper::before,
  .layout-dark .site-header.style-2.header-transparent .is-fixed .main-bar,
  .layout-dark .dz-team.style-1 .team-social li a,
  .layout-dark .dz-team.style-1 .dz-content,
  .layout-dark .modal-content,
  .layout-dark blockquote.style-1,
  .layout-dark .service-box,
  .layout-dark .search-inhead,
  .layout-dark .feature-wrapper::before,
  .layout-dark .top-bar,
  .layout-dark .flsbg .bg-wrap span,
  .layout-dark .contact-area2::after,
  .layout-dark .main-slider3:after,
  .layout-dark .service-wrapper::after,
  .layout-dark .banner-one::after,
  .layout-dark .testimonial-4 .testimonial-pic,
  .layout-dark .app-form-wraper .book-time li .btn,
  .layout-dark .app-form-wraper .book-time li .wp-block-button__link,
  .layout-dark .app-form-wraper .form-group label,
  .layout-dark .service_menu_nav ul li a,
  .layout-dark .service-wrapper-2::after,
  .layout-dark .overlay-white-light:before, .layout-dark .overlay-white-middle:before, .layout-dark .overlay-white-dark:before,
  .layout-dark .about-style1 .exp-bx .exp-info,
  .layout-dark .dz-box.style-3 .dz-info,
  .layout-dark #home-slider .swiper-slide,
  .layout-dark .payment-card .form-check span,
  .layout-dark .btn-quantity.style-1 input,
  .layout-dark .widget.style-1 .search-bx .form-control,
  .layout-dark .widget.style-1 .search-bx .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .layout-dark .widget.style-1 .search-bx select,
  .layout-dark .widget.style-1 .search-bx .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .layout-dark .widget.style-1 .search-bx select,
  .layout-dark .widget.style-1 .search-bx .wp-block-categories-dropdown select,
  .layout-dark .wp-block-categories-dropdown .widget.style-1 .search-bx select,
  .layout-dark .widget.style-1 .search-bx .wp-block-archives-dropdown select,
  .layout-dark .wp-block-archives-dropdown .widget.style-1 .search-bx select,
  .layout-dark .site-header.header-sidenav-1 .is-fixed .main-bar,
  .layout-dark .contact-wraper1:after,
  .layout-dark #loading-area.loading-page-4,
  .layout-dark #loading-area.loading-page-5,
  .layout-dark .app-form-wraper .form-wizard > .nav li .nav-link .number,
  .layout-dark .site-header.style-5 .main-bar::after {
    background-color: var(--dark); }
  .layout-dark .bg-white,
  .layout-dark .page-content {
    background-color: var(--dark) !important; }
  .layout-dark .section {
    position: relative;
    z-index: 1; }
    .layout-dark .section:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-color: var(--dark);
      opacity: 0.8; }
  .layout-dark .history-wrapper:after,
  .layout-dark .table-striped > tbody > tr:nth-of-type(2n+1) > *,
  .layout-dark .icon-bx-wraper.style-4 [class*="icon-bx-"],
  .layout-dark .site-header.style-4 .search-inhead .form-control,
  .layout-dark .site-header.style-4 .search-inhead .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .layout-dark .site-header.style-4 .search-inhead select,
  .layout-dark .site-header.style-4 .search-inhead .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .layout-dark .site-header.style-4 .search-inhead select,
  .layout-dark .site-header .extra-nav #quik-search-btn,
  .layout-dark .testimonial-1 .testimonial-pic,
  .layout-dark .site-header.style-5 .extra-nav #quik-search-btn,
  .layout-dark .site-header.style-5 .top-bar,
  .layout-dark .site-header.style-5 .main-bar .container,
  .layout-dark .icon-bx-wraper.style-3,
  .layout-dark .site-header.style-4 .search-inhead,
  .layout-dark .about-style10 .list-check-circle,
  .layout-dark .testimonial-3 .testimonial-info,
  .layout-dark .accordion.dz-accordion2 .accordion-header .accordion-button.collapsed,
  .layout-dark .accordion.dz-accordion2 .accordion-body,
  .layout-dark .accordion.dz-accordion1 .accordion-header .accordion-button.collapsed,
  .layout-dark .accordion.dz-accordion1 .accordion-body,
  .layout-dark .icon-bx-wraper.style-2::after,
  .layout-dark .icon-bx-wraper.style-2::before,
  .layout-dark .app-form-wraper .form-group .form-label,
  .layout-dark .app-form-wraper .check-date label::after,
  .layout-dark .app-form-wraper,
  .layout-dark .about-style12 .dz-media ul,
  .layout-dark .icon-bx-wraper.style-7,
  .layout-dark .history-wrapper .history-wrapper-bx .history-box::after,
  .layout-dark .history-wrapper .history-wrapper-bx .history-box .inner-content,
  .layout-dark .history-wrapper .history-wrapper-bx .year,
  .layout-dark .content-box2,
  .layout-dark .content-box2 .dz-bottom .btn-link,
  .layout-dark .pricingtable-wrapper.style-1,
  .layout-dark .content-box .dz-info,
  .layout-dark .icon-bx-wraper.style-8 [class*="icon-bx-"],
  .layout-dark .login-wrapper .login-form,
  .layout-dark .order-total-card,
  .layout-dark .payment-card,
  .layout-dark .btn-quantity.style-1 .input-group-btn-vertical button:first-child,
  .layout-dark .btn-quantity.style-1 .input-group-btn-vertical button:last-child,
  .layout-dark .product-size .btn,
  .layout-dark .product-size .wp-block-button__link,
  .layout-dark .product-size .wp-block-button__link,
  .layout-dark .author-box,
  .layout-dark .pagination li .page-numbers,
  .layout-dark .pagination li .page-link,
  .layout-dark .widget.style-1,
  .layout-dark .dz-card.style-1 .dz-info,
  .layout-dark .contact-area1,
  .layout-dark .accordion.dz-accordion2 .accordion-item {
    background: var(--dark-card); }
  .layout-dark .site-header.header-sidenav-1 {
    --secondary:#FFF; }
    .layout-dark .site-header.header-sidenav-1 .header-nav .nav > li .mega-menu,
    .layout-dark .site-header.header-sidenav-1 .header-nav .nav > li .sub-menu {
      background-color: transparent; }
      .layout-dark .site-header.header-sidenav-1 .header-nav .nav > li .mega-menu li:hover > a,
      .layout-dark .site-header.header-sidenav-1 .header-nav .nav > li .sub-menu li:hover > a {
        background-color: var(--dark-card); }
  .layout-dark .site-header.header-sidenav-1 .main-bar, .layout-dark.header-transparent .main-bar {
    background: transparent; }
  .layout-dark .site-header.header-sidenav-1 + .page-content .dz-bnr-inr .dz-bnr-inr-entry, .layout-dark.header-transparent + .page-content .dz-bnr-inr .dz-bnr-inr-entry {
    padding-top: 100px; }
  .layout-dark .site-header.header-sidenav-1 .nav > li > a, .layout-dark.header-transparent .nav > li > a {
    color: var(--secondary); }
  .layout-dark .main-bar {
    background: var(--secondary); }
  .layout-dark .is-fixed .header-content-bx {
    height: auto; }
  .layout-dark .is-fixed .main-bar {
    background: var(--secondary); }
  .layout-dark .site-header.header-sidenav-1 .header-content-bx > .logo-header.logo-dark {
    display: none; }
  .layout-dark .site-header.header-sidenav-1 .main-bar.show .header-content-bx > .logo-header.logo-dark {
    display: none; }
  .layout-dark .site-header.header-sidenav-1 .main-bar.show .header-content-bx > .logo-header.logo-white {
    display: block; }
  .layout-dark .header-nav .nav > li .mega-menu,
  .layout-dark .header-nav .nav > li .sub-menu {
    background-color: var(--dark); }
    .layout-dark .header-nav .nav > li .mega-menu .header-style-content,
    .layout-dark .header-nav .nav > li .sub-menu .header-style-content {
      background-color: var(--dark); }
    @media only screen and (max-width: 991px) {
      .layout-dark .header-nav .nav > li .mega-menu,
      .layout-dark .header-nav .nav > li .sub-menu {
        background-color: transparent; }
        .layout-dark .header-nav .nav > li .mega-menu .header-style-content,
        .layout-dark .header-nav .nav > li .sub-menu .header-style-content {
          background-color: transparent; } }
    .layout-dark .header-nav .nav > li .mega-menu li a,
    .layout-dark .header-nav .nav > li .sub-menu li a {
      color: rgba(255, 255, 255, 0.6); }
    .layout-dark .header-nav .nav > li .mega-menu li:hover > a,
    .layout-dark .header-nav .nav > li .sub-menu li:hover > a {
      background-color: var(--dark-card); }
  .layout-dark .header-nav .nav > li .mega-menu > li > a {
    color: #FFF; }
  .layout-dark .header-nav .nav > li .mega-menu > li:hover > a {
    background-color: transparent;
    color: #FFF; }
  .layout-dark .header-tabs-wraper .header-style-bx .header-style-tabs {
    background: var(--dark-card); }
    .layout-dark .header-tabs-wraper .header-style-bx .header-style-tabs .header-tabs.active {
      color: #fff;
      background: var(--dark); }
  .layout-dark .header-tabs-wraper .header-style-content .menu-list > li .title {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.21); }
  @media only screen and (max-width: 991px) {
    .layout-dark .mo-left .header-nav {
      background-color: var(--dark-card); }
    .layout-dark .header-nav .nav > li {
      border-color: rgba(255, 255, 255, 0.2); }
      .layout-dark .header-nav .nav > li > a {
        color: #fff; }
      .layout-dark .header-nav .nav > li .mega-menu,
      .layout-dark .header-nav .nav > li .sub-menu {
        border-color: rgba(255, 255, 255, 0.2);
        background-color: var(--dark-card); }
        .layout-dark .header-nav .nav > li .mega-menu li a:after,
        .layout-dark .header-nav .nav > li .sub-menu li a:after {
          color: var(--primary); }
    .layout-dark .header-transparent .is-fixed .nav > li > a {
      color: #fff; } }
  .layout-dark .input-group-prepend .input-group-text {
    background-color: transparent; }
  .layout-dark .main-slider2 .swiper-button .swiper-button-prev,
  .layout-dark .main-slider2 .swiper-button .swiper-button-next {
    background: #0e1828b3; }
  .layout-dark .nav-tabs.style-3,
  .layout-dark .icon-bx-wraper.style-6,
  .layout-dark .icon-bx-wraper.style-7:before,
  .layout-dark .progress-bx.style-1 .progress,
  .layout-dark .check-box,
  .layout-dark .icon-bx-wraper.style-2 {
    background: #213046; }
  .layout-dark svg.radial-progress .basic-circle {
    stroke: #213046 !important; }
  .layout-dark .overlay-gadient-white::after {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0.34) 1%, #0e1828 25%); }
  .layout-dark .testimonial-3 .testimonial-info::after {
    border-top: 15px solid var(--dark-card); }
  .layout-dark .dz-team.style-1 .team-social li a:hover,
  .layout-dark .site-header.style-1:after,
  .layout-dark .site-header.style-1:before,
  .layout-dark .dz-social-icon.social-light ul li a:hover,
  .layout-dark .icon-bx-wraper.style-7:hover,
  .layout-dark .icon-bx-wraper.style-8:hover [class*="icon-bx-"],
  .layout-dark .icon-bx-wraper.style-9:hover a,
  .layout-dark .content-box2:hover,
  .layout-dark .service_menu_nav ul li:hover a,
  .layout-dark .service_menu_nav ul li.active a,
  .layout-dark .history-wrapper .history-wrapper-bx .history-box:hover .inner-content,
  .layout-dark .check-box:hover,
  .layout-dark .check-box.active,
  .layout-dark .icon-bx-wraper.box-hover.style-6.active,
  .layout-dark .pagination li .page-numbers.current,
  .layout-dark .pagination li .page-numbers.active,
  .layout-dark .pagination li .page-numbers:hover,
  .layout-dark .pagination li .page-link.current,
  .layout-dark .pagination li .page-link.active,
  .layout-dark .pagination li .page-link:hover {
    background-color: var(--primary); }
  .layout-dark .content-box2:hover .dz-bottom .btn-link {
    background-color: var(--primary); }
  .layout-dark .history-wrapper .history-wrapper-bx .history-box:hover:nth-child(2n)::before {
    border-color: var(--primary) var(--primary) transparent transparent; }
  .layout-dark .history-wrapper .history-wrapper-bx .history-box:hover:nth-child(2n+1)::before {
    border-color: transparent transparent var(--primary) var(--primary); }
  .layout-dark .layout-dark .icon-bx-wraper.style-7::before {
    background: #4f4f66 !important; }
  .layout-dark .price-slide .amount,
  .layout-dark .blog-single.style-1 .dz-media + .dz-info,
  .layout-dark .pagination li .prev,
  .layout-dark .pagination li .next {
    color: #fff;
    background: transparent; }
  .layout-dark .layout-dark .pagination li .page-link,
  .layout-dark .about-bx2 .dz-info .text-black,
  .layout-dark .tab-pane .text-black,
  .layout-dark .icon-bx-wraper.style-8 [class*="icon-bx-"] .text-dark,
  .layout-dark .check-tbl .text-black {
    color: #fff !important; }
  .layout-dark .overlay-primary-middle:before,
  .layout-dark .overlay-white-middle:before,
  .layout-dark .overlay-gradient-middle:before,
  .layout-dark .overlay-black-middle:before {
    opacity: 0.6; }
  .layout-dark .bg-gray {
    background-color: #2d384a; }
  .layout-dark .layout-dark .form-control, .layout-dark .layout-dark .wp-block-categories-dropdown select, .wp-block-categories-dropdown .layout-dark .layout-dark select,
  .layout-dark .layout-dark .wp-block-archives-dropdown select, .wp-block-archives-dropdown .layout-dark .layout-dark select,
  .layout-dark ul.info-desc li,
  .layout-dark .modal-header,
  .layout-dark .modal-footer,
  .layout-dark .layout-dark .dz-accordion2 .accordion-header .accordion-button.collapsed,
  .layout-dark .site-footer.style-4 .input-group input,
  .layout-dark .nav-tabs.style-3,
  .layout-dark .site-header.style-5 .top-bar .dz-social li:last-child,
  .layout-dark .site-header.style-5 .top-bar .dz-social li,
  .layout-dark .dz-share-post .post-tags a,
  .layout-dark .btn-outline-secondary,
  .layout-dark .site-filters.style-2 .filters li a,
  .layout-dark .content-box2 .dz-bottom:after,
  .layout-dark .history-wrapper .history-wrapper-bx .year,
  .layout-dark .accordion.dz-accordion1 .accordion-header .accordion-button.collapsed,
  .layout-dark .accordion.dz-accordion1 .accordion-body,
  .layout-dark .search-inhead,
  .layout-dark .flsbg .bg-wrap,
  .layout-dark .top-bar,
  .layout-dark .nav-tabs.style-1 .nav-link,
  .layout-dark .accordion.dz-accordion2 .accordion-body,
  .layout-dark .about-style12 .dz-media ul,
  .layout-dark .icon-bx-wraper.style-7,
  .layout-dark .content-box2 .dz-info .title,
  .layout-dark thead, .layout-dark tbody, .layout-dark tfoot, .layout-dark tr, .layout-dark td, .layout-dark th,
  .layout-dark .tabs-site-button .nav-tabs,
  .layout-dark .table tr th, .layout-dark .table.wishlist tbody td, .layout-dark .table.cart tbody td,
  .layout-dark .table.wishlist thead th, .layout-dark .table.cart thead th,
  .layout-dark .dz-share-post,
  .layout-dark .comments-area .comment-list > .comment .comment-body,
  .layout-dark .widget.style-1 .widget-title,
  .layout-dark .widget_tag_cloud .tagcloud a,
  .layout-dark .header-box,
  .layout-dark .site-header.style-5 .top-bar .dz-topbar-left ul li,
  .layout-dark .pagination.style-1,
  .layout-dark .nav-tabs.style-1,
  .layout-dark .nav-tabs.style-1 .nav-item,
  .layout-dark .pricingtable-wrapper.style-1 .pricingtable-features,
  .layout-dark .history-wrapper .history-wrapper-bx .history-box:after,
  .layout-dark .header-nav .nav > li .mega-menu > li > a {
    border-color: rgba(255, 255, 255, 0.2); }
  .layout-dark .history-wrapper .history-wrapper-bx .history-box:nth-child(2n+1)::before,
  .layout-dark .history-wrapper .history-wrapper-bx .history-box:nth-child(2n)::before {
    border-color: var(--dark-card); }
  .layout-dark .accordion.dz-accordion2 .accordion-item,
  .layout-dark .dz-accordion2 .accordion-header .accordion-button.collapsed {
    border: 1px solid rgba(255, 255, 255, 0.2); }
  .layout-dark .icon-bx-wraper.style-3:hover {
    background-color: var(--primary) !important; }
  .layout-dark .dz-card.style-1 .dz-meta ul li,
  .layout-dark .slider-btn-1 .swiper-button .button-prev,
  .layout-dark .slider-btn-1 .swiper-button .button-next,
  .layout-dark .nav-tabs.style-3 .nav-link,
  .layout-dark .nav-tabs.style-2 .nav-link,
  .layout-dark .site-header.style-5 .service-info span,
  .layout-dark .btn-outline-secondary,
  .layout-dark .exp-row p,
  .layout-dark .site-header.style-3 .top-bar .help-text strong,
  .layout-dark .nav-tabs.style-1 .nav-link,
  .layout-dark .app-form-wraper .book-time li .btn,
  .layout-dark .app-form-wraper .book-time li .wp-block-button__link,
  .layout-dark .content-box2 .dz-bottom .btn-link,
  .layout-dark .service_menu_nav ul li a,
  .layout-dark .list-circle li,
  .layout-dark .table,
  .layout-dark .price-slide .amount {
    color: inherit; }
  .layout-dark .header-transparent .is-fixed .nav > li > a,
  .layout-dark .site-header.style-5 .extra-nav #quik-search-btn,
  .layout-dark .top-bar .dz-social li a,
  .layout-dark .site-header.style-5 .top-bar .dz-topbar-left ul li,
  .layout-dark .site-header.style-4 .header-nav .nav > li > a,
  .layout-dark .content-box2 .dz-info .title,
  .layout-dark .site-header.style-3 .top-bar .help-text,
  .layout-dark .site-header.style-3 .header-nav .nav > li > a,
  .layout-dark .history-wrapper .history-wrapper-bx .year,
  .layout-dark #home-slider .swiper-pagination .swiper-pagination-bullet,
  .layout-dark .tabs-site-button .nav-tabs a,
  .layout-dark .btn-quantity.style-1 .input-group-btn-vertical button,
  .layout-dark .product-size .btn,
  .layout-dark .product-size .wp-block-button__link, .layout-dark .product-size .wp-block-button__link,
  .layout-dark .table tr th,
  .layout-dark .table.wishlist tbody td,
  .layout-dark .table.cart tbody td,
  .layout-dark .check-box:before,
  .layout-dark .check-box.active:before,
  .layout-dark .icon-bx-wraper.box-hover.style-6.active .icon-cell i {
    color: #fff; }
  .layout-dark .history-wrapper .history-wrapper-bx .year {
    box-shadow: #152236 0px 0px 0px 8px, #1c2636 0px 0px 0px 16px; }
  .layout-dark .comment-respond .comment-form p.comment-form-author input,
  .layout-dark .comment-respond .comment-form p.comment-form-email input,
  .layout-dark .comment-respond .comment-form p.comment-form-url input,
  .layout-dark .comment-respond .comment-form p textarea,
  .layout-dark .form-control,
  .layout-dark .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .layout-dark select,
  .layout-dark .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .layout-dark select {
    background: var(--dark);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff; }
    .layout-dark .comment-respond .comment-form p.comment-form-author input:focus,
    .layout-dark .comment-respond .comment-form p.comment-form-email input:focus,
    .layout-dark .comment-respond .comment-form p.comment-form-url input:focus,
    .layout-dark .comment-respond .comment-form p textarea:focus,
    .layout-dark .form-control:focus,
    .layout-dark .wp-block-categories-dropdown select:focus,
    .wp-block-categories-dropdown .layout-dark select:focus,
    .layout-dark .wp-block-archives-dropdown select:focus,
    .wp-block-archives-dropdown .layout-dark select:focus {
      background: var(--dark-card);
      color: #fff; }
    .layout-dark .comment-respond .comment-form p.comment-form-author input::-webkit-input-placeholder,
    .layout-dark .comment-respond .comment-form p.comment-form-email input::-webkit-input-placeholder,
    .layout-dark .comment-respond .comment-form p.comment-form-url input::-webkit-input-placeholder,
    .layout-dark .comment-respond .comment-form p textarea::-webkit-input-placeholder,
    .layout-dark .form-control::-webkit-input-placeholder,
    .layout-dark .wp-block-categories-dropdown select::-webkit-input-placeholder,
    .wp-block-categories-dropdown .layout-dark select::-webkit-input-placeholder,
    .layout-dark .wp-block-archives-dropdown select::-webkit-input-placeholder,
    .wp-block-archives-dropdown .layout-dark select::-webkit-input-placeholder {
      color: #fff; }
    .layout-dark .comment-respond .comment-form p.comment-form-author input:-ms-input-placeholder,
    .layout-dark .comment-respond .comment-form p.comment-form-email input:-ms-input-placeholder,
    .layout-dark .comment-respond .comment-form p.comment-form-url input:-ms-input-placeholder,
    .layout-dark .comment-respond .comment-form p textarea:-ms-input-placeholder,
    .layout-dark .form-control:-ms-input-placeholder,
    .layout-dark .wp-block-categories-dropdown select:-ms-input-placeholder,
    .wp-block-categories-dropdown .layout-dark select:-ms-input-placeholder,
    .layout-dark .wp-block-archives-dropdown select:-ms-input-placeholder,
    .wp-block-archives-dropdown .layout-dark select:-ms-input-placeholder {
      color: #fff; }
    .layout-dark .comment-respond .comment-form p.comment-form-author input::placeholder,
    .layout-dark .comment-respond .comment-form p.comment-form-email input::placeholder,
    .layout-dark .comment-respond .comment-form p.comment-form-url input::placeholder,
    .layout-dark .comment-respond .comment-form p textarea::placeholder,
    .layout-dark .form-control::placeholder,
    .layout-dark .wp-block-categories-dropdown select::placeholder,
    .wp-block-categories-dropdown .layout-dark select::placeholder,
    .layout-dark .wp-block-archives-dropdown select::placeholder,
    .wp-block-archives-dropdown .layout-dark select::placeholder {
      color: #fff; }
  .layout-dark .comment-respond .comment-form p.comment-form-author input,
  .layout-dark .comment-respond .comment-form p.comment-form-email input,
  .layout-dark .comment-respond .comment-form p.comment-form-url input,
  .layout-dark .comment-respond .comment-form p textarea {
    background: var(--dark-card); }
  .layout-dark .btn.btn-border::after, .layout-dark .btn-border.wp-block-button__link::after,
  .layout-dark .btn-border.wp-block-button__link::after {
    border-color: #fff; }
  .layout-dark .search-inhead .dz-quik-search .form-control:focus, .layout-dark .search-inhead .dz-quik-search .wp-block-categories-dropdown select:focus, .wp-block-categories-dropdown .layout-dark .search-inhead .dz-quik-search select:focus,
  .layout-dark .search-inhead .dz-quik-search .wp-block-archives-dropdown select:focus, .wp-block-archives-dropdown .layout-dark .search-inhead .dz-quik-search select:focus {
    background: var(--dark-card);
    color: #fff; }
  .layout-dark .search-inhead .dz-quik-search .form-control::-webkit-input-placeholder, .layout-dark .search-inhead .dz-quik-search .wp-block-categories-dropdown select::-webkit-input-placeholder, .wp-block-categories-dropdown .layout-dark .search-inhead .dz-quik-search select::-webkit-input-placeholder,
  .layout-dark .search-inhead .dz-quik-search .wp-block-archives-dropdown select::-webkit-input-placeholder, .wp-block-archives-dropdown .layout-dark .search-inhead .dz-quik-search select::-webkit-input-placeholder {
    color: #fff; }
  .layout-dark .search-inhead .dz-quik-search .form-control:-ms-input-placeholder, .layout-dark .search-inhead .dz-quik-search .wp-block-categories-dropdown select:-ms-input-placeholder, .wp-block-categories-dropdown .layout-dark .search-inhead .dz-quik-search select:-ms-input-placeholder,
  .layout-dark .search-inhead .dz-quik-search .wp-block-archives-dropdown select:-ms-input-placeholder, .wp-block-archives-dropdown .layout-dark .search-inhead .dz-quik-search select:-ms-input-placeholder {
    color: #fff; }
  .layout-dark .search-inhead .dz-quik-search .form-control::placeholder, .layout-dark .search-inhead .dz-quik-search .wp-block-categories-dropdown select::placeholder, .wp-block-categories-dropdown .layout-dark .search-inhead .dz-quik-search select::placeholder,
  .layout-dark .search-inhead .dz-quik-search .wp-block-archives-dropdown select::placeholder, .wp-block-archives-dropdown .layout-dark .search-inhead .dz-quik-search select::placeholder {
    color: #fff; }
  .layout-dark #loading-area.loading-page-4 .loading-inner .load-text .text-load {
    color: rgba(255, 255, 255, 0.15); }
  .layout-dark .table-striped > tbody > tr:nth-of-type(2n+1) > *,
  .layout-dark .comments-area .comment-list > .comment .comment-body p {
    color: rgba(255, 255, 255, 0.6); }
  .layout-dark .app-form-wraper .form-wizard > .nav li .nav-link:after {
    background-color: rgba(255, 255, 255, 0.1) !important; }
  .layout-dark .app-form-wraper .form-wizard > .nav li .nav-link.active .number,
  .layout-dark .app-form-wraper .form-wizard > .nav li .nav-link.done .number {
    background-color: var(--primary) !important; }
  .layout-dark .about-bx2 .dz-info .signature {
    filter: invert(1); }
  .layout-dark .accordion.dz-accordion2 .accordion-item,
  .layout-dark .dz-accordion2 .accordion-header .accordion-button.collapsed {
    border: 1px solid rgba(255, 255, 255, 0.2); }
  @media only screen and (max-width: 575px) {
    .layout-dark .search-inhead {
      background-color: transparent; } }
  .layout-dark .pagination li .prev:hover,
  .layout-dark .pagination li .next:hover {
    background-color: transparent; }
  .layout-dark .history-wrapper .history-wrapper-bx .year:after {
    border: 2px dashed rgba(255, 255, 255, 0.18); }
  .layout-dark .bg-particles .particles-js-canvas-el {
    opacity: 0.1; }
  .layout-dark .nav-tabs .nav-link.active {
    background-color: transparent; }
  .layout-dark .btn-quantity.style-1 .btn, .layout-dark .btn-quantity.style-1 .wp-block-button__link,
  .layout-dark .btn-quantity.style-1 input {
    color: #fff;
    background-color: var(--dark-card); }

.dz-mode {
  background: #071c35;
  transform: scale(1.2);
  width: 34px;
  height: 62px;
  position: fixed;
  top: 50%;
  left: 20px;
  z-index: 999;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 28px;
  padding: 3px;
  transition: all 0.5s;
  cursor: pointer; }
  .dz-mode:after {
    content: "";
    width: 28px;
    height: 28px;
    border-radius: 28px;
    position: absolute;
    left: 3px;
    top: 3px;
    background: #fff;
    z-index: -1;
    transition: all 0.5s; }
  .dz-mode i {
    z-index: 2;
    width: 28px;
    height: 28px;
    display: block;
    text-align: center;
    line-height: 28px;
    transition: all 0.5s; }
    .dz-mode i:first-child {
      color: #000; }

.layout-light .dz-mode .fa-sun {
  color: #000; }

.layout-dark .dz-mode:after {
  top: 31px; }

.layout-dark .dz-mode .fa-moon {
  color: #000; }

.layout-dark .dz-mode i:first-child {
  color: rgba(255, 255, 255, 0.5); }

/*=== Switcher ===*/
.styleswitcher {
  left: -500px;
  padding: 15px;
  position: fixed;
  top: 50px;
  width: 500px;
  z-index: 99999;
  direction: ltr;
  height: calc(100% - 100px);
  transition: all .5s ease-in-out; }
  .styleswitcher .overlay-switch {
    border-radius: 10px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    left: 0;
    top: 0;
    opacity: 0.04;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    transition: all 0.5s ease 0s; }
  .styleswitcher.open {
    left: 30px; }
    .styleswitcher.open .overlay-switch {
      position: fixed; }
  .styleswitcher .styleswitcher-inner {
    background-color: #ffffff;
    height: 100%;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 30px;
    border-radius: 10px; }
    .styleswitcher .styleswitcher-inner::-webkit-scrollbar {
      width: 5px; }
    .styleswitcher .styleswitcher-inner::-webkit-scrollbar-thumb {
      background: #a0a0a0; }
  .styleswitcher.open .styleswitcher-inner {
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.2); }
  .styleswitcher .switcher-btn-bx {
    box-shadow: 7px 0 5px rgba(0, 0, 0, 0.1);
    position: absolute;
    right: -44px;
    top: 200px;
    z-index: 1; }
  .styleswitcher .sw-main-title {
    font-size: 20px;
    font-weight: 500;
    padding: 20px 20px;
    color: #000;
    display: flex;
    border-bottom: 1px solid #eee;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
    margin-bottom: 30px;
    background: #f9f9fd;
    align-items: center;
    justify-content: space-between; }
  .styleswitcher .switcher-title {
    font-weight: 600;
    margin: 0 0 10px 0;
    font-size: 12px;
    text-transform: uppercase;
    color: #000;
    letter-spacing: 1px; }
  .styleswitcher .site-button {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px; }
  .styleswitcher h6.switcher-title, .styleswitcher .switcher-title.h6 {
    color: #717171; }
  .styleswitcher .btn.btn-sm, .styleswitcher .btn-group-sm > .btn, .styleswitcher .btn-group-sm > .wp-block-button__link, .styleswitcher .btn-sm.wp-block-button__link {
    font-size: 12px;
    padding: 8px 15px; }

.theme-box {
  margin-bottom: 30px; }

.theme-design {
  display: flex;
  margin-left: -7px;
  margin-right: -7px;
  padding: 12px 10px;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-bottom: 20px;
  background: #f9f9fd; }
  .theme-design .theme-box {
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: 0; }

/* styleswitcher-right End */
.switch-btn {
  background: var(--primary);
  box-shadow: 2px 1px 60px rgba(0, 0, 0, 0.1);
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 21px;
  height: 45px;
  line-height: 43px;
  text-align: center;
  width: 45px;
  border-radius: 0 4px 4px 0;
  border-left: 0; }
  .switch-btn span {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear; }
  .switch-btn:hover, .switch-btn:active, .switch-btn:focus {
    color: #fff; }

.tab-checkbx {
  background: #dfe0ed;
  padding: 4px;
  border-radius: 6px;
  display: flex;
  align-items: center; }
  .tab-checkbx li {
    width: 50%;
    flex: 0 0 50%; }
    .tab-checkbx li a {
      text-align: center;
      color: #575971;
      display: block;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
      font-size: 12px;
      padding: 5px 10px;
      border-radius: 4px; }
    .tab-checkbx li.active a {
      background: #fff;
      color: #000;
      box-shadow: 2px 2px 5px 0 rgba(4, 0, 51, 0.2); }

.layout-view {
  display: flex; }
  .layout-view li {
    padding: 0 5px;
    width: 33.33%;
    flex: 0 0 33.33%;
    text-align: center; }
    .layout-view li a div {
      margin-left: auto;
      margin-right: auto;
      width: 100px;
      height: 75px;
      border: 3px solid;
      color: #7b7c87;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .layout-view li a div span {
        display: block;
        border: 2px solid;
        border-radius: 3px; }
    .layout-view li a > span {
      color: #575971;
      font-size: 12px;
      font-weight: 500; }
    .layout-view li.frame a div {
      padding: 5px; }
      .layout-view li.frame a div span {
        width: 100%;
        height: 100%; }
    .layout-view li.boxed a div span {
      width: 80%;
      height: 100%;
      border-width: 0px 2px 0 2px;
      border-radius: 0; }
    .layout-view li.active {
      color: var(--primary); }
      .layout-view li.active a div {
        box-shadow: 0 10px 20px 0 var(--rgba-primary-1);
        color: var(--primary);
        background: var(--rgba-primary-1); }
      .layout-view li.active a > span {
        color: var(--primary); }

.bg-color-switcher,
.color-skins {
  display: flex;
  justify-content: space-between; }
  .bg-color-switcher li,
  .color-skins li {
    cursor: pointer;
    display: inline-block;
    list-style: outside none none;
    margin: 4px 4px 4px 0; }
    .bg-color-switcher li a,
    .color-skins li a {
      background-size: 100%;
      display: block;
      height: 35px;
      width: 35px;
      height: 36px;
      width: 36px;
      border-radius: 6px;
      position: relative; }
    .bg-color-switcher li.active a:after,
    .color-skins li.active a:after {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px; }
    .bg-color-switcher li .bg-color-1,
    .bg-color-switcher li .skin-1,
    .color-skins li .bg-color-1,
    .color-skins li .skin-1 {
      background-color: #fe4c1c; }
    .bg-color-switcher li .bg-color-2,
    .bg-color-switcher li .skin-2,
    .color-skins li .bg-color-2,
    .color-skins li .skin-2 {
      background-color: #2eb999; }
    .bg-color-switcher li .bg-color-3,
    .bg-color-switcher li .skin-3,
    .color-skins li .bg-color-3,
    .color-skins li .skin-3 {
      background-color: #26AA5D; }
    .bg-color-switcher li .bg-color-4,
    .bg-color-switcher li .skin-4,
    .color-skins li .bg-color-4,
    .color-skins li .skin-4 {
      background-color: #e5588d; }
    .bg-color-switcher li .bg-color-5,
    .bg-color-switcher li .skin-5,
    .color-skins li .bg-color-5,
    .color-skins li .skin-5 {
      background-color: #FB751B; }
    .bg-color-switcher li .bg-color-6,
    .bg-color-switcher li .skin-6,
    .color-skins li .bg-color-6,
    .color-skins li .skin-6 {
      background-color: #e4484e; }
    .bg-color-switcher li .bg-color-7,
    .bg-color-switcher li .skin-7,
    .color-skins li .bg-color-7,
    .color-skins li .skin-7 {
      background-color: #977fff; }
    .bg-color-switcher li .bg-color-8,
    .bg-color-switcher li .skin-8,
    .color-skins li .bg-color-8,
    .color-skins li .skin-8 {
      background-color: #eb8153; }
    .bg-color-switcher li .bg-color-9,
    .bg-color-switcher li .skin-9,
    .color-skins li .bg-color-9,
    .color-skins li .skin-9 {
      background-color: #ff8f16; }

.background-switcher {
  display: flex;
  justify-content: space-between; }
  .background-switcher li {
    cursor: pointer;
    display: inline-block;
    height: 36px;
    margin: 4px 4px 4px 0;
    width: 36px;
    position: relative;
    overflow: hidden;
    border-radius: 6px; }
    .background-switcher li.active {
      box-shadow: 0 0 0px 3px #fff, 0 0 0px 6px var(--rgba-primary-5); }
      .background-switcher li.active:after {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
        top: 0; }

.pattern-switcher {
  display: flex;
  justify-content: space-between; }
  .pattern-switcher li {
    cursor: pointer;
    display: inline-block;
    height: 36px;
    margin: 4px 4px 4px 0;
    width: 36px;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #b3b3b3; }
    .pattern-switcher li.active {
      box-shadow: 0 0 0px 3px #fff, 0 0 0px 6px var(--rgba-primary-5); }
      .pattern-switcher li.active:after {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary);
        font-size: 16px;
        top: 0; }

/* background color switcher */
/* Range */
.theme-box .range-slider {
  margin-top: 15px; }

.theme-box .price .amount {
  font-weight: 500;
  border: 0;
  font-size: 14px;
  margin-bottom: 12px; }

#ThemeRangeSlider .ui-widget-content {
  background: var(--rgba-primary-3); }

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 2px solid var(--primary) !important; }

.theme-view-wrapper .theme-box:not(:last-child) {
  margin-bottom: 15px; }

@media only screen and (max-width: 1199px) {
  .theme-design.theme-layout-wrapper,
  .theme-design.theme-background-wrapper {
    display: none; }
  .styleswitcher {
    left: -300px !important;
    width: 300px;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    .styleswitcher.open {
      left: 30px !important; }
    .styleswitcher .sw-main-title {
      display: block;
      margin: -30px -30px 20px; }
      .styleswitcher .sw-main-title .btn, .styleswitcher .sw-main-title .wp-block-button__link {
        display: block;
        width: 100%;
        margin-top: 5px; }
    .styleswitcher .color-skins {
      justify-content: start;
      flex-wrap: wrap; } }

@media only screen and (max-width: 575px) {
  .styleswitcher {
    width: 250px;
    left: -250px !important;
    top: 0;
    height: 100%; }
    .styleswitcher.open {
      left: 0 !important; }
    .styleswitcher .styleswitcher-inner {
      border-radius: 0;
      padding: 25px; }
    .styleswitcher .sw-main-title {
      margin: -30px -25px 20px; }
    .styleswitcher .theme-design {
      padding: 10px 5px; }
    .styleswitcher .color-skins li {
      margin: 4px 5px 2px 0px; }
      .styleswitcher .color-skins li a {
        height: 30px;
        width: 30px; }
      .styleswitcher .color-skins li.active a::after {
        font-size: 12px; }
  .switcher-btn-bx {
    right: -35px !important; }
    .switcher-btn-bx .switch-btn {
      font-size: 16px;
      height: 35px;
      line-height: 35px;
      width: 35px; } }

