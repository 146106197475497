// Site Footer
.site-footer{
	&.style-2{
		.footer-top{
			background-color:#0f0f0f;
		    background-size: cover;
			background-position: center;
			padding-bottom:0;
			margin-bottom:-45px;
			padding-top:80px;
			@include respond ('phone-land'){
				padding-top:50px;
			}
		}
		.footer-bottom{
			background-color:#000000;
			padding-top: 70px;
			padding-bottom: 30px;
		}
		.footer-info{
			background:var(--primary);
			position:relative;
			.info-right{
				display:flex;
				flex-wrap:wrap;
				justify-content:space-between;
				height: 100%;
				align-items: center;
				padding-right:30px;
				@include custommq ($max:1200px){
					padding-bottom:0px;
					.btn{
						display:none;
					}
				}
				@include respond ('tab-port'){
					padding-left:30px;
				}
				@include respond ('phone'){
					display:block;
					padding-left:15px;
					padding-right:15px;
				}
			}
			.dz-media{
				height:100%;
				img{
					height:100%;
					object-fit:cover;
					width: 100%;
				}
				.popup-youtube{
					position:absolute;
					top:50%;
					left:50%;
					height:50px;
					width:50px;
					line-height:50px;
					color:#fff;
					border-width:3px;
					font-size:18px;
					transform:translate(-50%,-50%);
				}
			}
			.service-info{
				display:flex;
				p{
					margin-bottom: 0;
					color: #fff;
				}
				li{
					padding: 17px 20px;
					border-right:2px solid #f14306;
					&:last-child{
						border-right:0;
						padding-right:0;
					}
					&:first-child{
						padding-left:0;
					}
				}
				@include respond ('phone'){
					display:block;
					li{
						border:0;
						border-bottom:2px solid #f14306;
						padding:15px 0;
					}
				}
			}
			.title{
				color:#fff;
				margin-bottom:0;
				font-weight: 500;
			}
		}
		.dzSubscribe{
			background:#2c2c2c;
			padding:30px;
			span{
				font-size: 11px;
			}
			.ft-row{
				position:relative;
				.form-control{
					border-radius:0;
					height:50px;
					padding-right:50px;
				}
				.btn{
					padding:0;
					height:50px;
					width:50px;
					position:absolute;
					top:0;
					right:0;
					justify-content:center;
					color:var(--primary);
					&:hover{
						color:#fff;
						background:var(--primary);
					}
				}
			}
		}
	}
}