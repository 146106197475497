// dz-bnr-inr
.dz-bnr-inr{
	&.style-5{
		text-align:center;
		p{
			margin-left:auto;
			margin-right:auto;
			max-width: 560px;
			color: #fff;
		}
		&:after{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			clip-path:unset;
			background-image:url('../images/pattern2.png');
			width: 100%;
			height: 100%;
			background-size: cover;
			opacity: 1;
			background-color: transparent;

		}
		
		.breadcrumb-row{
			ul {
				background:var(--primary);
				padding: 0;
				display:inline-block;
				padding: 15px 30px;
			}
		}
		@include respond ('tab-port'){
			height:400px;
		}
		@include respond ('phone'){
			height:350px;
		}
	}
}