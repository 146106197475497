.about-style9{
	.dz-media{
		img{
			border-radius:var(--border-radius-base);
		}
	}
}
.company-info{
	display:flex;
	align-items:center;
	margin-bottom: 10px;
	.users,
	.brand{
		font-size:40px;
		margin-bottom:0;
		margin-right:20px;
	}
	.title{
		margin-bottom:3px;
		line-height:1;
	}
	span{
		font-size:13px;
		display: inline-block;
	}
}