.site-header.header-sidenav-1{
	&.header-transparent{
		.main-bar{
			background:transparent;
		}
		&+.page-content .dz-bnr-inr .dz-bnr-inr-entry{
			padding-top:100px;
		}
		.nav > li > a{
			color:var(--secondary);
		}
	}
	.main-bar{
		background:var(--secondary);
	}
	.is-fixed{
		.header-content-bx{
			height:auto;
		}
		.main-bar{
			background:var(--secondary);
		}
	}
	.header-content-bx{
		& > .logo-header.logo-dark{
			display: none;
		}
	}
	.main-bar.show{
		.header-content-bx{
			& > .logo-header{
				&.logo-dark{
					display: block;
				}
				&.logo-white{
					display: none;
				}
			}
		}
	}
	.header-nav {
		.nav{
			&>li {
				&>a {
				
				}
				.sub-menu li > .sub-menu{
					left: auto;
					margin: 0;
					padding: 0;
					top: auto;
				}
				.sub-menu,.mega-menu{
					@include respond ('tab-port'){
						display:block;
						border-top: 0;
						&.sub-menu-open{
							border-top: 1px solid #eee;
						}
					}
				}
			}
			.sub-menu {
				left: auto;
			    top: auto;
				height:0;
				padding:0;
				overflow:hidden;
				transition: height .5s ease!important;
				&.sub-menu-open{
					padding:10px 0;
					height:auto;
				}
			}
			.sub-menu-down > a{
				@include custommq ($min:991px){
					&:after{
						content:"\f078";
						vertical-align: middle;
						font-weight: 900;
						font-family: "Font Awesome 5 Free";
						font-size: 16px;
						position: absolute;
						right: 20px;
						margin-left: 10px;
						top: 50%;
						transform: translateY(-50%);
						transition: all 0.5s;
					}
					&.dz-open:after{
						transform: translateY(-50%) rotate(-90deg);
					}
				}
				
			}
			.sub-menu-down .sub-menu-down > a{
				@include custommq ($max:767px){
					&:before{
						content:"\f078";
						vertical-align: middle;
						font-weight: 900;
						font-family: "Font Awesome 5 Free";
						background-color: var(--rgba-primary-1);
						color: var(--primary);
						height: 30px;
						line-height: 30px;
						right: 0px;
						text-align: center;
						width: 30px;
						z-index: 3;
						float: right;
						font-size: 14px;
						margin: 0px;
					}
					&.dz-open:before{
						transform:rotate(-90deg);
					}
				}
			}
		}
	}
	.nav-elements span{
		font-size:18px;	
		margin-right:10px;	
	}
	
	
	.header-content-bx{
		display: flex;
		height: 140px;
		align-items: center;
		@include transitionMedium;
		
		@include custommq ($max:1024px){
			justify-content:space-between;
		}
		@include respond ('laptop'){
			height: 100px;
		}
		@include respond ('phone'){
			height: 80px;
		}
	}
	.logo-header{
		@include respond ('phone'){
			img{
				max-width:110px;
			}
		}
	}
	
	.container-fluid{
		padding-left:75px;
		padding-right:75px;
		@include respond ('laptop'){
			padding-left:40px;
			padding-right:40px;
		}
		@include respond ('phone'){
			padding-left:15px;
			padding-right:15px;
		}
	}
	.extra-nav{
		padding-left:80px;
		@include custommq ($max:1200px){
			padding-left:15px;			
		}
	}
	.menu-btn{
		height:55px;
		width:55px;
		background:var(--primary);
		display:inline-block;
		position:relative;
		vertical-align: middle;
		@include respond ('tab-land'){
			height:50px;
			width:50px;
		}
		span{
			background: #fff;
			right: 10px;
			display: block;
			position: absolute;
			height: 2px;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .25s ease-in-out;
			-moz-transition: .25s ease-in-out;
			-o-transition: .25s ease-in-out;
			transition: .25s ease-in-out;
			&:nth-child(1) {
				top: 15px;
				width: 35px;
			}
			&:nth-child(2) {
				top: 50%;
				margin-top:-1px;
				width: 30px;
			}
			&:nth-child(3) {
				bottom: 15px;
				width: 25px;
			}
			@include respond ('tab-land'){
				right: 8px;
			}
		}
		
		&.open{
			span{
				&:nth-child(1) {
					top: 50%;
					transform: rotate(45deg);
				}
				&:nth-child(2) {
					right:40px;
					opacity:0;
				}
				&:nth-child(3) {
					top: 50%;
					bottom: auto;
					width: 35px;
					transform: rotate(-45deg);
				}
			}
		}
		@include respond ('phone'){
			height: 50px;
			width: 50px;
			span{
				height:2px;
				right:10px;
				&:nth-child(1) {
					top: 15px;
				}
				&:nth-child(2) {
					top: 25.5px;
				}
				&:nth-child(3) {
					top: 35px;
				}
			}
			&.open{
				span{
					&:nth-child(1) {
						top: 25px;
					}
					&:nth-child(3) {
						top: 25px;
					}
				}
			}
		}
	}
}

.service-list{
	margin-left: auto;
	ul{
		display: flex;
		flex-flow:wrap;
		li {
			display: inline-block;
			position: relative;
			padding: 0 0 0 70px;
			margin-left: 30px;
			.title{
				color:#fff;
				font-size:18px;
			}
			span{
				color:var(--primary);
			}
			i{
				height:55px;
				width:55px;
				border-radius:55px;
				line-height:50px;
				text-align:center;
				color:var(--primary);
				border:2px solid var(--primary);
				position:absolute;
				left:0;
				top:0;
				font-size:26px;
				&:after{
					content: "";
					position: absolute;
					height: calc(100% + 2px);
					width: calc(100% + 2px);
					background: var(--primary);
					border-radius: inherit;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) scale(0);
					z-index: -1;
					-webkit-transition: all 0.5s;
					-ms-transition: all 0.5s;
					transition: all 0.5s;
				}
			}
			&:hover{
				i{
					color: #fff;
					&:after{
						transform: translate(-50%, -50%) scale(1);
					}
				}
			}
		}
	}
	@include custommq ($max:1200px){
		ul{
			li{
				margin-left: 20px;
				padding: 0px 0px 0px 52px;
				.title{
					font-size:17px;
				}
				i{
					height:45px;
					width:45px;
					border-radius:55px;
					line-height:40px;
				}
			}
		}
	}
	@include custommq ($max:1024px){
		display:none;
	}
}

.full-sidenav{
	position: fixed;
	height: 100vh;
	width: 100%;
	left: 0;
	top: 0;
	padding-top: 150px;
	padding-bottom: 50px;
	display: flex!important;
	opacity:0;
	visibility:hidden;
	background:rgba(0,0,0,.7);
	@include transitionMedium;
	&.show{
		visibility:visible;
		opacity:1;
		align-items:center;
		
		.flsbg {
			.bg-wrap {
				transform: translateY(0);
			}
		}
		
	}
	.container{
		height: 100%;
		overflow-y: auto;
		&::-webkit-scrollbar-track {
			background: #e9e9e9;
			border-radius: 8px;
		}
		&::-webkit-scrollbar {
			width: 8px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #a8a8a8;
			border-radius: 8px;
		}
	}
	.row{
		width:100%;
		height: 100%;
	}
	.social-menu{
		li{
			display:inline-block;
			margin-right: 10px;
			a{
				height:40px;
				width:40px;
				text-align:center;
				line-height:40px;
				border-radius:40px;
				background:var(--rgba-primary-1);
				display: block;
				&:hover{
					background:var(--primary);
					color:#fff;
				}
			}
		}
	}
	.service-list{
		display:block;
		ul{
			display:block;
			li{
				display:block;
				margin-bottom:40px;
				margin-left:0;
				.title{
					color:var(--title);
				}
			}
		}
	}
	&.header-nav .nav > li .mega-menu, &.header-nav .nav > li .sub-menu{
		opacity:1;
	}
	@include respond ('tab-port-min'){
		.navbar-nav{
			display:block;
			float:unset;
			li{
				a{
					display:block;
					padding: 18px 10px;
					font-size: 34px;
				}
			}
			&>li{
				.mega-menu{
					&>li{
						&>a{
							color:var(--secondary)!important;
						}
					}
				}
				.sub-menu,
				.mega-menu{
					position:relative;
					visibility:visible;
					width:100%;
					margin:0;
					opacity:1;
					box-shadow:none;
					transition:none!important;
					display:block;
					padding: 6px 0;
					&>li{
						width:100%;
						padding:0;
						ul{
							margin: 10px 0px 20px;
						}
					}
					li{
						a{
							font-size:22px;
							padding:8px 20px 8px 20px;
							color: #7e7e7e;
							&:after{
								top:22px;
							}
						}
						&:hover{
							a{
								padding: 8px 20px 8px 20px;
							}
						}
					}
				}
			}
		}
		
		.header-style-bx{
			display:block;
			.header-style-tabs{
				width:100%;
				padding: 10px 10px;
				ul{
					display:flex;
					li{
						width:50%;
						a{
							text-align:center;
						}
					}
				}
			}
			
		}
		.header-tabs-wraper{
			.header-style-content{
				.menu-list{
					display:block;
					&>li{
						.title{
							font-size:24px;
							padding: 10px 20px;
						}
						i{
							margin-right:10px;
						}
					}
				}
			}
		} 
	}
	
	@include respond ('tab-port'){
		width: 300px;
		left:-300px;
		padding-top:0;
		padding-bottom:30px;
		background:#fff;
		display:block!important;
		@include transitionSlow;
		&.show{
			left:0;
		}
		.service-list{
			display:none;
		}
		.navbar-nav li a{
			padding:10px 0;
			font-size:20px;
		}
		.row{
			margin:0;
		}
		.col-lg-6{
			padding:0;
		}
		.social-menu{
			margin-top:25px;
			text-align: center;
		}
		.container{
			height:auto;
			overflow-y: hidden;
		}
		.nav{
			padding:0;
		}
		.flsbg {
			display: none;
		}
		.navbar-nav{
			&>li{
				&>a.dz-open{
					&:after{
						content:"\f054" !important;
						background-color: var(--primary)!important;
					}
				}
				&>a{
					&:after{
						content:"\f078" !important;
						background-color: #333!important;
					}
				}
				.sub-menu .menu-item-has-children > a.dz-open:before{
					content:"\f078";
				}
			}
		}
	}
}


.flsbg {
	position: absolute;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
	z-index: -2;
	display: flex;
	.bg-wrap {
		display: block;
		overflow: hidden;
		width: 33.333333%;
		-webkit-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		transform: translateY(-100%);
		position: relative;
		background-color: transparent;
		pointer-events: none;
		border: 1px solid #fff;
		margin: -1px;
		box-sizing: content-box;
		@for $i from 1 to 4 {
			&:nth-child(#{$i}) {
				transition: $i * 0.2s;
			}
		} 
		span {
			position: absolute;
			height: 100%;
			left: 0;
			top: 0;
			width: 100%;
			z-index: -2;
			background: #fff;
		}
	}
}