.about-style10{
	.list-check-circle{
		background:#f4f4f4;
		height:100%;
		padding:50px 20px 50px 20px;
		li{
			padding:8px 5px 8px 30px;
			&:before{
				top:8px;
			}
		}
	}
}