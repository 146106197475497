.about-style11{
	.dz-media{
		border-radius:var(--border-radius-base);
		margin-top:30px;
		margin-left:30px;
		overflow:visible;
		&:before{
			border-radius:inherit;
		}
		img{
			border-radius:var(--border-radius-base);
		}
		.video-btn{
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			.popup-youtube{
				margin:auto;
				margin-bottom:10px;
			}
			p{
				margin-bottom:0;
				color:#fff;
				text-transform:uppercase;
				@include respond ('phone'){
					font-size:14px;
				}
			}
		}
		.exp-box{
			border-radius:var(--border-radius-base);
			background:var(--primary);
			position:absolute;
			top:-30px;
			left:-30px;
			width:150px;
			padding:30px 20px 30px 20px;
			-webkit-animation: movedelement 7s linear infinite;
			animation: movedelement 7s linear infinite;
			.year{
				color:#fff;
				font-size:55px;
				line-height:1;
			}
			span{
				color:#fff;
				font-size:25px;
				font-family:var(--font-family-title);
				line-height: 1.3;
				display: inline-block;
				font-weight:600;
			}
			@include respond ('phone'){
				padding:15px 12px;
				width:100px;
				.year{
					font-size: 35px;
				}
				span{
					font-size: 16px;
				}
			}
		}
	}
}

.check-box{
	border-radius: var(--border-radius-base);
	background-color: rgb(255, 255, 255);
	position:relative;
	padding:30px 30px 30px 95px;
	@include transitionSlow;
	&:before{
		content: "\f058";
		font-family: "Font Awesome 5 Free";
		font-size: 45px;
		font-weight: 500;
		top: 50%;
		position:absolute;
		left:30px;
		color:var(--primary);
		transform:translateY(-50%);
	}
	p{
		margin-bottom:0;
	}
	&:hover,
	&.active{
		background:var(--secondary);
		color:#fff;
		.title{
			color:#fff;
		}
	}
}