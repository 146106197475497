.site-footer{
	img{
		border-radius:var(--border-radius-base);
	}
	&.style-5{
		.footer-top{
			background:var(--secondary);
		}
		.footer-bottom{
			background:#0f1724;
		}
		.widget_services ul{
			margin-top:-.5rem;
		}
		.footer-title{
			position:relative;
			padding-bottom:0;
			margin-bottom: 30px;
		}
		.recent-posts-entry .widget-post-bx .widget-post .title a{
			color:rgba(255,255,255,1);
		}
		.footer-link li{
			color:rgba(255,255,255,.8);
		}
		.footer-bottom{
			color:rgba(255,255,255,.8);
			text-align:center;
			a{
				color:var(--primary);
			}
		}
		.ft-subscribe{
			margin-bottom: 30px;
			border:1px solid rgba(255,255,255,0.1);
			padding: 30px 40px;
			.ft-row{
				display: flex;
				.form-control{
					flex: 1;
					margin-right: 15px;
					border-radius: 0!important;
				}
			}
			@include respond ('phone'){
				padding: 30px 30px;
				.ft-row{
					display:block;
					text-align: center;
					.form-control{
						margin-bottom:20px;
					}
				}
				.footer-title{
					margin-bottom: 20px;
				}
			}
		}
		.dz-social-icon ul li a{
		    &:hover{
				background-color: #fff;
				color: #192436!important;
				border-color: #fff;
			}
		}
	}
}