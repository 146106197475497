[dir='rtl']{
    
    // PLACE ICON WITH BOX
    .icon-bx-wraper {
        &.left{
            .icon-bx-xl,
            .icon-bx-lg,
            .icon-bx-md,
            .icon-bx-sm,
            .icon-bx-xs{
                float: right;
                margin-left: 20px;
                margin-right: 0;
            }
            .icon-xl,
            .icon-lg,
            .icon-md,
            .icon-sm,
            .icon-xs{
                float: right;
                margin-right: 0;
                margin-left: 20px;
            }
        }
        &.right{
            text-align: left;
            .icon-bx-xl,
            .icon-bx-lg,
            .icon-bx-md,
            .icon-bx-sm,
            .icon-bx-xs{
                float: left;
                margin-left: 0;
                margin-right: 20px;
            }
            .icon-xl,
            .icon-lg,
            .icon-md,
            .icon-sm,
            .icon-xs{
                float: left;
                margin-left: 0;
                margin-right: 10px;
            }
        }
        &.bx-style-2 {
            &.center{
                [class*="icon-bx-"] {
                    left: auto;
                    right: 50%;
                }
                .icon-bx-xl {
                    margin-left: 0;
                    margin-right: -75px;
                }
                .icon-bx-lg {
                    margin-left: 0;
                    margin-right: -60px;
                }
                .icon-bx-md {
                    margin-left: 0;
                    margin-right: -50px;
                }
                .icon-bx-sm {
                    margin-left: 0;
                    margin-right: -40px;
                }
                .icon-bx-xs {
                    margin-left: 0;
                    margin-right: -20px;
                }
            }
            &.left{
                [class*="icon-bx-"] {
                    left: auto;
                    right: 0;
                }
            }
            &.right{ 
                [class*="icon-bx-"] {
                    right: auto;
                    left: 0;
                }
            }
        }
        
    }
   
    // BEFORE & AFTER FOR LINE CSS
    .right-border:before {
        right: auto;
        left: 0;
    }
    
    //Icon Box-1     
        
    .icon-bx-wraper{
        // Style 2
        &.style-2{
            padding:35px 70px 35px 30px;
            margin-left:0;
            margin-right:30px;
            .icon-bx-sm{
                left:auto;
                right:-30px;
            }
            &:before{
                left:auto;
                right:50%;
                transform: translateX(100%) rotate(180deg);
            }
            &:after{
                right:auto;
                left:0;
            }
            &:hover{
                &:before{
                    left:auto;
                    right:0;
                }
            }
            @include respond ('phone'){
                padding:20px 60px 20px 20px;
            }
        }
        
        // Style 4
        &.style-4{
            [class*="icon-bx-"] {
                margin: 12px 12px 12px 32px;
            }
        }
        
        // Style 5
        &.style-5{
            [class*="icon-bx-"] {
                &:after{
                    right:auto;
                    left:-20px;
                }
            }
            .icon-bx-sm{
                &:after{
                    right:auto;
                    left:-10px;
                }
            }
        }
        
        
        // Style 7
        &.style-7{
            &:before{
                left:auto;
                right:0;
            }
            &:hover{
                &:before{
                    left:auto;
                    right:10px;
                }
            }
        }
        
        // Style 8
        &.style-8{
            [class*="icon-bx-"] {
                margin-right: 0;
                margin-left: 15px;
            }
        }
        
        // Style 9
        &.style-9{
            display:flex;
            align-items:center;
            [class*="icon-bx-"] {
                margin-right:0;
                margin-left:20px;
            }
        }
        
        
    }

    .flip-bx{
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-perspective: 1000px;
        perspective: 1000px;
        .inner{
            -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
            transform: translateY(-50%) translateZ(60px) scale(0.94);
            left: auto;
            right: 0;
        }
        .back{
            left: auto;
            right: 0;
        }
        .front{
            &:after{
                left: auto;
                right: 0;
            }
        }
    }
}