// Site Footer Style 1
.site-footer{
	&.style-1{
		background-color: #192436;
		
		.footer-top{
			padding: 80px 0px 40px;
		}
		.footer-bottom{
			background-color: transparent;
			padding: 0;
			.ft-inner{
				border-top: 2px solid rgba(255,255,255,0.1);
				padding: 25px 0;
			}
		}
		.recent-posts-entry .widget-post-bx .widget-post{
			ul{
				margin-bottom: 10px;
			}
		}
		.dz-social-icon ul li a {
			color: #fff!important;
			
			&:hover{
				background-color: #fff;
				color: #192436!important;
				border-color: #fff;
			}
		}
		
		.widget_services > ul{
			margin-top:-.5rem;
		}
		.widget_about{
			padding-right: 10px;
		}
	}
}

.call-box2{
	color:var(--primary);
	position:relative;
	font-size:20px;
	padding:7px 10px 7px 55px;
	font-family:var(--font-family-title);
	font-weight:500;
	span{	
		position:absolute;
		top:0;
		left:0;
		height:45px;
		width:45px;
		border-radius:45px;
		line-height:45px;
		text-align:center;
		color:var(--primary);
		background:#fff;
		font-weight:800;
		&:before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			border: 1px solid #fff;
			-webkit-border-radius: 50%;
			-khtml-border-radius: 50%;
			-moz-border-radius: 50%;
			-ms-border-radius: 50%;
			-o-border-radius: 50%;
			border-radius: 100%;
			animation: animationSignal1;
			animation-iteration-count: infinite;
			animation-duration: 3s;
			-webkit-animation: animationSignal1;
			-webkit-animation-iteration-count: infinite;
			-webkit-animation-duration: 3s;
			z-index: 1;
			transform:  scale(1);
			-moz-transform:  scale(1);
			-webkit-transform:  scale(1);
			-ms-transform:  scale(1);
			-o-transform:  scale(1);
			-moz-transition:all 0.5;
			-o-transition:all 0.5;
			-webkit-transition:all 0.5;
			-ms-transition:all 0.5;
			transition:all 0.5;
		}
		&:after{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			border: 1px solid #fff;
			-webkit-border-radius: 100%;
			-khtml-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			-o-border-radius: 100%;
			border-radius: 100%;
			animation: animationSignal2;
			animation-iteration-count: infinite;
			animation-duration: 3s;
			-webkit-animation: animationSignal2;
			-webkit-animation-iteration-count: infinite;
			-webkit-animation-duration: 3s;
			z-index: 1;
			transform:  scale(1);
			-moz-transform:  scale(1);
			-webkit-transform:  scale(1);
			-ms-transform:  scale(1);
			-o-transform:  scale(1);
			-moz-transition:all 0.5;
			-o-transition:all 0.5;
			-webkit-transition:all 0.5;
			-ms-transition:all 0.5;
			transition:all 0.5;
		}
	}
}