[dir='rtl']{
	.recent-posts-entry .widget-post-bx .widget-post .dz-info{
		padding-left: 0;
		padding-right: 14px;
	}
	.widget_getintuch{
		ul li {
			padding-left: 0;
			padding-right: 35px;
			i{
				right: 0;
				left: auto;
			}
			@include respond('tab-land'){	
				padding-left: 0;
				padding-right: 50px;
			}
		}
		&.style-1 ul li{
			padding-left: 0;
			padding-right: 45px;
		}
	}
	
	// Footer
	footer{
		.wp-block-latest-posts li, 
		.wp-block-categories-list li, 
		.wp-block-archives-list li, 
		.widget_categories ul li, 
		.widget_archive ul li, 
		.widget_meta ul li, 
		.widget_pages ul li, 
		.widget_recent_comments ul li, 
		.widget_nav_menu li, 
		.widget_recent_entries ul li, 
		.widget_services ul li{
			right: auto;
			left: 0;
		}
	}
	
	// Widget
	.widget-title{
		/* padding-left: 0;
		padding-right: 15px; */
		.dz-separator{
			left:auto;
			right:0;
		}
	}

	// search-bx
	.search-bx{
		.form-control{
			padding: 6px 30px 6px 62px;
		}
		.input-group-btn{
			left: 6px;
			right: auto;
		}
	}

	// DOWNLOAD-FILE
	.download-file {
		background-image: var(--gradient);
		color: $white;
		padding: 25px 20px 20px 25px;
		ul li a {
			text-align: right;
			i {
				&:after, 
				&:before {
					left: auto;
					right: 0;
				}
			}
		}
	}
	
	// Contact Box
	.widget_contact{
		&:after{
			left:auto;
			right:0;
		}
	}
	.list-2{
		li{
			float: right;
		}
	}

	// WP WIDGET START
	.wp-block-latest-posts,
	.wp-block-categories-list,
	.wp-block-archives-list,
	.widget_categories ul,
	.widget_archive ul,
	.widget_meta ul,
	.widget_pages ul,
	.widget_recent_comments ul,
	.widget_nav_menu ul,
	.widget_recent_entries ul,
	.widget_services ul{
		li{
			li{
				&:before {
					left: auto;
					right: 0;
				}
			}
		}
		ul{
			padding-left: 0;
			padding-right: 1.125rem;
			margin-left: 0;
			margin-right: -0.9375rem;
		}
	}
	.widget_archive ul li,
	.wp-block-categories-list li,
	.wp-block-archives-list li,
	.wp-block-latest-posts li,
	.widget_categories ul li {
		text-align: left;
		padding: 0.5rem 1.5rem 0.5rem 0rem !important;
	}
	.widget_archive li a,
	.wp-block-latest-posts li a,
	.wp-block-categories-list li a,
	.wp-block-archives-list li a,
	.widget_categories li a {
		float: right;
		text-align: right;	
	}

	// WIDGET RECENT COMMENT
	.widget_recent_comments{
		ul{
			li{
				padding-left: 0;
				padding-right: 1.875rem;
				&:before {
					left: auto;
					right: 0;
				}
			}
		}
	}

	// WIDGET GALLERY
	.widget_gallery{
		li {
			float:right;
			&:nth-child(3n-3) {
				margin-right: auto;
				margin-left: 0;
			}
		}
	}

	// recent-posts-entry
	.recent-posts-entry{
		.widget-post-bx{
			.widget-post{
				.dz-info{
					padding-left: 0;
					padding-right: 14px;
				}
			}
		}
	}
	
	// WIDGET_SERVICES
	.service_menu_nav{
		ul{
			li{
				a{
					&:before{
						right:auto;
						left:50px;
						transform: translate(-50%, -50%) rotateY(180deg);
					}
					&:after{
						left:auto;
						right:0;
					}
					
				}
				&:hover,
				&.current-menu-item,
				&.active{
					a{
						padding-left: 0;
						padding-right: 20px;
						&:before{
							right:auto;
							left:30px;
							@include respond('tab-land'){
								right:auto;
								left:5px;
							}
							@include respond ('phone'){
								left:30px;							
							}
						}
					}
				}
			}
		}
	}
	.post-tags{
		strong{
			margin-right:0;
			margin-left:10px;
		}	
		a{
			margin-left:10px;
			margin-right:0;
		}
	}
    .dz-share-post{
        .post-tags a{
            margin: 0 5px 10px;
        }
        .dz-social-icon ul li {
            margin: 0;
        }
    }
	.pagination{
		li{
			.prev,
			.next{
				transform: rotateY(180deg);
			}
		}
		&.style-1{
			li{
				.page-link{
					transform: rotateY(0);
					i{
						transform: rotateY(180deg);
					}
					&.prev{
						img{
							margin-right:0;
							margin-left:15px;
						}
						i{
							margin-right:0;
							margin-left:5px;
						}
					}
					&.next{
						img{
							margin-left:0;
							margin-right:15px;
						}
						i{
							margin-left:0;
							margin-right:5px;
						}
					}
				}
			}
		}
	}
	
	// WIDGET_CATEGORIES
	.widget_categories{
		.cat-item{
			&:before{
				content: "\f0d9";
				left: auto;
				right: 0;
			}
		}
	}
	
	.btn-social i {
		margin-right: 0;
		margin-left: 8px;
	}
}