[dir='rtl']{
	
    .swiper-container{
		direction: ltr;
		.swiper-slide{
			direction: rtl;
		}
	}
	.lg-outer{
		direction: ltr;
	}
	
	// ALERT
	.alert[class*=alert-]{
		i {
			margin-right: auto;
			margin-left: 8px;
		}
		ul {
			padding-left: 0;
			padding-right: 25px;
		}
	}
    .alert-dismissible .btn-close{
        left: 0;
        right: auto;
    }
	
	// DZ-DIVIDER
	.dz-divider {
		&.icon-left {
			margin-left: 0;
			margin-right: 40px;
			i {
				left: auto;
				right: -40px;
			}
		}
		&.icon-right {
			margin-left: 40px;
			margin-right: 0;
			i {
				left: -40px;
				right: auto;
			}
		}
	}
	
	// FILTER BUTTONS
	.site-filters {
		li {
			a {
				margin: 0 0 5px 5px;
			}
		}
	}
	
	.toggle-switch {
		&:before{
			left: auto;
			right: 3px;
		}
		.toggle-checkbox:checked+&{
			&:before{
				left: auto;
				right: 26px;
			}
		}
	}
	
	// Upload btn
	.file-upload-wrapper {
		&:after {
			left: auto;
			right: 0;
		}
		&:before{
			left: 0;
			right: auto;
			border-radius : 0 0 5px 5px;
		}
	}
	.ui-datepicker-header a {
		&.ui-datepicker-prev {
			left: auto;
			right: 0;
		}
		&.ui-datepicker-next {
			right: auto;
			left: 0;
		}
	}

	.form-select{
		.dropdown-menu {
			ul li a img{
				margin-right: 0;
				margin-left: 5px;
			}
		}
	}
	.form-select.btn-group{
		.dropdown-toggle{
			.caret{
				right: auto;
				left: 5px;
			}
		}
	}
	
	
	.dz-img-effect {
		&.side-pan:hover img {
			margin-left: auto;
			margin-right: -11%;
		}
	}
    
    
    
    
// index:- 1,
    
    
    // subscribe
    .dz-subscribe.style-1{
        &:after{
            left: auto;
            right: 0px;
        }
        .subscribe-inner{
            .title-head{
                i{
                    margin-right: 0;
                    margin-left: 20px;
                }
            }
            .form-control{
                padding: 10px 25px 10px 80px;
            }
            .input-group-addon{
                right: auto;
                left: 12px;
            }
        }
    }


    .call-box{
        padding:15px 130px 15px 30px;
        .icon{
            left:auto;
            right:0;
        }
        @include respond ('phone'){
            padding:15px 100px 15px 30px;
        }
    }

    .dz-section-3{
        .bg-img{
            left:auto;
            right:0;
        }
    }

    .pagination.style-1{
        li{
            &.prev{
                img{
                    margin-right:0;
                    margin-left:15px;
                }
                i{
                    margin-right:0;
                    margin-left:5px;
                }
            }
            &.next{
                img{
                    margin-left:0;
                    margin-right:15px;
                }
                i{
                    margin-left:0;
                    margin-right:5px;
                }
            }
        }
    }




    .showcase-bottom {
        ul{
            margin-left: 0;
            margin-right: auto;
        }
    }
    #home-slider {
        .swiper-pagination {
            left: 0;
            right: auto;
        }
    }
    

    .sort-title{
        &:after{
            left:auto;
            right:-50px;
        }
        &:before{
            right:auto;
            left:-50px;
        }
    }

    .popover{
        z-index: 999999;
    }
        
     

    .split-box{
        & > div {
            &:after,
            &:before{
                left: auto;            
                right: 0;            
            }
        }
    }


    .sechedule-btn{
        &:after{
            left:auto;
            right:0;
            clip-path: polygon(0 0, 100% 100%, 100% 0%);
        }
        &:before{
            right:auto;
            left:0;
            clip-path: polygon(100% 100%, 0 100%, 0% 0);
        }
    } 
    @include respond('phone'){
        #rc-imageselect, .g-recaptcha {
            transform-origin: top right;
            -moz-transform-origin: top right;
            -o-transform-origin: top right;
            -webkit-transform-origin: top right;
        }
    }
    
    // index:- 2,
    
    .service-wrapper{
        background-position: right;
        &:after{
            left:auto;
            right:0;
        }
    }
    .content-box{
        .dz-info{
            &:after{
                right: auto;
                left: 30px;
            }
        }
    }

    .service-wrapper-2{
        &:after{
            left: auto;
            right: 0;
        }
    }
    
    // index:- 3,
    
    .exp-row{
        .year{
            margin-right:0;
            margin-left:10px;
        }
    }

    .content-box2{
        .dz-bottom{
            .btn-link{
                padding-right: 0;
                padding-left: 20px;
                i{
                    margin-left:0;
                    margin-right:6px;
                }
            }
            &:after{
                left:auto;
                right:0;
            }
        }
    }

    .bg-about-slider{
        left:auto;
        right:0;
        .dz-media{
            &:after{
                left:auto;
                right:0;
                background: linear-gradient(to left, rgba(25, 36, 54, 0.8) 0%, rgba(25, 36, 54, 0.9) 5%, rgba(25, 36, 54, 0.92) 6%, #192436 30%, rgba(25, 36, 54, 0.92) 33%, rgba(25, 36, 54, 0.22) 59%, rgba(25, 36, 54, 0.87) 79%, #192436 83%);
            }
        }
    }

    .about-pagination{
        right:auto;
        left:100px;
        text-align:left;
        .swiper-pagination-bullet{
            span{
                margin-left:0;
                margin-right:10px;
            }
        }
        @include respond ('phone-land'){
            left:auto;
            right:calc(calc(100% - 540px) / 2 + 15px);
            .swiper-pagination-bullet{
                text-align:right;
                span{
                    margin-right:0;
                    margin-left:10px;
                }
            }
        }
        @include respond ('phone-land'){
            right:15px;
            width:calc(100% - 30px);
        }
    }
    .overlay-effect{
        &:before{
            left:auto;
            right:0;
        }
    }

    .contact-area2{
        .dz-media{
            padding-right:0;
            padding-left:15px;
            margin-right:0;
            margin-left:20px;
            .info{
                left:0;
                right:auto;
            }
            @include respond ('tab-port'){
                margin-left:0;
                margin-right:auto;
            }
        }
        &:after{
            left:auto;
            right:0;
        }
    }

    .call-box3{
        padding-left: 0;
        padding-right: 75px;
        i{
            left:auto;
            right:0;
        }
    }

    .overlay-gradient-sec{
        &:before{
            left:auto;
            right:0;
        }
    }

    .about-wrapper-2{
        float:left;
        .swiper-button{
            right: auto;
            left: 15px;
        }
    }

    .dzSubscribe.style-1{
        .ft-row{
            .form-control{
                padding-right:20px;
                padding-left:68px;
            }
            .btn{
                right:auto;
                left:4px;
            }
        }
    }
    
    // index:- 4,
    
    .image-slider-wrapper{
        .button-prev{
            left:auto;
            right:30px;
        }
        .button-next{
            right:auto;
            left:30px;
        }
    }

    .image-box{
        .info{
            left:auto;
            right:0;
            padding: 30px 10px 30px 60px;
            @include respond ('phone'){
                padding: 30px 30px 30px 60px;
            }
        }
        .dz-media{
            transform: skewX(10deg);
            img{                    
                transform: skewX(-10deg) scale(1.3);
            }
            &:before{
                left:auto;
                right:0;
            }
            &:after{
                left:auto;
                right:0;
            }
        }
    }

    .bg-half-img{
        left:auto;
        right:0;
    }
    ul.info-desc{
        li{
            span{
                margin-right:auto;
                margin-left:0;
            }
        }
    }
    
    // index:- 5,
    
    .image-box2{
        .info{
            left:auto;
            right: 0;
        }
    }

    .feature-wrapper{
        position:relative;
        &:before{
            left:auto;
            right:0;
        }
    }
    .scroltop{
        left: 15px;    
        right: auto;    
    }
    
    
    // swiper-portfolio
    /* .swiper-portfolio-1 {
        .swiper-slide{
            margin-top: 70px;
            margin-bottom: 70px;
            @include transitionMedium;
            &.swiper-slide-active{
                margin-top: 70px;
                margin-bottom: 70px;
                .dz-media img{	
                    height:590px;
                }
            }
            &.swiper-slide-duplicate-prev{
                margin-top:0;
                margin-bottom:0;
                .dz-media img{	
                    height:730px;
                }
            }
            @include respond ('laptop'){
                margin-top: 30px;
                margin-bottom: 30px;
                &.swiper-slide-active{
                    margin-top: 30px;
                    margin-bottom: 30px;
                    .dz-media img{	
                        height:450px;
                    }
                }
                &.swiper-slide-next{
                    .dz-media img{	
                        height:510px;
                    }
                }
            }
            @include respond ('phone'){
                &.swiper-slide-active{
                    .dz-media img{	
                        height:360px;
                    }
                }
                &.swiper-slide-duplicate-prev{
                    .dz-media img{	
                        height:360px;
                    }
                }
            }
        }
    } */
    
    .swiper-portfolio-1 {
        .swiper-slide{
            @include custommq ($min:1280px){
                margin-top: 70px;
                margin-bottom: 70px;                
                
                &.swiper-slide-active{
                    margin-top: 70px;
                    margin-bottom: 70px;
                    .dz-media img{	
                        height:590px;
                    }
                }
                &.swiper-slide-duplicate-prev{
                    margin-top:0;
                    margin-bottom:0;
                    .dz-media img{	
                        height:730px;
                    }
                }
            }
            @include respond ('laptop'){
                margin-top: 30px;
                margin-bottom: 30px;
                &.swiper-slide-active{
                    margin-top: 30px;
                    margin-bottom: 30px;
                    .dz-media img{	
                        height:450px;
                    }
                }
                &.swiper-slide-duplicate-prev{
                    margin-top: 0;
                    margin-bottom: 0;
                    .dz-media img{	
                        height:510px;
                    }
                }
            }
            @include respond ('phone'){
                &.swiper-slide{
                    margin: 0!important;
                    .dz-media img{	
                        height:360px!important;
                    }
                }
            }
        }
    }
    
    // Appointment
    
    .app-form-wraper{        
        .dz-info{
            li{
                margin-right: auto;
                margin-left: 20px;
                i{
                    margin-right: auto;
                    margin-left: 10px;
                }
            }
        }
        
        
        /* Form Wizard */
        .form-wizard{
            &>.nav{
                box-shadow: unset!important;
                li{
                    .nav-link{
                        &:after{
                            left: auto;
                            right: 50%;
                        }
                    }
                }
            }
        }
        
        
        .check-date{
            label{
                input{
                    display: none;
                }
                &.active{
                    &:after{
                        background: var(--primary);
                    }
                }
            }
            .form-check{
                padding-right: 0;
            }
        }
    }
    
    
    //Coming Soon
    
    .dez-coming-soon {
        .bg-img{
            left:auto;
            right:0;
        }
        
        .dzSubscribe{
            .ft-row{
                .btn{
                    right:auto;
                    left:5px;
                }
                .form-control{
                    padding:10px 30px 10px 70px;
                }
            }
        }
    } 
    .bottom-right {
        right: auto;
        left: 60px;
    }
    .bottom-left {
        left: auto;
        right: 60px;
    }
    .top-right {
        right: auto;
        left: 60px;
    }
    .top-left {
        left: auto;
        right: 60px;
    }


    /* ===============================
        Coming Soon 3
    ================================ */
    .style-3 .dez-social-icon li a{
        margin-right: 0;
        margin-left: 5px;
    }
    @media only screen and (max-width: 1400px) {
        .bottom-left {
            right: 40px;
            left: auto;
        }
        .bottom-right {
            left: 40px;
            right: auto;
        }
        .top-left {
            left: auto;
            right: 40px;
        }
        .top-right {
            right: auto;
            left: 40px;
        }
    }
    
    //Company History
    
    .history-wrapper{	
        .history-wrapper-bx{
            .year{
                left:auto;
                right:50%;
                transform:translateX(50%);
            }
            .history-box{
                &:nth-child(even){
                    padding-right:0;
                    padding-left:40px;
                    text-align:left;
                    &:before{
                        right: auto;
                        left: 25px;
                        transform: rotate(-135deg);
                    }
                    &:after{
                        right: auto;
                        left: -5px;
                    }
                }
                &:nth-child(odd){
                    padding-left:0;
                    padding-right:40px;
                    &:before{
                        transform: rotate(-135deg);
                        left: auto;
                        right: 25px;
                    }
                    &:after{
                        left: auto;
                        right: -5px;
                    }
                }
            }
        }
        &:after{
            left:auto;
            right:50%;
            transform:translateX(50%);
        }
        @include respond ('phone-land'){
            .history-wrapper-bx{
                padding-left: 0;
                padding-right: 35px;
                .history-box{
                    padding-left:0;
                    padding-right:40px;
                    &:nth-child(even){
                        padding-left:0;
                        text-align:right;
                        padding-right: 40px;
                            
                        &:before{
                            right: 25px;
                            left:auto;
                        }
                        &:after{
                            right: -5px;
                            left: auto;
                        }
                    }
                    &:nth-child(odd){
                        padding-left:0;
                        padding-right:40px;
                        &:before{
                            left: auto;
                            right: 25px;
                        }
                        &:after{
                            left: auto;
                            right: -5px;
                        }
                    }
                }
                .year{
                    left:auto;
                    right:0;
                    transform:translateX(0px);
                }
            }
            &:after{
                left:auto;
                right:35px;
                transform:translateX(0px);
            }
        }
        @include respond ('phone'){
            .history-wrapper-bx{
                padding-right:0;
                .year{
                    left:auto;
                    right:50%;
                    transform:translateX(50%);
                }
            }
            &:after{
                left:auto;
                right:0;
            }
        }
    }    
    
    
    //Preloader-rtl
    #loading-area {
        left: auto;
        right: 0;
        direction: ltr;

        &.loading-page-1{
            &:before,
            &:after{
                left: auto;		
                right: 0;		
            }
            .line{
                &:after{
                    left:auto;                    
                    right:0;  
                }
            }

        }
    }


    @keyframes dzLoad4 {
        0% {
            left: auto;
            right: 0;
        }
        100% {
            left: auto;
            right: 235px;
        }
    }
    @keyframes dzLoad1{
        0%{
            transform:rotate(0deg);
        }
        100%{
            transform:rotate(360deg);
        }
    }
    @keyframes dzLoad2{
        0%{
            transform:rotate(45deg);
        }
        100%{
            transform:rotate(405deg);
        }
    }

    @keyframes dzLoad3{
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }
    @keyframes rotation {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes letters-loading{
        0%,
        75%,
        100% {
            opacity: 0;
            transform: rotateY(-90deg);
        }
        25%,
        50% {
            opacity: 1;
            transform: rotateY(0deg);
        }
    }
    @keyframes rotate360{
        0%{
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100%{
            -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
        } 
    }
    
    
    //Close btn
    
    .modal-header .btn-close{
        margin: -0.5rem auto -0.5rem -0.5rem;
    }
    .inquiry-modal .btn-close{
        right: auto;
        left: -40px;
        margin: -0.5rem -0.5rem -0.5rem auto;
    }
    
    //Contact Us
    .contact-wraper1{
        background-position: 260px center;
        &:before{
            left: auto;
            right: 0;
            background: -moz-linear-gradient(right,  rgba(20,20,20,0) 0%, rgba(20,20,20,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(right,  rgba(20,20,20,0) 0%,rgba(20,20,20,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to left,  rgba(20,20,20,0) 0%,rgba(20,20,20,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
        &:after{
            right: auto;
            left: 0;
        }
    }
    
    //Under construct
    
    .under-construct{
        direction: ltr;
        padding-right: 0;
        .dz-content{
            &.top-left{
                top: 60px;
                left: 60px;
                right: auto;
            }
        }
        .bottom-left{
            bottom: 60px;
            left: 60px;
            right: auto;
        }
        .dz-social-icon{ 
            li {
                margin-right: 5px;
                a{
                    margin-right: 0px;
                }
            }
        }
        
        .mining-worker{
            right:0;
        }
        .train-run{
            right: 0;
            .main-train{
                left: 0;
            }
        }
    }
    
    .alert-dismissible{
        padding-left: 3rem;
        padding-right: 1rem;
    }
	
	.btn-bottom-left .swiper-button {
		display: flex;
		flex-direction: row-reverse;
	}
	.swiper-button-prev-portfolio,
	.swiper-button-next-portfolio{
		i:before{
			transform: rotate(180deg);
			display: block;
		}
	}
	.boxed .main-slider3 {
		padding: 0 0 35px 35px;
	}
}

