.dz-card{
	&.style-1{
	    overflow: hidden;
		border-radius: var(--border-radius-base);
		
		.dz-media{
			img{
				border-radius:var(--border-radius-base);
			}
		}
		.dz-info{
			padding: 35px 30px 20px;
			@include respond ('phone'){
				padding: 35px 30px 15px;				
			}
		}
		.dz-title{
			margin-bottom: 12px;
		}
		p{
		    font-size: 16px;
			margin-bottom: 20px;
		}
		.dz-media + .dz-info .dz-meta{
			position: absolute;
			top: -20px;
			left: 30px;
			z-index: 2;
			@include respond ('phone'){
				left: 15px;				
			}
		}
		.dz-meta{	
			ul li {
				margin-right: 10px;
				@include respond ('phone'){
					margin-right: 5px;					
				}
			}
		}
		.post-date,
		.post-author a{
			background-color: var(--primary);
			color: #fff;
			font-size: 14px;
			text-transform: uppercase;
			font-family: var(--font-family-title);
			padding: 8px 18px;
		}
		
		&.blog-lg{
			.dz-info{
				padding: 40px 35px 30px;
			}
			@include respond ('phone-land'){
				.dz-info {
					padding: 35px 30px 20px;
				}
			}
		}
		&.shadow{
			box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.10)!important;
			.dz-media{
				img{
					border-radius: 0;
				}
			}
			.btn{
				margin-bottom: 10px;
			}
		}
		&.blog-half{
			.dz-meta {
				position: unset!important;
			}
		}
		&.text-white .dz-title a{
			color: #fff;
			&:hover{
				color:var(--primary);
			}
		}
	}
}